export const drawer = {
    base: {
        background: "black",
        color: "white"
    },
    bottomgizmo: {
        base: {
            background: "#990000"
        },
        button: {
            background: "black",
            backgroundHover: "#990000",
            backgroundActive: "#990000",
            color: "red",
            colorHover: "white",
            colorActive: "white",
            height: "40px",
            margin: "0px 0px 0px 0px",
            padding: "0px 0px 0px 0px",
            cursor: "pointer",
            textalign: "center",
            width: "35px"
        },
        params: {
            fontsizeprimary: '16px',
            fontsizesecondary: '13px'
        }
    },
    button: {
        base: {
            background: "#191919", // NOTE: "heading button" is located at: main.modules.typography.drawer.base.background
            backgroundHover: "red",
            backgroundActive: "red",
            color: "red",
            colorHover: "black",
            colorActive: "black",
        },
        nested: {
            base: {
                background: "#333333",
                backgroundHover: "red",
                backgroundActive: "red",
                color: "red",
                colorHover: "black",
                colorActive: "black",
            },
            params: {
                fontweight: 100,
                fontweightHover: 900,
                paddingbottom: "5px",
                paddingleft: "15px",
                paddingright: "5px",
                paddingtop: "5px"         
            }
        },
        params: {
            fontweight: 100,
            fontweightHover: 900,
            paddingbottom: "5px",
            paddingleft: "5px",
            paddingright: "5px",
            paddingtop: "5px"         
        }
    },
    inner: {
        base: {
            background: "black",
            color: "white"
        },
        params: {
            paddingbottom: "20px",
            paddingleft: "2px",            
            paddingright: "5px",
            paddingtop: "20px" 
        }
    }
}
// [][][][][][][][][][][][][][][][][][][][][ START IMPORTS ][][][][][][][][][][][][][][][][][][][][][][][][][][][]
// ####################### GLOBAL ############################################################################
// Import from React
// eslint-disable-next-line
import { useState, useEffect } from 'react'

// Import Toast
// eslint-disable-next-line
import { toast } from 'react-toastify'

// Import Auth
import { useAuth } from '../../middleware/contextHooks'

// Import reactDOM
import { useNavigate } from 'react-router-dom'

// Import utils
//import { AuthCheck } from '../../middleware/utils'
// ####################### END OF GLOBAL #####################################################################

// ####################### SPECIFIC ##########################################################################
// Import globalStyles elements
//import { /*CustomContainer as Container*/ } from '../../themes/Darwinian2023/skeleton/global'

// Import Setting
import { useSetting } from '../../middleware/contextHooks'

// Import Icons
import LockOutlinedIcon from '@mui/icons-material/LockOutlined'
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined'
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined'

// Import from MUI
import {
    //Container,
    InputAdornment,
} from '@mui/material'

/*
// Import mainStyles
import { 
    Bar,
    CustomBox,
    /*CustomButton as Button,
    CustomGrid as Grid,
    CustomLink,
    CustomMuiTelInput as MuiTelInput,
    CustomStack as Stack,
    Heading,
    Para,    
    CustomTextField as TextField
} from '../../themes/Darwinian2023/skeleton/sys'
*/

// Import Addons
import { Spacer } from '../../components/Addon/Spacer'

// Import utils
import { settingLookup } from '../../middleware/utils'
// ####################### END OF SPECIFIC ##################################################################
// [][][][][][][][][][][][][][][][][][][][][ END IMPORTS ][][][][][][][][][][][][][][][][][][][][][][][][][][][][]

export default function Register (props) { 
    // [][][][][][][][][][][][][][][][][][][][][ START SETUP ][][][][][][][][][][][][][][][][][][][][][][][][][][]
    // ####################### GLOBAL ############################################################################
    // #region -------------[ Variables ]-------------------------------------------------------------------------
    // Get user logged
    // eslint-disable-next-line
    const {
        // Variables
        isAuthenticated,
        registerUser,
        RegisterFormIsDisabled,

        // Functions
        setIsRegistering,
        setRegisterFormIsDisabled
    } = useAuth()   

    // Set navigate
    const navigate = useNavigate()

    // #region -----------------[ State Variables ]---------------------------------------------------------------
    
    // #end region -----------------[ State Variables ]-----------------------------------------------------------
    // #endregion ------------------------------------------------------------------------------------------------
    // #region --------------( FUNCTIONS )------------------------------------------------------------------------
    
    // #endregion ------------------------------------------------------------------------------------------------
    // ####################### END OF GLOBAL #####################################################################

    // ####################### SPECIFIC ##########################################################################
    // #region -------------[ Variables ]-------------------------------------------------------------------------
    
    const {
        // Variables
        variables,

    } = useSetting()

    // Set default user
    const [user, setUser] = useState({
        alphaCode: '',
        firstName: '',
        lastName: '',
        userName: '', 
        phone: '',
        displayName: '',
        email: '',
        password: '', 
        confirmPassword: '',         
        
        /*
        alphaCode: 'TESTER2023',
        firstName: 'Test',
        lastName: 'Man',
        userName: 'TestMan', 
        phone: '',
        displayName: 'Mr. Test Man',
        email: 'test@man.com',
        password: 'Password123', 
        confirmPassword: 'Password123',         
        */
    })

    // Set showPassword
    const [showPassword, setShowPassword] = useState(false)

    // Set RegisterSent
    //const [ RegisterSent, setRegisterSent ] = useState(false)

    // eslint-disable-next-line
    const [ phone, setPhone ] = useState('')

    // #endregion -----------------------------------------------------------------------------------------------
    // #region -------------[ Functions ]------------------------------------------------------------------------
    // Handle Go to Dashboard
    const handleGotoDashboard = () => {
        navigate("/dashboard")
    }
    
    // Handle Register
    const handleRegister = () => {
        const { 
            alphaCode, betaCode, firstName, lastName, userName, email, password, phone, confirmPassword 
        } = user
        if(!firstName || !lastName || !userName || !email || !password || !phone || !confirmPassword) {
            toast('Please fill all the fields', {type: 'error'})
            return
        }

        if (settingLookup('systemMode', variables) === "alpha") {
            if(!alphaCode) {
                toast('During this time, we require an Alpha Code', {type: 'error'})
                return
            }
        }
        
        if (settingLookup('systemMode', variables) === "beta") {
            if (settingLookup('requireBetaCodeOnRegister', variables) === "yes") {
                if(!betaCode) {
                    toast('During this time, we require an Beta Code', {type: 'error'})
                    return
                }
            }
            
        }

        if (settingLookup('systemMode', variables) === "closed") {
            toast('Registration is closed.', {type: 'error'})
                return
            
        }

        if(password !== confirmPassword) {
            toast('Passwords do not match', {type: 'error'})
            return
        }

        // Set isDisabled (disable form)
        setRegisterFormIsDisabled(true)

        // Set Registering 
        setIsRegistering(true)

        // Register the user
        registerUser(user)          

        // DEPRECATED: Version <2.0.3 - Don't need to set Authed state as we aren't logging the user in; gotta email verify first. 
        // Auth them - this helps to check for on the page being authed
        //setAuthed(true)
    }

    const handleTelChange = (newPhone) => {
        setPhone(newPhone)

        // Set the user state
        setUser({...user, phone: newPhone})
    }
    
    useEffect(() => {
        if (isAuthenticated) {
            //console.log("isAuthenticated: "+isAuthenticated)
        }
    }, [ isAuthenticated ])
    
    // #endregion -----------------------------------------------------------------------------------------------
    // ####################### END OF SPECIFIC ##################################################################
    // [][][][][][][][][][][][][][][][][][][][][ ENDSETUP ][][][][][][][][][][][][][][][][][][][][][][][][][][][]

    // Render
    return (
        <>
        <Spacer length={4} />

        <props.skeleton.CustomBox nobg="true">
            <props.skeleton.CustomGrid container spacing={0} direction={'column'}>
                <props.skeleton.CustomGrid item sx={{ textAlign: 'center' }}>
                    <LockOutlinedIcon  />
                </props.skeleton.CustomGrid>
                <props.skeleton.CustomGrid item>
                    <props.skeleton.CustomGrid container>
                        <props.skeleton.CustomGrid item xs={.5} center="true" gem="true" nobg="true">
                            
                        </props.skeleton.CustomGrid>
                        <props.skeleton.CustomGrid item xs={11} center="true" gem="true" nobg="true">
                            <props.skeleton.CustomStack spacing={0}>
                                <props.skeleton.CustomItem nobg="true">
                                    <props.skeleton.Heading center h1>Register</props.skeleton.Heading>
                                    {
                                        !isAuthenticated
                                        ? (
                                            <>
                                            <props.skeleton.Heading h3 center>Join the best thing to happen to the internet!</props.skeleton.Heading>                    
                                            <props.skeleton.Para center>
                                                Well maybe not entirely the best thing to happen to the internet but probably the best thing to happen to us! This site is a new social experience built using a rock-solid Content Management System (CMS) called mpress that our developers decided to start building one day just because they felt like it.
                                            </props.skeleton.Para>
                                            <props.skeleton.Heading h3 center>What do I get for joining?</props.skeleton.Heading>
                                            <props.skeleton.Para center="true">
                                                    *Our undying gratitude and maybe a high-five.<br />
                                                    *FREE for life membership to a new social experience.<br />
                                                    *A rock-solid, one-of-a-kind content creation system.<br />
                                                    *Prestigious account branding that let's everyone know you were an early-adopter.<br />
                                                    *20,000 FREE coins to members that sign up using an Alpha code. (to be added upon completion of Update 4)
                                            </props.skeleton.Para>

                                            <props.skeleton.Heading h3 center>Really? What's the catch?</props.skeleton.Heading>
                                            <props.skeleton.Para center>
                                                No catch but we would like it if you could report any problems that may arise to our 
                                            </props.skeleton.Para>

                                            <props.skeleton.Heading h3 center>
                                                <props.skeleton.CustomLink href="https://discord.gg/H3EsRrPZBC" target="_blank">Discord</props.skeleton.CustomLink> 
                                            </props.skeleton.Heading>

                                            <props.skeleton.Para center>                            
                                                and we would like you to join us as we embark on this long journey with a long roadmap together to create a social experience.
                                            </props.skeleton.Para>

                                            <props.skeleton.Heading h3 center>
                                                When you are ready, please fill out the form to create your account!
                                            </props.skeleton.Heading>
                                            <form>
                                                <props.skeleton.CustomGrid container spacing={3}>
                                                    <props.skeleton.CustomGrid item xs={12}>
                                                        <props.skeleton.Heading h4 center>...</props.skeleton.Heading>
                                                    </props.skeleton.CustomGrid>               
                                                    
                                                    {
                                                        (settingLookup('systemMode', variables) === "alpha")
                                                        ? (
                                                            <props.skeleton.CustomGrid item xs={12}>
                                                                <props.skeleton.Bar>
                                                                    <props.skeleton.CustomTextField
                                                                        label='Alpha Code' 
                                                                        name='alphaCode' 
                                                                        value={user.alphaCode || ''} 
                                                                        disabled={RegisterFormIsDisabled}
                                                                        onChange={(e) => setUser({...user, alphaCode: e.target.value})}
                                                                    />
                                                                </props.skeleton.Bar>
                                                            </props.skeleton.CustomGrid>   
                                                        )
                                                        : null
                                                    }       
                                                    
                                                    {
                                                        (settingLookup('systemMode', variables) === "beta")
                                                        ? (
                                                            (settingLookup('requireBetaCodeOnRegister', variables) === "yes")
                                                            ? (
                                                                <props.skeleton.CustomGrid item xs={12}>
                                                                    <props.skeleton.Bar>
                                                                        <props.skeleton.CustomTextField
                                                                            label='Beta Code' 
                                                                            name='betaCode' 
                                                                            value={user.betaCode || ''} 
                                                                            disabled={RegisterFormIsDisabled}
                                                                            onChange={(e) => setUser({...user, betaCode: e.target.value})}
                                                                        />
                                                                    </props.skeleton.Bar>
                                                                </props.skeleton.CustomGrid>   
                                                            )
                                                            : null
                                                        )
                                                        : null
                                                    }                                                 

                                                    <props.skeleton.CustomGrid item xs={12}>
                                                        <props.skeleton.Bar>
                                                            <props.skeleton.CustomTextField
                                                                label='First Name' 
                                                                name='firstName' 
                                                                value={user.firstName} 
                                                                disabled={RegisterFormIsDisabled}
                                                                onChange={(e) => setUser({...user, firstName: e.target.value})}
                                                            />
                                                        </props.skeleton.Bar>
                                                    </props.skeleton.CustomGrid>

                                                    <props.skeleton.CustomGrid item xs={12}>
                                                        <props.skeleton.Bar>
                                                            <props.skeleton.CustomTextField
                                                                placeholder='Enter Your Last Name' 
                                                                name='lastName' 
                                                                label='Last Name' 
                                                                value={user.lastName} 
                                                                disabled={RegisterFormIsDisabled}
                                                                onChange={(e) => setUser({...user, lastName: e.target.value})}
                                                            />
                                                        </props.skeleton.Bar>
                                                    </props.skeleton.CustomGrid>

                                                    <props.skeleton.CustomGrid item xs={12}>
                                                        <props.skeleton.Bar>
                                                            <props.skeleton.CustomTextField
                                                                placeholder='Enter a Username' 
                                                                name='userName' 
                                                                label='Username' value={user.userName} 
                                                                disabled={RegisterFormIsDisabled}
                                                                onChange={(e) => setUser({...user, userName: e.target.value})}
                                                            />
                                                        </props.skeleton.Bar>
                                                    </props.skeleton.CustomGrid>

                                                    <props.skeleton.CustomGrid item xs={12}>
                                                        <props.skeleton.Bar>
                                                            <props.skeleton.CustomMuiTelInput
                                                                placeholder='Enter your phone number' 
                                                                name='phone' 
                                                                label='Phone'
                                                                value={user.phone}
                                                                disabled={RegisterFormIsDisabled}
                                                                onChange={handleTelChange}
                                                            />
                                                        </props.skeleton.Bar>
                                                    </props.skeleton.CustomGrid>

                                                    <props.skeleton.CustomGrid item xs={12}>
                                                        <props.skeleton.Bar>
                                                            <props.skeleton.CustomTextField
                                                                placeholder='(Optional) Display Name' 
                                                                name='displayName' 
                                                                label='Display Name' 
                                                                value={user.displayName} 
                                                                disabled={RegisterFormIsDisabled}
                                                                onChange={(e) => setUser({...user, displayName: e.target.value})}
                                                            />
                                                        </props.skeleton.Bar>
                                                    </props.skeleton.CustomGrid>

                                                    <props.skeleton.CustomGrid item xs={12}>
                                                        <props.skeleton.Bar>
                                                            <props.skeleton.CustomTextField
                                                                placeholder='Enter Your Email' 
                                                                name='email' 
                                                                label='Email' 
                                                                value={user.email} 
                                                                disabled={RegisterFormIsDisabled}
                                                                onChange={(e) => setUser({...user, email: e.target.value})}
                                                            />
                                                        </props.skeleton.Bar>
                                                    </props.skeleton.CustomGrid>

                                                    <props.skeleton.CustomGrid item xs={12}>
                                                        <props.skeleton.Bar>
                                                            <props.skeleton.CustomTextField
                                                                placeholder='Enter Password' name='password' 
                                                                label='Password' value={user.password} 
                                                                autoComplete='false'
                                                                disabled={RegisterFormIsDisabled}
                                                                type={showPassword.password ? 'text' : 'password'}
                                                                onChange={(e) => setUser({...user, password: e.target.value})}
                                                                InputProps={{
                                                                    endAdornment: <InputAdornment position="end" onClick={() => setShowPassword({...showPassword, password: !showPassword.password})}>
                                                                        {!showPassword.password ? <VisibilityOutlinedIcon /> : <VisibilityOffOutlinedIcon />}
                                                                    </InputAdornment>,
                                                                }}
                                                            />
                                                        </props.skeleton.Bar>
                                                    </props.skeleton.CustomGrid>

                                                    <props.skeleton.CustomGrid item xs={12}>
                                                        <props.skeleton.Bar>
                                                            <props.skeleton.CustomTextField
                                                                placeholder='Confirm Password' name='confirmPassword' 
                                                                autoComplete='false'
                                                                disabled={RegisterFormIsDisabled}
                                                                label='Confirm Password' value={user.confirmPassword} 
                                                                type={showPassword.confirmPassword ? 'text' : 'password'}
                                                                onChange={(e) => setUser({...user, confirmPassword: e.target.value})}
                                                                InputProps={{
                                                                    endAdornment: <InputAdornment position="end" onClick={() => setShowPassword({...showPassword, confirmPassword: !showPassword.confirmPassword})}>
                                                                        {!showPassword.confirmPassword ? <VisibilityOutlinedIcon /> : <VisibilityOffOutlinedIcon />}
                                                                    </InputAdornment>,
                                                                }}
                                                            />
                                                        </props.skeleton.Bar>
                                                    </props.skeleton.CustomGrid>
                                                    
                                                    <props.skeleton.CustomGrid item xs={12}>
                                                        
                                                    </props.skeleton.CustomGrid>  

                                                    <props.skeleton.CustomGrid item xs={12}>
                                                        {
                                                            !RegisterFormIsDisabled 
                                                            ? <props.skeleton.Bar button="true" padding20="true" center="true" onClick={handleRegister}>Register</props.skeleton.Bar>
                                                            : <props.skeleton.Bar button="true" padding20="true" center="true">Register</props.skeleton.Bar>                     
                                                        }
                                                    </props.skeleton.CustomGrid>                              
                                                </props.skeleton.CustomGrid>
                                            </form>

                                            <Spacer length={1} />
                                            
                                            <props.skeleton.CustomGrid container spacing={2}>
                                                <props.skeleton.CustomGrid item xs={12} right>
                                                    <props.skeleton.CustomLink noUnderLine="true" nopadding="true" href="/login">
                                                        Already have an account? Sign in
                                                    </props.skeleton.CustomLink>
                                                </props.skeleton.CustomGrid>

                                                <props.skeleton.CustomGrid item xs={12} right>
                                                    <props.skeleton.CustomLink noUnderLine="true" nopadding="true" href="/">
                                                        Or go back home.
                                                    </props.skeleton.CustomLink>
                                                </props.skeleton.CustomGrid>
                                            </props.skeleton.CustomGrid>
                                                
                                            </>
                                        )
                                        : (
                                            <>
                                                <props.skeleton.Heading center>You are currently logged in.</props.skeleton.Heading>
                                                <props.skeleton.Para center>
                                                    No need to register again...I suppose.
                                                </props.skeleton.Para>
                                                <props.skeleton.Bar button="true" padding20="true" center="true" onClick={handleGotoDashboard}>Take me to the Dashboard!</props.skeleton.Bar>

                                                <Spacer length={1} />
                                            
                                                <props.skeleton.CustomGrid container spacing={2}>
                                                    <props.skeleton.CustomGrid item xs={12} right>
                                                        <props.skeleton.Heading h2 right>
                                                            Quick Links
                                                        </props.skeleton.Heading>
                                                    </props.skeleton.CustomGrid>

                                                    <props.skeleton.CustomGrid item xs={12} right>
                                                        <props.skeleton.CustomLink nopadding noUnderLine href="/dashboard/account">
                                                            Go to My Account
                                                        </props.skeleton.CustomLink>
                                                    </props.skeleton.CustomGrid>
                                                    
                                                    <props.skeleton.CustomGrid item xs={12} right>
                                                        <props.skeleton.CustomLink nopadding noUnderLine href="/dashboard/profile">
                                                            Go to My Profile
                                                        </props.skeleton.CustomLink>
                                                    </props.skeleton.CustomGrid>

                                                    <props.skeleton.CustomGrid item xs={12} right>
                                                        <props.skeleton.CustomLink nopadding noUnderLine href="/">
                                                            Or go back home
                                                        </props.skeleton.CustomLink>
                                                    </props.skeleton.CustomGrid>
                                                </props.skeleton.CustomGrid>
                                            </>                                    
                                        ) 
                                    }    
                                </props.skeleton.CustomItem>                                    
                            </props.skeleton.CustomStack>
                        </props.skeleton.CustomGrid>
                        <props.skeleton.CustomGrid item xs={.5} center="true" gem="true" nobg="true">
                            
                        </props.skeleton.CustomGrid>
                    </props.skeleton.CustomGrid>
                </props.skeleton.CustomGrid>
            </props.skeleton.CustomGrid>
        </props.skeleton.CustomBox>
        </>
    )
}
// [][][][][][][][][][][][][][][][][][][][][ START IMPORTS ][][][][][][][][][][][][][][][][][][][][][][][][][][][]
// ####################### GLOBAL ############################################################################
// Import from React
// eslint-disable-next-line
import { useState, useEffect } from 'react'

// Import Toast
// eslint-disable-next-line
import { toast } from 'react-toastify'

// Import Auth
import { useAuth/*, useSetting*/ } from '../middleware/contextHooks'

// Import reactDOM
import { useNavigate } from 'react-router-dom'

// Import utils
//import { AuthCheck } from '../../middleware/utils'
// ####################### END OF GLOBAL #####################################################################

// ####################### SPECIFIC ##########################################################################
// Import globalStyles elements
//import { /*CustomContainer as Container*/ } from '../../themes/Darwinian2023/skeleton/global'

// Import from MUI
import {
    //Container,
} from '@mui/material'

// Import components
import { CallToAction } from '../components/Addon/CallToAction'
import { Singlet } from '../components/Addon/Singlet'

/*
// Import mainStyles
import { 
    //Bar,
    //CustomBox,
    //CustomButton as Button,
    //CustomGrid as Grid,
    //CustomLink,
    CustomStack as Stack,
    Heading,
    Para,    
} from '../../themes/Darwinian2023/skeleton/main'
*/

// Import components
import { BreadCrumbs } from '../components/BreadCrumbs'
import { Spacer } from '../components/Addon/Spacer'
//import { QueryList } from '../components/Addon/QueryList'

// Import Post
import { usePage } from '../middleware/contextHooks'

// Import partials
//import PartialNotFound from './PartialNotFound'

// ####################### END OF SPECIFIC ##################################################################
// [][][][][][][][][][][][][][][][][][][][][ END IMPORTS ][][][][][][][][][][][][][][][][][][][][][][][][][][][][]

export default function Other (props) { 
    // [][][][][][][][][][][][][][][][][][][][][ START SETUP ][][][][][][][][][][]'''''''''[][][][][][][][][][][][][][][][]
    // ####################### GLOBAL ############################################################################
    // #region -------------[ Variables ]-------------------------------------------------------------------------
    // Get user logged
    // eslint-disable-next-line
    const {clearErrors, currentUser, getProfile, getGuestProfile, getPublicProfile, isAuthenticated, toasts} = useAuth()   

    // Set navigate
    // eslint-disable-next-line
    const navigate = useNavigate()

    // #region --------------( FUNCTIONS )------------------------------------------------------------------------
    
    // #endregion ------------------------------------------------------------------------------------------------
    // ####################### END OF GLOBAL #####################################################################

    // ####################### SPECIFIC ##########################################################################
    // #region -------------[ Variables ]-------------------------------------------------------------------------
    // Set up breadcrumbs here
    const BCItems = [
        {
            'name': 'Home',
            'type': 'link',
            'grayed': true,
            "url": '/',
        }, 
        {
            'name': 'Other',
            'type': 'text',
            'grayed': true,
            'url': ''
        },
    ]

    // Use Page
    const {       
        
    } = usePage()

    let found = false
    // #endregion -----------------------------------------------------------------------------------------------
    // #region -------------[ Functions ]------------------------------------------------------------------------
    
    // #endregion -----------------------------------------------------------------------------------------------
    // ####################### END OF SPECIFIC ##################################################################
    // [][][][][][][][][][][][][][][][][][][][][ ENDSETUP ][][][][][][][][][][][][][][][][][][][][][][][][][][][]

    // Render
    if (found) {
        return (
            <>
            <Spacer length={5} />
    
            <BreadCrumbs list={BCItems} skeleton={props.skeleton} />

            <Singlet skeleton={props.skeleton} 
                color={'secondary'} // options: primary, secondary, tertiary, quarternary
                content={`Other page (loaded from database)`}
                padding={'50px 0px 50px 0px'} // Top Right Bottom Left
                textalign={'center'}
                title={`Tags`}
            />
    
            
            </>
        )
    } else {
        return (
        <>
        <CallToAction skeleton={props.skeleton}     
            abovebutton={10}
            belowbutton={5}
            abovetext={7}
            belowtext={5}
            bodytext={'it appears that the page you are looking for is in another dimension.'}
            bodytextalign={'left'}
            buttontext={'Take me home!'}
            buttoncolor={'primary'} // options: primary, secondary, tertiary, quarternary
            buttonurl={'/'}
            buttontoast={''}
            color={'primary'} // options: primary, secondary, tertiary, quarternary
            padding={1}
            primarytext={'Uh oh!'}
            primarytextalign={'left'}
            secondarytext={'...404 page not found'}
            secondarytextalign={'left'}
        />

        <Singlet skeleton={props.skeleton} 
            color={'secondary'} // options: primary, secondary, tertiary, quarternary
            content={`Good question! Apparently the page you are trying to access is no where in our dimension and am I displaying to show you something instead of nothing.`}
            padding={'50px 0px 50px 0px'} // Top Right Bottom Left
            textalign={'center'}
            title={`Why am I seeing this page?`}
        />

        <Singlet skeleton={props.skeleton} 
            color={'tertiary'} // options: primary, secondary, tertiary, quarternary
            content={`Maybe you fancy a gander at something else or you might want to take a walk outside for a bit.`}
            padding={'50px 0px 50px 0px'} // Top Right Bottom Left
            textalign={'center'}
            title={`WIP: Other resources instead`}
        />
        
        <Singlet skeleton={props.skeleton} 
            color={'quarternary'} // options: primary, secondary, tertiary, quarternary
            content={`WIP: Something else`}
            padding={'50px 0px 50px 0px'} // Top Right Bottom Left
            textalign={'center'}
            title={`WIP: I don't know what to put here.`}
        />
        </>
        )
    }
}
export const pagination = {
    base: {
        background: "#191919",
        backgroundHover: "lime",
        backgroundActive: "lime",
        color: "white",
        colorGray: 'gray',
        colorHover: "black",
        colorActive: "black",
        params: {
            padding: '10px'
        }
    },
    button: {
        base: {
            background: "#474747",
            backgroundHover: "white",
            backgroundActive: "white",
            color: "white",
            colorGray: 'gray',
            colorHover: "black",
            colorActive: "black",
            params: {
                padding: '0px 0px 0px 0px' // Top Right Bottom Left
            }
        }
    },
    buttonnotselected: {
        base: {
            background: "#474747",
            backgroundHover: "white",
            backgroundActive: "white",
            color: "white",
            colorGray: 'gray',
            colorHover: "black",
            colorActive: "black",
            params: {
                padding: '0px 0px 0px 0px' // Top Right Bottom Left
            }
        }
    }
}
export const footer = {
    base: {
        background: "pink",
        color: "black",
        fontsize: "25px",
        fontfamily: 'Rubik'
    },
    button: {
        background: 'pink',
        backgroundHover: 'violet',
        backgroundActive: 'violet',
        color: 'black',
        colorHover: 'black',
        colorActive: 'black',
        fontfamily: 'Rubik'
    },
    item: {
        base: {
            background: 'pink',
            backgroundHover: 'white',
            backgroundActive: 'white',
            color: 'black',
            colorHover: 'black',
            colorActive: 'black',
            fontfamily: 'Rubik'
        },
        full: {
            background: 'violet',
            backgroundHover: 'white',
            backgroundActive: 'white',
            color: 'black',
            colorHover: 'black',
            colorActive: 'black',
            fontfamily: 'Rubik'
        }
    },
    full: {
        background: "pink",
        color: "white",
        fontsize: "25px",
        fontfamily: 'Rubik'
    }
}
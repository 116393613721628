import {createContext, useReducer} from 'react'
import axios from 'axios'
import settingReducer from './settingReducer'
import * as ActionTypes from '../ContextActions'

export const SettingContext = createContext()

export default function SettingState(props){
    const initialstate = {
        currentSetting: null,
        isUpdatingSettingSent: false,
        settings: null,
        settingCreated: false,
        SettingsEditing: false,
        SettingsFormIsDisabled: true,
        sliceItemDisplay: null,
        sliceItemPostTypeDisplay: null,

        variables: [],

        toastsSetting: null        
    }

    const [state, dispatch] = useReducer(settingReducer, initialstate)

    const config = {
        headers: {
            'Content-Type': 'application/json',
            'x-auth-token': localStorage.getItem('token'),
        }
    }

    // #region --------------[ Actions ]--------------

    const clearCurrentSetting = () =>{
        dispatch({
            type: ActionTypes.CLEAR_CURRENT_SETTING
        })
    }

    const clearErrorsSetting = async () => {
        dispatch({
            type: ActionTypes.CLEAR_ERRORS_SETTING,
        })
    }

    const clearSettings = async () => {
        dispatch({
            type: ActionTypes.CLEAR_SETTINGS
        })
    }
    
    const getSettingsPublically = async (limit = null, namespace = null, stat = null, order = 'DESC', orderBy = 'createdAt') => {
        try {
            const parameters = {
                limit: limit,
                namespace: namespace,
                stat: stat,
                sort: order,
                sortBy: orderBy
            }

            const res = await axios.post(`/api/v1/settings/public`, parameters, config)
            dispatch({
                type: ActionTypes.GET_SETTINGS,
                payload: res.data
            })
        } catch (err) {
            //console.log(err.response.data)
            dispatch({
                type: ActionTypes.GET_SETTINGS_FAIL,
                payload: err.response.data,
            })
        }
    }

    const getSettingByName = async (name) => {
        try {
            const res = await axios.get(`/api/v1/setting/public/${name}`, config)
            dispatch({
                type: ActionTypes.GET_SETTING_BYNAME,
                payload: res.data
            })
        } catch (err) {
            //console.log(err.response.data)
            dispatch({
                type: ActionTypes.GET_SETTING_BYNAME_FAIL,
                payload: err.response.data,
            })
        }
    }

    const updateSetting = async (section, name, value, status = 'active', namespace = 'local') => {
        const parameters = {
            name: name,
            namespace: namespace,
            value: value,
            status: status,
            section: section
        }

        try {
            const res = await axios.put(`/api/v1/setting/update/${name}`, parameters, config)
            dispatch({
                type: ActionTypes.UPDATE_SETTING,
                payload: res.data
            })
        } catch (err) {
            //console.log(err.response.data)
            dispatch({
                type: ActionTypes.UPDATE_SETTING_FAIL,
                payload: err.response.data,
            })
        }
    }

    const updateSliceItemToUserListDisplay = async (listDisplay) => {
        try {
            //console.log(JSON.stringify({ "listDisplay": listDisplay }))
            const res = await axios.put(`/api/v1/user/updateListDisplay`, JSON.stringify({ "listDisplay": listDisplay }), config)
            dispatch({
                type: ActionTypes.UPDATE_SLICEITEMDISPLAY,
                payload: res.data
            })
        } catch (err) {
            dispatch({
                type: ActionTypes.UPDATE_SLICEITEMDISPLAY_ERROR,
                payload: err.response.data
            })
        }
    }
    
    const updateSliceItemToUserListPostTypeDisplay = async (listPostTypeDisplay) => {
        try {
            //console.log(JSON.stringify({ "listPostTypeDisplay": listPostTypeDisplay }))
            const res = await axios.put(`/api/v1/user/updateListPostTypeDisplay`, JSON.stringify({ "listPostTypeDisplay": listPostTypeDisplay }), config)
            dispatch({
                type: ActionTypes.UPDATE_SLICEITEMPOSTTYPEDISPLAY,
                payload: res.data
            })
        } catch (err) {
            dispatch({
                type: ActionTypes.UPDATE_SLICEITEMPOSTTYPEDISPLAY_ERROR,
                payload: err.response.data
            })
        }
    }

    // #region -------------------[ SETTERS ]---------------------------------------------------------------------------------------------------
    const setIsUpdatingSettingSent = async (value) => {
        dispatch({
            type: ActionTypes.SET_SETTINGS_IS_UPDATING_SETTING_SENT,
            payload: value
        })
    }
    
    const setSettingsFormIsDisabled = async (value) => {
        dispatch({
            type: ActionTypes.SET_SETTINGS_FORM_IS_DISABLED,
            payload: value
        })
    }

    const setSettingsEditing = async (value) => {
        dispatch({
            type: ActionTypes.SET_SETTINGS_EDITING,
            payload: value
        })
    }
    
    const setSliceItemDisplay = (value) =>{
        dispatch({
            type: ActionTypes.SET_SLICEITEMDISPLAY,
            payload: value
        })
    }
    
    const setSliceItemPostTypeDisplay = (value) =>{
        dispatch({
            type: ActionTypes.SET_SLICEITEMPOSTTYPEDISPLAY,
            payload: value
        })
    }

    const setVariables = (valueName, valueValue) =>{
        dispatch({
            type: ActionTypes.SET_VARIABLES,
            payload: [valueName, valueValue]
        })
    }
    // #endregion ------------------------------------------------------------------------------------------------------------------------------

    

    // #endregion

    return (
        <SettingContext.Provider value={{
            currentSetting: state.currentSetting,
            isUpdatingSettingSent: state.isUpdatingSettingSent,
            settings: state.settings,
            settingCreated: state.settingCreated,
            SettingsEditing: state.SettingsEditing,
            SettingsFormIsDisabled: state.SettingsFormIsDisabled,
            sliceItemDisplay: state.sliceItemDisplay,
            sliceItemPostTypeDisplay: state.sliceItemPostTypeDisplay,
            toastsSetting: state.toastsSetting,
            variables: state.variables,            
            
            clearCurrentSetting,            
            clearErrorsSetting,
            clearSettings,
            getSettingByName,
            getSettingsPublically,
            setIsUpdatingSettingSent,
            setSettingsEditing,
            setSettingsFormIsDisabled,
            setSliceItemDisplay,
            setSliceItemPostTypeDisplay,
            setVariables,
            updateSetting,
            updateSliceItemToUserListDisplay,
            updateSliceItemToUserListPostTypeDisplay 
        }}>
            {props.children}
        </SettingContext.Provider>
    )
}
export const menu = {
    base: {
        background: "#00cc00",
        color: "black"
    }, 
    arrow: {
        base: {
            background: "#00cc00",        
        },
        params: {
            height: "10px",
            right: "14px",
            top: "0px",     
            transform: {
                rotate: "45deg",
                translatey: "-50%"
            }         
        }
    },
    item: {
        base: {
            background: "#00b300",
            backgroundHover: "#00b300",
            color: "black",
            colorHover: "black"
        },
        params: {
            padding: "10px"
        }
    },
    params: {
        minwidth: "150px",
        padding: "0px 10px 5px 10px" // top right bottom left
    }
}
export const gem = {
    base: {
        primary: {
            background: "#191919",
        }, 
        secondary: {
            background: "#333333",
        },
        tertiary: {
            background: "#4d4d4d",
        },
        quarternary: {
            background: "black",
        }
    }, 
    button: {
        base: {
            background: "white",
            backgroundHover: "#8b0000",
            backgroundActive: "white",
            color: "#ff4d4d",
            colorHover: "white",
            colorActive: "white",
        },
        primary: {
            background: "red",
            backgroundHover: "#ff9999",
            backgroundActive: "#ff9999",
            color: "white",
            colorHover: "black",
            colorActive: "black",
        },
        secondary: {
            background: "violet",
            backgroundHover: "pink",
            backgroundActive: "pink",
            color: "white",
            colorHover: "black",
            colorActive: "black",
        },
        tertiary: {
            background: "lime",
            backgroundHover: "black",
            backgroundActive: "black",
            color: "black",
            colorHover: "lime",
            colorActive: "lime",
        },
        quarternary: {
            background: "white",
            backgroundHover: "cyan",
            backgroundActive: "cyan",
            color: "black",
            colorHover: "black",
            colorActive: "black",
        }
    },
    card: {
        background: "#191919",
        backgroundHover: "#8b0000",
        backgroundActive: "white",
        color: "white",
        colorHover: "white",
        colorActive: "white",
    }
}
// [][][][][][][][][][][][][][][][][][][][][ START IMPORTS ][][][][][][][][][][][][][][][][][][][][][][][][][][][]
// ####################### GLOBAL ############################################################################
// Import from React
import { useState, useEffect } from 'react'

// Import axios
//import axios from 'axios'

// Import Toast
// eslint-disable-next-line
import { toast } from 'react-toastify'

// Import Auth
import { useAuth } from '../../middleware/contextHooks'

// Import reactDOM
import { useNavigate } from 'react-router-dom'
// ####################### END OF GLOBAL #####################################################################

// ####################### SPECIFIC ##########################################################################
// Import globalStyles elements
import { /*CustomContainer as Container*/ } from '../../themes/Darwinian2023/skeleton/global'

// Import from MUI
import {
    Avatar,
    //Button,
} from '@mui/material'

/*
// Import mainStyles
import { 
    Bar,
    //Card,
    //CardActions,
    //CardContent,
    //CardHeader,
    //CustomBox,
    //CustomButton as Button,
    CustomGrid as Grid,
    CustomItem as Item,
    //CustomLink,
    CustomStack as Stack,
    CustomTextField as TextField,
    //Heading,
    //Para,    
    //CustomTextField as TextField
} from '../../themes/Darwinian2023/skeleton/main'
*/

//import ReactHtmlParser from 'react-html-parser'

// Import Icons
//import EditIcon from '@mui/icons-material/Edit'
import LoginIcon from '@mui/icons-material/Login'
import LogoutIcon from '@mui/icons-material/Logout'
//import SendIcon from '@mui/icons-material/Send'
import BluetoothIcon from '@mui/icons-material/Bluetooth'
import NotificationsIcon from '@mui/icons-material/Notifications'
import NotificationsOffIcon from '@mui/icons-material/NotificationsOff'

// #region -----------------------[ Functions ]-------------------------------------------------------------------

// #endregion ----------------------------------------------------------------------------------------------------
// ####################### END OF SPECIFIC ##################################################################
// [][][][][][][][][][][][][][][][][][][][][ END IMPORTS ][][][][][][][][][][][][][][][][][][][][][][][][][][][][]

function loadUserImg (userName, image, settingServerAPIBaseUrl ) {
    return settingServerAPIBaseUrl+"user/file/byuser/"+userName+"/"+image
}

export function ProfileGizmo (props) { 
    // [][][][][][][][][][][][][][][][][][][][][ START SETUP ][][][][][][][][][][][][][][][][][][][][][][][][][][]
    // ####################### GLOBAL ############################################################################
    // #region -------------[ Variables ]-------------------------------------------------------------------------
    // Get user logged
    // eslint-disable-next-line
    const {
        //AccountEditing,
        //AccountFormIsDisabled,
        currentUser, 
        //isAuthenticated, 
        logoutUser,
        //setAccountEditing,
        //setAccountFormIsDisabled,
        //updateUser
    } = useAuth()   

    // Set navigate
    // eslint-disable-next-line
    const navigate = useNavigate()

    // #region --------------( FUNCTIONS )------------------------------------------------------------------------
    
    // #endregion ------------------------------------------------------------------------------------------------
    // ####################### END OF GLOBAL #####################################################################

    // ####################### SPECIFIC ##########################################################################
    // #region -------------[ Variables ]-------------------------------------------------------------------------
    // eslint-disable-next-line
    const [ updateStatusSent, setUpdateStatusSent ] = useState(false)

    // Set a temp state to be used with updating profile from gizmo
    //const [temp, setTemp] = useState(null)
    const [notifications, setNotifications] = useState(null)
    // #endregion -----------------------------------------------------------------------------------------------
    // #region -------------[ Functions ]------------------------------------------------------------------------  
    const handleUnknown = (path) => {
        toast.info('I lost the game.', {theme: 'dark'})
    }

    const handleLogoutUser = (path) => {
        logoutUser()
        navigate(path)
    }

    const handleNotifications = (notifications) => {
        if (notifications) {
            toast.info('Notifications turned on. WIP: JK.', {theme: 'dark'})
            setNotifications(false)
        } else {
            toast.info('Notifications turned off. WIP: JK.', {theme: 'dark'})
            setNotifications(true)            
        }
    }
    
    // State Rendering
    useEffect(() => {
        if (notifications == null) {
            setNotifications(true)
        }
        // eslint-disable-next-line
    }, [ notifications ])

    // State management for debugging
    useEffect(() => {
        
    }, [  ])
    // #endregion -----------------------------------------------------------------------------------------------
    // ####################### END OF SPECIFIC ##################################################################
    // [][][][][][][][][][][][][][][][][][][][][ ENDSETUP ][][][][][][][][][][][][][][][][][][][][][][][][][][][]

    // Render
    return (
        <>
        {
        // #region -----[ Module: ProfileGizmo ]----------------------------------------------------------------------------------------
        }
        <props.skeleton.CustomStack 
            bottomgizmo="true"
            spacing={0}
            direction={'column'}
            justifyContent={'center'}
            alignItems={'stretch'}                        
            sx={{ 
                //border: 'thin solid black',
            }}
        >
            <props.skeleton.CustomItem bottomgizmo>
                <props.skeleton.CustomStack
                    bottomgizmo="true"
                    spacing={1}
                    direction={'row'}
                    justifyContent={'space-between'}
                    alignItems={'stretch'}                        
                    sx={{ 
                        //border: 'thin solid black',
                    }}
                >
                    
                    <props.skeleton.CustomItem bottomgizmo>
                        {
                        // #region -----[ Section: Nougat ]----------------------------------------------------------------------------------------
                        }   
                        <props.skeleton.CustomStack
                            bottomgizmo="true"
                            spacing={0}
                            direction={'row'}
                            justifyContent={'space-between'}
                            alignItems={'stretch'}                        
                            sx={{ 
                                //border: 'thin solid black',
                                width: '101%',
                                maxWidth: '101%'
                            }}
                        >
                            {
                            // #region -----[ Section: Item #1: Profile Icon ]-----------------------------------------------------------------------------
                            }   
                            <props.skeleton.CustomItem bottomgizmo>
                                <props.skeleton.CustomStack
                                    bottomgizmo="true"
                                    spacing={0}
                                    direction={'column'}
                                    justifyContent={'space-between'}
                                    alignItems={'stretch'}
                                    sx={{ 
                                        //border: 'thin solid black',
                                        height: '100%',
                                        justifyContent: 'center'
                                    }}
                                >
                                    <Avatar alt={currentUser?.displayName} sx={{ width: "40px", height: "40px" }} src={
                                        (currentUser?.profileImage !== "" && currentUser?.profileImage !== "no-profileimage.png")
                                        ? loadUserImg(currentUser?.userName, currentUser?.profileImage, props.settingServerAPIBaseUrl)
                                        : window.location.origin+"/user/thumbs/no-profileimage.png"                                        
                                    } />    
                                </props.skeleton.CustomStack>
                            </props.skeleton.CustomItem>
                            {
                            // #endregion -----[ Section: Item #1: Profile Icon ]--------------------------------------------------------------------------
                            }

                            {
                            // #region -----[ Section: Item #2: Details ]----------------------------------------------------------------------------------
                            }   
                            <props.skeleton.CustomItem justifyContent={'center'} bottomgizmo>
                                <props.skeleton.CustomStack
                                    bottomgizmo="true"
                                    spacing={0}
                                    direction={'column'}
                                    justifyContent={'space-between'}
                                    alignItems={'stretch'}                        
                                    sx={{ 
                                        //border: 'thin solid black',
                                        height: '100%',
                                    }}
                                >
                                    <props.skeleton.CustomItem bottomgizmo bottomgizmodetailsprimary>
                                        {
                                            (currentUser)
                                            ? (currentUser?.userName?.length <= 9) ? currentUser?.userName : currentUser?.userName?.substr(0, 10)+"..."
                                            : "Guestererr"
                                        }
                                    </props.skeleton.CustomItem>

                                    <props.skeleton.CustomItem bottomgizmo bottomgizmodetailssecondary>
                                        {
                                            (currentUser)
                                            ? (currentUser?.status?.length <= 14) ? currentUser?.status : currentUser?.status?.substr(0, 13)+"..."
                                            : "In the Code..."
                                        }
                                    </props.skeleton.CustomItem>
                                </props.skeleton.CustomStack>
                            </props.skeleton.CustomItem>
                            {
                            // #endregion -----[ Section: Item #2: Details ]-------------------------------------------------------------------------------
                            }    
                            
                            {
                            // #region -----[ Section: Item #3: Buttons ]----------------------------------------------------------------------------------
                            } 
                            <props.skeleton.CustomItem justifyContent={'center'} bottomgizmo>
                                <props.skeleton.CustomStack
                                    bottomgizmo="true"
                                    spacing={0}
                                    direction={'row'}
                                    justifyContent={'center'}
                                    alignItems={'center'}                        
                                    sx={{ 
                                        //border: 'thin solid black',
                                        height: '100%'
                                    }}
                                >
                                    <props.skeleton.CustomItem>
                                        {
                                            (currentUser)
                                            ? <props.skeleton.Bar bottomgizmobutton="true" onClick={() => handleUnknown()}><BluetoothIcon /></props.skeleton.Bar>                                        
                                            : <props.skeleton.Bar bottomgizmobutton="true" onClick={() => handleUnknown()}></props.skeleton.Bar>                                        
                                        }
                                    </props.skeleton.CustomItem>

                                    <props.skeleton.CustomItem>
                                        {
                                            (currentUser)
                                            ? <props.skeleton.Bar bottomgizmobutton="true" onClick={() => handleNotifications(notifications)}>{(notifications) ? <NotificationsOffIcon /> : <NotificationsIcon />}</props.skeleton.Bar>                                        
                                            : <props.skeleton.Bar bottomgizmobutton="true" onClick={() => handleUnknown()}></props.skeleton.Bar>                                        
                                        }
                                    </props.skeleton.CustomItem>

                                    <props.skeleton.CustomItem right>
                                        {
                                            (currentUser)
                                            ? <props.skeleton.Bar bottomgizmobutton="true" onClick={() => handleLogoutUser("/login")}><LogoutIcon /></props.skeleton.Bar>                                        
                                            : <props.skeleton.Bar bottomgizmobutton="true" onClick={() => handleLogoutUser("/login")}><LoginIcon /></props.skeleton.Bar>                                        
                                        }
                                    </props.skeleton.CustomItem>
                                </props.skeleton.CustomStack>
                            </props.skeleton.CustomItem>
                            {
                            // #endregion -----[ Section: Item #3: Buttons ]--------------------------------------------------------------------------
                            } 
                        </props.skeleton.CustomStack>
                        {
                        // #endregion -----[ Section: Nougat ]----------------------------------------------------------------------------------------
                        }
                    </props.skeleton.CustomItem>
                </props.skeleton.CustomStack>
            </props.skeleton.CustomItem>
           
        </props.skeleton.CustomStack>
        {
        // #endregion -----[ Module: ProfileGizmo ]----------------------------------------------------------------------------------------
        }
        </>
    )
}
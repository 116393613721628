// [][][][][][][][][][][][][][][][][][][][][ START IMPORTS ][][][][][][][][][][][][][][][][][][][][][][][][][][][]
// ####################### GLOBAL ############################################################################
// Import from React
// eslint-disable-next-line
import { useState, useEffect } from 'react'

// Import axios
//import axios from 'axios'

// Import Toast
// eslint-disable-next-line
import { toast } from 'react-toastify'

// Import Auth
import { useAuth } from '../middleware/contextHooks'

// Import reactDOM
import { useNavigate } from 'react-router-dom'

// Import utils
// eslint-disable-next-line
import { AuthCheck } from '../middleware/utils'
// ####################### END OF GLOBAL #####################################################################

// ####################### SPECIFIC ##########################################################################
// Import globalStyles elements
import { /*CustomContainer as Container*/ } from '../themes/Darwinian2023/skeleton/global'

// Import from MUI
import {
    //Button,
} from '@mui/material'

/*
// Import mainStyles
import { 
    //Bar,
    //Card,
    //CardActions,
    //CardContent,
    //CardHeader,
    //CustomBox,
    //CustomButton as Button,
    CustomGrid as Grid,
    //CustomLink,
    //CustomStack as Stack,
    //Heading,
    //Para,    
    //CustomTextField as TextField
} from '../../themes/Darwinian2023/skeleton/main'
*/

//import ReactHtmlParser from 'react-html-parser'
// ####################### END OF SPECIFIC ##################################################################
// [][][][][][][][][][][][][][][][][][][][][ END IMPORTS ][][][][][][][][][][][][][][][][][][][][][][][][][][][][]

export function Row (props) { 
    // [][][][][][][][][][][][][][][][][][][][][ START SETUP ][][][][][][][][][][][][][][][][][][][][][][][][][][]
    // ####################### GLOBAL ############################################################################
    // #region -------------[ Variables ]-------------------------------------------------------------------------
    // Get user logged
    // eslint-disable-next-line
    const {clearErrors, currentUser, getProfile, getGuestProfile, getPublicProfile, isAuthenticated, toasts} = useAuth()   

    // Set navigate
    // eslint-disable-next-line
    const navigate = useNavigate()

    // #region -----------------[ State Variables ]---------------------------------------------------------------
    // Set Authed
    //const [ Authed, setAuthed ] = useState(false)

    // Set LoggingIn
    // eslint-disable-next-line
    //const [ LoggingIn, setLoggingIn ] = useState(false)

    // Set profile
    // eslint-disable-next-line
    //const [profile, setProfile] = useState({})

    // Set Updating
    // eslint-disable-next-line
    //const [ Updating, setUpdating ] = useState(false)
    // #end region -----------------[ State Variables ]-----------------------------------------------------------

    // #endregion ------------------------------------------------------------------------------------------------
    // #region --------------( FUNCTIONS )------------------------------------------------------------------------
    
    // #endregion ------------------------------------------------------------------------------------------------
    // ####################### END OF GLOBAL #####################################################################

    // ####################### SPECIFIC ##########################################################################
    // #region -------------[ Variables ]-------------------------------------------------------------------------
    
    // #endregion -----------------------------------------------------------------------------------------------
    // #region -------------[ Functions ]------------------------------------------------------------------------   
    // State Rendering
    useEffect(() => {
        
    }, [ ])
    // #endregion -----------------------------------------------------------------------------------------------
    // ####################### END OF SPECIFIC ##################################################################
    // [][][][][][][][][][][][][][][][][][][][][ ENDSETUP ][][][][][][][][][][][][][][][][][][][][][][][][][][][]

    // Render

    return (
        <>
        {
            props.type === "mobile" &&
            (               
                <props.skeleton.CustomGrid item xs={(props.width) ? props.width : 10 } sx={{ height: props.height, display: { xs: 'block', sm: 'block', md: 'none', lg: 'none' } }} bgcolor={props.bgcolor}>
                    { props.children }
                </props.skeleton.CustomGrid>
            )
        }

        {
            props.type === "desktop" && (
                <props.skeleton.CustomGrid item xs={(props.width) ? props.width : 10 } sx={{ height: props.height,  display: { xs: 'none', sm: 'none', md: 'block', lg: 'block' } }} bgcolor={props.bgcolor}>
                    { props.children }
                </props.skeleton.CustomGrid>
            )
        }

        {
            !props.type && (
                <props.skeleton.CustomGrid item xs={(props.width) ? props.width : 12 } sx={{ height: props.height }} bgcolor={props.bgcolor}>
                    { props.children }
                </props.skeleton.CustomGrid>
            )
        }
        </>
    )
}
// [][][][][][][][][][][][][][][][][][][][][ START IMPORTS ][][][][][][][][][][][][][][][][][][][][][][][][][][][]
// ####################### GLOBAL ############################################################################
// Import from React
// eslint-disable-next-line
import { useState, useEffect, useContext, useCallback } from 'react'

// Import Toast
// eslint-disable-next-line
import { toast } from 'react-toastify'

// Import Auth
// eslint-disable-next-line
import { useAuth } from '../../middleware/contextHooks'

// Import reactDOM
import { useNavigate } from 'react-router-dom'

// Import utils
//import { AuthCheck } from '../../middleware/utils'
// ####################### END OF GLOBAL #####################################################################

// ####################### SPECIFIC ##########################################################################
// Import globalStyles elements
// eslint-disable-next-line
//import { CustomContainer as Container } from '../../themes/Darwinian2023/skeleton/global'

// Import from MUI
import { 
     
} from '@mui/material'

/*
// Import mainStyles
import { 
    Bar,
    Bold,
    //CustomBox as Box,
    //CustomButton as Button,
    //CustomGrid as Grid,
    CustomItem as Item,
    CustomLink,
    //CustomStack as Stack,
    Heading,
    Para,    
} from '../../themes/Darwinian2023/skeleton/main'
*/

// Import from Components
//import { Col } from '../../components/Col'
//import { InnerCol } from '../../components/InnerCol'
//import { Row } from '../../components/Row'

// Import Addon Components
import { CallToAction } from '../../components/Addon/CallToAction'
import { QueryList } from '../../components/Addon/QueryList'
import { Singlet } from '../../components/Addon/Singlet'
import { Spacer } from '../../components/Addon/Spacer'
import { Triplet } from '../../components/Addon/Triplet'

// Import Post
import { usePost, useSetting } from '../../middleware/contextHooks'

// Import utils
import { settingLookup } from '../../middleware/utils'

// ####################### END OF SPECIFIC ##################################################################
// [][][][][][][][][][][][][][][][][][][][][ END IMPORTS ][][][][][][][][][][][][][][][][][][][][][][][][][][][][]



export default function Home (props) { 
    // [][][][][][][][][][][][][][][][][][][][][ START SETUP ][][][][][][][][][][][][][][][][][][][][][][][][][][]
    // ####################### GLOBAL ############################################################################
    // #region -------------[ Variables ]-------------------------------------------------------------------------
    // eslint-disable-next-line
    const {
        //currentUser, 
        //getProfile, 
        //getGuestProfile, 
        //getPublicProfile, 
        //isAuthenticated
    } = useAuth()      

    // Set navigate
    // eslint-disable-next-line
    const navigate = useNavigate()

    /* DEPRECATED < 2.8.1 (???): Trying to load context outside the component to prevent re-renders
    */
    // Use Post
    const {       
        // Variables
        currentSlice,
        featuredslices,   
        
        // Functions
        getAllFeaturedSlices,
        setCurrentSlice
    } = usePost()

    // #region --------------( FUNCTIONS )------------------------------------------------------------------------
    
    // #endregion ------------------------------------------------------------------------------------------------
    // ####################### END OF GLOBAL #####################################################################

    // ####################### SPECIFIC ##########################################################################
    // #region -------------[ Variables ]-------------------------------------------------------------------------
    const {
        variables
    } = useSetting()
    
    // #endregion -----------------------------------------------------------------------------------------------
    // #region -------------[ Functions ]------------------------------------------------------------------------

    // State management for setting currentSlice to null
    useEffect(() => {
        // Update 2.6.4 : Fix for currentSlice not updating til the next render
        if (currentSlice) {
            setCurrentSlice(null)
        }
    }, [ 
        currentSlice,
        setCurrentSlice
    ])

    // State management for getting the posts
    useEffect(() => {
        //getAllFeaturedSlices('anyposts', 20, 'global','published', 'DESC', 'createdAt')
        
        // Get a live update of posts
        if (!featuredslices) {
            getAllFeaturedSlices('anyposts', 20, 'global','published', 'DESC', 'createdAt')             
        } else {
            setTimeout(() => {
                getAllFeaturedSlices('anyposts', 20, 'global','published', 'DESC', 'createdAt') 
            }, 10000)
        }
        
        
        // NOTE: I don't know if getAllFeaturedSlices needs to be in dependancy array, use this not show the missing dependancy warning
        // eslint-disable-next-line
    }, [
        featuredslices,
    ])

    // State management for debugging
    useEffect(() => {
        //if (variables) console.log(variables)
        // eslint-disable-next-line
    }, [ 
        variables
     ])

    // #endregion -----------------------------------------------------------------------------------------------
    // ####################### END OF SPECIFIC ##################################################################
    // [][][][][][][][][][][][][][][][][][][][][ ENDSETUP ][][][][][][][][][][][][][][][][][][][][][][][][][][][]

    // Render
    return (
        <>
        <Spacer />

        <CallToAction skeleton={props.skeleton}     
            abovebutton={10}
            belowbutton={5}
            abovetext={7}
            belowtext={7}
            bodytext={settingLookup('SiteDescription', variables)}
            bodytextalign={'left'}
            buttontext={settingLookup('homepageCallToActionButtonText', variables)}
            buttoncolor={'primary'} // options: primary, secondary, tertiary, quarternary
            buttonurl={'/register'}
            buttontoast={''}
            color={settingLookup('homepageCallToActionBackgroundOption', variables)} // options: primary, secondary, tertiary, quarternary
            padding={1}
            primarytext={settingLookup('SiteTitle', variables)}
            primarytextalign={'left'}
            secondarytext={settingLookup('SiteSlogan', variables)}
            secondarytextalign={'left'}
        />
        
        <QueryList skeleton={props.skeleton} 
            abovebutton={1}
            belowbutton={1}
            abovetext={1}
            belowtext={1}
            color={'quarternary'} // options: primary, secondary, tertiary, quarternary            
            bodytext={settingLookup('homepageQueryListBodyText', variables)}             
            buttontext={'See more'}
            buttoncolor={'primary'} // options: primary, secondary, tertiary, quarternary
            buttonurl={'/posts'}
            buttontoast={''}
            data={featuredslices}      
            padding={'20px 0px 20px 0px'} // Top Right Bottom Left       
            primarytext={settingLookup('homepageQueryListPrimaryText', variables)}
            primarytextalign={'left'}
            secondarytext={settingLookup('homepageQueryListSecondaryText', variables)}
            secondarytextalign={'left'}
            settingServerAPIBaseUrl={props.settingServerAPIBaseUrl}
            textalign={'center'}
            uriMap="post"  
        />

        <CallToAction skeleton={props.skeleton}            
            abovebutton={10}
            belowbutton={7}
            abovetext={6}
            belowtext={7}
            bodytext={settingLookup('homepageCallToActionAboutBodyText', variables)}
            bodytextalign={'left'}
            buttontext={settingLookup('homepageCallToActionAboutButtonText', variables)}
            buttoncolor={settingLookup('homepageCallToActionAboutButtonBackgroundOption', variables)} // options: primary, secondary, tertiary, quarternary
            buttonurl={'/about'}
            buttontoast={''}
            color={settingLookup('homepageCallToActionAboutBackgroundOption', variables)} // options: primary, secondary, tertiary, quarternary
            padding={parseInt(settingLookup('homepageCallToActionAboutPadding', variables))}
            primarytext={settingLookup('homepageCallToActionAboutPrimaryText', variables)}
            primarytextalign={'left'}
            secondarytext={settingLookup('homepageCallToActionAboutSecondaryText', variables)}
            secondarytextalign={'left'}
        />

        <Singlet skeleton={props.skeleton} 
            color={'secondary'} // options: primary, secondary, tertiary, quarternary
            content={`
                Did you know that strawberries aren't berries but bananas are? Don't believe me? <a href='https://stanfordmag.org/contents/bananas-are-berries' target='_blank'>Look it up</a>. 
            `}
            padding={'50px 0px 50px 0px'} // Top Right Bottom Left
            textalign={'left'}
            title={'OneUI (one-column) layout'}
        />

        <Triplet skeleton={props.skeleton} 
            leftcolumncontent={`
                2<br />
                3<br />
                5<br />
                7<br />
                11<br />
                13<br />
                17<br />
            `}

            centercolumncontent={`
                19<br />
                23<br />
                29<br />
                31<br />
                37<br />
                41<br />
                43<br />
                47<br />
                53<br />
                59<br />
                61<br />
                67<br />
                71<br />
                73<br />                
            `}

            padding={'50px 0px 50px 0px'} // Top Right Bottom Left

            rightcolumncontent={`
                79<br />
                83<br />
                89<br />
                97<br />
                101<br />
                103<br />
                107<br />
                109<br />
                113<br />
                127<br />
                131<br />
                137<br />
                139<br />
                149<br />
                151<br />
                157<br />
                163<br />
                167<br />
                173<br />
                179<br />
                181<br />
                191<br />
                193<br />
                197<br />
                199<br />
                211<br />
                223<br />
                227
            `}
        />

        <Singlet skeleton={props.skeleton} 
            color={'secondary'} // options: primary, secondary, tertiary, quarternary
            content={`
                Be forewarned that this site includes many bugs and is provided "as-is".<br />If you see a problem please report it to our <a href='https://discord.gg/8e9YsF5mXK' target='_blank'>Discord</a>
            `}
            padding={'50px 0px 50px 0px'} // Top Right Bottom Left
            textalign={'center'}
            title={'OneUI (one-column) layout<br />WARNING: Work In Progress (WIP)'}
        />

        <Singlet skeleton={props.skeleton} 
            color={'tertiary'} // options: primary, secondary, tertiary, quarternary
            content={`
                Ancestry<br />
                BillNye.com<br />
                Google<br />
                StackOverflow<br />
                Goodle<br />
                Facebook<br />
                Library<br />
                Minecraft.com<br />
                NeverGunnaGiveYouUpRadio.podcast<br />
                Noname.com<br />
                Thething.com<br />
                Twitch<br />                                                                
            `}
            padding={'50px 0px 50px 0px'} // Top Right Bottom Left
            textalign={'center'}
            title={'OneUI (one-column) layout<br />Here are the amazing sites that use our system'}
        />

        </>
    )
}
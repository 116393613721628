export const NavItems = [
    /*
    {
        name: "Logout", 
        type: "single-level",
        partialPage: {
            "func": "HandleLogoutUser",
        },
        path: "/",
        icon: "HomeIcon",
        displayTitle: true, // WIP: To determine whether to show the heading for this section of the nav
        children: [
            {
                
            },            
        ]                          
    },
    */
   /*
    {
        name: "Home", 
        type: "single-level",
        partialPage: "Home",
        path: "/",
        icon: "HomeIcon",
        displayTitle: true, // WIP: To determine whether to show the heading for this section of the nav
        children: [
            {
                id: 1,
                name: "",
                partialPage: "",
                path: "/",
                icon: "Icon"
            },            
        ]                          
    },*/
    {
        name: "General", 
        type: "multi-level",
        partialPage: "",
        path: "",
        icon: "HomeIcon",
        displayTitle: true, // WIP: To determine whether to show the heading for this section of the nav
        children: [
            {
                id: 1,
                name: "Overview",
                partialPage: "Overview",
                path: "/dashboard",
                icon: "AnalyticsIcon"
            },
            /*
            {         
                id: 2,
                name: "MyVar",
                partialPage: {                    
                    "func": "HandleLogoutUser"
                },
                path: "/dashboard",
                icon: "LogoutIcon"
                
            },
            */
        ]                          
    },
    {
        name: "Post", 
        type: "multi-level",
        partialPage: "",
        path: "",
        icon: "BookIcon",
        children: [
            {
                id: 1,
                name: "All Posts",
                partialPage: "All",
                path: "/dashboard/posts",
                icon: "LibraryBooksIcon"
            },
            {
                id: 2,
                name: "Create New Post",
                partialPage: "New",
                path: "/dashboard/posts/new",
                icon: "PostIcon"
            },
            {
                id: 3,
                name: "Categories",
                partialPage: "All",
                path: "/dashboard/categories",
                icon: "CategoryIcon"
            },
            {
                id: 5,
                name: "Tags",
                partialPage: "All",
                path: "/dashboard/tags",
                icon: "TagIcon"
            },            
        ]
    },
    {
        name: "Settings", 
        type: "multi-level",
        partialPage: "",
        path: "",
        icon: "SettingsSuggestIcon",
        children: [
            {
                id: 1,
                name: "Global Settings",
                partialPage: "SettingsGlobal",
                path: "/dashboard/settings/global",
                icon: "AdminPanelSettingsIcon"
            },
            {
                id: 2,
                name: "All Codes",
                roleReq: ["admin", "superadmin"],
                partialPage: "All",
                path: "/dashboard/codes",
                icon: "CodeIcon"
            },
            {
                id: 3,
                name: "New Code",
                roleReq: ["admin", "superadmin"],
                partialPage: "New",
                path: "/dashboard/codes/new",
                icon: "LibraryAddIcon"
            },
            {
                id: 4,
                name: "User Settings",
                partialPage: "SettingsUser",
                path: "/dashboard/settings/user",
                icon: "TuneIcon"
            },                
        ]
    },
    {
        name: "User", 
        type: "multi-level",
        partialPage: "",
        path: "",
        icon: "PersonIcon",
        children: [
            {
                id: 1,
                name: "Account",
                partialPage: "Account",
                path: "/dashboard/account",
                icon: "ManageAccountsIcon"
            },
            {
                id: 2,
                name: "Profile",
                partialPage: "Profile",
                path: "/dashboard/profile",
                icon: "ProfileIcon"
            },
        ]
    }
]
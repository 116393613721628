export const item = {
    base: {
        background: "white",
    },
    bottomgizmo: {
        background: "black"
    },
    full: {
        background: 'red'
    }
}
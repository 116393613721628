// [][][][][][][][][][][][][][][][][][][][][ START IMPORTS ][][][][][][][][][][][][][][][][][][][][][][][][][][][]
// ####################### GLOBAL ############################################################################
// Import from React
// eslint-disable-next-line
import { useState, useEffect, useCallback, useMemo } from 'react'

// Import Toast
// eslint-disable-next-line
// eslint-disable-next-line
import { toast } from 'react-toastify'

// Import Auth
// eslint-disable-next-line
import { useAuth } from '../middleware/contextHooks'

// Import reactDOM
// eslint-disable-next-line
import { useNavigate } from 'react-router-dom'

// Import utils
// eslint-disable-next-line
import { DeTemps } from '../middleware/utils'
// ####################### END OF GLOBAL #####################################################################

// ####################### SPECIFIC ##########################################################################
// Import globalStyles elements
//import { /*CustomContainer as Container*/ } from '../themes/Darwinian2023/skeleton/global'

// Import Icons

// Import from MUI
import {
    
} from '@mui/material'

/*
// Import mainStyles
import { 
    CustomBox as Box,
    CustomGrid as Grid,
    //CustomItem as Item,
    //CustomStack as Stack,
} from '../themes/Darwinian2023/skeleton/dash'
*/

// Import DropZone
import {useDropzone} from 'react-dropzone'

//import axios from 'axios'
// ####################### END OF SPECIFIC #######################################################################
// [][][][][][][][][][][][][][][][][][][][][ END IMPORTS ][][][][][][][][][][][][][][][][][][][][][][][][][][][][]

// #region ----------------[ Outside Functions ]------------------------------------------------------------------

// #endregion -------------[ Outside Functions ]------------------------------------------------------------------

// #region ----------------[ Outside Variables ]------------------------------------------------------------------
var baseStyle = {
    display: 'flex',
    height: '100%',
    width: '100%',
    fontSize: '18px',
    fontWeight: '700',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    borderWidth: 1,
    borderRadius: 2,
    borderColor: '#eeeeee',
    borderStyle: 'dashed',
    backgroundColor: 'cyan',
    color: '#000000',
    transition: 'border .3s ease-in-out'
}

const activeStyle = {
    backgroundColor: '#2196f3',
    width: '100%'
}

const acceptStyle = {
    backgroundColor: '#00e676',
}

const rejectStyle = {
    backgroundColor: '#ff1744'
}
// #endregion -------------[ Outside Variables ]------------------------------------------------------------------

export function UploadDragZone(props) { 
    // [][][][][][][][][][][][][][][][][][][][][ START SETUP ][][][][][][][][][][][][][][][][][][][][][][][][][][]
    // ####################### GLOBAL ############################################################################
    // #region -------------[ Variables ]-------------------------------------------------------------------------
    // Set the resources from context
    const resources = props.resources

    // Set the user from props
    const user = props.user || [{userName: 'guest'}]

    const img = props.img
    
    const what = props.what

    const query = props.query

    let extra

    const userObject = (what === "headerimage" || what === "profileimage") ? user : user[0]
    
    //console.log(userObject)

    // #region -----------------[ State Variables ]---------------------------------------------------------------
    
    // #endregion --------------[ State Variables ]---------------------------------------------------------------

    // #endregion ------------------------------------------------------------------------------------------------
    // ####################### END OF GLOBAL #####################################################################

    // ####################### SPECIFIC ##########################################################################
    // #region -------------[ Variables ]-------------------------------------------------------------------------
    
    if (props.constraints) {
        baseStyle.height = props.constraints.height
        baseStyle.width = props.constraints.width
    }

    // Add the onDrop to handle when files are dropped
    const onDrop = useCallback(acceptedFiles => {
        // Debugging: display acceptedFiles
        //console.log(acceptedFiles)        

        // Determine what is being uploaded

        switch (props.section) {
            case 'anypost':
                switch (props.what) {
                    case 'featuredimage':
                        //console.log("Uploading file for longpost: featuredimage")
                        // eslint-disable-next-line
                        extra = {
                            ...extra,
                            postslug: props.postslug
                        }
                    break

                    default: 
                        //console.log("Uploading file for longpost: unknown")
                    break
                }                
            break

            case 'user':
                switch (props.what) {
                    case 'profileimage':
                        //console.log("Uploading file for account: profile")
                    break

                    case 'headerimage':
                        //console.log("Uploading file for account: header")
                    break

                    default:
                        //console.log("Uploading file for account: unknown")
                    break
                }
            break

            default:
                switch (props.what) {
                    case '':

                    break

                    default:
                        //console.log("Uploading file for unknown: unknown")
                    break
                }
            break
        }

        // Setup acceptedFiles with more parameters
        acceptedFiles.map(file => Object.assign(file, {
            preview: URL.createObjectURL(file),
        }))

        const data = new FormData()
        
        data.append("name", acceptedFiles[0]?.name)
        data.append("file", acceptedFiles[0])

        //console.log(data)

        /* SEND TO HTTPBIN - testing
        axios.post("https://httpbin.org/anything", data)
            //.then(res => console.log(res))
            .then((res) => {
                // Debug: display res
                console.log(res)
            })
            .catch(err => console.log())
        */

        // Send files to backend api
        resources.uploadFile(query, {section: props.section, what: props.what, extra: extra}, data)

        //resources.setUploadFiles(acceptedFiles.map(file => Object.assign(file, {
        //    preview: URL.createObjectURL(file),
        //    section: props.section,
        //    what: props.what
        //})))

        // Debug: Display preview blob url
        //console.log(acceptedFiles[0].preview)

    }, [ extra, props.section, props.what, query, resources ])

    // The basic useDropzone component
    const {
        getRootProps,
        getInputProps,
        isDragActive,
        isDragAccept,
        isDragReject
    } = useDropzone({
        onDrop,
        accept: {
            'image/*': ['.jpeg', '.jpg', '.png']
        }
    })

    // Set up style
    const style = useMemo(() => ({
        ...baseStyle,
        ...(isDragActive ? activeStyle : {}),
        ...(isDragAccept ? acceptStyle : {}),
        ...(isDragReject ? rejectStyle : {})
    }), [
        isDragActive,
        isDragReject,
        isDragAccept
    ])

    // Set up preview window
    // eslint-disable-next-line
    const thumbs = resources?.uploadFiles?.map(file => (
        <div key={file.name}>
            <img 
                src={file.preview}
                alt={file.name}
                width='100%'
            />
        </div>
    ))

    // Cleanup - to avoid memory leaks, to avoid storing the preview unnecessarily
    //useEffect(() => {
    //    resources.uploadFiles?.forEach(file => URL.revokeObjectURL(file.preview))
    //}, [ resources.uploadFiles ])

    // #region -----------------[ State Variables ]---------------------------------------------------------------    
    const [ image, setImage ] = useState(null)
    // #end region -------------[ State Variables ]---------------------------------------------------------------
    // #end region ----------------[ Variables ]------------------------------------------------------------------
    
    // #endregion ------------------------------------------------------------------------------------------------
    // #region -------------[ Functions ]-------------------------------------------------------------------------
    
    // State rendering for image
    useEffect(() => {
        if (resources?.currentSlice?.imageUrl && !image) {
            //console.log(resources.currentSlice?.imageUrl)
            setImage(resources.currentSlice?.imageUrl)
        }
    }, [ image, resources, setImage ])

    // State rendering for debugging
    useEffect(() => {
        //console.log("isUpdatingSlice: "+resources.isUpdatingSlice)
        //console.log("isCreatingSlice: "+resources.isCreatingSlice)
        //console.log("SliceFormIsDisabled: "+resources.SliceFormIsDisabled)
        //console.log(user?.userName)
        
    }, [ resources ])

    // #endregion -----------------------------------------------------------------------------------------------
    // ####################### END OF SPECIFIC ##################################################################
    // [][][][][][][][][][][][][][][][][][][][][ ENDSETUP ][][][][][][][][][][][][][][][][][][][][][][][][][][][]

    // Render
    return (
        <>
        <props.skeleton.CustomGrid container>
            {
                //(thumbs)
                //? (
                //    <Grid item xs={12}>
                //        {thumbs}
                //    </Grid>
                //)
                //: (
                    <props.skeleton.CustomGrid item xs={12}>
                        {
                            (userObject?.userName !== "guest")
                            ? (                                
                                (img !== "no-"+what+".png" && img !== "")
                                ? (
                                    <>
                                    <props.skeleton.CustomBox
                                        component="img"
                                        sx={{
                                            height: baseStyle.height,
                                            width: baseStyle.width,
                                            maxHeight: { xs: baseStyle.height, sm: baseStyle.height, md: baseStyle.height, lg: baseStyle.height },
                                            maxWidth: { xs: baseStyle.width, sm: baseStyle.width, md: baseStyle.width, lg: baseStyle.width },
                                        }}
                                        alt={ userObject?.userName+"'s "+what}
                                        src={`${props.settingServerAPIBaseUrl}${query}/file/byuser/${userObject?.userName}/`+img}
                                    />
                                    </>
                                )
                                : (
                                    <>
                                    <props.skeleton.CustomBox
                                        component="img"
                                        sx={{
                                            height: baseStyle.height,
                                            width: baseStyle.width,
                                            maxHeight: { xs: baseStyle.height, sm: baseStyle.height, md: baseStyle.height, lg: baseStyle.height },
                                            maxWidth: { xs: baseStyle.width, sm: baseStyle.width, md: baseStyle.width, lg: baseStyle.width },
                                        }}
                                        alt={user?.userName+"'s "+what}
                                        src={window.location.origin+"/"+props.section+"/thumbs/no-"+what+".png"}
                                    />
                                    </>
                                )
                            )
                            :
                            null
                        }
                    </props.skeleton.CustomGrid>
                //)
            }

            {
                (resources?.SliceFormIsDisabled || resources?.AccountFormIsDisabled) 
                ? <>
                    <props.skeleton.CustomGrid item xs={12}>
                        <i>Upload disabled, click on the title to enable it.</i>
                    </props.skeleton.CustomGrid>
                  </>
                : (
                    <>
                    <props.skeleton.CustomGrid item xs={12}>
                        &nbsp;
                    </props.skeleton.CustomGrid>
                    <props.skeleton.CustomGrid item xs={12}>
                        <div {...getRootProps({style})}>
                            <input {...getInputProps()} />
                            {isDragAccept && (
                                <p>Looks good!</p>
                            )}

                            {isDragReject && (
                                <p>Wrong file type!</p>
                            )}

                            {!isDragActive && (
                                <p>Drag 'n' Drop a file here, or click to select file</p>
                            )}            
                        </div>
                    </props.skeleton.CustomGrid>   
                    </>
                )
            }                     
        </props.skeleton.CustomGrid>
        </>
    )
}
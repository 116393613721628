export const drawer = {
    base: {
        background: "green",
        color: "white"
    },
    bottomgizmo: {
        base: {
            background: "lime"
        },
        button: {
            background: "pink",
            backgroundHover: "cyan",
            backgroundActive: "cyan",
            color: "black",
            colorHover: "black",
            colorActive: "black",
            height: "40px",
            margin: "0px 0px 0px 0px",
            padding: "0px 0px 0px 0px",
            cursor: "pointer",
            textalign: "center",
            width: "35px"
        },
        params: {
            fontsizeprimary: '16px',
            fontsizesecondary: '13px'
        }
    },
    button: {
        base: {
            background: "lime",
            backgroundHover: "black",
            backgroundActive: "black",
            color: "black",
            colorHover: "lime",
            colorActive: "lime",
        },
        nested: {
            base: {
                background: "lime",
                backgroundHover: "black",
                backgroundActive: "black",
                color: "black",
                colorHover: "lime",
                colorActive: "lime",
            },
            params: {
                fontweight: 100,
                fontweightHover: 900,
                paddingbottom: "5px",
                paddingleft: "15px",
                paddingright: "5px",
                paddingtop: "5px"         
            }
        },
        params: {
            fontweight: 100,
            fontweightHover: 900,
            paddingbottom: "5px",
            paddingleft: "5px",
            paddingright: "5px",
            paddingtop: "5px"
        }
    },
    inner: {
        base: {
            background: "lime",
            color: "black"
        },
        params: {
            paddingbottom: "20px",
            paddingleft: "2px",            
            paddingright: "2px",
            paddingtop: "20px"            
        }
    }
}
export const navbar = {
    base: {
        background: "red"
    },
    button: {
        background: "red",
        backgroundHover: "darkred",
        backgroundActive: "darkred",
        color: "black",
        colorHover: "white",
        colorActive: "white",
        underbar: {
            params: {
                border: "thin solid #990001",
                borderwidth: "0em 0em 0.125em .25em"
            }
        }
    },
    drawer: {
        button: {
            background: "red",
            backgroundHover: "white",
            backgroundActive: "white",
            color: "black",
            colorHover: "black",
            colorActive: "black"
        },
    },
    params: {
        height: '10px'
    }
}
// [][][][][][][][][][][][][][][][][][][][][ START IMPORTS ][][][][][][][][][][][][][][][][][][][][][][][][][][][]
// ####################### GLOBAL ############################################################################
// Import from React
import { useState, useEffect } from 'react'

// Import Toast
// eslint-disable-next-line
import { toast } from 'react-toastify'

// Import Auth
import { useAuth } from '../../middleware/contextHooks'

// Import reactDOM
import { useNavigate } from 'react-router-dom'

// Import utils
// eslint-disable-next-line
import { DeTemps } from '../../middleware/utils'
// ####################### END OF GLOBAL #####################################################################

// ####################### SPECIFIC ##########################################################################
// Import globalStyles elements
//import { /*CustomContainer as Container*/ } from '../../themes/Darwinian2023/skeleton/global'

// Import Icons
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined'
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined'

// Import from MUI
import {
    //Avatar,
    //Container,
    InputAdornment,
    Switch,
    //Typography
} from '@mui/material'

/*
// Import mainStyles
import { 
    Bar,
    CustomBox as Box,
    CustomGrid as Grid,
    CustomItem as Item,
    //CustomLink,
    CustomStack as Stack,
    CustomMuiTelInput as MuiTelInput,
    //Heading,
    //Para,
    CustomTab as Tab,
    CustomTabs as Tabs,
    CustomTextField as TextField
} from '../../themes/Darwinian2023/skeleton/dash'
*/

// Import BreadCrumbs
//import { BreadCrumbs } from '../../components/BreadCrumbs'

// Import proptypes for tabs
import { PropTypes } from 'prop-types'

// Import DragZone component
import { UploadDragZone } from '../../components/UploadDragZone'
import { Spacer } from '../../components/Addon/Spacer'

import { BreadCrumbs } from '../../components/BreadCrumbs'
// ####################### END OF SPECIFIC #######################################################################
// [][][][][][][][][][][][][][][][][][][][][ END IMPORTS ][][][][][][][][][][][][][][][][][][][][][][][][][][][][]

// #region ----------------[ Outside Functions ]------------------------------------------------------------------
// Function for creating tabpanel
function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`vertical-tabpanel-${index}`}
        aria-labelledby={`vertical-tab-${index}`}
        {...other}
      >
        {value === index && (
          <props.skeleton.CustomBox sx={{ p: 0, width: '100%' }}>
            <props.skeleton.CustomBox sx={{ width: '100%' }}>{children}</props.skeleton.CustomBox>
          </props.skeleton.CustomBox>
        )}
      </div>
    )
}

// Set the propsTypes for tabpanel
TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
}
// #endregion -------------[ Outside Functions ]------------------------------------------------------------------
function loadUserProfileImage (userName, image, settingServerAPIBaseUrl ) {
    return settingServerAPIBaseUrl+"user/file/byuser/"+userName+"/"+image
}
// #region ----------------[ Outside Variables ]------------------------------------------------------------------

// #endregion -------------[ Outside Variables ]------------------------------------------------------------------

export default function Account (props) { 
    // [][][][][][][][][][][][][][][][][][][][][ START SETUP ][][][][][][][][][][][][][][][][][][][][][][][][][][]
    // ####################### GLOBAL ############################################################################
    // #region -------------[ Variables ]-------------------------------------------------------------------------
    // Get user logged
    // eslint-disable-next-line
    const {
        // Variables
        AccountFormIsDisabled,
        currentUser, 
        isPremium,
        // Functions
        CheckIsPremium,
        setAccountEditing,    
        setAccountFormIsDisabled,
        setAccountUpdating,
        setCurrentUser,
        updateUser
    } = useAuth()   

    // Set navigate
    // eslint-disable-next-line
    const navigate = useNavigate()

    // #region -----------------[ State Variables ]---------------------------------------------------------------
    // Set Authed
    //const [ Authed, setAuthed ] = useState(false)

    // Set LoggingIn
    //const [ LoggingIn, setLoggingIn ] = useState(false)

    // Set profile - set from the props to control things here
    //const [profile, setProfile] = useState({})

    // Set Updating
    //const [ Updating, setUpdating ] = useState(false)
    // #endregion -----------------[ State Variables ]------------------------------------------------------------
    // eslint-disable-next-line
    const [ phone, setPhone ] = useState('')
    // #endregion ------------------------------------------------------------------------------------------------
    // #region --------------( FUNCTIONS )------------------------------------------------------------------------
    const handleTelChange = (newPhone) => {
        setPhone(newPhone)

        // Set the user state
        setTemp({...temp, phone: newPhone})
    }
    // #endregion ------------------------------------------------------------------------------------------------
    // ####################### END OF GLOBAL #####################################################################

    // ####################### SPECIFIC ##########################################################################
    // #region -------------[ Variables ]-------------------------------------------------------------------------
    
    // #region -----------------[ State Variables ]---------------------------------------------------------------    
    // Set state for profile image
    // eslint-disable-next-line
    const [ files, setFiles ] = useState([])

    // Set imageUrl
    // eslint-disable-next-line
    const [image, setImage] = useState(null)

    // Set isDisabled for form state - DEPRECATED
    //const [isDisabled, setIsDisabled] = useState(true)

    // Set password field states
    const [showCPassword, setShowCPassword] = useState(false)
    const [showNPassword, setShowNPassword] = useState(false)
    const [showCNPassword, setShowCNPassword] = useState(false)

    // Set private switch state
    const [privateSwitchChecked, setPrivateSwitchChecked] = useState(false)

    // Set tabValue
    const [tabValue, setTabValue] = useState(0)

    // Set a temp state
    const [temp, setTemp] = useState(null)
    // #end region -----------------[ State Variables ]------------------------------------------------------------
    // #end region -----------------[ Variables ]--------------------------------------------------------------------------------------------

    // UPDATE 2.4.71 : Load contexts "conditionally"
    // Set up the slice
    var slice = props.tertiary

    // Set up Modules
    // eslint-disable-next-line
    const Modules = props.Modules

    // Set up variables
    let resources
    let query

    // Set all the resources from the contexts
    const resourcesAuth = useAuth()

    // Conditional setting resources
    switch (slice) {
        case 'Account':
            query="user"
            resources=resourcesAuth
        break
        default: 
            /* ??? No resource; what are we doing here? */
        break
    }

    // Set up breadcrumbs here
    const BCItems = [
        {
            'name': 'Dashboard',
            'type': 'link',
            'grayed': true,
            "url": '/dashboard',
        }, 
        {
            'name': 'User',
            'type': 'link',
            'grayed': true,
            'url': '/dashboard/account'
        },
        {
            'name': 'Account',
            'type': 'text',
            'grayed': false,
            'url': '/dashboard/account'
        },
    ]
    
    // Set up dropzone
    /*const { getRootProps, getInputProps } = useDropzone({
        accept: {
            "image/*": ['.jpeg','.jpg','.png']
        },
        onDrop: (acceptedFiles) => {
            setFiles(
                acceptedFiles.map((file) => Object.assign(file, {
                    preview: URL.createObjectURL(file)
                }))
            )
        }
    })
    */

    // Create images for dropzone
    /*const images = files.map((file) => (
        <div key={file.name}>
            <div>
                <img src={file.preview} style={{ width: "200px"}} alt="preview" />
            </div>
        </div>
    ))
    */

    // Handle for changing tab
    const handleTabChange = (e, newValue) => {
        setTabValue(newValue)
    }

    const handlePrivateSwitchChange = e => {
        //profile.isPrivate = e.target.checked // Version 1.3.8: Issue/Enhancement Implementation: https://github.com/serafirim/MERNStackStarter/issues/5
        setTemp({...temp, isPrivate: e.target.checked})
        //alert(profile.isPrivate)
        setPrivateSwitchChecked(e.target.checked)
    }
    
    const handleCancel = e => {
        setAccountEditing(false)
        setAccountFormIsDisabled(true)
    }
    
    const handleDeleteAccount = e => {
        toast.info('WIP: Delete Account', { theme: 'light' })
    }

    const handleUpgradeAccount = e => {
        toast.info('WIP: Upgrade to premium account', { theme: 'light' })
    }

    const handleEmailVerify = e => {
        toast.info('WIP: Verify Email - send code', { theme: 'light' })
    }

    const handleEditing = e => {
        // Set editing mode
        setAccountEditing(true)

        // Disable form
        setAccountFormIsDisabled(false)

        // Set temp
        setTemp(currentUser)
    }

    const handleUpdate = e => {
        // Set profile to temp - deprecated
        //setProfile(temp)

        // Set currentUser to temp
        setCurrentUser(temp)

        // Disable form
        //setAccountFormIsDisabled(true)

        // No longer editing
        //setAccountEditing(false)

        // Tell the system we are updating
        setAccountUpdating(true)

        // Update 
        updateUser(temp)
        
        // Set temp to null
        //setTemp(null)
    }

    const handleVerifyAccount = e => {
        toast.info('WIP: Verify account', { theme: 'light' })
    }

    // Get userLogged
    //const {updateUser} = useAuth()    

    // #endregion ------------------------------------------------------------------------------------------------
    // #region -------------[ Functions ]-------------------------------------------------------------------------
    
    // Do I need this? Used for tab panel
    function allyProps(index) {
        return {
          id: `vertical-tab-${index}`,
          'aria-controls': `vertical-tabpanel-${index}`,
        }
    }

    // State rendering for switches
    useEffect(() => {
        if (temp) {
            setPrivateSwitchChecked(temp?.isPrivate)
        } else {
            setPrivateSwitchChecked(currentUser?.isPrivate)
        }
        // eslint-disable-next-line
    }, [ currentUser?.isPrivate ])

    // State rendering for image adding
    useEffect(() => {
        if (resources.profileImage) {
            // DEBUG: Display profileImage
            //console.log("profileImage: "+resources.profileImage)

            resources.setAccountProfileImageAdding(true)

            // Set the temp
            setTemp({ ...temp, profileImage: resources.profileImage })
        }

        if (resources.headerImage) {
            // DEBUG: Display headerImage
            //console.log("headerImage: "+resources.headerImage)

            resources.setAccountHeaderImageAdding(true)

            // Set the temp
            setTemp({ ...temp, headerImage: resources.headerImage })
        }
        // eslint-disable-next-line
    }, [ resources ])

    // State management for checking premium
    useEffect(() => {
        if (currentUser && isPremium == null) {
            CheckIsPremium(currentUser?.userName)
        }
        // eslint-disable-next-line        
    }, [ CheckIsPremium, currentUser ])

    /* DEPRECATED < 2.6.7
    useEffect(() => {
        if (files) {            
            // Set imageUrl state
            setImage(files[0]?.name)

            // Save to the profile state
            // Broken? some thing funky
            setTemp({...temp, image: files[0]?.name})
        }
        // eslint-disable-next-line
    }, [ files ])
    */

    // State rendering for debugging
    useEffect(() => {
        //console.log("---[ Debugging ]---")
        //console.log(resources.AccountProfileImageAdding)
        //console.log("AccountEditing: "+AccountEditing)
        //console.log("AccountFormIsDisabled: "+AccountFormIsDisabled)
        //console.log("AccountUpdating: "+AccountUpdating)
        //console.log("AccountUpdatingSent: "+AccountUpdatingSent)
        //console.log("image: "+image)
        //console.log("isDisabled: "+isDisabled)
        //console.log("Profile: "+profile.firstName)   
        //console.log("profile image: "+temp?.image)     
        //console.log("Toasts: "+toasts)
        //console.log("Updating: "+Updating)
        //console.log("UpdateSent: "+UpdateSent)  
    }, [ 
        //resources, 
        //AccountEditing, 
        //AccountFormIsDisabled, 
        //AccountUpdating, 
        //AccountUpdatingSent
     ])

    // #endregion -----------------------------------------------------------------------------------------------
    // ####################### END OF SPECIFIC ##################################################################
    // [][][][][][][][][][][][][][][][][][][][][ ENDSETUP ][][][][][][][][][][][][][][][][][][][][][][][][][][][]

    // Render
    return (
        <>
        <props.skeleton.InnerContent paddingleft20paddingright10>
            <Spacer length={4} />

            <props.skeleton.CustomStack spacing={0}>
                <BreadCrumbs list={BCItems} skeleton={props.skeleton} />

                <props.skeleton.CustomGrid container spacing={2} sx={{  }}>
                    <props.skeleton.CustomGrid item xs={12}>
                        <props.skeleton.CustomGrid container justify={'flex-start'} alignItems={'flex-start'} spacing={0} sx={{  }}>
                            <props.skeleton.CustomGrid item xs={12} md={3}  sx={{  }}>
                                <props.skeleton.CustomBox sx={{ width: {xs: '100%', sm: '100%', md: '100%', lg: '100%'}, height: '100%' }}>
                                    <props.skeleton.CustomStack>
                                        <props.skeleton.CustomStack sx={{  }} direction="column" alignItems="center">
                                            <props.skeleton.CustomItem nobg="true">
                                                &nbsp;
                                            </props.skeleton.CustomItem>
                                            <props.skeleton.CustomItem nobg="true">
                                                &nbsp;
                                            </props.skeleton.CustomItem>
                                            <props.skeleton.CustomItem nobg="true">
                                                {
                                                    (currentUser?.profileImage && (currentUser?.profileImage !== "no-profileimage.png" && currentUser?.profileImage !== ""))
                                                    ? (
                                                        <props.skeleton.CustomAvatar 
                                                        alt={currentUser?.userName} 
                                                        sx={{ width: "200px", height: "200px" }} 
                                                        src={loadUserProfileImage (currentUser?.userName, currentUser?.profileImage, props.settingServerAPIBaseUrl )} 
                                                        />
                                                    )
                                                    : (
                                                        <props.skeleton.CustomAvatar 
                                                        alt={currentUser?.userName+"'s profile image"} 
                                                        sx={{ width: "200px", height: "200px" }} 
                                                        src={window.location.origin+"/user/thumbs/no-profileimage.png"} 
                                                        />
                                                    )
                                                }
                                            </props.skeleton.CustomItem>
                                            <props.skeleton.CustomItem nobg="true">
                                                <props.skeleton.CustomTypography variant="h4">{currentUser?.firstName+" "+currentUser?.lastName}</props.skeleton.CustomTypography>
                                            </props.skeleton.CustomItem>
                                            <props.skeleton.CustomItem nobg="true">
                                                &nbsp;
                                            </props.skeleton.CustomItem>
                                            <props.skeleton.CustomItem nobg="true">
                                                &nbsp;
                                            </props.skeleton.CustomItem>                                    
                                        </props.skeleton.CustomStack>

                                        {/* #region ---------[ Tabs - Desktop ]--------------------------------------------------------------- */}
                                        <props.skeleton.CustomStack direction="column" sx={{ display: { xs: 'none', sm: 'none', md: 'flex', lg: 'flex'} }}>
                                            <props.skeleton.CustomItem>
                                                <props.skeleton.CustomTabs
                                                    orientation="vertical"
                                                    variant="scrollable"
                                                    value={tabValue}
                                                    onChange={handleTabChange}
                                                    aria-label="Vertical tab"
                                                    sx={{ borderRight: 1, borderColor: 'divider' }}
                                                >
                                                    <props.skeleton.CustomTab label="Account" {...allyProps(0)} />
                                                    <props.skeleton.CustomTab label="Password" {...allyProps(1)} />
                                                    <props.skeleton.CustomTab label="Security" {...allyProps(2)} />
                                                    <props.skeleton.CustomTab label="Application" {...allyProps(3)} />
                                                    <props.skeleton.CustomTab label="Notification" {...allyProps(4)} />
                                                </props.skeleton.CustomTabs>
                                            </props.skeleton.CustomItem>
                                        </props.skeleton.CustomStack>
                                        {/* -------------------------------------------------------------------------------------------------- */}

                                        {/* #region ---------[ Tabs - Mobile ]---------------------------------------------------------------- */}
                                        <props.skeleton.CustomTabs 
                                            sx={{ display: { xs: 'none', sm: 'flex', md: 'none', lg: 'none' } }}
                                            value={tabValue} 
                                            onChange={handleTabChange} 
                                            centered
                                            variant={'fullWidth'}
                                            aria-label="Vertical tab"
                                        >
                                            <props.skeleton.CustomTab label="Account" {...allyProps(0)} />
                                            <props.skeleton.CustomTab label="Password" {...allyProps(1)} />
                                            <props.skeleton.CustomTab label="Security" {...allyProps(2)} />
                                            <props.skeleton.CustomTab label="Application" {...allyProps(3)} />
                                            <props.skeleton.CustomTab label="Notification" {...allyProps(4)} />
                                        </props.skeleton.CustomTabs>
                                        {/* --------------------------------------------------------------------------------------------- */}

                                        {/* #region ---------[ Tabs - Mobile small ]----------------------------------------------------- */}
                                        <props.skeleton.CustomTabs 
                                            sx={{ display: { xs: 'flex', sm: 'none', md: 'none', lg: 'none' } }}
                                            value={tabValue} 
                                            onChange={handleTabChange} 
                                            variant={'scrollable'}
                                            aria-label="Vertical tab"
                                        >
                                            <props.skeleton.CustomTab label="Account" {...allyProps(0)} />
                                            <props.skeleton.CustomTab label="Password" {...allyProps(1)} />
                                            <props.skeleton.CustomTab label="Security" {...allyProps(2)} />
                                            <props.skeleton.CustomTab label="Application" {...allyProps(3)} />
                                            <props.skeleton.CustomTab label="Notification" {...allyProps(4)} />
                                        </props.skeleton.CustomTabs>
                                        {/* --------------------------------------------------------------------------------------------- */}
                                    </props.skeleton.CustomStack>
                                </props.skeleton.CustomBox>
                            </props.skeleton.CustomGrid>

                            <props.skeleton.CustomGrid item xs={12} md={9}>
                                <props.skeleton.CustomBox sx={{ width: '100%', height: '100%', alignItems: 'flex-start', justifyContent: 'flex-start' }}>
                                    <form>
                                    <TabPanel value={tabValue} index={0} skeleton={props.skeleton}>
                                        <props.skeleton.CustomGrid container spacing={2} sx={{ textAlign: 'left' }}>
                                            <props.skeleton.CustomGrid item xs={12} md={12}>
                                                <props.skeleton.CustomGrid container spacing={0}>
                                                    <props.skeleton.CustomGrid item xs={12} md={6}>
                                                        <props.skeleton.CustomTypography variant='h4'>Account Settings</props.skeleton.CustomTypography> 
                                                    </props.skeleton.CustomGrid>

                                                    <props.skeleton.CustomGrid item xs={12} md={6}>
                                                        <props.skeleton.CustomStack direction="row" justifyContent="flex-end" alignContent="flex-end" spacing={1}>                                                    
                                                            <props.skeleton.CustomItem>
                                                                {!AccountFormIsDisabled
                                                                    ?   <>
                                                                            <props.skeleton.Bar buttonerror="true" padding5lronly="true" center="true" onClick={handleCancel}>Cancel</props.skeleton.Bar>
                                                                        </>
                                                                    : null
                                                                }
                                                            </props.skeleton.CustomItem>
                                                            <props.skeleton.CustomItem>
                                                                {AccountFormIsDisabled
                                                                    ? <props.skeleton.Bar center="true" button="true" padding5lronly="true" onClick={handleEditing}>Edit</props.skeleton.Bar>
                                                                    : <props.skeleton.Bar center="true" button="true" padding5lronly="true" onClick={handleUpdate}>Save</props.skeleton.Bar>
                                                                }                                                            
                                                            </props.skeleton.CustomItem>
                                                        </props.skeleton.CustomStack>
                                                    </props.skeleton.CustomGrid>
                                                </props.skeleton.CustomGrid>
                                            </props.skeleton.CustomGrid>                                        
                                            
                                            {
                                                /* ================[ ROW 1 ]=========================================================*/
                                            }

                                            <props.skeleton.CustomGrid item xs={12} md={6}>                                            
                                                {AccountFormIsDisabled 
                                                    ? <props.skeleton.CustomTypography><b>First Name</b><br />{ currentUser?.firstName }</props.skeleton.CustomTypography>
                                                    : <props.skeleton.CustomTextField
                                                        name='firstName'
                                                        label='First Name'
                                                        value={temp?.firstName || ''} 
                                                        disabled={AccountFormIsDisabled}
                                                        type="text"
                                                        onChange={(e) => setTemp({...temp, firstName: e.target.value})}                                    
                                                    />
                                                }
                                            </props.skeleton.CustomGrid>
                                            <props.skeleton.CustomGrid item xs={12} md={6}>
                                                {AccountFormIsDisabled
                                                    ? <props.skeleton.CustomTypography><b>Last Name</b><br />{ currentUser?.lastName }</props.skeleton.CustomTypography>
                                                    : <props.skeleton.CustomTextField
                                                        name='lastName'                                     
                                                        label='Last Name'
                                                        value={temp?.lastName || ''} 
                                                        disabled={AccountFormIsDisabled}
                                                        type="text"                                     
                                                        onChange={(e) => setTemp({...temp, lastName: e.target.value})}
                                                    />                                                    
                                                }
                                            </props.skeleton.CustomGrid>

                                            {
                                                /* ================[ ROW 2 ]=========================================================*/
                                            }

                                            <props.skeleton.CustomGrid item xs={12} md={6}>                                            
                                                {AccountFormIsDisabled
                                                    ? <props.skeleton.CustomTypography><b>Email</b><br />{ currentUser?.email }</props.skeleton.CustomTypography>
                                                    : <props.skeleton.CustomTextField
                                                        name='email' 
                                                        label='Email'
                                                        value={temp?.email || ''} 
                                                        disabled={AccountFormIsDisabled}
                                                        type="text"
                                                        onChange={(e) => setTemp({...temp, email: e.target.value})}
                                                    />
                                                }
                                            </props.skeleton.CustomGrid>
                                            <props.skeleton.CustomGrid item xs={12} md={6}>
                                                {AccountFormIsDisabled
                                                    ? <props.skeleton.CustomTypography><b>Phone Number</b><br />{ currentUser?.phone }</props.skeleton.CustomTypography>
                                                    : <props.skeleton.CustomMuiTelInput
                                                            placeholder='Enter your phone number' 
                                                            name='phone' 
                                                            label='Phone'
                                                            value={temp?.phone || ''}
                                                            disabled={AccountFormIsDisabled}
                                                            onChange={handleTelChange}
                                                        />                                                                                                                                                                               
                                                }
                                            </props.skeleton.CustomGrid>
                                            
                                            {
                                                /* ================[ ROW 3 ]=========================================================*/
                                            }

                                            <props.skeleton.CustomGrid item xs={12} md={6}>                                            
                                                {AccountFormIsDisabled
                                                    ? <props.skeleton.CustomTypography><b>Display Name</b><br />{ currentUser?.displayName }</props.skeleton.CustomTypography>
                                                    : <props.skeleton.CustomTextField
                                                            name='displayName' 
                                                            label='Display Name'
                                                            value={temp?.displayName || ''} 
                                                            disabled={AccountFormIsDisabled}
                                                            type="text"
                                                            onChange={(e) => setTemp({...temp, displayName: e.target.value})}
                                                    />                                                
                                                }
                                            </props.skeleton.CustomGrid>
                                            <props.skeleton.CustomGrid item xs={12} md={6}>
                                                {AccountFormIsDisabled
                                                    ? <props.skeleton.CustomTypography><b>User Name</b><br />{ currentUser?.userName}</props.skeleton.CustomTypography>
                                                    : <props.skeleton.CustomTextField
                                                            name='userName' 
                                                            label='User Name'
                                                            value={temp?.userName || ''} 
                                                            disabled={AccountFormIsDisabled}
                                                            type="text"
                                                            onChange={(e) => setTemp({...temp, userName: e.target.value})}
                                                    />
                                                }
                                            </props.skeleton.CustomGrid>

                                            {
                                                /* ================[ ROW 4 ]=========================================================*/
                                            }

                                            <props.skeleton.CustomGrid item xs={12} md={6}>                                            
                                                {AccountFormIsDisabled
                                                    ? <props.skeleton.CustomTypography><b>Company</b><br />{ currentUser?.company }</props.skeleton.CustomTypography>
                                                    : <props.skeleton.CustomTextField
                                                            name='company' 
                                                            label='Company'
                                                            value={temp?.company || ''} 
                                                            disabled={AccountFormIsDisabled}
                                                            type="text"
                                                            onChange={(e) => setTemp({...temp, company: e.target.value})}
                                                    />                                                
                                                }
                                            </props.skeleton.CustomGrid>
                                            <props.skeleton.CustomGrid item xs={12} md={6}>
                                                {AccountFormIsDisabled
                                                    ? <props.skeleton.CustomTypography><b>Designation</b><br />{ currentUser?.designation }</props.skeleton.CustomTypography>
                                                    : <props.skeleton.CustomTextField
                                                            name='designation'                                     
                                                            label='Designation'
                                                            value={temp?.designation || ''} 
                                                            disabled={AccountFormIsDisabled}
                                                            type="text"                                     
                                                            onChange={(e) => setTemp({...temp, designation: e.target.value})}
                                                    />                                                                                                
                                                }
                                            </props.skeleton.CustomGrid>

                                            {
                                                /* ================[ ROW 5 ]=========================================================*/
                                            }

                                            <props.skeleton.CustomGrid item xs={12} md={6}>                                            
                                                {AccountFormIsDisabled
                                                    ? <props.skeleton.CustomTypography><b>Account Created</b><br />{ DeTemps('timeFrom', currentUser?.createdAt) }</props.skeleton.CustomTypography>
                                                    : <props.skeleton.CustomTypography><b>Account Created</b><br />No settings here</props.skeleton.CustomTypography>                                                    
                                                }
                                            </props.skeleton.CustomGrid>
                                            <props.skeleton.CustomGrid item xs={12} md={6}>
                                                {AccountFormIsDisabled
                                                    ? <props.skeleton.CustomTypography><b>Location</b><br />{ currentUser?.location}</props.skeleton.CustomTypography>
                                                    : <props.skeleton.CustomTextField
                                                        name='location'                                     
                                                        label='Location'
                                                        value={temp?.location || ''} 
                                                        disabled={AccountFormIsDisabled}
                                                        type="text"                                     
                                                        onChange={(e) => setTemp({...temp, location: e.target.value})}
                                                    />  
                                                }
                                            </props.skeleton.CustomGrid>

                                            {
                                                /* ================[ ROW 6 ]=========================================================*/
                                            }

                                            <props.skeleton.CustomGrid item xs={12} md={6}>                                            
                                                {AccountFormIsDisabled
                                                    ? <props.skeleton.CustomTypography><b>Bio</b><br />{ currentUser?.bio }</props.skeleton.CustomTypography>
                                                    : <props.skeleton.CustomTextField
                                                            name='bio' 
                                                            label='Bio'
                                                            multiline
                                                            rows={5}
                                                            sx={{
                                                                height: "100%"
                                                            }}
                                                            value={temp?.bio || ''} 
                                                            disabled={AccountFormIsDisabled}
                                                            type="text"
                                                            onChange={(e) => setTemp({...temp, bio: e.target.value})}
                                                    />                                                                                                    
                                                }
                                            </props.skeleton.CustomGrid> 

                                            {
                                                /* ================[ ROW 7 ]=========================================================*/
                                            }

                                            <props.skeleton.CustomGrid item xs={12} md={6}>                                            
                                                {AccountFormIsDisabled
                                                    ? <props.skeleton.CustomTypography><b>Reality</b><br />{ currentUser?.reality }</props.skeleton.CustomTypography>
                                                    : <props.skeleton.CustomTextField
                                                        name='reality'                                     
                                                        label='Reality'
                                                        value={temp?.reality || ''} 
                                                        disabled={AccountFormIsDisabled}
                                                        type="text"                                     
                                                        onChange={(e) => setTemp({...temp, reality: e.target.value})}
                                                    />                                                                                              
                                                }
                                            </props.skeleton.CustomGrid> 

                                            <props.skeleton.CustomGrid item xs={12} md={12}>                                            
                                                {AccountFormIsDisabled
                                                    ? <props.skeleton.CustomTypography variant="h6"><b>Profile Picture</b><br />
                                                        { currentUser?.profileImage }
                                                        <br />
                                                        { 
                                                            (currentUser?.profileImage && (currentUser?.profileImage !== "no-profileimage.png" && currentUser?.profileImage !== ""))
                                                            ? (
                                                                <props.skeleton.CustomBox
                                                                    component="img"
                                                                    sx={{
                                                                        height: { xs: 320, sm: 500, md: 200, lg: 300 },
                                                                        width: { xs: '100%', sm: '100%', md: 220, lg: 300 },
                                                                        maxHeight: { xs: 320, sm: 500, md: 200, lg: 300 },
                                                                        maxWidth: { xs: '100%', sm: '100%', md: '100%', lg: 300 }
                                                                    }}
                                                                    alt={ currentUser?.userName+"'s profile image"}
                                                                    src={`${props.settingServerAPIBaseUrl}user/file/byuser/${currentUser?.userName}/`+currentUser?.profileImage}
                                                                />
                                                            )
                                                            : (
                                                                <props.skeleton.CustomBox
                                                                    component="img"
                                                                    sx={{
                                                                        height: { xs: 320, sm: 500, md: 200, lg: 300 },
                                                                        width: { xs: '100%', sm: '100%', md: 220, lg: 300 },
                                                                        maxHeight: { xs: 320, sm: 500, md: 200, lg: 300 },
                                                                        maxWidth: '100%',
                                                                    }}
                                                                    alt={ currentUser?.userName+"'s profile image"}
                                                                    src={window.location.origin+"/user/thumbs/no-profileimage.png"}
                                                                />
                                                            )
                                                        }
                                                        </props.skeleton.CustomTypography>
                                                    : <>
                                                        <props.skeleton.CustomTypography variant="h6"><b>Profile Picture (current)</b></props.skeleton.CustomTypography>                                                    
                                                        {
                                                            <UploadDragZone 
                                                                skeleton={props.skeleton}
                                                                constraints={{ 
                                                                    height: 300, 
                                                                    width: '100%'
                                                                }}
                                                                query={query}
                                                                section="user"
                                                                what="profileimage"
                                                                resources={resources} 
                                                                user={currentUser} 
                                                                img={currentUser?.profileImage} 
                                                                width={"200px"} 
                                                                height={"200px"} 
                                                                settingServerAPIBaseUrl={props.settingServerAPIBaseUrl}
                                                            />
                                                        }
                                                        </>                                                
                                                }
                                            </props.skeleton.CustomGrid>

                                            <props.skeleton.CustomGrid item xs={12}>
                                                &nbsp;
                                            </props.skeleton.CustomGrid>

                                            <props.skeleton.CustomGrid item xs={12}>
                                                &nbsp;
                                            </props.skeleton.CustomGrid>

                                            <props.skeleton.CustomGrid item xs={12}>
                                                &nbsp;
                                            </props.skeleton.CustomGrid>
                                            
                                            <props.skeleton.CustomGrid item xs={12} md={12}>                                            
                                                {AccountFormIsDisabled
                                                    ? <props.skeleton.CustomTypography variant="h6"><b>Header Image</b><br />
                                                        { currentUser?.headerImage }
                                                        { 
                                                            (currentUser?.headerImage && currentUser?.headerImage !== "no-headerimage.png" && currentUser?.headerImage !== "")
                                                            ? (
                                                                <props.skeleton.CustomBox
                                                                    component="img"
                                                                    sx={{
                                                                        height: { xs: 200, sm: 250, md: 220, lg: 300 },
                                                                        width: '100%',
                                                                        maxHeight: { xs: 200, sm: 250, md: 220, lg: 300 },
                                                                        maxWidth: '100%',
                                                                    }}
                                                                    alt={ currentUser?.userName+"'s header image"}
                                                                    src={`${props.settingServerAPIBaseUrl}user/file/byuser/${currentUser?.userName}/`+currentUser?.headerImage}
                                                                />
                                                            )
                                                            : (
                                                                <props.skeleton.CustomBox
                                                                    component="img"
                                                                    sx={{
                                                                        height: { xs: 200, sm: 250, md: 220, lg: 300 },
                                                                        width: '100%',
                                                                        maxHeight: { xs: 200, sm: 250, md: 220, lg: 300 },
                                                                        maxWidth: '100%',
                                                                    }}
                                                                    alt={ currentUser?.userName+"'s header image"}
                                                                    src={window.location.origin+"/user/thumbs/no-headerimage.png"}
                                                                />
                                                            )
                                                        }
                                                    </props.skeleton.CustomTypography>
                                                    : <>
                                                        <props.skeleton.CustomTypography variant="h6"><b>Header Image (current)</b></props.skeleton.CustomTypography>                                                    
                                                        {
                                                            <UploadDragZone
                                                                skeleton={props.skeleton}                                                    
                                                                constraints={{ 
                                                                    height: 300, 
                                                                    width: '100%'
                                                                }}
                                                                query={query} 
                                                                section="user"                                                        
                                                                what="headerimage"
                                                                resources={resources} 
                                                                width={"200px"} 
                                                                height={"200px"} 
                                                                user={currentUser} 
                                                                img={currentUser?.headerImage} 
                                                                settingServerAPIBaseUrl={props.settingServerAPIBaseUrl}
                                                            />
                                                        }
                                                        </>                                                
                                                }
                                            </props.skeleton.CustomGrid>
                                        </props.skeleton.CustomGrid>
                                    </TabPanel>
                                    
                                    <TabPanel value={tabValue} index={1} skeleton={props.skeleton}>
                                        <props.skeleton.CustomGrid container spacing={2} sx={{ textAlign: 'left' }}>
                                            <props.skeleton.CustomGrid item xs={12} md={12}>
                                                <props.skeleton.CustomGrid container spacing={0}>
                                                    <props.skeleton.CustomGrid item xs={12} md={6}>
                                                        <props.skeleton.CustomTypography variant='h4'>Password Settings</props.skeleton.CustomTypography> 
                                                    </props.skeleton.CustomGrid>

                                                    <props.skeleton.CustomGrid item xs={12} md={6}>
                                                        <props.skeleton.CustomStack direction="row" justifyContent="flex-end" alignContent="flex-end" spacing={1}>                                                    
                                                            <props.skeleton.CustomItem>
                                                                {!AccountFormIsDisabled
                                                                    ?   <>
                                                                            <props.skeleton.Bar buttonerror="true" padding5lronly="true" center="true" onClick={handleCancel}>Cancel</props.skeleton.Bar>
                                                                        </>
                                                                    : null
                                                                }
                                                            </props.skeleton.CustomItem>
                                                            <props.skeleton.CustomItem>
                                                                {AccountFormIsDisabled
                                                                    ? <props.skeleton.Bar center="true" button="true" padding5lronly="true" onClick={handleEditing}>Edit</props.skeleton.Bar>
                                                                    : <props.skeleton.Bar center="true" button="true" padding5lronly="true" onClick={handleUpdate}>Save</props.skeleton.Bar>
                                                                }                                                            
                                                            </props.skeleton.CustomItem>
                                                        </props.skeleton.CustomStack>
                                                    </props.skeleton.CustomGrid>
                                                </props.skeleton.CustomGrid>
                                            </props.skeleton.CustomGrid>                                        
                                            
                                            {
                                                /* ================[ ROW 1 ]=========================================================*/
                                            }

                                            <props.skeleton.CustomGrid item xs={12} md={6}>                                            
                                                {AccountFormIsDisabled
                                                    ? <props.skeleton.CustomTypography><b>Current Password</b><br />Click Edit to change</props.skeleton.CustomTypography>
                                                    : <props.skeleton.CustomTextField
                                                            name='currentPassword' 
                                                            placeholder='Enter your current password'
                                                            label='Current Password'
                                                            autoComplete='password'
                                                            value={temp?.currentPassword || ''} 
                                                            type={showCPassword ? 'text' : 'password'}                                     
                                                            disabled={AccountFormIsDisabled}
                                                            onChange={(e) => setTemp({...temp, currentPassword: e.target.value})}
                                                            InputProps={{
                                                                endAdornment: <InputAdornment position="end" onClick={() => setShowCPassword(!showCPassword)}>
                                                                    {!showCPassword ? <VisibilityOutlinedIcon /> : <VisibilityOffOutlinedIcon />}
                                                                </InputAdornment>,
                                                            }}
                                                    />                                                
                                                }
                                            </props.skeleton.CustomGrid>
                                            <props.skeleton.CustomGrid item xs={12} md={6}>
                                                {AccountFormIsDisabled
                                                    ? <props.skeleton.CustomTypography><b>????????</b><br />Idk</props.skeleton.CustomTypography>
                                                    : <props.skeleton.CustomTypography><b>????????</b><br />Nothing</props.skeleton.CustomTypography>                                             
                                                }
                                            </props.skeleton.CustomGrid>

                                            {
                                                /* ================[ ROW 2 ]=========================================================*/
                                            }

                                            <props.skeleton.CustomGrid item xs={12} md={6}>                                            
                                                {AccountFormIsDisabled
                                                    ? <props.skeleton.CustomTypography><b>New Password</b><br />????????</props.skeleton.CustomTypography>
                                                    : <props.skeleton.CustomTextField
                                                            name='newPassword' 
                                                            placeholder='Enter a new password'
                                                            label='New Password'
                                                            autoComplete='new-password'
                                                            type={showNPassword ? 'text' : 'password'}
                                                            value={temp?.newPassword || ''} 
                                                            disabled={AccountFormIsDisabled}
                                                            onChange={(e) => setTemp({...temp, newPassword: e.target.value})}
                                                            InputProps={{
                                                                endAdornment: <InputAdornment position="end" onClick={() => setShowNPassword(!showNPassword)}>
                                                                    {!showNPassword ? <VisibilityOutlinedIcon /> : <VisibilityOffOutlinedIcon />}
                                                                </InputAdornment>,
                                                            }}
                                                    />                                                
                                                }
                                            </props.skeleton.CustomGrid>
                                            <props.skeleton.CustomGrid item xs={12} md={6}>
                                                {AccountFormIsDisabled
                                                    ? <props.skeleton.CustomTypography><b>????????</b><br />Idk</props.skeleton.CustomTypography>
                                                    : <props.skeleton.CustomTypography><b>????????</b><br />Nothing here</props.skeleton.CustomTypography>                                                                                                                                                                          
                                                }
                                            </props.skeleton.CustomGrid>
                                            
                                            {
                                                /* ================[ ROW 3 ]=========================================================*/
                                            }

                                            <props.skeleton.CustomGrid item xs={12} md={6}>                                            
                                                {AccountFormIsDisabled
                                                    ? <props.skeleton.CustomTypography><b>Confirm New Password</b><br />????????</props.skeleton.CustomTypography>
                                                    : <props.skeleton.CustomTextField
                                                            name='confirmNewPassword' 
                                                            placeholder='Confirm your new password'
                                                            label='Confirm New Password'
                                                            autoComplete='confirm-password'
                                                            type={showCNPassword ? 'text' : 'password'}
                                                            value={temp?.confirmNewPassword || ''} 
                                                            disabled={AccountFormIsDisabled}
                                                            onChange={(e) => setTemp({...temp, confirmNewPassword: e.target.value})}
                                                            InputProps={{
                                                                endAdornment: <InputAdornment position="end" onClick={() => setShowCNPassword(!showCNPassword)}>
                                                                    {!showCNPassword ? <VisibilityOutlinedIcon /> : <VisibilityOffOutlinedIcon />}
                                                                </InputAdornment>,
                                                            }}
                                                    />                                                                                                
                                                }
                                            </props.skeleton.CustomGrid>
                                            <props.skeleton.CustomGrid item xs={12} md={6}>
                                                {AccountFormIsDisabled
                                                    ? <props.skeleton.CustomTypography><b>????????</b><br />Idk</props.skeleton.CustomTypography>
                                                    : <props.skeleton.CustomTypography><b>????????</b><br />Nothing here</props.skeleton.CustomTypography>
                                                }
                                            </props.skeleton.CustomGrid>

                                            {
                                                /* ================[ ROW 4 ]=========================================================*/
                                            }

                                            <props.skeleton.CustomGrid item xs={12} md={6}>                                            
                                                {AccountFormIsDisabled
                                                    ? <props.skeleton.CustomTypography><b>????????</b><br />Idk</props.skeleton.CustomTypography>
                                                    : <props.skeleton.CustomTypography><b>????????</b><br />Nothing</props.skeleton.CustomTypography>                                       
                                                }
                                            </props.skeleton.CustomGrid>
                                            <props.skeleton.CustomGrid item xs={12} md={6}>
                                                {AccountFormIsDisabled
                                                    ? <props.skeleton.CustomTypography><b>????????</b><br />Idk</props.skeleton.CustomTypography>
                                                    : <props.skeleton.CustomTypography><b>????????</b><br />Nothing</props.skeleton.CustomTypography>                                                                                       
                                                }
                                            </props.skeleton.CustomGrid>

                                            {
                                                /* ================[ ROW 5 ]=========================================================*/
                                            }

                                            <props.skeleton.CustomGrid item xs={12} md={6}>                                            
                                                {AccountFormIsDisabled
                                                    ? <props.skeleton.CustomTypography><b>????????</b><br />Idk</props.skeleton.CustomTypography>
                                                    : <props.skeleton.CustomTypography><b>????????</b><br />Nothing</props.skeleton.CustomTypography>                                                  
                                                }
                                            </props.skeleton.CustomGrid>
                                            <props.skeleton.CustomGrid item xs={12} md={6}>
                                                {AccountFormIsDisabled
                                                    ? <props.skeleton.CustomTypography><b>????????</b><br />Idk</props.skeleton.CustomTypography>
                                                    : <props.skeleton.CustomTypography><b>????????</b><br />Nothing</props.skeleton.CustomTypography>                                                     
                                                }
                                            </props.skeleton.CustomGrid>

                                            {
                                                /* ================[ ROW 6 ]=========================================================*/
                                            }

                                            <props.skeleton.CustomGrid item xs={12} md={6}>                                            
                                                {AccountFormIsDisabled
                                                    ? <props.skeleton.CustomTypography><b>????????</b><br />Idk</props.skeleton.CustomTypography>
                                                    : <props.skeleton.CustomTypography><b>????????</b><br />Nothing</props.skeleton.CustomTypography>                                                                                          
                                                }
                                            </props.skeleton.CustomGrid> 

                                            {
                                                /* ================[ ROW 7 ]=========================================================*/
                                            }

                                            <props.skeleton.CustomGrid item xs={12} md={6}>                                            
                                                {AccountFormIsDisabled
                                                    ? <props.skeleton.CustomTypography><b>????????</b><br />Idk</props.skeleton.CustomTypography>
                                                    : <props.skeleton.CustomTypography><b>????????</b><br />Nothing</props.skeleton.CustomTypography>                                            
                                                }
                                            </props.skeleton.CustomGrid>
                                        </props.skeleton.CustomGrid>                  
                                    </TabPanel>

                                    <TabPanel value={tabValue} index={2} skeleton={props.skeleton}>
                                        <props.skeleton.CustomGrid container spacing={2} sx={{ textAlign: "left" }}>
                                            <props.skeleton.CustomGrid item xs={12}>
                                                <props.skeleton.CustomGrid container spacing={1}>
                                                    <props.skeleton.CustomGrid item xs={12} md={6}>
                                                        <props.skeleton.CustomTypography variant='h4'>Security Settings</props.skeleton.CustomTypography> 
                                                    </props.skeleton.CustomGrid>

                                                    <props.skeleton.CustomGrid item xs={12} md={6}>
                                                        <props.skeleton.CustomStack direction="row" justifyContent="flex-end" alignContent="flex-end" spacing={1}>                                                    
                                                            <props.skeleton.CustomItem>
                                                                {!AccountFormIsDisabled
                                                                    ?   <>
                                                                            <props.skeleton.Bar buttonerror="true" padding5lronly="true" center="true" onClick={handleCancel}>Cancel</props.skeleton.Bar>
                                                                        </>
                                                                    : null
                                                                }
                                                            </props.skeleton.CustomItem>
                                                            <props.skeleton.CustomItem>
                                                                {AccountFormIsDisabled
                                                                    ? <props.skeleton.Bar center="true" button="true" padding5lronly="true" onClick={handleEditing}>Edit</props.skeleton.Bar>
                                                                    : <props.skeleton.Bar center="true" button="true" padding5lronly="true" onClick={handleUpdate}>Save</props.skeleton.Bar>
                                                                }                                                            
                                                            </props.skeleton.CustomItem>
                                                        </props.skeleton.CustomStack>
                                                    </props.skeleton.CustomGrid>
                                                </props.skeleton.CustomGrid>
                                            </props.skeleton.CustomGrid>                                        
                                            
                                            {
                                                /* ================[ ROW 1 ]=========================================================*/
                                            }

                                            <props.skeleton.CustomGrid item xs={12} md={6}>                                            
                                                {AccountFormIsDisabled 
                                                    ? <props.skeleton.CustomTypography><b>Information Private?</b><br />{ (currentUser?.isPrivate) ? "Yes" : "No" }</props.skeleton.CustomTypography>
                                                    : <>
                                                        <props.skeleton.CustomGrid container spacing={2}>
                                                            <props.skeleton.CustomGrid item xs={12}>
                                                                <props.skeleton.CustomGrid container spacing={1}>
                                                                    <props.skeleton.CustomGrid item xs={12} md={8}>
                                                                        <props.skeleton.CustomStack direction="row">
                                                                            <props.skeleton.CustomItem>
                                                                                <props.skeleton.CustomTypography><b>Information Private?</b><br /><i>Hide your sensitive info? :)</i></props.skeleton.CustomTypography>
                                                                            </props.skeleton.CustomItem>
                                                                        </props.skeleton.CustomStack>
                                                                    </props.skeleton.CustomGrid>
                                                                    <props.skeleton.CustomGrid item xs={12} md={4}>
                                                                        <props.skeleton.CustomStack direction="row" justifyContent="flex-end" alignItems="center">
                                                                            <props.skeleton.CustomItem>
                                                                                <Switch 
                                                                                    checked={privateSwitchChecked || false}
                                                                                    onChange={handlePrivateSwitchChange}
                                                                                    disabled={AccountFormIsDisabled}                                        
                                                                                    inputProps={{ 'aria-label': 'isPrivate' }} 
                                                                                    name="isPrivate"
                                                                                ></Switch>                                                                            
                                                                            </props.skeleton.CustomItem>
                                                                        </props.skeleton.CustomStack>
                                                                    </props.skeleton.CustomGrid>
                                                                </props.skeleton.CustomGrid>
                                                            </props.skeleton.CustomGrid>
                                                        </props.skeleton.CustomGrid> 
                                                    </> 
                                                }
                                            </props.skeleton.CustomGrid>
                                            <props.skeleton.CustomGrid item xs={12} md={6}>
                                                {AccountFormIsDisabled
                                                    ? <props.skeleton.CustomTypography><b>Email Verified?</b><br />{ (currentUser?.isEmailVerified ? "Yes" : "No") }</props.skeleton.CustomTypography>
                                                    : <>
                                                        <props.skeleton.CustomGrid container spacing={2}>
                                                            <props.skeleton.CustomGrid item xs={12}>
                                                                <props.skeleton.CustomGrid container spacing={1}>
                                                                    <props.skeleton.CustomGrid item xs={12} md={8}>
                                                                        <props.skeleton.CustomStack direction="row">
                                                                            <props.skeleton.CustomItem>
                                                                                <props.skeleton.CustomTypography><b>Email Verified?</b><br />Your Email: { currentUser?.email }</props.skeleton.CustomTypography>                                                                      
                                                                            </props.skeleton.CustomItem>
                                                                        </props.skeleton.CustomStack>
                                                                    </props.skeleton.CustomGrid>
                                                                    <props.skeleton.CustomGrid item xs={12} md={4}>
                                                                        <props.skeleton.CustomStack direction="row" justifyContent="flex-end" alignItems="center">
                                                                            <props.skeleton.CustomItem>
                                                                                {
                                                                                    !currentUser?.isEmailVerified && (
                                                                                        <props.skeleton.Bar center="true" button="true" onClick={handleEmailVerify}>Send Code</props.skeleton.Bar>
                                                                                    )
                                                                                }
                                                                                {
                                                                                    currentUser?.isEmailVerified && (
                                                                                        <props.skeleton.CustomTypography>Already verified</props.skeleton.CustomTypography>
                                                                                    )
                                                                                }
                                                                            </props.skeleton.CustomItem>
                                                                        </props.skeleton.CustomStack>
                                                                    </props.skeleton.CustomGrid>
                                                                </props.skeleton.CustomGrid>
                                                            </props.skeleton.CustomGrid>
                                                        </props.skeleton.CustomGrid>                                          
                                                    </>                                               
                                                }
                                            </props.skeleton.CustomGrid>

                                            {
                                                /* ================[ ROW 2 ]=========================================================*/
                                            }

                                            <props.skeleton.CustomGrid item xs={12} md={6}>                                            
                                                {AccountFormIsDisabled
                                                    ? <props.skeleton.CustomTypography><b>Verified Account?</b><br />{ (currentUser?.isVerified) ? "Yes" : "No" }</props.skeleton.CustomTypography>
                                                    : <>
                                                        <props.skeleton.CustomGrid container spacing={2}>
                                                            <props.skeleton.CustomGrid item xs={12}>
                                                                <props.skeleton.CustomGrid container spacing={1}>
                                                                    <props.skeleton.CustomGrid item xs={12} md={6}>
                                                                        <props.skeleton.CustomStack direction="row" alignItems="center">
                                                                            <props.skeleton.CustomItem>
                                                                                <props.skeleton.CustomTypography><b>Verify Account</b><br />Click the button</props.skeleton.CustomTypography>                                                                         
                                                                            </props.skeleton.CustomItem>
                                                                        </props.skeleton.CustomStack>
                                                                    </props.skeleton.CustomGrid>
                                                                    <props.skeleton.CustomGrid item xs={12} md={6}>
                                                                        <props.skeleton.CustomStack direction="row" justifyContent="flex-end" alignItems="center">
                                                                            <props.skeleton.CustomItem>
                                                                                <props.skeleton.Bar center="true" button="true" onClick={handleVerifyAccount}>Verify Account</props.skeleton.Bar>
                                                                            </props.skeleton.CustomItem>
                                                                        </props.skeleton.CustomStack>
                                                                    </props.skeleton.CustomGrid>
                                                                </props.skeleton.CustomGrid>
                                                            </props.skeleton.CustomGrid>
                                                        </props.skeleton.CustomGrid>            
                                                    </>    
                                                }
                                            </props.skeleton.CustomGrid>
                                            <props.skeleton.CustomGrid item xs={12} md={6}>
                                                {AccountFormIsDisabled
                                                    ? <props.skeleton.CustomTypography><b>Account Role</b><br />{ currentUser?.role }</props.skeleton.CustomTypography>
                                                    : <>
                                                        <props.skeleton.CustomGrid container spacing={2}>
                                                            <props.skeleton.CustomGrid item xs={12}>
                                                                <props.skeleton.CustomGrid container spacing={1}>
                                                                    <props.skeleton.CustomGrid item xs={12} md={6}>
                                                                        <props.skeleton.CustomStack direction="row" alignItems="center">
                                                                            <props.skeleton.CustomItem>
                                                                                <props.skeleton.CustomTypography><b>Account Role</b><br />No settings here</props.skeleton.CustomTypography>                                                                         
                                                                            </props.skeleton.CustomItem>
                                                                        </props.skeleton.CustomStack>
                                                                    </props.skeleton.CustomGrid>
                                                                    <props.skeleton.CustomGrid item xs={12} md={6}>
                                                                        <props.skeleton.CustomStack direction="row" justifyContent="flex-end" alignItems="center">
                                                                            <props.skeleton.CustomItem>
                                                                                
                                                                            </props.skeleton.CustomItem>
                                                                        </props.skeleton.CustomStack>
                                                                    </props.skeleton.CustomGrid>
                                                                </props.skeleton.CustomGrid>
                                                            </props.skeleton.CustomGrid>
                                                        </props.skeleton.CustomGrid>        
                                                        
                                                    </>                                                                                      
                                                }
                                            </props.skeleton.CustomGrid>
                                            
                                            {
                                                /* ================[ ROW 3 ]=========================================================*/
                                            }

                                            <props.skeleton.CustomGrid item xs={12} md={6}>                                            
                                                {AccountFormIsDisabled
                                                    ? <props.skeleton.CustomTypography><b>Delete Account</b><br />Nothing to display. Click Edit to show button.</props.skeleton.CustomTypography>
                                                    : <>
                                                        <props.skeleton.CustomGrid container spacing={2}>
                                                            <props.skeleton.CustomGrid item xs={12}>
                                                                <props.skeleton.CustomGrid container spacing={1}>
                                                                    <props.skeleton.CustomGrid item xs={12} md={6}>
                                                                        <props.skeleton.CustomStack direction="row" alignItems="center">
                                                                            <props.skeleton.CustomItem>
                                                                                <props.skeleton.CustomTypography><b>Delete Account</b><br />Click the button</props.skeleton.CustomTypography>                                                                         
                                                                            </props.skeleton.CustomItem>
                                                                        </props.skeleton.CustomStack>
                                                                    </props.skeleton.CustomGrid>
                                                                    <props.skeleton.CustomGrid item xs={12} md={6}>
                                                                        <props.skeleton.CustomStack direction="row" justifyContent="flex-end" alignItems="center">
                                                                            <props.skeleton.CustomItem>
                                                                                <props.skeleton.Bar center="true" buttonerror="true" onClick={handleDeleteAccount}>Delete Account</props.skeleton.Bar>
                                                                            </props.skeleton.CustomItem>
                                                                        </props.skeleton.CustomStack>
                                                                    </props.skeleton.CustomGrid>
                                                                </props.skeleton.CustomGrid>
                                                            </props.skeleton.CustomGrid>
                                                        </props.skeleton.CustomGrid>            
                                                    </>                                          
                                                }
                                            </props.skeleton.CustomGrid>

                                            <props.skeleton.CustomGrid item xs={12} md={6}>                                            
                                                {AccountFormIsDisabled
                                                    ? <props.skeleton.CustomTypography><b>Premium?</b><br />{ (isPremium) ? "Yes" : "No" }</props.skeleton.CustomTypography>
                                                    : <>
                                                        <props.skeleton.CustomGrid container spacing={2}>
                                                            <props.skeleton.CustomGrid item xs={12}>
                                                                <props.skeleton.CustomGrid container spacing={1}>
                                                                    <props.skeleton.CustomGrid item xs={12} md={6}>
                                                                        <props.skeleton.CustomStack direction="row" alignItems="center">
                                                                            <props.skeleton.CustomItem>
                                                                                {
                                                                                    (!isPremium)
                                                                                    ? <props.skeleton.CustomTypography><b>Premium?</b><br />Click the button to upgrade</props.skeleton.CustomTypography>
                                                                                    : <props.skeleton.CustomTypography><b>Premium?</b><br />Already upgraded</props.skeleton.CustomTypography>
                                                                                }                                                                         
                                                                            </props.skeleton.CustomItem>
                                                                        </props.skeleton.CustomStack>
                                                                    </props.skeleton.CustomGrid>
                                                                    <props.skeleton.CustomGrid item xs={12} md={6}>
                                                                        <props.skeleton.CustomStack direction="row" justifyContent="flex-end" alignItems="center">
                                                                            <props.skeleton.CustomItem>
                                                                                {
                                                                                    (!isPremium)
                                                                                    ? <props.skeleton.Bar center="true" button="true" onClick={handleUpgradeAccount}>Upgrade</props.skeleton.Bar>
                                                                                    : null
                                                                                }
                                                                            </props.skeleton.CustomItem>
                                                                        </props.skeleton.CustomStack>
                                                                    </props.skeleton.CustomGrid>
                                                                </props.skeleton.CustomGrid>
                                                            </props.skeleton.CustomGrid>
                                                        </props.skeleton.CustomGrid>            
                                                    </>                                          
                                                }
                                            </props.skeleton.CustomGrid>                                        
                                        </props.skeleton.CustomGrid>      
                                    </TabPanel>

                                    <TabPanel value={tabValue} index={3} skeleton={props.skeleton}>
                                        <props.skeleton.CustomGrid container spacing={2} sx={{ textAlign: 'left' }}>
                                            <props.skeleton.CustomGrid item xs={12} md={12}>
                                                <props.skeleton.CustomGrid container spacing={0}>
                                                    <props.skeleton.CustomGrid item xs={12} md={6}>
                                                        <props.skeleton.CustomTypography variant='h4'>Application Settings</props.skeleton.CustomTypography> 
                                                    </props.skeleton.CustomGrid>

                                                    <props.skeleton.CustomGrid item xs={12} md={6}>
                                                        <props.skeleton.CustomStack direction="row" justifyContent="flex-end" alignContent="flex-end" spacing={1}>                                                    
                                                            <props.skeleton.CustomItem>
                                                                {!AccountFormIsDisabled
                                                                    ?   <>
                                                                            <props.skeleton.Bar buttonerror="true" padding5lronly="true" center="true" onClick={handleCancel}>Cancel</props.skeleton.Bar>
                                                                        </>
                                                                    : null
                                                                }
                                                            </props.skeleton.CustomItem>
                                                            <props.skeleton.CustomItem>
                                                                {AccountFormIsDisabled
                                                                    ? <props.skeleton.Bar center="true" button="true" padding5lronly="true" onClick={handleEditing}>Edit</props.skeleton.Bar>
                                                                    : <props.skeleton.Bar center="true" button="true" padding5lronly="true" onClick={handleUpdate}>Save</props.skeleton.Bar>
                                                                }                                                            
                                                            </props.skeleton.CustomItem>
                                                        </props.skeleton.CustomStack>
                                                    </props.skeleton.CustomGrid>
                                                </props.skeleton.CustomGrid>
                                            </props.skeleton.CustomGrid>                                        
                                            
                                            {
                                                /* ================[ ROW 1 ]=========================================================*/
                                            }

                                            <props.skeleton.CustomGrid item xs={12} md={6}>                                            
                                                {AccountFormIsDisabled 
                                                    ? <props.skeleton.CustomTypography><b>Current Status</b><br />{ currentUser?.status }</props.skeleton.CustomTypography>
                                                    : <props.skeleton.CustomTextField
                                                            name='status'                                     
                                                            label='Status'
                                                            value={temp?.status || ''} 
                                                            disabled={AccountFormIsDisabled}
                                                            type="text"                                     
                                                            onChange={(e) => setTemp({...temp, status: e.target.value})}
                                                    />
                                                }
                                            </props.skeleton.CustomGrid>
                                            <props.skeleton.CustomGrid item xs={12} md={6}>
                                                {AccountFormIsDisabled
                                                    ? <props.skeleton.CustomTypography><b>Custom Follow Message</b><br />{(isPremium) ? currentUser?.customFollowMessage : "Premium Account required to change"}</props.skeleton.CustomTypography>
                                                    : (
                                                        (isPremium) 
                                                        ? <props.skeleton.CustomTextField
                                                                name='customFollowMessage'                                     
                                                                label='Custom Follow Message'
                                                                value={temp?.customFollowMessage || ''} 
                                                                disabled={AccountFormIsDisabled}
                                                                type="text"                                     
                                                                onChange={(e) => setTemp({...temp, customFollowMessage: e.target.value})}
                                                        />
                                                        : <props.skeleton.CustomTypography><b>Custom Follow Message</b><br />Premium Account required to change</props.skeleton.CustomTypography>
                                                    )
                                                }
                                            </props.skeleton.CustomGrid>

                                            {
                                                /* ================[ ROW 2 ]=========================================================*/
                                            }

                                            <props.skeleton.CustomGrid item xs={12} md={6}>                                            
                                                {AccountFormIsDisabled
                                                    ? <props.skeleton.CustomTypography><b>Alpha Code</b><br />{ (currentUser?.alphaCode !== "") ? currentUser?.alphaCode : "None" }</props.skeleton.CustomTypography>
                                                    : <props.skeleton.CustomTypography><b>Alpha Code</b><br />No settings here</props.skeleton.CustomTypography>                                                
                                                }
                                            </props.skeleton.CustomGrid>
                                            <props.skeleton.CustomGrid item xs={12} md={6}>
                                                {AccountFormIsDisabled
                                                    ? <props.skeleton.CustomTypography><b>Custom Unfollow Message</b><br />{(isPremium) ? currentUser?.customUnFollowMessage : "Premium Account required to change"}</props.skeleton.CustomTypography>
                                                    : (
                                                        (isPremium) 
                                                        ? <props.skeleton.CustomTextField
                                                                name='customUnFollowMessage'                                     
                                                                label='Custom Unfollow Message'
                                                                value={temp?.customUnFollowMessage || ''} 
                                                                disabled={AccountFormIsDisabled}
                                                                type="text"                                     
                                                                onChange={(e) => setTemp({...temp, customUnFollowMessage: e.target.value})}
                                                        />
                                                        : <props.skeleton.CustomTypography><b>Custom Unfollow Message</b><br />Premium Account required to change</props.skeleton.CustomTypography>
                                                    )
                                                }
                                            </props.skeleton.CustomGrid>
                                            
                                            {
                                                /* ================[ ROW 3 ]=========================================================*/
                                            }

                                            <props.skeleton.CustomGrid item xs={12} md={6}>                                            
                                                {AccountFormIsDisabled
                                                    ? <props.skeleton.CustomTypography><b>Beta Code</b><br />{ (currentUser?.betaCode !== "") ? currentUser?.betaCode : "None" }</props.skeleton.CustomTypography>
                                                    : <props.skeleton.CustomTypography><b>Beta Code</b><br />No settings here</props.skeleton.CustomTypography>                                                
                                                }
                                            </props.skeleton.CustomGrid>
                                            <props.skeleton.CustomGrid item xs={12} md={6}>
                                                {AccountFormIsDisabled
                                                    ? <props.skeleton.CustomTypography><b>????????</b><br />Idk</props.skeleton.CustomTypography>
                                                    : <props.skeleton.CustomTypography><b>????????</b><br />Nothing here</props.skeleton.CustomTypography>
                                                }
                                            </props.skeleton.CustomGrid>

                                            {
                                                /* ================[ ROW 4 ]=========================================================*/
                                            }

                                            <props.skeleton.CustomGrid item xs={12} md={6}>                                            
                                                {AccountFormIsDisabled
                                                    ? <props.skeleton.CustomTypography><b>????????</b><br />Idk</props.skeleton.CustomTypography>
                                                    : <props.skeleton.CustomTypography><b>????????</b><br />Nothing</props.skeleton.CustomTypography>                                       
                                                }
                                            </props.skeleton.CustomGrid>
                                            <props.skeleton.CustomGrid item xs={12} md={6}>
                                                {AccountFormIsDisabled
                                                    ? <props.skeleton.CustomTypography><b>????????</b><br />Idk</props.skeleton.CustomTypography>
                                                    : <props.skeleton.CustomTypography><b>????????</b><br />Nothing</props.skeleton.CustomTypography>                                                                                       
                                                }
                                            </props.skeleton.CustomGrid>

                                            {
                                                /* ================[ ROW 5 ]=========================================================*/
                                            }

                                            <props.skeleton.CustomGrid item xs={12} md={6}>                                            
                                                {AccountFormIsDisabled
                                                    ? <props.skeleton.CustomTypography><b>????????</b><br />Idk</props.skeleton.CustomTypography>
                                                    : <props.skeleton.CustomTypography><b>????????</b><br />Nothing</props.skeleton.CustomTypography>                                                  
                                                }
                                            </props.skeleton.CustomGrid>
                                            <props.skeleton.CustomGrid item xs={12} md={6}>
                                                {AccountFormIsDisabled
                                                    ? <props.skeleton.CustomTypography><b>????????</b><br />Idk</props.skeleton.CustomTypography>
                                                    : <props.skeleton.CustomTypography><b>????????</b><br />Nothing</props.skeleton.CustomTypography>                                                     
                                                }
                                            </props.skeleton.CustomGrid>

                                            {
                                                /* ================[ ROW 6 ]=========================================================*/
                                            }

                                            <props.skeleton.CustomGrid item xs={12} md={6}>                                            
                                                {AccountFormIsDisabled
                                                    ? <props.skeleton.CustomTypography><b>????????</b><br />Idk</props.skeleton.CustomTypography>
                                                    : <props.skeleton.CustomTypography><b>????????</b><br />Nothing</props.skeleton.CustomTypography>                                                                                          
                                                }
                                            </props.skeleton.CustomGrid> 

                                            {
                                                /* ================[ ROW 7 ]=========================================================*/
                                            }

                                            <props.skeleton.CustomGrid item xs={12} md={6}>                                            
                                                {AccountFormIsDisabled
                                                    ? <props.skeleton.CustomTypography><b>????????</b><br />Idk</props.skeleton.CustomTypography>
                                                    : <props.skeleton.CustomTypography><b>????????</b><br />Nothing</props.skeleton.CustomTypography>                                            
                                                }
                                            </props.skeleton.CustomGrid>
                                        </props.skeleton.CustomGrid>   
                                    </TabPanel>

                                    <TabPanel value={tabValue} index={4} skeleton={props.skeleton}>
                                            <props.skeleton.CustomGrid container spacing={2} sx={{ textAlign: 'left' }}>
                                            <props.skeleton.CustomGrid item xs={12} md={12}>
                                                <props.skeleton.CustomGrid container spacing={0}>
                                                    <props.skeleton.CustomGrid item xs={12} md={6}>
                                                        <props.skeleton.CustomTypography variant='h4'>Notification Settings</props.skeleton.CustomTypography> 
                                                    </props.skeleton.CustomGrid>

                                                    <props.skeleton.CustomGrid item xs={12} md={6}>
                                                        <props.skeleton.CustomStack direction="row" justifyContent="flex-end" alignContent="flex-end" spacing={1}>                                                    
                                                            <props.skeleton.CustomItem>
                                                                {!AccountFormIsDisabled
                                                                    ?   <>
                                                                            <props.skeleton.Bar buttonerror="true" padding5lronly="true" center="true" onClick={handleCancel}>Cancel</props.skeleton.Bar>
                                                                        </>
                                                                    : null
                                                                }
                                                            </props.skeleton.CustomItem>
                                                            <props.skeleton.CustomItem>
                                                                {AccountFormIsDisabled
                                                                    ? <props.skeleton.Bar center="true" button="true" padding5lronly="true" onClick={handleEditing}>Edit</props.skeleton.Bar>
                                                                    : <props.skeleton.Bar center="true" button="true" padding5lronly="true" onClick={handleUpdate}>Save</props.skeleton.Bar>
                                                                }                                                            
                                                            </props.skeleton.CustomItem>
                                                        </props.skeleton.CustomStack>
                                                    </props.skeleton.CustomGrid>
                                                </props.skeleton.CustomGrid>
                                            </props.skeleton.CustomGrid>                                        
                                            
                                            {
                                                /* ================[ ROW 1 ]=========================================================*/
                                            }

                                            <props.skeleton.CustomGrid item xs={12} md={6}>                                            
                                                {AccountFormIsDisabled 
                                                    ? <props.skeleton.CustomTypography><b>????????</b><br />Idk</props.skeleton.CustomTypography>
                                                    : <props.skeleton.CustomTypography><b>????????</b><br />Nothing</props.skeleton.CustomTypography>  
                                                }
                                            </props.skeleton.CustomGrid>
                                            <props.skeleton.CustomGrid item xs={12} md={6}>
                                                {AccountFormIsDisabled
                                                    ? <props.skeleton.CustomTypography><b>????????</b><br />Idk</props.skeleton.CustomTypography>
                                                    : <props.skeleton.CustomTypography><b>????????</b><br />Nothing</props.skeleton.CustomTypography>                                             
                                                }
                                            </props.skeleton.CustomGrid>

                                            {
                                                /* ================[ ROW 2 ]=========================================================*/
                                            }

                                            <props.skeleton.CustomGrid item xs={12} md={6}>                                            
                                                {AccountFormIsDisabled
                                                    ? <props.skeleton.CustomTypography><b>????????</b><br />Idk</props.skeleton.CustomTypography>
                                                    : <props.skeleton.CustomTypography><b>????????</b><br />Nothing</props.skeleton.CustomTypography>                                                 
                                                }
                                            </props.skeleton.CustomGrid>
                                            <props.skeleton.CustomGrid item xs={12} md={6}>
                                                {AccountFormIsDisabled
                                                    ? <props.skeleton.CustomTypography><b>????????</b><br />Idk</props.skeleton.CustomTypography>
                                                    : <props.skeleton.CustomTypography><b>????????</b><br />Nothing here</props.skeleton.CustomTypography>                                                                                                                                                                          
                                                }
                                            </props.skeleton.CustomGrid>
                                            
                                            {
                                                /* ================[ ROW 3 ]=========================================================*/
                                            }

                                            <props.skeleton.CustomGrid item xs={12} md={6}>                                            
                                                {AccountFormIsDisabled
                                                    ? <props.skeleton.CustomTypography><b>????????</b><br />Idk</props.skeleton.CustomTypography>
                                                    : <props.skeleton.CustomTypography><b>????????</b><br />Nothing here</props.skeleton.CustomTypography>                                                                             
                                                }
                                            </props.skeleton.CustomGrid>
                                            <props.skeleton.CustomGrid item xs={12} md={6}>
                                                {AccountFormIsDisabled
                                                    ? <props.skeleton.CustomTypography><b>????????</b><br />Idk</props.skeleton.CustomTypography>
                                                    : <props.skeleton.CustomTypography><b>????????</b><br />Nothing here</props.skeleton.CustomTypography>
                                                }
                                            </props.skeleton.CustomGrid>

                                            {
                                                /* ================[ ROW 4 ]=========================================================*/
                                            }

                                            <props.skeleton.CustomGrid item xs={12} md={6}>                                            
                                                {AccountFormIsDisabled
                                                    ? <props.skeleton.CustomTypography><b>????????</b><br />Idk</props.skeleton.CustomTypography>
                                                    : <props.skeleton.CustomTypography><b>????????</b><br />Nothing</props.skeleton.CustomTypography>                                       
                                                }
                                            </props.skeleton.CustomGrid>
                                            <props.skeleton.CustomGrid item xs={12} md={6}>
                                                {AccountFormIsDisabled
                                                    ? <props.skeleton.CustomTypography><b>????????</b><br />Idk</props.skeleton.CustomTypography>
                                                    : <props.skeleton.CustomTypography><b>????????</b><br />Nothing</props.skeleton.CustomTypography>                                                                                       
                                                }
                                            </props.skeleton.CustomGrid>

                                            {
                                                /* ================[ ROW 5 ]=========================================================*/
                                            }

                                            <props.skeleton.CustomGrid item xs={12} md={6}>                                            
                                                {AccountFormIsDisabled
                                                    ? <props.skeleton.CustomTypography><b>????????</b><br />Idk</props.skeleton.CustomTypography>
                                                    : <props.skeleton.CustomTypography><b>????????</b><br />Nothing</props.skeleton.CustomTypography>                                                  
                                                }
                                            </props.skeleton.CustomGrid>
                                            <props.skeleton.CustomGrid item xs={12} md={6}>
                                                {AccountFormIsDisabled
                                                    ? <props.skeleton.CustomTypography><b>????????</b><br />Idk</props.skeleton.CustomTypography>
                                                    : <props.skeleton.CustomTypography><b>????????</b><br />Nothing</props.skeleton.CustomTypography>                                                     
                                                }
                                            </props.skeleton.CustomGrid>

                                            {
                                                /* ================[ ROW 6 ]=========================================================*/
                                            }

                                            <props.skeleton.CustomGrid item xs={12} md={6}>                                            
                                                {AccountFormIsDisabled
                                                    ? <props.skeleton.CustomTypography><b>????????</b><br />Idk</props.skeleton.CustomTypography>
                                                    : <props.skeleton.CustomTypography><b>????????</b><br />Nothing</props.skeleton.CustomTypography>                                                                                          
                                                }
                                            </props.skeleton.CustomGrid> 

                                            {
                                                /* ================[ ROW 7 ]=========================================================*/
                                            }

                                            <props.skeleton.CustomGrid item xs={12} md={6}>                                            
                                                {AccountFormIsDisabled
                                                    ? <props.skeleton.CustomTypography><b>????????</b><br />Idk</props.skeleton.CustomTypography>
                                                    : <props.skeleton.CustomTypography><b>????????</b><br />Nothing</props.skeleton.CustomTypography>                                            
                                                }
                                            </props.skeleton.CustomGrid>
                                        </props.skeleton.CustomGrid>
                                    </TabPanel>
                                    </form>
                                </props.skeleton.CustomBox>                            
                            </props.skeleton.CustomGrid>
                        </props.skeleton.CustomGrid>
                    </props.skeleton.CustomGrid>
                </props.skeleton.CustomGrid>
            </props.skeleton.CustomStack>
        </props.skeleton.InnerContent>
        </>
    )
}
// [][][][][][][][][][][][][][][][][][][][][ START IMPORTS ][][][][][][][][][][][][][][][][][][][][][][][][][][][]
// ####################### GLOBAL ############################################################################
// Import from React
// eslint-disable-next-line
import { useState, useEffect } from 'react'

// Import Toast
// eslint-disable-next-line
import { toast } from 'react-toastify'

// Import Auth
import { useAuth, usePost } from '../../middleware/contextHooks'

// Import reactDOM
import { useNavigate } from 'react-router-dom'

// Import utils
import { DeTemps } from '../../middleware/utils'
// ####################### END OF GLOBAL #####################################################################

// ####################### SPECIFIC ##########################################################################
// Import globalStyles elements
//import { CustomContainer as Container } from '../../themes/Darwinian2023/skeleton/global'

// Import Icons
// eslint-disable-next-line
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import CalendarIcon from '@mui/icons-material/CalendarMonth'
import LocationIcon from '@mui/icons-material/LocationOn'
import RealityIcon from '@mui/icons-material/Public'
import VerifiedIcon from '@mui/icons-material/Verified'
// eslint-disable-next-line
import WarningIcon from '@mui/icons-material/Warning'

// Import from MUI
import {
    
} from '@mui/material'

/*
// Import mainStyles
import { 
    Bar,
    CustomBox as Box,
    //CustomButton as Button,
    CustomGrid as Grid,
    CustomLink,
    CustomItem as Item,
    CustomStack as Stack,
    Heading,
    //Para,    
    CustomBox,
    //CustomTextField as TextField
    CustomTypography as Typography
} from '../../themes/Darwinian2023/skeleton/dash'
*/

// Import BreadCrumbs
//import { BreadCrumbs } from '../../components/BreadCrumbs'

// Import components
//import PublicSlice from '../../components/PublicSlice'
import { Spacer } from '../../components/Addon/Spacer'
import { BreadCrumbs } from '../../components/BreadCrumbs'
// ####################### END OF SPECIFIC ##################################################################
// [][][][][][][][][][][][][][][][][][][][][ END IMPORTS ][][][][][][][][][][][][][][][][][][][][][][][][][][][][]

function loadUserProfileImage (userName, image, settingServerAPIBaseUrl ) {
    return settingServerAPIBaseUrl+"user/file/byuser/"+userName+"/"+image
}

function loadUserHeaderImage (userName, image, settingServerAPIBaseUrl ) {
    return settingServerAPIBaseUrl+"user/file/byuser/"+userName+"/"+image
}

export default function Profile (props) { 
    // [][][][][][][][][][][][][][][][][][][][][ START SETUP ][][][][][][][][][][][][][][][][][][][][][][][][][][]
    // ####################### GLOBAL ############################################################################
    // #region -------------[ Variables ]-------------------------------------------------------------------------
    // Get user logged
    // eslint-disable-next-line
    const {
        // Functions
        getFollowers,

        // Variables
        currentUser, 
        FollowerCount,
        isFollowing,
        UnfollowUser
    } = useAuth()   

    const { 
        // Functions
        getAllSlicesByUserNamePublically,

        // Variables
        theslices
    } = usePost()

    // Set navigate
    // eslint-disable-next-line
    const navigate = useNavigate()

    // #endregion ------------------------------------------------------------------------------------------------
    // #region --------------( FUNCTIONS )------------------------------------------------------------------------
    
    // #endregion ------------------------------------------------------------------------------------------------
    // ####################### END OF GLOBAL #####################################################################

    // ####################### SPECIFIC ##########################################################################
    // #region -------------[ Variables ]-------------------------------------------------------------------------

    // Handle back to users 
    // eslint-disable-next-line
    const handleBackToUsers = () => {
        // Clear PublicProfile
        //setPublicProfile(null)

        // Clear the followers
        //setFollowers(null)

        // Clear the follower count
        //setFollowerCount(0)

        // Reset RetrievedPublicProfile
        //setRetrievedPublicProfile(false)

        // Navigate
        //navigate("/@")

        toast.info(`This is not your public profile, therefore going back to users is not possible.`, {theme: 'light'})
    }

    // Handle Edit Profile
    // eslint-disable-next-line
    const handleEditProfile = () => {
        toast.info('WIP: Editing profile right on this page', { theme: 'light' })
    }

    // Handle Follow
    // eslint-disable-next-line
    const handleFollowUser = () => {
        toast.error('You cannot follow yourself', { theme: 'light' })
    }

    // Handle Give
    // eslint-disable-next-line
    const handleGiveToUser = () => {
        toast.error('You cannot give to yourself', { theme: 'light' })
    }

    // Handle More
    const handleMore = () => {
        toast.info('WIP: More options???', { theme: 'light' })
    }

    // Handle Report
    // eslint-disable-next-line
    const handleReportUser = () => {
        toast.info('Why do you want to report yourself?', { theme: 'light' })
    }

    // Handle Follow
    const handleSendMessageToUser = () => {
        toast.info('You cannot send a message to yourself', { theme: 'light' })
    }

    // Handle Unfollow
    const handleUnfollowUser = (whom) => {
        if (currentUser) {
            UnfollowUser(whom)
        } else {
            toast.error('You must be logged in to unfollow someone.', {theme: 'light'})
        }
    }

    const JoinedDate = DeTemps("prettyShort", currentUser?.createdAt)
    const JoinedDateAgo = DeTemps("timeFrom", currentUser?.createdAt)

    // Stats
    const Following = (6969).toLocaleString()
    //const Followers = (128988750).toLocaleString()
    const Friends = (6969).toLocaleString()

    const UseBorder = false
    const Border = 'thin dashed black'

    // Set up breadcrumbs here
    const BCItems = [
        {
            'name': 'Dashboard',
            'type': 'link',
            'grayed': true,
            "url": '/dashboard',
        }, 
        {
            'name': 'User',
            'type': 'link',
            'grayed': true,
            'url': '/dashboard/account'
        },
        {
            'name': 'My Profile',
            'type': 'text',
            'grayed': false,
            'url': '/dashboard/profile'
        },
    ]

    // #region -------------[ State Variables ]------------------------------------------------------------------
    
    // #endregion -----------------------------------------------------------------------------------------------
    // #endregion -----------------------------------------------------------------------------------------------
    // #region -------------[ Functions ]------------------------------------------------------------------------
    
    // State Rendering for setting profile things
    // All user's slices/posts
    useEffect(() => {
        if (!theslices) {
            getAllSlicesByUserNamePublically(currentUser?.userName, 'anyposts')
        } else {
            setTimeout(() => {
                getAllSlicesByUserNamePublically(currentUser?.userName, 'anyposts')
            }, 3000)
        }
        // eslint-disable-next-line
    }, [ 
        theslices 
    ])

    // Follower count
    useEffect(() => {
        // Get from contxt
        if (!FollowerCount) {
            getFollowers(currentUser?.userName)
        } else {
            setTimeout(() => {
                getFollowers(currentUser?.userName)
            }, 3000)
        }

        // eslint-disable-next-line
    }, [ 
        FollowerCount
    ])

    // State Rendering for debug
    useEffect(() => {
        //if (theslices) {console.log(theslices)}
    }, [ 
        //theslices 
    ])
    // #endregion -----------------------------------------------------------------------------------------------
    // ####################### END OF SPECIFIC ##################################################################
    // [][][][][][][][][][][][][][][][][][][][][ ENDSETUP ][][][][][][][][][][][][][][][][][][][][][][][][][][][]

    // Render
    return (
        <>
        <props.skeleton.InnerContent nopadding>
            <Spacer length={4} />
            <props.skeleton.CustomStack direction={'column'} justifyContent={'space-between'} sx={{  }}>
                <BreadCrumbs list={BCItems} skeleton={props.skeleton} />
                <props.skeleton.CustomBox>
                    <props.skeleton.Heading nobg h6 center>This is <u>not</u> your Public Profile</props.skeleton.Heading>                
                </props.skeleton.CustomBox>    
                <props.skeleton.CustomBox>
                    <props.skeleton.CustomLink nobg="true" padding5="true" href={"/@/"+currentUser?.userName}>Click Here</props.skeleton.CustomLink>&nbsp;to see what others see
                </props.skeleton.CustomBox>                    
            </props.skeleton.CustomStack>

            <Spacer length={1} />

            {
                (currentUser)
                ? (
                    <>
                    {
                        /*
                        <props.skeleton.CustomStack direction={'row'} justifyContent={'center'} sx={{ padding: '10px' }}>
                            <props.skeleton.CustomItem>
                                <props.skeleton.CustomLink href="/">Return Home</props.skeleton.CustomLink>
                            </props.skeleton.CustomItem>
                        </props.skeleton.CustomStack>
                        */
                    }
                    
                    <props.skeleton.CustomStack direction={'column'} spacing={0} justifyContent={'space-between'} sx={{ }}>
                        <props.skeleton.CustomItem>
                            {
                                (currentUser?.headerImage && (currentUser?.headerImage !== "no-headerimage.png" && currentUser?.headerImage !== ""))
                                ? (
                                    <props.skeleton.CustomBox nobg="true" paddingzero="true"
                                        component="img"
                                        sx={{
                                            height: 250,
                                            width: '100%',
                                            maxHeight: { xs: 250, md: 250 },
                                            maxWidth: { xs: '100%', md: '100%' }                            
                                        }}
                                        alt={`${currentUser?.userName}'s Header Image`}
                                        src={loadUserHeaderImage(currentUser?.userName, currentUser?.headerImage, props.settingServerAPIBaseUrl )}
                                    />
                                )
                                : (
                                    <props.skeleton.CustomBox headerimage="true" nobg="true" paddingzero="true"
                                        component="img"
                                        sx={{
                                            height: 250,
                                            width: '100%',
                                            maxHeight: { xs: 250, md: 250 },
                                            maxWidth: { xs: '100%', md: '100%' }                            
                                        }}
                                        alt={`${currentUser?.userName}'s Header Image`}
                                        src={window.location.origin+"/user/thumbs/no-headerimage.png"}
                                    />
                                )
                            }
                        </props.skeleton.CustomItem>
                    </props.skeleton.CustomStack>

                    <props.skeleton.CustomStack direction={'row'} sx={{ justifyContent: { xs: "center", sm: "flex-start", md: "flex-start", lg: "flex-start" } }}>
                        <props.skeleton.CustomItem nobg="true">
                            {
                                (currentUser?.profileImage && (currentUser?.profileImage !== "no-profileimage.png" && currentUser?.profileImage !== ""))
                                ? (
                                    <props.skeleton.CustomBox
                                        component="img"
                                        sx={{
                                            height: 175,
                                            width: 175,
                                            maxHeight: { xs: 175, md: 175 },
                                            maxWidth: { xs: 175, md: 175 },
                                            position: 'relative',
                                            top: -90,
                                            background: 'black',
                                            border: '1px solid black',
                                            left: { xs: 0, sm: 20, md: 20, lg: 20 },
                                        }}
                                        alt={`${currentUser?.userName}'s Profile Image`}
                                        src={loadUserProfileImage(currentUser?.userName, currentUser?.profileImage, props.settingServerAPIBaseUrl )}
                                    />
                                )
                                : (
                                    <props.skeleton.CustomBox
                                        component="img"
                                        sx={{
                                            height: 175,
                                            width: 175,
                                            maxHeight: { xs: 175, md: 175 },
                                            maxWidth: { xs: 175, md: 175 },
                                            position: 'relative',
                                            top: -90,
                                            background: 'black', 
                                            border: '1px solid black',
                                            left: { xs: 0, sm: 20, md: 20, lg: 20 }
                                        }}
                                        alt={`${currentUser?.userName}'s Profile Image`}
                                        src={window.location.origin+"/user/thumbs/no-profileimage.png"}
                                    />
                                )
                            }                                       
                        </props.skeleton.CustomItem>
                    </props.skeleton.CustomStack>

                    <props.skeleton.CustomGrid container spacing={0} sx={{ padding: '10px', position: 'relative', top: -70 }}>
                        {/* #region -----------------[ UserBar - mobile ]----------------------------------------------------------------- */}
                        <props.skeleton.CustomGrid item xs={12} sm={2} md={2} lg={2} alignItems={'center'} sx={{
                            display: { xs: 'flex', sm: 'none', md: 'none', lg: 'none' },
                            justifyContent: {xs: 'center', sm: 'flex-end', md: 'flex-end', lg: 'flex-end'},
                            marginBottom: '10px',
                            border: (UseBorder) ? Border : 'none'
                        }}>
                            <props.skeleton.CustomStack direction={'row'} spacing={1}>
                                <props.skeleton.CustomItem>
                                    <props.skeleton.Bar button="true" padding5lronly="true" center="true" sx={{ display: { xs: 'flex', sm: 'flex', md: 'flex', lg: 'flex' }, maxWidth: 80 }} onClick={() => handleBackToUsers()}><ArrowBackIcon /> Users</props.skeleton.Bar>
                                </props.skeleton.CustomItem>
                                <props.skeleton.CustomItem>
                                    <props.skeleton.Bar button="true" padding5lronly="true" center="true" sx={{ display: { xs: 'flex', sm: 'flex', md: 'flex', lg: 'flex' }, maxWidth: 80 }} onClick={() => handleReportUser()}><WarningIcon /></props.skeleton.Bar>
                                </props.skeleton.CustomItem>
                                <props.skeleton.CustomItem>
                                    {
                                        isFollowing
                                        ? <props.skeleton.Bar button="true" padding5lronly="true" center="true" sx={{ display: { xs: 'flex', sm: 'flex', md: 'flex', lg: 'flex' }, maxWidth: 80 }} onClick={() => handleUnfollowUser(currentUser?.userName)}>Unfollow</props.skeleton.Bar>
                                        : <props.skeleton.Bar button="true" padding5lronly="true" center="true" sx={{ display: { xs: 'flex', sm: 'flex', md: 'flex', lg: 'flex' }, maxWidth: 80 }} onClick={() => handleFollowUser(currentUser?.userName)}>Follow</props.skeleton.Bar>
                                    }
                                </props.skeleton.CustomItem>
                                <props.skeleton.CustomItem>
                                    <props.skeleton.Bar button="true" padding5lronly="true" center="true" sx={{ display: { xs: 'flex', sm: 'flex', md: 'flex', lg: 'flex' }, maxWidth: 80 }} onClick={() => handleGiveToUser()}>Give</props.skeleton.Bar>
                                </props.skeleton.CustomItem>
                            </props.skeleton.CustomStack>
                        </props.skeleton.CustomGrid>
                        {/* #endregion --------------------------------------------------------------------------------------------------- */}
                        
                        {/* #region -----------------[ DisplayName - desktop ]------------------------------------------------------------ */}
                        <props.skeleton.CustomGrid item xs={12} sm={9.83} md={9.85} lg={9.85} sx={{
                            justifyContent: {xs: 'center', sm: 'flex-start', md: 'flex-start', lg: 'flex-start'},
                            alignItems: {xs: 'center', sm: 'flex-start', md: 'flex-start', lg: 'flex-start'},
                            display: {xs: 'none', sm: 'flex', md: 'flex', lg: 'flex'},
                            marginLeft: { xs: 0, sm: 1, md: 1, lg: 1 },
                            marginBottom: '0px',
                            border: (UseBorder) ? Border : 'none'
                        }}>
                            <props.skeleton.CustomStack direction={'row'} spacing={1} alignItems={'center'} sx={{  }}>
                                <props.skeleton.CustomItem>
                                    <props.skeleton.CustomStack direction={'row'} alignItems={'center'}>
                                        <props.skeleton.CustomItem>
                                            <props.skeleton.Heading nobg h2>
                                                {currentUser?.firstName + " " + currentUser?.lastName}
                                            </props.skeleton.Heading>
                                            
                                        </props.skeleton.CustomItem>
                                        <props.skeleton.CustomItem>
                                            <props.skeleton.Heading nobg>
                                                { currentUser?.isVerified ? <VerifiedIcon /> : "" }
                                            </props.skeleton.Heading>
                                        </props.skeleton.CustomItem>
                                    </props.skeleton.CustomStack>                            
                                </props.skeleton.CustomItem>
                            </props.skeleton.CustomStack>
                        </props.skeleton.CustomGrid>
                        {/* #endregion -------------------------------------------------------------------------------------------------- */}

                        {/* #region -----------------[ DisplayName - mobile ]------------------------------------------------------------ */}
                        <props.skeleton.CustomGrid item xs={12} sm={9.83} md={9.85} lg={9.85} sx={{
                            justifyContent: {xs: 'center', sm: 'flex-start', md: 'flex-start', lg: 'flex-start'},
                            alignItems: {xs: 'center', sm: 'flex-start', md: 'flex-start', lg: 'flex-start'},
                            display: {xs: 'flex', sm: 'none', md: 'none', lg: 'none'},
                            marginLeft: { xs: 0, sm: 1, md: 1, lg: 1 },
                            marginBottom: '0px',
                            border: (UseBorder) ? Border : 'none'
                        }}>
                            <props.skeleton.CustomStack direction={'row'} spacing={1} alignItems={'center'} sx={{  }}>
                                <props.skeleton.CustomItem>
                                    <props.skeleton.CustomStack direction={'row'} alignItems={'center'}>
                                        <props.skeleton.CustomItem nobg="true">
                                            <props.skeleton.Heading nobg h2>
                                                {currentUser?.firstName + " " + currentUser?.lastName}
                                            </props.skeleton.Heading>
                                            
                                        </props.skeleton.CustomItem>
                                        <props.skeleton.CustomItem>
                                            <props.skeleton.Heading nobg>
                                                { currentUser?.isVerified ? <VerifiedIcon /> : "" }
                                            </props.skeleton.Heading>
                                        </props.skeleton.CustomItem>
                                    </props.skeleton.CustomStack>                            
                                </props.skeleton.CustomItem>
                            </props.skeleton.CustomStack>
                        </props.skeleton.CustomGrid>
                        {/* #endregion -------------------------------------------------------------------------------------------------- */}

                        {/* #region -----------------[ UserName - desktop ]-------------------------------------------------------------- */}
                        <props.skeleton.CustomGrid item xs={12} sm={9.83} md={9.85} lg={9.85} alignItems={'center'} sx={{
                            justifyContent: {xs: 'center', sm: 'flex-start', md: 'flex-start', lg: 'flex-start'},
                            marginLeft: { xs: 0, sm: 1, md: 1, lg: 1 },
                            marginBottom: '20px',                            
                            border: (UseBorder) ? Border : 'none',
                            display: {xs: 'none', sm: 'flex', md: 'flex', lg: 'flex'},
                        }}>
                            <props.skeleton.CustomStack direction={'row'} spacing={1} alignItems={'center'} sx={{ }}>
                                <props.skeleton.CustomItem nobg="true">
                                    <props.skeleton.Heading grayed>
                                        @{currentUser?.userName}
                                    </props.skeleton.Heading>
                                </props.skeleton.CustomItem>
                            </props.skeleton.CustomStack>
                        </props.skeleton.CustomGrid>
                        {/* #endregion -------------------------------------------------------------------------------------------------- */}

                        {/* #region -----------------[ UserName - mobile ]--------------------------------------------------------------- */}
                        <props.skeleton.CustomGrid item xs={12} sm={9.83} md={9.85} lg={9.85} alignItems={'center'} sx={{
                            justifyContent: {xs: 'center', sm: 'flex-start', md: 'flex-start', lg: 'flex-start'},
                            marginLeft: { xs: 0, sm: 1, md: 1, lg: 1 },
                            marginBottom: '20px',
                            border: (UseBorder) ? Border : 'none',
                            display: {xs: 'flex', sm: 'none', md: 'none', lg: 'none'},
                        }}>
                            <props.skeleton.CustomStack direction={'row'} spacing={1} alignItems={'center'} sx={{ }}>
                                <props.skeleton.CustomItem nobg="true">
                                    <props.skeleton.Heading grayed>
                                        @{currentUser?.userName}
                                    </props.skeleton.Heading>
                                </props.skeleton.CustomItem>
                            </props.skeleton.CustomStack>
                        </props.skeleton.CustomGrid>
                        {/* #endregion -------------------------------------------------------------------------------------------------- */}
                        
                        {/* #region -----------------[ UserInfo - desktop ]-------------------------------------------------------------- */}
                        <props.skeleton.CustomGrid item xs={12} sm={9.83} md={9.85} lg={9.85} alignItems={'center'} sx={{
                            justifyContent: {xs: 'center', sm: 'flex-start', md: 'flex-start', lg: 'flex-start'},
                            marginLeft: { xs: 0, sm: 1, md: 1, lg: 1 },
                            marginBottom: '10px',
                            border: (UseBorder) ? Border : 'none',
                            paddingTop: '10px',
                            paddingBottom: '10px',
                            display: {xs: 'none', sm: 'flex', md: 'flex', lg: 'flex'},
                        }}>
                            <props.skeleton.CustomStack direction={'row'} spacing={1} alignItems={'center'} sx={{  }}>
                                <props.skeleton.CustomItem nobg="true">
                                    <props.skeleton.CustomStack direction={'row'} alignItems={'center'}>
                                        <props.skeleton.CustomItem nobg="true">
                                            {
                                                (currentUser?.role === "superadmin")
                                                && (
                                                    <>
                                                    <props.skeleton.Heading badge permissiontier3>
                                                        Super Admin
                                                    </props.skeleton.Heading>
                                                    </>
                                                )                                                
                                            }

                                            {
                                                (currentUser?.role === "admin")
                                                && (
                                                    <>
                                                    <props.skeleton.Heading badge permissiontier2>
                                                        Admin
                                                    </props.skeleton.Heading>
                                                    </>
                                                )                                                
                                            }

                                            {
                                                (currentUser?.role === "editor")
                                                && (
                                                    <>
                                                    <props.skeleton.Heading badge permissiontier1>
                                                        Editor
                                                    </props.skeleton.Heading>
                                                    </>
                                                )                                                
                                            }

                                            {
                                                (currentUser?.role === "user")
                                                && (
                                                    <>
                                                    <props.skeleton.Heading badge permissiontier0>
                                                        User
                                                    </props.skeleton.Heading>
                                                    </>
                                                )                                                
                                            }
                                            
                                        </props.skeleton.CustomItem>                                        
                                    </props.skeleton.CustomStack>                            
                                </props.skeleton.CustomItem>
                            </props.skeleton.CustomStack>
                        </props.skeleton.CustomGrid>
                        {/* #endregion -------------------------------------------------------------------------------------------------- */}

                        {/* #region -----------------[ UserInfo - mobile ]--------------------------------------------------------------- */}
                        <props.skeleton.CustomGrid item xs={12} sm={9.83} md={9.85} lg={9.85} alignItems={'center'} sx={{
                            justifyContent: {xs: 'center', sm: 'flex-start', md: 'flex-start', lg: 'flex-start'},
                            marginLeft: { xs: 0, sm: 1, md: 1, lg: 1 },
                            marginBottom: '10px',
                            border: (UseBorder) ? Border : 'none',
                            paddingTop: '10px',
                            paddingBottom: '10px',
                            display: {xs: 'flex', sm: 'none', md: 'none', lg: 'none'},
                        }}>
                            <props.skeleton.CustomStack direction={'row'} spacing={1} alignItems={'center'} sx={{  }}>
                                <props.skeleton.CustomItem nobg="true">
                                    <props.skeleton.CustomStack direction={'row'} alignItems={'center'}>
                                        <props.skeleton.CustomItem nobg="true">
                                            {
                                                (currentUser?.role === "superadmin")
                                                && (
                                                    <>
                                                    <props.skeleton.Heading badge permissiontier3>
                                                        Super Admin
                                                    </props.skeleton.Heading>
                                                    </>
                                                )                                                
                                            }

                                            {
                                                (currentUser?.role === "admin")
                                                && (
                                                    <>
                                                    <props.skeleton.Heading badge permissiontier2>
                                                        Admin
                                                    </props.skeleton.Heading>
                                                    </>
                                                )                                                
                                            }

                                            {
                                                (currentUser?.role === "editor")
                                                && (
                                                    <>
                                                    <props.skeleton.Heading badge permissiontier1>
                                                        Editor
                                                    </props.skeleton.Heading>
                                                    </>
                                                )                                                
                                            }

                                            {
                                                (currentUser?.role === "user")
                                                && (
                                                    <>
                                                    <props.skeleton.Heading badge permissiontier0>
                                                        User
                                                    </props.skeleton.Heading>
                                                    </>
                                                )                                                
                                            }
                                            
                                        </props.skeleton.CustomItem>                                        
                                    </props.skeleton.CustomStack>                            
                                </props.skeleton.CustomItem>
                            </props.skeleton.CustomStack>
                        </props.skeleton.CustomGrid>
                        {/* #endregion -------------------------------------------------------------------------------------------------- */}

                        {/* #region -----------------[ Details ]------------------------------------------------------------------------ */}
                        <props.skeleton.CustomGrid item xs={12} sm={9.83} md={9.85} lg={9.85} alignItems={'center'} sx={{
                            justifyContent: {xs: 'center', sm: 'flex-start', md: 'flex-start', lg: 'flex-start'},
                            marginLeft: { xs: 0, sm: 1, md: 1, lg: 1 },
                            marginBottom: '10px',
                            border: (UseBorder) ? Border : 'none'
                        }}>
                            {/* #region -----------------[ Desktop ]--------------------------------------------------------------------- */}
                            <props.skeleton.CustomStack direction={'column'} spacing={1} sx={{
                                alignItems: { xs: 'center', sm: 'flex-start', md: 'flex-start', lg: 'flex-start'},
                                display: { xs: 'none', sm: 'none', md: 'flex', lg: 'flex'},
                                textAlign: { xs: 'center', sm: 'left', md: 'left', lg: 'left'}
                            }}>
                                <props.skeleton.CustomItem nobg="true">
                                    <props.skeleton.CustomStack direction={'row'} alignItems={'center'}>
                                        <props.skeleton.CustomItem nobg="true">
                                            <props.skeleton.Heading userinfotextarea><AdminPanelSettingsIcon /> {currentUser?.designation} at <b>{currentUser?.company}</b></props.skeleton.Heading>
                                        </props.skeleton.CustomItem>
                                    </props.skeleton.CustomStack>
                                </props.skeleton.CustomItem>

                                <props.skeleton.CustomItem nobg="true">
                                    <props.skeleton.CustomStack direction={'row'} alignItems={'center'}>
                                        <props.skeleton.CustomItem nobg="true">
                                            <props.skeleton.Heading userinfotextarea><LocationIcon /></props.skeleton.Heading>
                                        </props.skeleton.CustomItem>
                                        <props.skeleton.CustomItem nobg="true">
                                            <props.skeleton.Heading userinfotextarea>{currentUser?.location}</props.skeleton.Heading>
                                        </props.skeleton.CustomItem>
                                    </props.skeleton.CustomStack>
                                </props.skeleton.CustomItem>
                                
                                <props.skeleton.CustomItem nobg="true">
                                    <props.skeleton.CustomStack direction={'row'} alignItems={'center'}>
                                        <props.skeleton.CustomItem nobg="true">
                                            <props.skeleton.Heading userinfotextarea><RealityIcon /></props.skeleton.Heading>
                                        </props.skeleton.CustomItem>
                                        <props.skeleton.CustomItem nobg="true">
                                            <props.skeleton.Heading userinfotextarea>{currentUser?.reality}</props.skeleton.Heading>
                                        </props.skeleton.CustomItem>
                                    </props.skeleton.CustomStack>
                                </props.skeleton.CustomItem>
                                
                                <props.skeleton.CustomItem nobg="true">
                                    <props.skeleton.CustomStack direction={'row'} alignItems={'center'}>
                                        <props.skeleton.CustomItem nobg="true">
                                            <props.skeleton.Heading userinfotextarea><CalendarIcon /></props.skeleton.Heading>
                                        </props.skeleton.CustomItem>
                                        <props.skeleton.CustomItem nobg="true">
                                            <props.skeleton.Heading userinfotextarea>Joined {JoinedDate}</props.skeleton.Heading>
                                            <props.skeleton.Heading userinfotextarea grayed>{JoinedDateAgo}</props.skeleton.Heading>
                                        </props.skeleton.CustomItem>
                                    </props.skeleton.CustomStack>
                                </props.skeleton.CustomItem>
                            </props.skeleton.CustomStack>
                            {/* #endregion ----------------------------------------------------------------------------------------------*/}

                            {/* #region -----------------[ Mobile ]----------------------------------------------------------------------*/}
                            <props.skeleton.CustomStack direction={'column'} spacing={1} sx={{
                                alignItems: { xs: 'center', sm: 'flex-start', md: 'flex-start', lg: 'flex-start'},
                                display: { xs: 'flex', sm: 'flex', md: 'none', lg: 'none'},
                                textAlign: { xs: 'center', sm: 'left', md: 'left', lg: 'left'}
                            }}>
                                <props.skeleton.CustomItem nobg="true">
                                    <props.skeleton.CustomStack direction={'row'} alignItems={'center'}>
                                        <props.skeleton.CustomItem nobg="true">
                                            <props.skeleton.Heading userinfotextarea><AdminPanelSettingsIcon /> {currentUser?.designation} at <b>{currentUser?.company}</b></props.skeleton.Heading>
                                        </props.skeleton.CustomItem>
                                    </props.skeleton.CustomStack>
                                </props.skeleton.CustomItem>

                                <props.skeleton.CustomItem nobg="true">
                                    <props.skeleton.CustomStack direction={'row'} alignItems={'center'}>
                                        <props.skeleton.CustomItem nobg="true">
                                            <props.skeleton.Heading userinfotextarea><LocationIcon /></props.skeleton.Heading>
                                        </props.skeleton.CustomItem>
                                        <props.skeleton.CustomItem nobg="true">
                                            <props.skeleton.Heading userinfotextarea>{currentUser?.location}</props.skeleton.Heading>
                                        </props.skeleton.CustomItem>
                                    </props.skeleton.CustomStack>
                                </props.skeleton.CustomItem>
                                
                                <props.skeleton.CustomItem nobg="true">
                                    <props.skeleton.CustomStack direction={'row'} alignItems={'center'}>
                                        <props.skeleton.CustomItem nobg="true">
                                            <props.skeleton.Heading userinfotextarea><RealityIcon /></props.skeleton.Heading>
                                        </props.skeleton.CustomItem>
                                        <props.skeleton.CustomItem nobg="true">
                                            <props.skeleton.Heading userinfotextarea>{currentUser?.reality}</props.skeleton.Heading>
                                        </props.skeleton.CustomItem>
                                    </props.skeleton.CustomStack>
                                </props.skeleton.CustomItem>
                                
                                <props.skeleton.CustomItem nobg="true">
                                    <props.skeleton.CustomStack direction={'row'} alignItems={'center'}>
                                        <props.skeleton.CustomItem nobg="true">
                                            <props.skeleton.Heading userinfotextarea><CalendarIcon /></props.skeleton.Heading>
                                        </props.skeleton.CustomItem>
                                        <props.skeleton.CustomItem nobg="true">
                                            <props.skeleton.Heading userinfotextarea>Joined {JoinedDate}</props.skeleton.Heading>
                                            <props.skeleton.Heading userinfotextarea grayed>{JoinedDateAgo}</props.skeleton.Heading>
                                        </props.skeleton.CustomItem>
                                    </props.skeleton.CustomStack>
                                </props.skeleton.CustomItem>

                            </props.skeleton.CustomStack>
                            {/* #endregion ----------------------------------------------------------------------------------------------- */}
                        </props.skeleton.CustomGrid>
                        {/* #endregion --------------------------------------------------------------------------------------------------- */}

                        {/* #region -----------------[ UserBar - Desktop ]--------------------------------------------------------------- */}
                        <props.skeleton.CustomGrid item xs={12} sm={2} md={2} lg={2} alignItems={'center'} sx={{
                            display: { xs: 'none', sm: 'flex', md: 'flex', lg: 'flex' },
                            position: 'relative',
                            top: -360,
                            justifyContent: {xs: 'center', sm: 'flex-end', md: 'flex-end', lg: 'flex-end'}
                        }}>
                            <props.skeleton.CustomStack direction={'row'} spacing={1}>
                                <props.skeleton.CustomItem>
                                    <props.skeleton.Bar button="true" padding5lronly="true" center="true" sx={{ display: { xs: 'flex', sm: 'flex', md: 'flex', lg: 'flex' }, maxWidth: 80 }} onClick={() => handleBackToUsers()}><ArrowBackIcon /> Users</props.skeleton.Bar>
                                </props.skeleton.CustomItem>
                                <props.skeleton.CustomItem>
                                    <props.skeleton.Bar button="true" padding5lronly="true" center="true" sx={{ display: { xs: 'flex', sm: 'flex', md: 'flex', lg: 'flex' }, maxWidth: 80 }} onClick={() => handleReportUser()}><WarningIcon /></props.skeleton.Bar>
                                </props.skeleton.CustomItem>
                                <props.skeleton.CustomItem>
                                    <props.skeleton.Bar button="true" padding5lronly="true" center="true" sx={{ display: { xs: 'flex', sm: 'flex', md: 'flex', lg: 'flex' }, maxWidth: 80 }} onClick={() => handleGiveToUser()}>Give</props.skeleton.Bar>
                                </props.skeleton.CustomItem>
                                <props.skeleton.CustomItem>
                                    {
                                        isFollowing
                                        ? <props.skeleton.Bar button="true" padding5lronly="true" center="true" sx={{ display: { xs: 'flex', sm: 'flex', md: 'flex', lg: 'flex' }, maxWidth: 80 }} onClick={() => handleUnfollowUser(currentUser?.userName)}>Unfollow</props.skeleton.Bar>
                                        : <props.skeleton.Bar button="true" padding5lronly="true" center="true" sx={{ display: { xs: 'flex', sm: 'flex', md: 'flex', lg: 'flex' }, maxWidth: 80 }} onClick={() => handleFollowUser(currentUser?.userName)}>Follow</props.skeleton.Bar>
                                    }                                        
                                </props.skeleton.CustomItem>
                            </props.skeleton.CustomStack>
                        </props.skeleton.CustomGrid>
                        {/* #endregion --------------------------------------------------------------------------------------------------- */}

                        {/* #region -----------------[ Desktop - Stats ]------------------------------------------------------------------ */}
                        <props.skeleton.CustomGrid item xs={12} sm={9.83} md={9.85} lg={9.85} alignItems={'center'} sx={{
                            justifyContent: {xs: 'center', sm: 'flex-start', md: 'flex-start', lg: 'flex-start'},
                            display: { xs: 'none', sm: 'none', md: 'flex', lg: 'flex' },
                            marginLeft: { xs: 0, sm: 1, md: 1, lg: 1 },
                            border: (UseBorder) ? Border : 'none',
                            paddingTop: '0px',
                            paddingBottom: '30px'
                        }}>
                            <props.skeleton.CustomStack userinfobar="true" direction={'row'} spacing={1} alignItems={'center'} sx={{ }}>
                                <props.skeleton.CustomItem nobg>
                                    <props.skeleton.CustomItem nobg>
                                        <props.skeleton.CustomStack direction={'row'} spacing={4} alignItems={'center'}>
                                            <props.skeleton.CustomItem nobg userinfobar>
                                                <props.skeleton.Heading nobg h7>
                                                    <b>{Friends}</b> Friends
                                                </props.skeleton.Heading>
                                            </props.skeleton.CustomItem>
                                            <props.skeleton.CustomItem nobg userinfobar>
                                                <props.skeleton.Heading nobg h7>
                                                    <b>{FollowerCount}</b> Follower{(FollowerCount === 1) ? "" : "s"}
                                                </props.skeleton.Heading>
                                            </props.skeleton.CustomItem>
                                            <props.skeleton.CustomItem nobg userinfobar>
                                                <props.skeleton.Heading nobg h7>
                                                    <b>{Following}</b> Following
                                                </props.skeleton.Heading>
                                            </props.skeleton.CustomItem>
                                            <props.skeleton.CustomItem nobg userinfobar>
                                                <props.skeleton.Heading nobg h7>
                                                    <props.skeleton.Bar button="true" padding5lronly="true" center="true" sx={{ display: { xs: 'flex', sm: 'flex', md: 'flex', lg: 'flex' }, maxWidth: 80 }} onClick={() => handleSendMessageToUser()}>Message</props.skeleton.Bar>
                                                </props.skeleton.Heading>
                                            </props.skeleton.CustomItem>
                                            <props.skeleton.CustomItem nobg userinfobar>
                                                <props.skeleton.Heading nobg h7>
                                                <props.skeleton.Bar button="true" padding5lronly="true" center="true" sx={{ display: { xs: 'flex', sm: 'flex', md: 'flex', lg: 'flex' }, maxWidth: 80 }} onClick={() => handleMore()}>More</props.skeleton.Bar>
                                                </props.skeleton.Heading>
                                            </props.skeleton.CustomItem>
                                        </props.skeleton.CustomStack>
                                    </props.skeleton.CustomItem>
                                </props.skeleton.CustomItem>
                            </props.skeleton.CustomStack>
                        </props.skeleton.CustomGrid>
                        {/* #endregion --------------------------------------------------------------------------------------------------- */}

                        {/* #region -----------------[ Mobile - stats ]------------------------------------------------------------------- */}
                        <props.skeleton.CustomGrid item xs={12} sm={9.83} md={9.85} lg={9.85} alignItems={'center'} sx={{
                            padding: '0px',
                            marginBottom: '0px',
                            justifyContent: {xs: 'center', sm: 'flex-start', md: 'flex-start', lg: 'flex-start'},
                            display: { xs: 'flex', sm: 'flex', md: 'none', lg: 'none' },
                            marginLeft: { xs: 0, sm: 1, md: 1, lg: 1 },
                            border: (UseBorder) ? Border : 'none'
                        }}>
                            <props.skeleton.CustomStack userinfobar="true" direction={'row'} spacing={1} alignItems={'center'} sx={{ }}>
                                <props.skeleton.CustomItem nobg>
                                    <props.skeleton.CustomItem nobg>
                                        <props.skeleton.CustomStack direction={'row'} spacing={4} alignItems={'center'}>
                                            <props.skeleton.CustomItem nobg userinfobar>
                                                <props.skeleton.Heading nobg h7>
                                                    <b>{Friends}</b> Friends
                                                </props.skeleton.Heading>
                                            </props.skeleton.CustomItem>
                                            <props.skeleton.CustomItem nobg userinfobar>
                                                <props.skeleton.Heading nobg h7>
                                                    <b>{FollowerCount}</b> Follower{(FollowerCount === 1) ? "" : "s"}
                                                </props.skeleton.Heading>
                                            </props.skeleton.CustomItem>
                                            <props.skeleton.CustomItem nobg userinfobar>
                                                <props.skeleton.Heading nobg h7>
                                                    <b>{Following}</b> Following
                                                </props.skeleton.Heading>
                                            </props.skeleton.CustomItem>
                                            
                                        </props.skeleton.CustomStack>
                                    </props.skeleton.CustomItem>
                                    <props.skeleton.CustomItem nobg>
                                        &nbsp;
                                    </props.skeleton.CustomItem>
                                </props.skeleton.CustomItem>
                            </props.skeleton.CustomStack>
                        </props.skeleton.CustomGrid>
                        {/* #endregion ------------------------------------------------------------------------------------------------- */}

                        {/* #region -----------------[ Mobile - Action Bar ]-------------------------------------------------------------- */}
                        <props.skeleton.CustomGrid item xs={12} sm={9.83} md={9.85} lg={9.85} alignItems={'center'} sx={{
                            marginBottom: '10px',
                            justifyContent: {xs: 'center', sm: 'flex-start', md: 'flex-start', lg: 'flex-start'},
                            display: { xs: 'flex', sm: 'flex', md: 'none', lg: 'none' },
                            marginLeft: { xs: 0, sm: 1, md: 1, lg: 1 },
                            border: (UseBorder) ? Border : 'none'
                        }}>
                            <props.skeleton.CustomStack direction={'row'} spacing={1} alignItems={'center'} sx={{ }}>
                                <props.skeleton.CustomItem nobg="true">
                                    <props.skeleton.CustomItem nobg="true">
                                        <props.skeleton.CustomStack direction={'row'} spacing={4}>
                                            <props.skeleton.CustomItem nobg userinfobar>
                                                <props.skeleton.Heading h7>
                                                    <props.skeleton.Bar button="true" padding5lronly="true" center="true" sx={{ display: { xs: 'flex', sm: 'flex', md: 'flex', lg: 'flex' }, maxWidth: 80 }} onClick={() => handleSendMessageToUser()}>Message</props.skeleton.Bar>
                                                </props.skeleton.Heading>
                                            </props.skeleton.CustomItem>
                                            <props.skeleton.CustomItem nobg userinfobar>
                                                <props.skeleton.Heading h7>
                                                <props.skeleton.Bar button="true" padding5lronly="true" center="true" sx={{ display: { xs: 'flex', sm: 'flex', md: 'flex', lg: 'flex' }, maxWidth: 80 }} onClick={() => handleMore()}>More</props.skeleton.Bar>
                                                </props.skeleton.Heading>
                                            </props.skeleton.CustomItem>
                                        </props.skeleton.CustomStack>
                                    </props.skeleton.CustomItem>
                                </props.skeleton.CustomItem>
                            </props.skeleton.CustomStack>
                        </props.skeleton.CustomGrid>
                        {/* #endregion --------------------------------------------------------------------------------------------------- */}

                        {/* #region -----------------[ Bio - desktop ]-------------------------------------------------------------------- */}
                        <props.skeleton.CustomGrid item xs={12} sm={12} md={12} lg={12} alignItems={'center'} sx={{
                            justifyContent: {xs: 'center', sm: 'flex-start', md: 'flex-start', lg: 'flex-start'},
                            marginLeft: { xs: 0, sm: 1, md: 1, lg: 1 },
                            paddingTop: '30px',
                            marginBottom: '10px',
                            border: (UseBorder) ? Border : 'none',
                            display: {xs: 'none', sm: 'flex', md: 'flex', lg: 'flex'},
                        }}>
                            <props.skeleton.CustomGrid container spacing={0}>
                            {/* #region ----[ Left Column ]--------------------------------------------------------------------------------*/}
                            <props.skeleton.CustomGrid item xs={12} sm={12} md={8} lg={8} sx={{ 
                                paddingBottom: { xs: '50px', sm: '50px', md: '0px', lg: '0px'}
                             }}>                                
                                <props.skeleton.CustomGrid container>
                                    {/* #region ---[ Left ]------------------------------------------------------------------------------- */}
                                    <props.skeleton.CustomGrid item xs={12} sm={12} md={12} lg={12} sx={{  }}>
                                        <props.skeleton.Heading nobg h2>Professional Bio</props.skeleton.Heading>

                                        <props.skeleton.Heading nobg userinfotextarea>
                                            {currentUser?.bio}
                                        </props.skeleton.Heading>
                                    </props.skeleton.CustomGrid>
                                    {/* #endregoin --------------------------------------------------------------------------------------- */}
                                </props.skeleton.CustomGrid>                                
                            </props.skeleton.CustomGrid>                            
                            {/* #endregion ----------------------------------------------------------------------------------------------- */}

                            {/* #region ----[ Right Column ]------------------------------------------------------------------------------ */}
                            <props.skeleton.CustomGrid item xs={12} sm={11.9} md={4} lg={4} sx={{
                                paddingBottom: { xs: '0px', sm: '0px', md: '0px', lg: '0px'}
                             }}>
                                <props.skeleton.CustomGrid container>
                                    <props.skeleton.CustomGrid item xs={12}>
                                        <props.skeleton.Heading nobg h2>Where I am</props.skeleton.Heading>
                                    </props.skeleton.CustomGrid>

                                    <props.skeleton.CustomGrid item>
                                        <props.skeleton.Heading nobg userinfotextarea>
                                            WIP: Display other networks in<br />
                                            ------------------------------<br />
                                            llthing.com - Published Author<br />
                                            onlyfoxes.com - Top-tier poster<br />
                                            onlytunnels.com - A++ Rater<br />
                                        </props.skeleton.Heading>
                                    </props.skeleton.CustomGrid>
                                </props.skeleton.CustomGrid>
                            </props.skeleton.CustomGrid>
                            {/* #endregion ----------------------------------------------------------------------------------------------- */}
                            </props.skeleton.CustomGrid>                            
                        </props.skeleton.CustomGrid>
                        {/* #endregion --------------------------------------------------------------------------------------------------- */}

                        {/* #region -----------------[ Bio - mobile ]--------------------------------------------------------------------- */}
                        <props.skeleton.CustomGrid item xs={12} sm={12} md={12} lg={12} alignItems={'center'} sx={{
                            justifyContent: {xs: 'center', sm: 'flex-start', md: 'flex-start', lg: 'flex-start'},
                            marginLeft: { xs: 0, sm: 1, md: 1, lg: 1 },
                            paddingTop: '30px',
                            marginBottom: '10px',
                            border: (UseBorder) ? Border : 'none',
                            display: {xs: 'flex', sm: 'none', md: 'none', lg: 'none'},
                        }}>
                            <props.skeleton.CustomGrid container spacing={0}>
                            {/* #region ----[ Left Column ]--------------------------------------------------------------------------------*/}
                            <props.skeleton.CustomGrid item xs={12} sm={12} md={8} lg={8} sx={{ 
                                paddingBottom: { xs: '50px', sm: '50px', md: '0px', lg: '0px'}
                             }}>                                
                                <props.skeleton.CustomGrid container>
                                    {/* #region ---[ Left ]------------------------------------------------------------------------------- */}
                                    <props.skeleton.CustomGrid item xs={12} sm={12} md={12} lg={12} sx={{  }}>
                                        <props.skeleton.Heading nobg h2 center>Professional Bio</props.skeleton.Heading>

                                        <props.skeleton.Heading nobg center userinfotextarea>
                                            {currentUser?.bio}
                                        </props.skeleton.Heading>
                                    </props.skeleton.CustomGrid>
                                    {/* #endregoin --------------------------------------------------------------------------------------- */}
                                </props.skeleton.CustomGrid>                                
                            </props.skeleton.CustomGrid>                            
                            {/* #endregion ----------------------------------------------------------------------------------------------- */}

                            {/* #region ----[ Right Column ]------------------------------------------------------------------------------ */}
                            <props.skeleton.CustomGrid item xs={12} sm={11.9} md={4} lg={4} sx={{
                                paddingBottom: { xs: '0px', sm: '0px', md: '0px', lg: '0px'}
                             }}>
                                <props.skeleton.CustomGrid container>
                                    <props.skeleton.CustomGrid item xs={12}>
                                        <props.skeleton.Heading nobg h2 center>Where I am</props.skeleton.Heading>
                                    </props.skeleton.CustomGrid>

                                    <props.skeleton.CustomGrid item xs={12} sm={12} md={12} lg={12} sx={{  }}>
                                        <props.skeleton.Heading nobg center userinfotextarea>
                                            WIP: Display other networks in                                            
                                        </props.skeleton.Heading>
                                    </props.skeleton.CustomGrid>
                                </props.skeleton.CustomGrid>
                            </props.skeleton.CustomGrid>
                            {/* #endregion ----------------------------------------------------------------------------------------------- */}
                            </props.skeleton.CustomGrid>                            
                        </props.skeleton.CustomGrid>
                        {/* #endregion --------------------------------------------------------------------------------------------------- */}
                                        
                    </props.skeleton.CustomGrid>
                    
                        
                    {/* #region -----------------[ User Feed - desktop ]------------------------------------------------------------------ */}
                    <props.skeleton.CustomGrid container spacing={0} sx={{ 
                        border: 'thin solid back',
                        padding: '20px',
                        display: { xs: 'none', sm: 'flex', md: 'flex', lg: 'flex'}                        
                    }}>
                        {/* #region ----[ Left Column ]----------------------------------------------------------------------------------- */}
                        <props.skeleton.CustomGrid item xs={12} sm={12} md={8} lg={8} sx={{
                            paddingBottom: { xs: '50px', sm: '50px', md: '0px', lg: '0px'}
                        }}>
                            <props.skeleton.Heading nobg h2>My Feed</props.skeleton.Heading>
                            {/* #region ---[ Feed information ]--------------------------------------------------------------------------- */}
                            <props.skeleton.Heading nobg userinfotextarea>WIP: Posts will display here</props.skeleton.Heading>
                            {/* #endregion ------------------------------------------------------------------------------------------------*/}
                        </props.skeleton.CustomGrid>
                        {/* #endregion ----------------------------------------------------------------------------------------------------*/}

                        {/* #region ----[ Right Column ]---------------------------------------------------------------------------------- */}                        
                        <props.skeleton.CustomGrid item xs={12} sm={12} md={4} lg={4} sx={{
                            
                        }}>
                            <props.skeleton.Heading nobg h2>My Widgets</props.skeleton.Heading>

                            {/* #region ---[ Sidebar Widgets ]---------------------------------------------------------------------------- */}
                            <props.skeleton.CustomGrid container spacing={0}>
                                {/* #region --[ Widget: Template ]-------------------------------------------------------------------------*/}
                                <props.skeleton.CustomGrid item xs={12}>
                                    <props.skeleton.Heading nobg userinfotextarea>Widget: Similiar Users</props.skeleton.Heading>
                                </props.skeleton.CustomGrid>
                                {/* #endregion --------------------------------------------------------------------------------------------*/}

                                {/* #region --[ Widget: Template ]-------------------------------------------------------------------------*/}
                                <props.skeleton.CustomGrid item xs={12}>
                                    <props.skeleton.Heading nobg userinfotextarea>Widget: My Other Socials</props.skeleton.Heading>
                                </props.skeleton.CustomGrid>
                                {/* #endregion --------------------------------------------------------------------------------------------*/}

                                {/* #region --[ Widget: Template ]-------------------------------------------------------------------------*/}
                                <props.skeleton.CustomGrid item xs={12}>
                                    <props.skeleton.Heading nobg userinfotextarea>Widget: TBD</props.skeleton.Heading>
                                </props.skeleton.CustomGrid>
                                {/* #endregion --------------------------------------------------------------------------------------------*/}

                            </props.skeleton.CustomGrid>
                            {/* #endregion ------------------------------------------------------------------------------------------------*/}
                        </props.skeleton.CustomGrid>
                        {/* #endregion ----------------------------------------------------------------------------------------------------*/}
                    </props.skeleton.CustomGrid>
                    {/* #endregion ------------------------------------------------------------------------------------------------------- */}

                    {/* #region -----------------[ User Feed - mobile ]------------------------------------------------------------------- */}
                    <props.skeleton.CustomGrid container spacing={0} sx={{ 
                        border: 'thin solid back',
                        padding: '20px',
                        display: { xs: 'flex', sm: 'none', md: 'none', lg: 'none'}, 
                        justifyContent: {xs: 'center', sm: 'flex-start', md: 'flex-start', lg: 'flex-start'},                      
                    }}>
                        {/* #region ----[ Left Column ]----------------------------------------------------------------------------------- */}
                        <props.skeleton.CustomGrid item xs={12} sm={12} md={8} lg={8} sx={{
                            paddingBottom: { xs: '50px', sm: '50px', md: '0px', lg: '0px'}
                        }}>
                            <props.skeleton.Heading nobg h2 center>My Feed</props.skeleton.Heading>
                            {/* #region ---[ Feed information ]--------------------------------------------------------------------------- */}
                            <props.skeleton.Heading nobg userinfotextarea center>WIP: Posts will display here</props.skeleton.Heading>
                            {/* #endregion ------------------------------------------------------------------------------------------------*/}
                        </props.skeleton.CustomGrid>
                        {/* #endregion ----------------------------------------------------------------------------------------------------*/}

                        {/* #region ----[ Right Column ]---------------------------------------------------------------------------------- */}                        
                        <props.skeleton.CustomGrid item xs={12} sm={12} md={4} lg={4} sx={{
                            
                        }}>
                            <props.skeleton.Heading nobg h2 center>My Widgets</props.skeleton.Heading>

                            {/* #region ---[ Sidebar Widgets ]---------------------------------------------------------------------------- */}
                            <props.skeleton.CustomGrid container spacing={0}>
                                {/* #region --[ Widget: Template ]-------------------------------------------------------------------------*/}
                                <props.skeleton.CustomGrid item xs={12}>
                                    <props.skeleton.Heading nobg userinfotextarea center>Widget: Similiar Users</props.skeleton.Heading>
                                </props.skeleton.CustomGrid>
                                {/* #endregion --------------------------------------------------------------------------------------------*/}

                                {/* #region --[ Widget: Template ]-------------------------------------------------------------------------*/}
                                <props.skeleton.CustomGrid item xs={12}>
                                    <props.skeleton.Heading nobg userinfotextarea center>Widget: My Other Socials</props.skeleton.Heading>
                                </props.skeleton.CustomGrid>
                                {/* #endregion --------------------------------------------------------------------------------------------*/}

                                {/* #region --[ Widget: Template ]-------------------------------------------------------------------------*/}
                                <props.skeleton.CustomGrid item xs={12}>
                                    <props.skeleton.Heading nobg userinfotextarea center>Widget: TBD</props.skeleton.Heading>
                                </props.skeleton.CustomGrid>
                                {/* #endregion --------------------------------------------------------------------------------------------*/}

                            </props.skeleton.CustomGrid>
                            {/* #endregion ------------------------------------------------------------------------------------------------*/}
                        </props.skeleton.CustomGrid>
                        {/* #endregion ----------------------------------------------------------------------------------------------------*/}
                    </props.skeleton.CustomGrid>
                    {/* #endregion ------------------------------------------------------------------------------------------------------- */}
                    </>
                )
                : (
                    <props.skeleton.CustomGrid container sx={{ padding: '20px', justifyContent: 'center' }}>
                        <props.skeleton.CustomGrid item xs={12} sm={12} md={12} lg={12} alignItems={'center'} sx={{
                            justifyContent: {xs: 'center', sm: 'center', md: 'center', lg: 'center'},
                            marginLeft: { xs: 0, sm: 1, md: 1, lg: 1 },
                            marginBottom: '10px',
                            border: (UseBorder) ? Border : 'none'
                        }}>
                            <props.skeleton.CustomStack>
                                <props.skeleton.CustomItem>
                                    <props.skeleton.CustomTypography fontheading1="true" variant='h4'>
                                        Error 418 <props.skeleton.CustomLink noUnderLine href="https://developer.mozilla.org/en-US/docs/Web/HTTP/Status/418" target="_blank">I'm a teapot</props.skeleton.CustomLink> - Profile Not Found
                                    </props.skeleton.CustomTypography>
                                </props.skeleton.CustomItem>

                                <props.skeleton.CustomItem>
                                    <props.skeleton.CustomTypography fontheading1="true" variant='h6'>
                                        <props.skeleton.CustomLink noUnderLine href="/users">Go back</props.skeleton.CustomLink>
                                    </props.skeleton.CustomTypography>
                                </props.skeleton.CustomItem>
                            </props.skeleton.CustomStack>
                        </props.skeleton.CustomGrid>
                    </props.skeleton.CustomGrid>
                )
            }

        </props.skeleton.InnerContent>      
        </>
    )
}
import {createContext, useReducer} from 'react'
import axios from 'axios'
import postReducer from './postReducer'
import * as ActionTypes from '../ContextActions'

export const PostContext = createContext()

export default function PostState(props){
    const initialstate = {
        CreatingSliceSent: false,
        currentSlice: null,
        featuredImage: null,
        featuredslices: null,
        hasDeletedSlice: false,
        hasRestoredSlice: false,
        hasMovedSliceToTrash: false,
        isPermDeletingSlice: false,
        isPermDeletingSliceSent: false,
        isCreatingSlice: false,
        isCreatingSliceSent: false,
        isLikingPost: false,
        isRestoringSlice: false,
        isUpdatingSlice: false,
        isUpdatingSliceSent: false,
        latestFeaturedSlice: null, 
        NewLongSliceSlug: null,
        NewShortSliceSlug: null, 
        PermDeleteSliceData: null,
        PermDeleteSliceSlug: null,
        RetrievedSlice: false,
        sliceAuthor: null,
        sliceComments: null,
        sliceCreated: false,
        sliceDelete: false,        
        SliceFormIsDisabled: true,
        SliceIsMovingToTrash: false,
        sliceLikes: null,
        sliceShares: null,
        SliceDeletingData: null,
        SliceRestoringData: null,
        sliceViews: null,
        sliceViewsVisitors: null, 
        theslices: null,  
        toastsPost: null,
        uploadFiles: null        
    }

    const [state, dispatch] = useReducer(postReducer, initialstate)

    const config = {
        headers: {
            'Content-Type': 'application/json',
            'x-auth-token': localStorage.getItem('token'),
        }
    }

    // #region --------------[ Actions ]--------------

    const clearCurrentSlice = () =>{
        dispatch({type: ActionTypes.CLEAR_CURRENT_SLICE})
    }

    const clearErrorsPost = async () => {
        dispatch({
            type: ActionTypes.CLEAR_ERRORS_POST,
        })
    }

    const clearSlices = async () => {
        dispatch({
            type: ActionTypes.CLEAR_SLICES
        })
    }

    const createSlice = async (type, sliceData) => {
        try {
            //console.log(sliceData)
            const res = await axios.post('/api/v1/'+type+'/create', sliceData, config)
            dispatch({
                type: ActionTypes.NEW_SLICE_SUCCESS,
                payload: res.data
            })
        } catch (err) {
            //console.log(err.response?.data)
            dispatch({
                type: ActionTypes.NEW_SLICE_FAIL,
                payload: err.response?.data,
            })
        }
    }

    // NOTE: This is not Move To Trash
    const deleteSlice = async (type, currentSlug) => {
        try {
            const res = await axios.delete(`/api/v1/`+type+`/delete/${currentSlug}`, config)
            dispatch({
                type: ActionTypes.SLICE_DELETE,
                payload: res.data
            })            
        } catch (err) {
            //console.log(err.response?.data)
            dispatch({
                type: ActionTypes.SLICE_DELETE_FAIL,
                payload: err.response?.data,
            })
        }
    }

    const getAllFeaturedSlices = async (type, limit = null, namespace = null, stat = null, sort = 'DESC', sortBy = 'createdAt') => {
        try {
            const parameters = {
                limit: limit,
                namespace: namespace,
                stat: stat,
                sort: sort,
                sortBy: sortBy
            }
            const res = await axios.post('/api/v1/'+type+'/public', parameters, config)
            dispatch({
                type: ActionTypes.GET_ALLFEATUREDPOSTS_SUCCESS,                
                payload: res.data
            })
        } catch (err) {
            //console.log(err.response?.data)
            dispatch({
                type: ActionTypes.GET_ALLFEATUREDPOSTS_FAIL,
                payload: err.response?.data,
            })
        }
    }

    const getAllSlices = async (type, limit = null, namespace = null, stat = null, order = 'DESC', orderBy = 'createdAt') => {
        try {
            const parameters = {
                limit: limit,
                namespace: namespace,
                stat: stat,
                sort: order,
                sortBy: orderBy
            }
            //console.log(type)
            const res = await axios.post('/api/v1/'+type+'', parameters, config)

            dispatch({
                type: ActionTypes.GET_ALLSLICES_SUCCESS,                
                payload: res.data
            })
        } catch (err) {
            //console.log(err.response?.data)
            dispatch({
                type: ActionTypes.GET_ALLSLICES_FAIL,
                payload: err.response?.data,
            })
        }
    }

    const getAllSlicesByUserName = async (userName, type, limit = null, namespace = null, stat = null, order = 'DESC', orderBy = 'createdAt') => {
        try {
            const parameters = {
                limit: limit,
                namespace: namespace,
                stat: stat,
                sort: order,
                sortBy: orderBy
            }
            const res = await axios.post('/api/v1/'+type+'/'+userName, parameters, config)
            dispatch({
                type: ActionTypes.GET_ALLSLICES_SUCCESS,
                payload: res.data
            })
        } catch (err) {
            //console.log(err.response?.data)
            dispatch({
                type: ActionTypes.GET_ALLSLICES_FAIL,
                payload: err.response?.data,
            })
        }
    }

    const getAllSlicesPublically = async (type, limit = null, namespace = null, stat = null, order = 'DESC', orderBy = 'createdAt') => {
        try {
            const parameters = {
                limit: limit,
                namespace: namespace,
                stat: stat,
                sort: order,
                sortBy: orderBy
            }
            const res = await axios.post('/api/v1/'+type+'/public', parameters, config)
            dispatch({
                type: ActionTypes.GET_ALLSLICESPUBLICALLY_SUCCESS,
                payload: res.data
            })
        } catch (err) {
            //console.log(err.response?.data)
            dispatch({
                type: ActionTypes.GET_ALLSLICESPUBLICALLY_FAIL,
                payload: err.response?.data,
            })
        }
    }

    const getAllSlicesByUserNamePublically = async (userName, type, limit = null, namespace = null, stat = null, order = 'DESC', orderBy = 'createdAt') => {
        try {
            const parameters = {
                limit: limit,
                namespace: namespace,
                stat: stat,
                sort: order,
                sortBy: orderBy
            }
            const res = await axios.post('/api/v1/'+type+'/byuser/'+userName+'/public', parameters, config)
            dispatch({
                type: ActionTypes.GET_ALLSLICESPUBLICALLY_SUCCESS,
                payload: res.data
            })
        } catch (err) {
            //console.log(err.response?.data)
            dispatch({
                type: ActionTypes.GET_ALLSLICESPUBLICALLY_FAIL,
                payload: err.response?.data,
            })
        }
    }

    const getAllMySlices = async (type, limit = null, namespace = null, stat = null) => {
        try {
            const parameters = {
                limit: limit,
                namespace: namespace,
                stat: stat,
            }
            const res = await axios.post('/api/v1/'+type+'/myposts', parameters, config)
            dispatch({
                type: ActionTypes.GET_ALLMYSLICES_SUCCESS,                
                payload: res.data
            })
        } catch (err) {
            //console.log(err.response?.data)
            dispatch({
                type: ActionTypes.GET_ALLMYSLICES_FAIL,
                payload: err.response?.data,
            })
        }
    }

    const getLatestFeaturedSlice = async (type, limit = null, namespace = null, stat = null, sort = 'DESC', sortBy = 'createdAt') => {
        try {
            const parameters = {
                limit: limit,
                namespace: namespace,
                stat: stat,
                sort: sort,
                sortBy: sortBy
            }
            const res = await axios.post('/api/v1/'+type+'/public', parameters, config)
            dispatch({
                type: ActionTypes.GET_LATESTFEATUREDPOST_SUCCESS,                
                payload: res.data
            })
        } catch (err) {
            //console.log(err.response?.data)
            dispatch({
                type: ActionTypes.GET_LATESTFEATUREDPOST_FAIL,
                payload: err.response?.data,
            })
        }
    }

    /*
    const getMySlices = async (type, limit = null, namespace = null, stat = null) => {
        try {
            const parameters = {
                limit: limit,
                namespace: namespace,
                stat: stat
            }
            const res = await axios.post('/api/v1/'+type+'/myslices', parameters, config)
            dispatch({
                type: ActionTypes.GET_SLICES_SUCCESS,                
                payload: res.data
            })
        } catch (err) {
            //console.log(err.response?.data)
            dispatch({
                type: ActionTypes.SLICE_FAIL,
                payload: err.response?.data,
            })
        }
    }
    */
    
    const getSliceAuthorDetails = async (authorID) => {
        try {
            const res = await axios.get(`/api/v1/user/detail/${authorID}`, config)
            dispatch({
                type: ActionTypes.GET_SLICEAUTHOR_SUCCESS,
                payload: res.data
            })
        } catch (err) {
            //console.log(err.response?.data);
            dispatch({
                type: ActionTypes.GET_SLICEAUTHOR_FAIL,
                payload: err.response?.data,
            })
        }
    }

    const getSliceComments = async (sliceid) => {
        try {
            const parameters = {
                what: "comments"
            }
            const res = await axios.post(`/api/v1/interactions/post/${sliceid}`, parameters, config)

            dispatch({
                type: ActionTypes.GET_SLICECOMMENTS_SUCCESS,
                payload: res.data
            })
        } catch (err) {
            //console.log(err.response?.data);
            dispatch({
                type: ActionTypes.GET_SLICECOMMENTS_FAIL,
                payload: err.response?.data,
            })
        }
    }

    const getSliceLikes = async (sliceid) => {
        try {
            const parameters = {
                what: "likes"
            }
            // Even though this is a "GET" request, in order to pass parameters we must use a post method. Weird right?
            const res = await axios.post(`/api/v1/interactions/post/${sliceid}`, parameters, config)
            dispatch({
                type: ActionTypes.GET_SLICELIKES_SUCCESS,
                payload: res.data
            })
        } catch (err) {
            //console.log(err.response?.data);
            dispatch({
                type: ActionTypes.GET_SLICELIKES_FAIL,
                payload: err.response?.data,
            })
        }
    }

    const getSliceShares = async (sliceid) => {
        try {
            const parameters = {
                what: "shares"
            }
            // Even though this is a "GET" request, in order to pass parameters we must use a post method. Weird right?
            const res = await axios.post(`/api/v1/interactions/post/${sliceid}`, parameters, config)
            dispatch({
                type: ActionTypes.GET_SLICESHARES_SUCCESS,
                payload: res.data
            })
        } catch (err) {
            //console.log(err.response?.data);
            dispatch({
                type: ActionTypes.GET_SLICESHARES_FAIL,
                payload: err.response?.data,
            })
        }
    }

    const getSliceViews = async (sliceid) => {
        try {
            const parameters = {
                what: "views"
            }
            // Even though this is a "GET" request, in order to pass parameters we must use a post method. Weird right?
            const res = await axios.post(`/api/v1/interactions/post/${sliceid}`, parameters, config).then()
            dispatch({
                type: ActionTypes.GET_SLICEVIEWS_SUCCESS,
                payload: res.data
            })
        } catch (err) {
            //console.log(err.response?.data);
            dispatch({
                type: ActionTypes.GET_SLICEVIEWS_FAIL,
                payload: err.response?.data,
            })
        }
    }

    const getSliceViewsVisitors = async (sliceid) => {
        try {
            const parameters = {
                what: "views"
            }
            // Even though this is a "GET" request, in order to pass parameters we must use a post method. Weird right?
            const res = await axios.post(`/api/v1/interactions/post/visitor/${sliceid}`, parameters, config).then()
            dispatch({
                type: ActionTypes.GET_SLICEVIEWSVISITORS_SUCCESS,
                payload: res.data
            })
        } catch (err) {
            //console.log(err.response?.data);
            dispatch({
                type: ActionTypes.GET_SLICEVIEWSVISITORS_FAIL,
                payload: err.response?.data,
            })
        }
    }

    const getSliceById = async (type, sliceId) => {
        try {
            const res = await axios.get('/api/v1/'+type+'/'+sliceId, config)
            dispatch({
                type: ActionTypes.GET_SLICE_SUCCESS,
                payload: res.data
            })
        } catch (err) {
            //console.log(err.response?.data);
            dispatch({
                type: ActionTypes.SLICE_FAIL,
                payload: err.response?.data,
            })
        }
    }

    const getSliceBySlug = async (type, sliceId) => {
        try {
            const res = await axios.get('/api/v1/'+type+'/public/'+sliceId, config)
            dispatch({
                type: ActionTypes.GET_SLICEBYSLUG_SUCCESS,
                payload: res.data
            })
        } catch (err) {
            //console.log(err.response?.data);
            dispatch({
                type: ActionTypes.GET_SLICEBYSLUG_FAIL,
                payload: err.response?.data,
            })
        }
    }    
    
    const getSliceFromSlug = async (type, sliceId) => {
        try {
            const res = await axios.get('/api/v1/'+type+'/'+sliceId, config)
            dispatch({
                type: ActionTypes.GET_SLICEFROMSLUG_SUCCESS,
                payload: res.data
            })
        } catch (err) {
            //console.log(err.response?.data);
            dispatch({
                type: ActionTypes.GET_SLICEFROMSLUG_FAIL,
                payload: err.response?.data,
            })
        }
    }   

    const LikePost = async (type, slug, whom) => {
        try {
            const parameters = {
                type: "post",
                action: type,
                data: {
                    whatslug: slug,
                    whom: whom
                }
            }

            const res = await axios.post('/api/v1/interaction/create', parameters, config)
            dispatch({
                type: ActionTypes.LIKE_POST,
                payload: res.data
            })
        } catch (err) {
            //console.log(err.response.data);
            dispatch({
                type: ActionTypes.LIKE_POST_FAIL,
                payload: err.response.data,
            })
        }
    }

    const updateSlice = async (type, sliceData, currentSlug) => {
        try {
            const res = await axios.put(`/api/v1/`+type+`/update/${currentSlug}`, sliceData, config)
            dispatch({
                type: ActionTypes.UPDATE_SLICE,
                payload: res.data
            })
        } catch (err) {
            //console.log(err.response?.data);
            dispatch({
                type: ActionTypes.UPDATE_SLICE_FAIL,
                payload: err.response?.data,
            })
        }
    }

    const uploadFile = async (type, sliceData, data) => {
        try {
            const config2 = {
                headers: {
                    'x-auth-token': localStorage.getItem('token'),
                }
            }

            //data = {
            //    ...data,
            //    extra: sliceData.extra
            //}

            //console.log(sliceData.extra.postslug)

            // Determine if uploading for a post or creating a new post
            if (sliceData.extra.postslug) {
                const res = axios.post(`/api/v1/`+type+`/upload/${sliceData.extra.postslug}`, data, config2)

                res.then((response) => {
                    //console.log(sliceData.what)
                    
                    // Determine the what
                    switch (sliceData.what) {
                        case 'featuredimage':
                            dispatch({
                                type: ActionTypes.UPLOAD_FEATUREDIMAGE,
                                payload: response.data[0].filename
                            })   
                        break
    
                        default:
                            // Unknown what
                        break
                    }                
                    
                })
            } else {
                const res = axios.post(`/api/v1/`+type+`/upload/`, data, config2)

                res.then((response) => {
                    //console.log(sliceData.what)
                    
                    // Determine the what
                    switch (sliceData.what) {
                        case 'featuredimage':
                            dispatch({
                                type: ActionTypes.UPLOAD_FEATUREDIMAGE,
                                payload: response.data[0].filename
                            })   
                        break
    
                        default:
                            // Unknown what
                        break
                    }                
                    
                })
            }                     
        } catch (err) {
            //console.log(err.response?.data);
            //dispatch({
            //    type: ActionTypes.UPLOAD_FEATUREDIMAGE_FAIL,
            //    payload: err.response?.data,
            //})
        }
    }

    // #region -------------------[ SETTERS ]---------------------------------------------------------------------------------------------------
    const setCurrentSlice = (value) =>{
        dispatch({
            type: ActionTypes.SET_CURRENTSLICE,
            payload: value
        })
    }
    
    const setHasDeletedSlice = (value) =>{
        dispatch({
            type: ActionTypes.SET_SLICE_HASDELETEDSLICE,
            payload: value
        })
    }

    const setHasMovedSliceToTrash = (value) =>{
        dispatch({
            type: ActionTypes.SET_SLICE_HASMOVEDSLICETOTRASH,
            payload: value
        })
    }

    const setHasRestoredSlice = (value) =>{
        dispatch({
            type: ActionTypes.SET_SLICE_HASRESTOREDSLICE,
            payload: value
        })
    }
    
    const setNewLongSliceSlug = (value) =>{
        dispatch({
            type: ActionTypes.SET_NEW_SLICE_SLUG,
            payload: value
        })
    }
    
    const setNewShortSliceSlug = (value) =>{
        dispatch({
            type: ActionTypes.SET_NEW_SHORTSLICE_SLUG,
            payload: value
        })
    }
    
    const setSliceFormIsDisabled = async (value) => {
        dispatch({
            type: ActionTypes.SET_SLICE_FORM_IS_DISABLED,
            payload: value
        })
    }    
    
    const setIsCreatingSlice = (value) =>{
        dispatch({
            type: ActionTypes.SET_ISCREATINGSLICE,
            payload: value
        })
    }
    
    const setIsCreatingSliceSent = (value) =>{
        dispatch({
            type: ActionTypes.SET_ISCREATINGSLICESENT,
            payload: value
        })
    }

    const setIsPermDeletingSlice = (value) =>{
        dispatch({
            type: ActionTypes.SET_ISSLICEPERMDELETING,
            payload: value
        })
    }

    const setIsPermDeletingSliceSent = (value) =>{
        dispatch({
            type: ActionTypes.SET_ISSLICEPERMDELETINGSENT,
            payload: value
        })
    }

    const setIsRestoringSlice = (value) =>{
        dispatch({
            type: ActionTypes.SET_ISSLICERESTORING,
            payload: value
        })
    }

    const setSliceIsMovingToTrash = (value) =>{
        dispatch({
            type: ActionTypes.SET_SLICE_ISMOVINGTOTRASH,
            payload: value
        })
    }

    const setIsUpdatingSlice = (value) =>{
        dispatch({
            type: ActionTypes.SET_ISUPDATING_SLICE,
            payload: value
        })
    }
    
    const setIsUpdatingSliceSent = (value) =>{
        dispatch({
            type: ActionTypes.SET_ISUPDATING_SLICE_SENT,
            payload: value
        })
    }
    
    const setPermDeleteSliceSlug = (value) =>{
        dispatch({
            type: ActionTypes.SET_PERM_DELETE_SLICE_SLUG,
            payload: value
        })
    }

    const setRetrievedSlice = (value) =>{
        dispatch({
            type: ActionTypes.SET_RETRIEVEDSLICE,
            payload: value
        })
    }

    const setSliceDeletingData = (value) =>{
        dispatch({
            type: ActionTypes.SET_SLICEDELETINGDATA,
            payload: value
        })
    }

    const setSliceRestoringData = (value) =>{
        dispatch({
            type: ActionTypes.SET_SLICERESTORINGDATA,
            payload: value
        })
    }
    
    const setTheSlices = (value) =>{
        dispatch({
            type: ActionTypes.SET_THESLICES,
            payload: value
        })
    }
    
    const setUploadFiles = (value) =>{
        dispatch({
            type: ActionTypes.SET_UPLOADFILES,
            payload: value
        })
    }

    // #endregion ------------------------------------------------------------------------------------------------------------------------------

    

    // #endregion

    return (
        <PostContext.Provider value={{
            currentSlice: state.currentSlice,
            featuredslices: state.featuredslices,
            featuredImage: state.featuredImage,
            hasDeletedSlice: state.hasDeletedSlice,
            hasMovedSliceToTrash: state.hasMovedSliceToTrash,
            hasRestoredSlice: state.hasRestoredSlice,
            isPermDeletingSlice: state.isPermDeletingSlice,
            isPermDeletingSliceSent: state.isPermDeletingSliceSent,
            isCreatingSlice: state.isCreatingSlice,
            isCreatingSliceSent: state.isCreatingSliceSent,
            isLikingPost: state.isLikingPost,
            isRestoringSlice: state.isRestoringSlice,
            isMovingToTrash: state.isMovingToTrash,
            isUpdatingSlice: state.isUpdatingSlice,
            isUpdatingSliceSent: state.isUpdatingSliceSent,
            latestFeaturedSlice: state.latestFeaturedSlice,
            NewLongSliceSlug: state.NewLongSliceSlug,
            NewShortSliceSlug: state.NewShortSliceSlug,
            PermDeleteSliceData: state.PermDeleteSliceData,
            PermDeleteSliceSlug: state.PermDeleteSliceSlug,
            RetrievedSlice: state.RetrievedSlice,
            sliceAuthor: state.sliceAuthor,
            sliceComments: state.sliceComments,
            sliceCreated: state.sliceCreated,
            sliceDelete: state.sliceDelete,
            SliceDeletingData: state.SliceDeletingData,            
            SliceFormIsDisabled: state.SliceFormIsDisabled,
            CreatingSliceSent: state.CreatingSliceSent,
            SliceIsMovingToTrash: state.SliceIsMovingToTrash,
            sliceIsUpdating: state.sliceIsUpdating,
            sliceLikes: state.sliceLikes,
            sliceShares: state.sliceShares,
            SliceRestoringData: state.SliceRestoringData,
            sliceViews: state.sliceViews, 
            sliceViewsVisitors: state.sliceViewsVisitors,
            theslices: state.theslices,
            toastsPost: state.toastsPost,            
            uploadFiles: state.uploadFiles,
            
            clearCurrentSlice,
            clearErrorsPost,
            clearSlices,
            createSlice,
            deleteSlice,
            getAllFeaturedSlices,
            getAllSlices,
            getAllSlicesByUserName,
            getAllSlicesPublically,
            getAllSlicesByUserNamePublically,
            getAllMySlices,
            getLatestFeaturedSlice,
            getSliceAuthorDetails,
            getSliceComments,
            getSliceLikes,
            getSliceShares,
            getSliceViews,
            getSliceViewsVisitors,
            getSliceById,          
            getSliceBySlug, 
            getSliceFromSlug,
            LikePost,
            setCurrentSlice,
            setHasDeletedSlice,
            setHasMovedSliceToTrash,
            setHasRestoredSlice,
            setIsPermDeletingSlice,            
            setIsPermDeletingSliceSent,
            setIsCreatingSlice,
            setIsCreatingSliceSent,
            setIsRestoringSlice,
            setIsUpdatingSlice, 
            setIsUpdatingSliceSent,
            setNewLongSliceSlug,  
            setNewShortSliceSlug, 
            setPermDeleteSliceSlug, 
            setRetrievedSlice,          
            setSliceFormIsDisabled,
            setSliceIsMovingToTrash,                 
            setSliceDeletingData,
            setSliceRestoringData,
            setTheSlices,
            setUploadFiles,            
            updateSlice,
            uploadFile                  
        }}>
            {props.children}
        </PostContext.Provider>
    )
}
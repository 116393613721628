export const footer = {
    base: {
        background: "red",
        color: "black",
        fontsize: "25px",
        fontfamily: 'Rubik'
    },
    button: {
        background: '#4d4d4d',
        backgroundHover: 'red',
        backgroundActive: 'red',
        color: 'white',
        colorHover: 'black',
        colorActive: 'black',
        fontfamily: 'Rubik'
    },
    item: {
        base: {
            background: '#4d4d4d',
            backgroundHover: 'red',
            backgroundActive: 'red',
            color: 'white',
            colorHover: 'black',
            colorActive: 'black',
            fontfamily: 'Rubik'
        },
        full: {
            background: '#191919',
            backgroundHover: 'red',
            backgroundActive: 'red',
            color: 'white',
            colorHover: 'black',
            colorActive: 'black',
            fontfamily: 'Rubik'
        }
    },
    full: {
        background: "black",
        color: "white",
        fontsize: "25px",
        fontfamily: 'Rubik'
    }
}
export const textfield = {
    base: {
        background: "green",
        backgroundHover: "white",
        backgroundActive: "white",
        color: "black",
        colorHover: "white",
        colorActive: "white",
    },
    label: {
        background: "green",
        backgroundHover: "white",
        backgroundActive: "white",
        color: "black",
        colorHover: "white",
        colorActive: "white",
    }
}
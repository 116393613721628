// [][][][][][][][][][][][][][][][][][][][][ START IMPORTS ][][][][][][][][][][][][][][][][][][][][][][][][][][][]
// ####################### GLOBAL ############################################################################
// Import from React
import { useState, useEffect } from 'react'

// Import Toast
// eslint-disable-next-line
import { toast } from 'react-toastify'

// Import Auth
import { useAuth } from '../../middleware/contextHooks'

// Import reactDOM
import { useNavigate } from 'react-router-dom'
// ####################### END OF GLOBAL #####################################################################

// ####################### SPECIFIC ##########################################################################
// Import globalStyles elements
//import { /*CustomContainer as Container*/ } from '../../themes/Darwinian2023/skeleton/global'

// Import Icons
//import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined'
//import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined'
import LongSliceIcon from '@mui/icons-material/PostAdd'
import ShortSliceIcon from '@mui/icons-material/Message'
import StillSliceIcon from '@mui/icons-material/Image'
import VideoSliceIcon from '@mui/icons-material/Videocam'
import MoveToTrashIcon from '@mui/icons-material/Delete'

// Import from MUI
import {
    Checkbox,
    FormControlLabel,
    InputLabel,
    ListItemText,
    MenuItem,
    Slider,
    Tab,
    OutlinedInput,
} from '@mui/material'

// Import from MUI lab
import {
    TabContext,
    TabList,
    TabPanel
} from '@mui/lab'

/*
// Import dashStyles
import { 
    Bar,
    CustomBox as Box,
    //CustomButton as Button,
    CustomGrid as Grid,
    CustomItem as Item,
    // eslint-disable-next-line
    CustomLink,
    CustomStack as Stack,
    Heading,
    //Para,    
    CustomTextField as TextField,
    //CustomTextareaAutosize as TextareaAutosize
} from '../../themes/Darwinian2023/skeleton/dash'
*/

// Import components
//import Masonry from '@mui/lab/Masonry'
//import PostCard from '../../components/PostCard'

// Import gsap (???)
import gsap from 'gsap'

// Import Lorem Ipsum
import {LoremIpsum} from 'lorem-ipsum'

// Import useParams
import { useParams } from 'react-router-dom'

// Import context
import { useCategory, useCode, usePost, useTag } from '../../middleware/contextHooks'

// Import Transition
import { Transition } from 'react-transition-group'
//import { truncateString } from '../../middleware/utils'

// Import BreadCrumbs
import { BreadCrumbs } from '../../components/BreadCrumbs'

// Dropzone
//import { useDropzone } from 'react-dropzone'

// Import DragZone component
import { UploadDragZone } from '../../components/UploadDragZone'
import { Spacer } from '../../components/Addon/Spacer'
// ####################### END OF SPECIFIC ##################################################################
// [][][][][][][][][][][][][][][][][][][][][ END IMPORTS ][][][][][][][][][][][][][][][][][][][][][][][][][][][][]

// #region -------------[ Variables ]-----------------------------------------------------------------------------
const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250
        }
    }
}


// Setup for loading cats and tags
let loadedcategories = []
let loadedtags = []

// #endregion ----------------------------------------------------------------------------------------------------
// ---------------------------------------------------------------------------------------------------------------

export default function Detail (props) { 
    // [][][][][][][][][][][][][][][][][][][][][ START SETUP ][][][][][][][][][][][][][][][][][][][][][][][][][][]
    // ####################### GLOBAL ############################################################################
    // #region -------------[ Variables ]-------------------------------------------------------------------------
    // Get user logged
    const {
        // Variables
        currentUser,  

        // Functions
        //getGuestProfile, 
        //getProfile, 
        //getPublicProfile, 
    } = useAuth() 

    // Set navigate
    // eslint-disable-next-line
    const navigate = useNavigate()

    // #region -----------------[ State Variables ]---------------------------------------------------------------
    
    // #end region -----------------[ State Variables ]-----------------------------------------------------------

    // #endregion ------------------------------------------------------------------------------------------------
    // #region --------------( FUNCTIONS )------------------------------------------------------------------------
    
    // #endregion ------------------------------------------------------------------------------------------------
    // ####################### END OF GLOBAL #####################################################################

    // ####################### SPECIFIC ##########################################################################
    // #region -------------[ Variables ]-------------------------------------------------------------------------

    // Set tabstates
    const [LongSlice, setLongSlice] = useState(true)
    // eslint-disable-next-line
    const [ShortSlice, setShortSlice] = useState(false)
    // eslint-disable-next-line
    const [StillSlice, setStillSlice] = useState(false)
    // eslint-disable-next-line
    const [VideoSlice, setVideoSlice] = useState(false)

    // Set currentSnippetLength
    const [currentSnippetLength, setCurrentSnippetLength] = useState(0)

    // Handle the changing of the tabs event
    const handleTabChange = (e, newValue) => {
        // Change tab state (to not mix functionality between the different posting types)
        // NOTE: If you don't, the browser will scream at you repeatedly in a weird language :P
        switch(newValue) {
            case '1':
                // Turn on: LongSlice
                setLongSlice(true)

                // Turn off everything else
                setShortSlice(false)
                setStillSlice(false)
                setVideoSlice(false)
            break

            case '2':
                // Turn on: LongSlice
                setShortSlice(true)
                
                // Turn off everything else
                setLongSlice(false)
                setStillSlice(false)
                setVideoSlice(false)
            break

            case '3':
                // Turn on: StillSlice
                setStillSlice(true)

                // Turn off everything else
                setLongSlice(false)
                setShortSlice(false)
                setVideoSlice(false)
            break

            case '4':
                // Turn on: LongSlice
                setVideoSlice(true)

                // Turn off everything else
                setLongSlice(false)
                setShortSlice(false)
                setStillSlice(false)
            break
            default: 
                setLongSlice(false)
                setShortSlice(false)
                setStillSlice(false)
                setVideoSlice(false)
            break;
        }

        setTabValue(newValue)
    }

    // Get the parameters from the url
    const { id } = useParams()

    /* DEPRECTATED < 2.4.71 : Load them in "conditionally"
    // Use the post context
    const {        
        // Variables
        currentPost,
        hasMovedPostToTrash,
        isUpdatingPost,
        isUpdatingPostSent,
        postAuthor,
        PostFormIsDisabled,
        PostIsMovingToTrash,

        // Functions
        getPostBySlug,
        setIsUpdatingPost,
        setIsUpdatingPostSent,
        setHasMovedPostToTrash,
        setPostFormIsDisabled,
        setPostIsMovingToTrash,
        updatePost,
    } = usePost()
    */

    // UPDATE 2.4.71 : Load contexts "conditionally"
    // Set up the slice
    var slice = props.tertiary

    // Set up Modules
    const Modules = props.Modules

    // Set up variables
    let resources
    let query

    // Set all the resources from the contexts
    const resourcesCategory = useCategory()
    const resourcesCode = useCode()
    const resourcesPost = usePost()
    const resourcesTag = useTag()

    // Conditional setting resources
    switch (slice) {
        case 'Categories':
            query="category"
            resources=resourcesCategory
        break
        case 'Codes':
            query="code"
            resources=resourcesCode
        break
        case 'Posts':
            query="anypost"
            resources=resourcesPost
        break
        case 'Tags':
            query="tag"
            resources=resourcesTag
        break
        default: 
            /* ??? No resource; what are we doing here? */
        break
    }

    // ######region ################[ LongSlice ]##################################################################

    // Set up breadcrumbs here
    const BCItems = [
        {
            'name': 'Dashboard',
            'type': 'link',
            'grayed': true,
            "url": '/dashboard',
        }, 
        {
            'name': `${slice}`,
            'type': 'link',
            'grayed': true,
            'url': `/dashboard/${slice.toLowerCase()}`
        },
        {
            'name': `${(resources.currentSlice?.code) ? resources.currentSlice?.slug : resources.currentSlice?.title}`,
            'type': 'text',
            'grayed': false,
            'url': `/dashboard/${slice.toLowerCase()}/${props.id}`
        },
    ]
    
    // Hold current slug
    // eslint-disable-next-line
    const [ currentSlug, setCurrentSlug ] = useState(id)

    // Set isDisabled state
    // eslint-disable-next-line
    //const [isDisabled, setIsDisabled] = useState(false)

    // Slice type    
    // eslint-disable-next-line
    const handleSliceTypeChange = (event) => {
        //setSliceType(event.target.value)
        alert("wip")
    }

    // #region --------------------[ PLUGIN: LoremImpsum Generate ]----------------------------------------------
    // onGenerate
    const [ onGenerate, setOnGenerate ] = useState(false)

    // Lorem Ipsum options
    const [loremOptions, setLoremOptions] = useState({
        minWordPerSentence: 3,
        maxWordPerSentence: 16,
        wordPerSentence: 4,

        minSentencePerParagraph: 4,
        maxSentencePerParagraph: 20,
        sentencePerParagraph: 5,

        minParagraphPerPost: 2,
        maxParagraphPerPost: 10,
        paragraphPerPost: 3,
    })
    // #endregion -----------------------------------------------------------------------------------------------

    // Get Category
    const {
        // Functions
        getAllCategories, 
        getAllCategoriesPublically,
        getMyCategories, 
        setSelectedCategories,

        // Variables
        categories,
        selectedCategories,
    } = useCategory()

    // Get Category
    const {
        // Functions
        getAllTags, 
        getAllTagsPublically,
        getMyTags, 
        setSelectedTags,

        // Variables
        tags,
        selectedTags
    } = useTag()

    // Set state of tabs
    const [tabValue, setTabValue ] = useState('1')

    // Set temp to handle with updating
    //const [ temp, setTemp ] = useState(null)

    // Set state of publishGlobally
    //const [ publishGlobally, setPublishGlobally ] = useState(false)
    
    // Set category
    //const [selectedCategories, setSelectedCategories] = useState([])

    // Set tags state
    //const [selectedTags, setSelectedTags] = useState([])

    // Set status state
    //const [pstatus, setPstatus] = useState(false)

    // Set visiblity
    //const [visibility, setVisibility] = useState('public')

    // Set imageUrl
    //const [imageUrl, setImageUrl] = useState(null)

    // Set state for post image
    // eslint-disable-next-line
    //const [ files, setFiles ] = useState([])

    // --- Set up dropzone -------------------------------------------------------------------------------------------
    // eslint-disable-next-line
    /*
    const { 
        getRootProps, 
        getInputProps,         
    } = useDropzone({
        accept: {
            "image/*": ['.jpeg','.jpg','.png']
        },
        onDrop: (acceptedFiles) => {
            setFiles(
                acceptedFiles.map((file) => Object.assign(file, {
                    preview: URL.createObjectURL(file)
                }))
            )

            // Set imageUrl state
            resources.setSliceImage(files[0]?.name)

            // Save to the newSlice
            resources.setCurrentSlice({...resources.currentSlice, imageUrl: files[0]?.name})
        }
    })
    */
    // --------------------------------------------------------------------------------------------------------------

    // Create images for dropzone
    // eslint-disable-next-line
    /*const images = files.map((file) => (
        <div key={file.name}>
            <div>
                <img src={file.preview} style={{ width: "200px"}} alt="preview" />
            </div>
        </div>
    ))
    */

    // #endregion -----------------------------------------------------------------------------------------------
    // #region -------------[ Functions ]------------------------------------------------------------------------
    // Handle changing categories
    const handleCategoryChange = (e) => {
        // Get switch input
        const {
            target: { value },
        } = e

        //console.log("value: "+value)

        // Update 2.7.2: Fix for removing category
        if (value === "") {
            // Set category state
            setSelectedCategories(
                ['Uncategorized']
            )
        } else {
            // Set category state
            setSelectedCategories(
                // On autofill we get a stringified value.
                typeof value === 'string' ? value.split(',') : value,                
            )
        }
    }
    
    // Handle publishGlobally switch
    const handleChangeStatus = (e) => {
        //setPstatus(e.target.checked)

        // Update the NewSlice state
        resources.setCurrentSlice({...resources.currentSlice, status: (slice !== "Posts") ? (resources.currentSlice.status === "inactive") ? "active" : "inactive" : (resources.currentSlice.status === "draft") ? "published" : "draft"})
    }

    // Handle visibility dropdown
    const handleChangeVisibility = (e) => {
        //setVisibility(e.target.value)

        // Update the post state
        resources.setCurrentSlice({...resources.currentSlice, visibility: e.target.value})
    }

    // Handle Create New Category
    const handleCreateNewCategory = () => {
        //alert('wip')
        navigate("../../dashboard/categories/new")
    }

    // Handle Create New Tag
    const handleCreateNewTag = () => {
        //alert('wip')
        navigate("../../dashboard/tags/new")
    }

    // Handle Editing (allows form to stop receiving from db)
    const handleEditing = (e) => {
        //console.log("from handleEditing: "+resources.isUpdatingSlice)
        // Set temp to currentSlice first
        //if (!resources.isUpdatingSlice) {
            //setTemp(resources.currentSlice)
        //}

        // Enable the form
        resources.setSliceFormIsDisabled(false)

        // Tell the system we are updating post
        resources.setIsUpdatingSlice(true)
    }

    // #region --------------------[ PLUGIN: LoremImpsum Generate ]----------------------------------------------
    const handleGenerate = () => {
        const lorem = new LoremIpsum({
            sentencesPerParagraph:{
                max: loremOptions.maxSentencePerParagraph,
                min: loremOptions.minSentencePerParagraph
            },
            wordsPerSentence: {
                max: loremOptions.maxWordPerSentence,
                min: loremOptions.minWordPerSentence
            }
        })

        resources.setCurrentSlice({...resources.currentSlice, 
            title: lorem.generateSentences(1),
            content: lorem.generateParagraphs(loremOptions.paragraphPerPost)
        })
    }
    // #endregion -----------------------------------------------------------------------------------------------

    // Handle function for moving to trash
    const handleMoveToTrash = () => {
        // Update the NewSlice state to refect the status so we can set in the update on the backend (easily way to do it)
        resources.setCurrentSlice({...resources.currentSlice, status: "trash"})

        // Change that we are Moving to Trash to update the renderer
        resources.setSliceIsMovingToTrash(true)
    }

    // Handle function for preview
    const handlePreview = () => {
        alert('WIP: Preview in a window')
        //createSlice(post)
    }

    // Handle publishGlobally switch
    const handlePublishGlobally = (e) => {
        //setPublishGlobally(e.target.checked)

        // Update the NewSlice state
        resources.setCurrentSlice({...resources.currentSlice, namespace: (resources?.currentSlice?.namespace === "local") ? "global" : "local"})
    }

    // Handle function for saving
    const handleSendSlice = (status) => {
        // Tell the system that we have sent the post to be updated
        resources.setIsUpdatingSliceSent(true)

        // Detemine if sending status draft
        resources.setCurrentSlice({...resources.currentSlice, status: status})

        // Disable the form
        resources.setSliceFormIsDisabled(true)
    }

    // Set SlugField itself
    // eslint-disable-next-line
    const handleSetSlugField = () => {
        // Set up a variable to hold our generated slug from the inputted slug
        let generatedSlug = resources.currentSlice.slug

        // Replace
        generatedSlug = generatedSlug?.replace(/\s/g, '-').toLowerCase()
        generatedSlug = generatedSlug?.replace(',', '--comma--')
        generatedSlug = generatedSlug?.replace('#', '--num--')
        generatedSlug = generatedSlug?.replace('!', '--ex--')
        generatedSlug = generatedSlug?.replace('/', '--slash--')
        generatedSlug = generatedSlug?.replace('&', '--and--')

        // Set the state of slug
        resources.setCurrentSlice({...resources.currentSlice, slug: generatedSlug})
    }

    // Set SlugField itself
    const handleSetSlugFieldFrom = (slice) => {
        // Set up a variable to hold our generated slug from the inputted slug

        let generatedSlug = (slice === "Codes") ? resources.currentSlice.code : resources.currentSlice.title

        // Replace
        generatedSlug = generatedSlug?.replace(/\s/g, '-').toLowerCase()
        generatedSlug = generatedSlug?.replace(',', '--comma--')
        generatedSlug = generatedSlug?.replace('#', '--num--')
        generatedSlug = generatedSlug?.replace('!', '--ex--')
        generatedSlug = generatedSlug?.replace('/', '--slash--')
        generatedSlug = generatedSlug?.replace('&', '--and--')

        // Set the state of slug
        resources.setCurrentSlice({...resources.currentSlice, slug: generatedSlug})
    }

    // Set from title field
    // eslint-disable-next-line
    const handleSetSlugFieldFromTitle = () => {
        // Set up a variable to hold our generated slug from title
        //console.log("temp?.title inside handleSetSlugFieldFromTitle: "+resources.currentSlice?.title)
        let generatedSlug = resources.currentSlice?.title

        // Replace
        generatedSlug = generatedSlug?.replace(/\s/g, '-').toLowerCase()
        generatedSlug = generatedSlug?.replace(',', '--comma--')
        generatedSlug = generatedSlug?.replace('#', '--num--')
        generatedSlug = generatedSlug?.replace('!', '--ex--')
        generatedSlug = generatedSlug?.replace('/', '--slash--')
        generatedSlug = generatedSlug?.replace('&', '--and--')

        // Set the state of slug
        resources.setCurrentSlice({...resources.currentSlice, slug: generatedSlug})
    }

    // Handle changing tags
    const handleTagsChange = (e) => {
        // Get switch input
        const {
            target: { value },
        } = e

        //console.log("value: "+value)
        
        // Update 2.7.2: Fix for removing category
        if (value === "") {
            // Set category state
            setSelectedTags(
                ['Untagged']
            )
        } else {
            // Set category state
            setSelectedTags(
                // On autofill we get a stringified value.
                typeof value === 'string' ? value.split(',') : value,                
            )
        }
    }
    
    // State management for getting the post
    useEffect(() => {
        // -- Get post ----------------------------------------------------------------------------------------    
        if (!resources.currentSlice) {
            switch (currentUser?.role) {
                case 'superadmin':
                    resources.getSliceFromSlug(query, id)
                break
                case 'admin':
                    resources.getSliceFromSlug(query, id)
                break

                default: 
                    resources.getSliceBySlug(query, id)
                break
            }
        }
    }, [ currentUser?.role, query, resources, resources.currentSlice, resources.getSliceFromSlug, resources.getSliceBySlug, id, LongSlice ])

    // State management for setting the post
    useEffect(() => {
        // Get the currentSlice from our context
        if (resources.currentSlice) {
            // Set the post if it matches the slug provided from the params id
            if(resources.currentSlice.slug === id) {
                // Only set the snippet when it's present in currentSlice or it will throw an error
                if (resources.currentSlice.snippet) {
                    setCurrentSnippetLength(resources.currentSlice.snippet.length)
                }
                //resources.setCurrentSlice(resources.currentSlice)

                // Set data
                //setPstatus((resources.currentSlice.status === "published") ? false : true)
                //setPublishGlobally((resources.currentSlice.namespace === "global") ? true : false)
                //setVisibility(resources.currentSlice.visibility)
                //setImageUrl(resources.currentSlice.imageUrl)
            }
        }
    }, [ resources, resources.currentSlice, resources.setCurrentSlice, id ])

    // State management for getting categories
    useEffect(() => {
        if (LongSlice) {
            //console.log("LongSlice detected")

            // 
            if (currentUser) {
                if (currentUser.role) {
                    switch (currentUser.role) {
                        case 'superadmin': 
                            //console.log('getting categories using superadmin permission')
                            getAllCategories(null, 'any')         
                            getAllTags(null, 'any')                
                        break
                        
                        case 'admin':
                            //console.log('getting categories using admin permission')
                            getAllCategories(null, 'any')
                            getAllTags(null, 'any')
                        break
    
                        case 'editor':
                            //console.log('getting categories using editor permission')
                            getAllCategoriesPublically(null, 'any')
                            getAllTagsPublically(null, 'any')
                        break
    
                        case 'user':
                            //console.log('getting categories using user permission')
                            getMyCategories()
                            getMyTags()
                        break
    
                        default: 
                            //console.log('getting categories using user (default) permission')
                            getMyCategories()
                            getMyTags()
                        break
                    }
                }
            }
        }
        // eslint-disable-next-line
    }, [
        //categories,
        //tags
    ])

    // State management for setting up categories and tags
    useEffect(() => {
        // Setup variables
        let categoryInDBString = ""
        let categoriesIndex = 1        
        let tagsInDBString = ""
        let tagsIndex = 1       
        let trueLoadedCategories = [] 
        let trueLoadedTags = []

        // only if categories are there
        if (categories) {
            // DEBUG: Display categories array
            //console.log("there are: "+categories)

            // Parse through array of categories
            Array.from(categories).forEach((ele) => {

                // Push to loadedcategories
                loadedcategories.push(
                    [
                        {
                            'title': ele?.title, 
                            'type': ele?.namespace,
                            'author': ele?.authorDetails[0]?.userName
                        }
                    ]
                )

                // Version 2.3.2 Update - if category loaded (ele.title) is in the post's category array (currentSlice[0].category), select it?
                //setSlice({...post, category: loadedcategories[ele].title})
                //console.log(resources.currentSlice)
                if (resources.currentSlice) { 
                    //console.log(resources.currentSlice)                
                    //console.log(ele.title)
                    //console.log(resources.currentSlice.category.indexOf(ele.title))
                    
                    // Check for categories in the slice
                    if (resources.currentSlice?.category?.indexOf(ele.title) > -1) {
                        // Found category in post's array
                        //console.log(ele.title + " is found in the post's category array")

                        // Add category to a comma separated list
                        //console.log("categoriesIndex: "+categoriesIndex)
                        //console.log("resources.currentSlice?.category.length: "+ resources.currentSlice.category.length)
                        if (categoriesIndex < resources.currentSlice?.category?.length) {
                            categoryInDBString += ele.title+","
                        } else {
                            categoryInDBString += ele.title
                        }  
                    }


                }
            })

            // DEBUG: Display loadedcategories
            //console.log(loadedcategories)

            // DEBUG: Display categoryInDBString
            //console.log(categoryInDBString)

            // Filter categories by global and local based on permission
            Array.from(loadedcategories).forEach((ele) => {
                if (ele[0].type === "global") {
                    // Remap
                    trueLoadedCategories.push(ele[0].title)
                }
                
                if (ele[0].type === "local") {
                    // --- Determine if the category is owned by user ------------------------------------------
                    // Set up variables
                    let ownedCat = false

                    // Compare author to logged user
                    if (ele[0].author === currentUser.userName) {
                        ownedCat = true
                    }

                    // Do they own this category?
                    if (ownedCat) {
                        // Remap 
                        trueLoadedCategories.push(ele[0].title)
                    }
                    // -----------------------------------------------------------------------------------------
                }
            })

            // Set the loadedcategories
            loadedcategories = trueLoadedCategories

            // Set category state (emulate like the category dropdown)
            setSelectedCategories(
                // On autofill we get a stringified value.
                typeof categoryInDBString === 'string' ? categoryInDBString.split(',') : categoryInDBString,
                    //value
            )

            //console.log(selectedCategories)

            //console.log(loadedcategories)
        }

        // only if tags are there
        if (tags) {
            // DEBUG: Display tags array
            //console.log("there are: "+tags)

            // Parse through array of categories
            Array.from(tags).forEach((ele) => {

                // Push to loadedcategories
                loadedtags.push(
                    [
                        {
                            'title': ele?.title, 
                            'type': ele?.namespace,
                            'author': ele?.authorDetails[0]?.userName
                        }
                    ]
                )

                // Version 2.3.2 Update - if category loaded (ele.title) is in the post's category array (currentSlice[0].category), select it?
                //setSlice({...post, category: loadedcategories[ele].title})
                //console.log(resources.currentSlice)
                if (resources.currentSlice) { 
                    //console.log(resources.currentSlice)                
                    //console.log(ele.title)
                    //console.log(resources.currentSlice.category.indexOf(ele.title))
                    
                    // Check for tags in the slice
                    if (resources.currentSlice?.tags?.indexOf(ele.title) > -1) {
                        // Found tag in post's array
                        //console.log(ele.title + " is found in the post's tag array")

                        // Add category to a comma separated list
                        //console.log("tagsIndex: "+categoriesIndex)
                        //console.log("resources.currentSlice?.tags.length: "+ resources.currentSlice.tags.length)
                        if (tagsIndex < resources.currentSlice?.tags?.length) {
                            tagsInDBString += ele.title+","
                        } else {
                            tagsInDBString += ele.title
                        }  
                    }


                }
            })

            // DEBUG: Display loadedcategories
            //console.log(loadedtags)

            // DEBUG: Display tagsInDBString
            //console.log(tagsInDBString)

            // Filter tags by global and local based on permission
            Array.from(loadedtags).forEach((ele) => {
                if (ele[0].type === "global") {
                    // Remap
                    trueLoadedTags.push(ele[0].title)
                }
                
                if (ele[0].type === "local") {
                    // --- Determine if the tag is owned by user ------------------------------------------
                    // Set up variables
                    let ownedCat = false

                    // Compare author to logged user
                    if (ele[0].author === currentUser.userName) {
                        ownedCat = true
                    }

                    // Do they own this tag?
                    if (ownedCat) {
                        // Remap 
                        trueLoadedTags.push(ele[0].title)
                    }
                    // -----------------------------------------------------------------------------------------
                }
            })

            // Set the loadedtags
            loadedtags = trueLoadedTags

            // Set tags state (emulate like the category dropdown)
            setSelectedTags(
                // On autofill we get a stringified value.
                typeof tagsInDBString === 'string' ? tagsInDBString.split(',') : tagsInDBString,
                    //value
            )

            //console.log(selectedTags)

            //console.log(loadedtags)
        }
        // eslint-disable-next-line
    }, [
        categories,
        tags
    ])

    /*
    //State Management for setting tags
    useEffect(() => {
        if (tags && selectedTags.length < 1) {
            // Set up variables
            let tagsInDBString = ""
            let tagsIndex = 1
            
            // Parse through array of categories
            Array.from(tags).forEach((ele) => {
                // Push to loadedcategories
                loadedtags.push(ele?.title)

                // Version 2.3.2 Update - if category loaded (ele.title) is in the post's category array (currentSlice[0].category), select it?
                //setSlice({...post, category: loadedcategories[ele].title})
                //console.log(resources.currentSlice)
                if (resources.currentSlice) { 
                    //console.log(resources.currentSlice)                
                    //console.log(ele.title)
                    //console.log(resources.currentSlice.category.indexOf(ele.title))                            
                    if (resources.currentSlice?.tags?.indexOf(ele.title) > -1) {
                        // Found tag in post's array
                        //console.log(ele.title + " is found in the post's category array")

                        // Add tag to a comma separated list
                        //console.log("tagsIndex: "+categoriesIndex)
                        //console.log("resources.currentSlice?.tags.length: "+ resources.currentSlice.tags.length)
                        if (tagsIndex < resources.currentSlice?.tags?.length) {
                            tagsInDBString += ele.title+","
                        } else {
                            tagsInDBString += ele.title
                        }                        
                    }
                }
            })

            // Set category state (emulate like the category dropdown)
            setSelectedTags(
                // On autofill we get a stringified value.
                typeof tagsInDBString === 'string' ? tagsInDBString.split(',') : tagsInDBString,
                    //value
            )
        }
    }, [ 
        resources.currentSlice,
        setSelectedTags,
        selectedTags,
        tags,
    ])
    */

    /* DEPRECATED < 2.4.72
    // State Management for loading categories
    useEffect(() => {
        if (LongSlice) {
            // Load categories into loadedcategories array
            if (!categories && currentUser) {
                // Check what role
                if (currentUser) {
                    if (currentUser.role) {
                        switch (currentUser.role) {
                            case 'admin':
                                getAllCategories(null, 'any')
                            break
        
                            case 'editor':
                                getAllCategories(null, 'any')
                            break
        
                            case 'user':
                                getMyCategories()
                            break
        
                            default: 
                                getMyCategories()
                            break
                        }
                    }
                }
            }
        }
    // eslint-disable-next-line
    }, [ categories, currentUser, getAllCategories, getMyCategories, LongSlice ])
    */

    /* DEPRECATED < 2.4.72
    // State Management for setting categories
    useEffect(() => {
        //console.log("isDoneLoadingCategories: "+isDoneLoadingCategories)
        if (categories && !isDoneLoadingCategories) {
            // Set up variables
            let categoryInDBString = ""

            // Array for categories
            let categoriesIndex = 1
            Array.from(categories).forEach(ele => {
                // push to loadedcategories
                loadedcategories.push(ele?.title)

                // Version 2.3.2 Update - if category loaded (ele.title) is in the post's category array (currentSlice[0].category), select it?
                //setSlice({...post, category: loadedcategories[ele].title})
                //console.log(resources.currentSlice)
                if (resources.currentSlice) {                 
                    console.log(ele?.title)
                    console.log(resources.currentSlice?.category?.indexOf(ele?.title))
                    if (resources.currentSlice?.category?.indexOf(ele?.title) > -1) {
                        // Found category in post's array
                        //console.log(ele.title + " is found in the post's category array")

                        // Add category to a comma separated list
                        console.log("categoriesIndex: "+categoriesIndex)
                        console.log("resources.currentSlice?.category.length: "+ resources.currentSlice?.category.length)
                        if (categoriesIndex < resources.currentSlice?.category.length) {
                            categoryInDBString += ele.title+","
                        } else {
                            categoryInDBString += ele.title
                        }                        
                    }
                }
                
            })

            setIsDoneLoadingCategories(true)

            // Set category state (emulate like the category dropdown)
            setSelectedCategories(
                // On autofill we get a stringified value.
                typeof categoryInDBString === 'string' ? categoryInDBString.split(',') : categoryInDBString,
                    //value
            )
        }
    }, [ categories, resources.currentSlice, isDoneLoadingCategories, setIsDoneLoadingCategories, setSelectedCategories ])

    */

    /* DEPRECATED < 2.4.72
    // State Management for getting tages (tags)
    useEffect(() => {
        if (LongSlice) {
            // Load tages into loadedtags array
            if (!tags && currentUser) {
                // Check what role
                if (currentUser) {
                    //console.log(currentUser)
                    switch (currentUser.role) {
                        case 'admin':
                            getAllTags(null, 'any')
                        break
    
                        case 'editor':
                            getAllTags(null, 'any')
                        break
    
                        case 'user':
                            getMyTags()
                        break
    
                        default: 
                            getMyTags()
                        break
                    }
                }
            }
        }

    // eslint-disable-next-line
    }, [ currentUser, getAllTags, getMyTags, LongSlice, tags ])
    */
    
    /* DEPRECATED < 2.4.72
    // State Management for setting tags
    useEffect(() => {
        //console.log("isDoneLoadingTags: "+isDoneLoadingTags)
        if (tags && !isDoneLoadingTags && currentUser ) {
            // Set up variables
            let tagInDBString = ""

            // Array for tags
            let tagsIndex = 1
            Array.from(tags).forEach(ele => {
                // push to loadedcategories
                loadedtags.push(ele?.title)

                // Version 2.3.2 Update - if tag loaded (ele.title) is in the post's tags array (currentSlice[0].tags), select it?
                //setSlice({...post, tags: loadedcategories[ele].title})
                //console.log(resources.currentSlice)
                if (resources.currentSlice) {                 
                    //console.log(currentSlice[0]?.category?.indexOf(ele?.title))
                    if (resources.currentSlice?.tags?.indexOf(ele?.title) > -1) {
                        // Found tag in post's array
                        //console.log(ele.title + " is found in the post's category array")

                        // Add tag to a comma separated list
                        if (tagsIndex < resources.currentSlice?.tags.length) {
                            tagInDBString += ele.title+","
                        } else {
                            tagInDBString += ele.title
                        }                        
                    }
                }
                
            })

            setIsDoneLoadingTags(true)

            // Set category state (emulate like the category dropdown)
            setSelectedTags(
                // On autofill we get a stringified value.
                typeof tagInDBString === 'string' ? tagInDBString.split(',') : tagInDBString,
                    //value
            )
        }
    }, [ tags, resources.currentSlice, currentUser, isDoneLoadingTags, setIsDoneLoadingTags, setSelectedTags ])
    */

    // State management for categories, tags, and uploadFiles
    // NOTE: This is required to send the right category, tags, and uploadFiles (in this case imageUrl) to the API backend
    //       Basically: 
    //       1.         Just set an " || (check for change in state)"" to the IF conditional to check for a change
    //       2.         Add the item to be checked for in the state to the "setCurrentSlice()" as what it is.
    //       ?.         NOTE: Not required for things like switches (boolean) values. They are changed in the context

    useEffect(() => {
        if ((selectedCategories && selectedCategories.length > 0) || (selectedTags && selectedTags.length > 0) || (resources.uploadFiles && resources.uploadFiles.length > 0)) {
            
            resources.setCurrentSlice({...resources.currentSlice, 
                category: selectedCategories, 
                imageUrl: (resources.uploadFiles) ? resources?.uploadFiles[0]?.name : (resources.currentSlice?.imageUrl) ? resources.currentSlice?.imageUrl : null, 
                tags: selectedTags, 
                visibility: resources?.currentSlice?.visibility})        
        }

    }, [ resources, resources.currentSlice, selectedCategories, resources.uploadFiles, selectedTags, resources.currentSlice?.imageUrl, resources?.currentSlice?.visibility, resources?.uploadFiles ])

    // State management for updating post
    useEffect(() => {
        if (LongSlice) {
            if (resources.isUpdatingSliceSent) {
                // Version 2.3.2 - Determine if we are Moving to Trash or just updating
                // Update the post

                //console.log(resources.currentSlice)

                resources.updateSlice(query, resources.currentSlice, currentSlug)

                // Set UpdatingSlice - deprecated: handle on updateSlice
                //setIsUpdatingSlice(false)                           
            }
        }
    }, [ currentSlug, query, resources, resources.isUpdatingSliceSent, LongSlice, resources.currentSlice, resources.updateSlice ])

    // State management for moving to trash
    useEffect(() => {
        if (LongSlice) {
            if (resources.SliceIsMovingToTrash && !resources.hasMovedSliceToTrash) {
                // Version 2.3.2 - Determine if we are Moving to Trash or just updating
                // Update the post
                //console.log(resources.currentSlice)
                
                resources.updateSlice(query, resources.currentSlice, currentSlug)

                // Set HasMovedSliceToTrash
                resources.setHasMovedSliceToTrash(true)

                // Set UpdatingSlice - deprecated: handle on updateSlice
                //setIsUpdatingSlice(false)                           
            }
        }
    }, [ currentSlug, query, resources, resources.hasMovedSliceToTrash, LongSlice, resources.currentSlice, resources.SliceIsMovingToTrash, resources.setHasMovedSliceToTrash, resources.updateSlice ])

    // State rendering for image adding
    useEffect(() => {
        if (resources.featuredImage) {
            // DEBUG: Display profileImage
            //console.log("featuredImage: "+resources.featuredImage)

            // Set the temp
            resources.setCurrentSlice({ ...resources?.currentSlice, imageUrl: resources.featuredImage })
        }
    }, [ resources ])

    // State management for SliceFormIsDisabled - 2.4.73 Workaround (something was causing SliceFormIsDisabled to undefine)
    // FIXED
    /*
    useEffect(() => {
        
        if (resources.SliceFormIsDisabled) {
            console.log(resources.SliceFormIsDisabled)
        }

        if (!resources.SliceFormIsDisabled && !resources.isUpdatingSlice) {
            //resources.setSliceFormIsDisabled(true)
        }
    }, [ resources ])
    */

    // State Management for debugging
    useEffect(() => {
        if (LongSlice) {
            ///console.log("featured image: "+newSlice.imageUrl)
            //console.log("--- DEBUG Variables ---") 
            //console.log("hasMovedSliceToTrash: "+hasMovedSliceToTrash)
            //if (loadedcategories) {console.log(loadedcategories)}
            //if (loadedtags) {console.log(loadedtags)}
            //console.log("categories: "+categories)
            //console.log("tags:"+tags)  
            //console.log("selectedCategories: "+selectedCategories)
            //console.log("selectedTags: "+selectedTags)
            //if (resources.currentSlice) {console.log("resources.currentSlice category: "+resources.currentSlice.category)}
            //if (resources?.currentSlice?.category) {console.log("category: "+resources.currentSlice?.category.length)}
            //if (selectedTags.length > 0) {console.log("selectedTags: "+selectedTags)}
            //if (post?.tags) {console.log("post categories: "+post.tags)}
            //console.log(Modules)
            //console.log("SliceFormIsDisabled: "+resources.SliceFormIsDisabled)
            //console.log("uploadFiles: "+resources.uploadFiles[0].name)
            //console.log("NewSliceSlug: "+NewSliceSlug)
            //console.log("isUpdatingSlice: "+resources.isUpdatingSlice)
            //console.log("isUpdatingSliceSent: "+resources.isUpdatingSliceSent)
            //console.log("Visibility: "+visibility)     
            //console.log("slug: "+resources?.currentSlice?.slug)
            //console.log("-----------------------")
            
        }
    }, [ 
        //resources.currentSlice, 
        //resources.hasMovedSliceToTrash, 
        //resources.isUpdatingSlice, 
        //resources.isUpdatingSliceSent, 
        //resources.isUpdatingSlice, 
        LongSlice, 
        //Modules,
        //resources.SliceFormIsDisabled, 
        //resources.uploadFiles,
        //resources.currentSlice
        //selectedCategories
     ])
    // #endregion -----------------------------------------------------------------------------------------------

    // ##### endregion ###############[ LongSlice ]###############################################################



    // ##### region ##################[ ShortSlice ]##############################################################
    // Set isDisabled state
    // eslint-disable-next-line
    const [isDisabledSPost, setIsDisabledSPost] = useState(false)
    
    // State variables for post
    const [sPost, setSPost] = useState(null)

    // Handle function for saving
    const handleSendShortSlice = () => {
        // Tell the system that we are updating the post (disable the form)
        //setUpdatingPost(true)
        alert('WIP')
    }
    // ##### endregion ###############[ ShortSlice ]##############################################################



    // ##### region ##################[ StillSlice ]##############################################################

    // ##### endregion ###############[ StillSlice ]##############################################################



    // ##### region ##################[ VideoSlice ]##############################################################

    // ##### endregion ###############[ VideoSlice ]##############################################################

    // ####################### END OF SPECIFIC ##################################################################
    // [][][][][][][][][][][][][][][][][][][][][ ENDSETUP ][][][][][][][][][][][][][][][][][][][][][][][][][][][]

    // Render
    return (
        <>
        <props.skeleton.InnerContent>
            <Spacer length={4} />
            <props.skeleton.CustomStack spacing={0}>
                <BreadCrumbs list={BCItems} skeleton={props.skeleton} />
                <props.skeleton.CustomGrid container spacing={0}>
                    <props.skeleton.CustomGrid item xs={12}>
                        <props.skeleton.CustomGrid container spacing={0} sx={{ 
                            //border: 'thin solid black', 
                            padding: '10px' }}>
                            <props.skeleton.CustomGrid item xs={12}>
                                {/* #region -----[ NOUGAT ]------------------------------------------------------------------------*/}
                                {
                                    /* 
                                    -- #region - Full column layout --------------------------------------------------------------------------------
                                    */
                                    <props.skeleton.CustomGrid item xs={12} sm={12} sx={{ height: '100%' }}>
                                        <props.skeleton.CustomStack direction="column" sx={{ alignItems: "center", justifyContent: "flex-start" }}>
                                            <TabContext value={tabValue} sx={{ }}>
                                                {
                                                    // #region --------------[ base: TabMan ]---------------------------------------------------------------
                                                    (
                                                        Modules?.base?.TabMan 
                                                        && Modules?.base?.TabMan?.use 
                                                        && Modules?.base?.TabMan?.sections?.indexOf(slice) > -1
                                                        && Modules?.base?.TabMan?.showOnDetail
                                                    )
                                                    ? (
                                                        <props.skeleton.CustomBox sx={{ borderBottom: 1, borderColor: 'divider', maxwidth: 310 }} bgteritary="true">
                                                            <TabList onChange={handleTabChange} aria-label="PostTabs" sx={{display: {xs: 'none', sm: 'flex', md: 'flex', lg: 'flex'} }}>
                                                                <Tab label="Long Post" value="1" />
                                                                <Tab label="Short Post" value="2" />
                                                                <Tab label="Still Post" value="3" />
                                                                <Tab label="Video Post" value="4" />
                                                            </TabList>

                                                            <TabList onChange={handleTabChange} aria-label="PostTabs" sx={{ display: {xs: 'flex', sm: 'none', md: 'none', lg: 'none'} }}>
                                                                <Tab icon={<LongSliceIcon />} value="1" />
                                                                <Tab icon={<ShortSliceIcon />} value="2" />
                                                                <Tab icon={<StillSliceIcon />} value="3" />
                                                                <Tab icon={<VideoSliceIcon />} value="4" />
                                                            </TabList>
                                                        </props.skeleton.CustomBox>
                                                    )
                                                    : null
                                                    // #endregion --------------------------------------------------------------------------------------------------                                                             
                                                }

                                                <TabPanel value="1" sx={{ width: '100%'}}>
                                                    {
                                                        /*
                                                        ===============================================================================================
                                                        #region - Long Post (ie. Blog post for global or personal page)
                                                        ===============================================================================================
                                                        */
                                                    }
                                                    <props.skeleton.CustomGrid container spacing={0}>
                                                        <props.skeleton.CustomGrid item xs={12} sm={12} md={9} sx={{ padding: '0px' }}>
                                                            <props.skeleton.CustomStack direction={'column'} spacing={2}>
                                                                {
                                                                    // #region --------------[ template: template ]------------------------------------------------------------------
                                                                    //(
                                                                        //Modules?.base?.<name> 
                                                                        //&& Modules?.base?.<name>?.use 
                                                                        //&& Modules?.base?.<name>?.<child>?.indexOf(slice) > -1
                                                                    //)
                                                                    //? (
                                                                        
                                                                    //)
                                                                    //: null
                                                                    // #endregion --------------------------------------------------------------------------------------------------
                                                                }
                                                                
                                                                {
                                                                    // #region --------------[ base: Code ]------------------------------------------------------------------
                                                                    (
                                                                        Modules?.base?.Code 
                                                                        && Modules?.base?.Code?.use 
                                                                        && Modules?.base?.Code?.sections?.indexOf(slice) > -1
                                                                    )
                                                                    ? (
                                                                        <props.skeleton.CustomItem>
                                                                            <props.skeleton.CustomTextField
                                                                                name='code'
                                                                                label="Add Code"
                                                                                value={resources.currentSlice?.code || ''}
                                                                                disabled={(!resources.isUpdatingSliceSent) ? false : true}
                                                                                onChange={(e) => resources.setCurrentSlice({...resources.currentSlice, code: e.target.value})}
                                                                                onFocus={(e) => handleEditing()}
                                                                                //onBlur={(e) => handleSetSlugFieldFromTitle()}
                                                                            />
                                                                        </props.skeleton.CustomItem>
                                                                    )
                                                                    : null
                                                                    // #endregion --------------------------------------------------------------------------------------------------                                                             
                                                                }

                                                                {
                                                                    // #region --------------[ base: CodeType ]------------------------------------------------------------------
                                                                    (
                                                                        Modules?.base?.CodeType 
                                                                        && Modules?.base?.CodeType?.use 
                                                                        && Modules?.base?.CodeType?.sections?.indexOf(slice) > -1
                                                                    )
                                                                    ? (
                                                                        <props.skeleton.CustomItem>
                                                                            <props.skeleton.CustomTextField
                                                                                name='codetype'
                                                                                label="Code Type"
                                                                                value={resources.currentSlice?.type || ''}
                                                                                disabled={resources.SliceFormIsDisabled}
                                                                                onChange={(e) => resources.setCurrentSlice({...resources.currentSlice, type: e.target.value})}
                                                                            />
                                                                        </props.skeleton.CustomItem>
                                                                    )
                                                                    : null
                                                                    // #endregion --------------------------------------------------------------------------------------------------                                                             
                                                                }
                                                                
                                                                {
                                                                    // #region --------------[ base: CodeUses ]------------------------------------------------------------------
                                                                    (
                                                                        Modules?.base?.CodeUses 
                                                                        && Modules?.base?.CodeUses?.use 
                                                                        && Modules?.base?.CodeUses?.sections?.indexOf(slice) > -1
                                                                    )
                                                                    ? (
                                                                        <props.skeleton.CustomItem>
                                                                            <props.skeleton.CustomTextField
                                                                                name='uses'
                                                                                label="Code Uses"
                                                                                value={resources.currentSlice?.uses || 0}
                                                                                disabled={resources.SliceFormIsDisabled}
                                                                                onChange={(e) => resources.setCurrentSlice({...resources.currentSlice, uses: e.target.value})}
                                                                            />
                                                                        </props.skeleton.CustomItem>
                                                                    )
                                                                    : null
                                                                    // #endregion --------------------------------------------------------------------------------------------------                                                        
                                                                }

                                                                {
                                                                    // #region --------------[ base: CodeUsedCount ]------------------------------------------------------------------
                                                                    (
                                                                        Modules?.base?.CodeUsedCount
                                                                        && Modules?.base?.CodeUsedCount?.use 
                                                                        && Modules?.base?.CodeUsedCount?.sections?.indexOf(slice) > -1
                                                                    )
                                                                    ? (
                                                                        <props.skeleton.CustomItem>
                                                                            <props.skeleton.CustomTextField
                                                                                name='usedCount'
                                                                                label="Used"
                                                                                value={resources.currentSlice?.usedCount || 0}
                                                                                disabled={true}                                                                        
                                                                            />
                                                                        </props.skeleton.CustomItem>
                                                                    )
                                                                    : null
                                                                    // #endregion --------------------------------------------------------------------------------------------------     
                                                                }

                                                                {
                                                                    // #region --------------[ base: Title ]------------------------------------------------------------------
                                                                    (
                                                                        Modules?.base?.Title 
                                                                        && Modules?.base?.Title?.use 
                                                                        && Modules?.base?.Title?.sections?.indexOf(slice) > -1
                                                                    )
                                                                    ? (
                                                                        <>
                                                                        <props.skeleton.CustomItem>
                                                                            <props.skeleton.CustomTextField
                                                                                name='title'
                                                                                label="Add Title"
                                                                                value={resources.currentSlice?.title || ''}
                                                                                disabled={(!resources.isUpdatingSliceSent) ? false : true}
                                                                                onChange={(e) => resources.setCurrentSlice({...resources.currentSlice, title: e.target.value})}
                                                                                onFocus={(e) => handleEditing()}
                                                                                //onBlur={(e) => handleSetSlugFieldFromTitle()}
                                                                            />
                                                                        </props.skeleton.CustomItem>                                                                        
                                                                        </>
                                                                    )
                                                                    : null
                                                                    // #endregion --------------------------------------------------------------------------------------------------                                                             
                                                                }

                                                                {
                                                                    // #region --------------[ base: Slug ]------------------------------------------------------------------
                                                                    (
                                                                        Modules?.base?.Slug 
                                                                        && Modules?.base?.Slug?.use 
                                                                        && Modules?.base?.Slug?.sections?.indexOf(slice) > -1
                                                                    )
                                                                    ? (
                                                                        <props.skeleton.CustomItem>
                                                                            <props.skeleton.CustomTextField
                                                                                name='slug'
                                                                                label="Slug"
                                                                                value={resources.currentSlice?.slug || ''}
                                                                                disabled={resources.SliceFormIsDisabled}
                                                                                onChange={(e) => resources.setCurrentSlice({...resources.currentSlice, slug: e.target.value})}
                                                                                onFocus={(e) => handleEditing()}
                                                                                //onBlur={(e) => handleSetSlugFieldFromCode()}
                                                                            />
                                                                            <props.skeleton.Bar button="true" padding10="true" onClick={() => handleSetSlugFieldFrom(slice)}>Convert Title to Slug</props.skeleton.Bar>
                                                                        </props.skeleton.CustomItem>
                                                                    )
                                                                    : null
                                                                    // #endregion --------------------------------------------------------------------------------------------------                                                             
                                                                }

                                                                {
                                                                    // #region --------------[ base: Snippet ]------------------------------------------------------------------
                                                                    (
                                                                        Modules?.base?.Snippet 
                                                                        && Modules?.base?.Snippet?.use 
                                                                        && Modules?.base?.Snippet?.sections?.indexOf(slice) > -1
                                                                    )
                                                                    ? (
                                                                        <props.skeleton.CustomItem>
                                                                            <props.skeleton.CustomTextField
                                                                                name='snippet'
                                                                                label="Snippet"
                                                                                value={resources.currentSlice?.snippet || ''}
                                                                                disabled={resources.SliceFormIsDisabled}
                                                                                placeholder='Describe your post in a few words'
                                                                                onChange={(e) => {
                                                                                    // ---  Version 2.8.0 - Set length of snippet to show how long they have currently -----------
                                                                                    if (currentSnippetLength < 97) {
                                                                                        resources.setCurrentSlice({...resources.currentSlice, snippet: e.target.value})
                                                                                        setCurrentSnippetLength(e.target.value.length)
                                                                                    } else {
                                                                                        // You can limit the amount if you would like. Just uncomment this: 
                                                                                        //setNewLongSlice({...newLongSlice, snippet: e.target.value.substr(0, 96)})

                                                                                        // And comment this out
                                                                                        resources.setCurrentSlice({...resources.currentSlice, snippet: e.target.value})
                                                                                        
                                                                                        setCurrentSnippetLength(e.target.value.length)
                                                                                    }
                                                                                    // -------------------------------------------------------------------------------------------
                                                                                }}
                                                                                onFocus={(e) => handleEditing()}
                                                                                InputProps={{
                                                                                    endAdornment: <props.skeleton.CustomInputAdornment disableTypography position="end" variant="standard">
                                                                                        {currentSnippetLength} / 97
                                                                                    </props.skeleton.CustomInputAdornment>
                                                                                }}
                                                                            />
                                                                        </props.skeleton.CustomItem>
                                                                    )
                                                                    : null
                                                                    // #endregion --------------------------------------------------------------------------------------------------                                                             
                                                                }

                                                                {
                                                                    // #region --------------[ base: Content ]------------------------------------------------------------------
                                                                    (
                                                                        Modules?.base?.Content 
                                                                        && Modules?.base?.Content?.use 
                                                                        && Modules?.base?.Content?.sections?.indexOf(slice) > -1
                                                                    )
                                                                    ? (
                                                                        <>
                                                                        <props.skeleton.CustomItem>
                                                                            <props.skeleton.CustomTextField
                                                                                multiline   
                                                                                rows={20}  
                                                                                name='content'
                                                                                label="Content"
                                                                                sx={{
                                                                                    height: "100%"
                                                                                }}
                                                                                value={resources.currentSlice?.content || ''}
                                                                                disabled={resources.SliceFormIsDisabled}
                                                                                onChange={(e) => resources.setCurrentSlice({...resources.currentSlice, content: e.target.value})}
                                                                                onFocus={(e) => handleEditing()}
                                                                            />
                                                                        </props.skeleton.CustomItem>
                                                                        <props.skeleton.CustomItem>
                                                                            <props.skeleton.Heading nobg>Character count: {resources.currentSlice?.content?.length} | Word count: {resources.currentSlice?.content?.split(' ').length}</props.skeleton.Heading>                                    
                                                                        </props.skeleton.CustomItem>
                                                                        </>
                                                                    )
                                                                    : null
                                                                    // #endregion --------------------------------------------------------------------------------------------------                                                             
                                                                }

                                                            </props.skeleton.CustomStack>
                                                        </props.skeleton.CustomGrid>
                                                        <props.skeleton.CustomGrid item xs={12} sm={12} md={3} sx={{ paddingLeft: '10px' }}>
                                                            <props.skeleton.CustomGrid container alignContent={'flex-start'}>                                                        
                                                                {
                                                                    <>
                                                                    {
                                                                        // #region --------------[ template: template ]------------------------------------------------------------------
                                                                        //(
                                                                            //Modules?.base?.<name> 
                                                                            //&& Modules?.base?.<name>?.use 
                                                                            //&& Modules?.base?.<name>?.<child>?.indexOf(slice) > -1
                                                                        //)
                                                                        //? (
                                                                            <>

                                                                            </>
                                                                        //)
                                                                        //: null
                                                                        // #endregion --------------------------------------------------------------------------------------------------
                                                                    }

                                                                    {
                                                                        // #region --------------[ base: TopGizmo ]---------------------------------------------------------------------
                                                                        (
                                                                            Modules?.base?.TopGizmo 
                                                                            && Modules?.base?.TopGizmo?.use 
                                                                            && Modules?.base?.TopGizmo?.sections?.indexOf(slice) > -1
                                                                        )
                                                                        ? (
                                                                            <>
                                                                            <props.skeleton.CustomGrid item sink="true" xs={12} sx={{ border: 'thin solid #83B1B5', padding: '10px', marginBottom: '10px' }}>
                                                                                <props.skeleton.CustomStack sink="true" direction={'column'}>
                                                                                    <props.skeleton.CustomItem>
                                                                                        <props.skeleton.CustomGrid container sink="true" spacing={0} sx={{ /*border: 'thin solid red',*/ padding: '0px' }}>
                                                                                            <props.skeleton.CustomGrid item sink="true" xs={9} sx={{ /*border: 'thin dashed green',*/ paddingTop: '10px', paddingBottom: '10px' }}>
                                                                                                Update
                                                                                            </props.skeleton.CustomGrid>
                                                                                            <props.skeleton.CustomGrid item sink="true" xs={3} sx={{ /*border: 'thin dashed green',*/ paddingTop: '10px', paddingBottom: '10px', justifyContent: 'center', overflow: 'hidden' }}>
                                                                                                [Controls?]
                                                                                            </props.skeleton.CustomGrid>
                                                                                        </props.skeleton.CustomGrid>
                                                                                    </props.skeleton.CustomItem>
                                                                                    <props.skeleton.CustomItem>
                                                                                        <props.skeleton.CustomGrid container sink="true" spacing={0} sx={{ /*border: 'thin solid red',*/ padding: '0px' }}>
                                                                                            <props.skeleton.CustomGrid item sink="true" xs={3} sx={{ /*border: 'thin dashed green',*/ paddingTop: '0px', paddingBottom: '0px' }}>
                                                                                                <props.skeleton.Bar button="true" padding5lronly="true" center="true" sink="true" onClick={(e) => handlePreview()}>Preview</props.skeleton.Bar>
                                                                                            </props.skeleton.CustomGrid>
                                                                                            <props.skeleton.CustomGrid item xs={6} sx={{ /*border: 'thin dashed green',*/ paddingTop: '0px', paddingBottom: '0px' }}>
                                                                                                
                                                                                            </props.skeleton.CustomGrid>
                                                                                            <props.skeleton.CustomGrid item xs={3} sx={{ border: '', paddingTop: '0px', paddingBottom: '0px', justifyContent: 'center' }}>
                                                                                                <props.skeleton.Bar button="true" padding5lronly="true" center="true" sink="true" onClick={
                                                                                                    (e) => handleSendSlice(
                                                                                                        (slice !== "Posts")
                                                                                                        ? "inactive"
                                                                                                        : "draft"
                                                                                                    )
                                                                                                    }>{
                                                                                                        (slice !== "Posts") 
                                                                                                        ? "Save Inactive"
                                                                                                        : "Save Draft"                                                                                                
                                                                                                    }
                                                                                                </props.skeleton.Bar>
                                                                                            </props.skeleton.CustomGrid>
                                                                                        </props.skeleton.CustomGrid>
                                                                                    </props.skeleton.CustomItem>
                                                                                    <props.skeleton.CustomItem>
                                                                                        <props.skeleton.CustomGrid container sink="true" spacing={0} sx={{ /*border: 'thin solid red',*/ padding: '0px' }}>
                                                                                            <props.skeleton.CustomGrid item sink="true" xs={9} sx={{ /*border: 'thin dashed green',*/ paddingTop: '0px', paddingBottom: '0px' }}>
                                                                                                <props.skeleton.CustomStack direction={'row'} alignItems={'center'}>
                                                                                                    <props.skeleton.CustomItem sink="true">
                                                                                                        Save as {(slice !== "Posts") ? "Inactive" : "Draft"}?                                                                    
                                                                                                    </props.skeleton.CustomItem>
                                                                                                    <props.skeleton.CustomItem>
                                                                                                        
                                                                                                    </props.skeleton.CustomItem>
                                                                                                </props.skeleton.CustomStack>
                                                                                            </props.skeleton.CustomGrid>
                                                                                            <props.skeleton.CustomGrid item sink="true" xs={3} sx={{ /*border: 'thin dashed green',*/ paddingTop: '0px', paddingBottom: '0px', justifyContent: 'center' }}>
                                                                                                <props.skeleton.CustomSwitch
                                                                                                    sink="true"
                                                                                                    checked={(slice !== "Posts" ? ((resources?.currentSlice?.status === "inactive") ? true : false) : ((resources?.currentSlice?.status === "draft") ? true : false))}
                                                                                                    onChange={handleChangeStatus}
                                                                                                    disabled={resources.SliceFormIsDisabled}
                                                                                                    name="status"
                                                                                                    inputProps={{ 'aria-label': 'controlled' }}
                                                                                                />
                                                                                            </props.skeleton.CustomGrid>
                                                                                        </props.skeleton.CustomGrid>
                                                                                    </props.skeleton.CustomItem>
                                                                                    <props.skeleton.CustomItem>
                                                                                        <props.skeleton.CustomGrid container sink="true" spacing={0} sx={{ /*border: 'thin solid red',*/ padding: '0px' }}>
                                                                                            <props.skeleton.CustomGrid item sink="true" xs={9} sx={{ /*border: 'thin dashed green',*/ paddingTop: '10px', paddingBottom: '10px' }}>
                                                                                                <props.skeleton.CustomStack direction={'row'} alignItems={'center'}>
                                                                                                    <props.skeleton.CustomItem sink="true">
                                                                                                        Status: { (slice !== "Posts") ? (resources?.currentSlice?.status === "inactive") ? "Inactive" : "Active" : (resources?.currentSlice?.status === "draft") ? "Draft" : "Published" }                                                                   
                                                                                                    </props.skeleton.CustomItem>
                                                                                                </props.skeleton.CustomStack>
                                                                                            </props.skeleton.CustomGrid>
                                                                                            <props.skeleton.CustomGrid item sink="true" xs={3} sx={{ /*border: 'thin dashed green',*/ paddingTop: '10px', paddingBottom: '10px', justifyContent: 'center' }}>
                                                                                                
                                                                                            </props.skeleton.CustomGrid>
                                                                                        </props.skeleton.CustomGrid>
                                                                                    </props.skeleton.CustomItem>
                                                                                    <props.skeleton.CustomItem>
                                                                                        <props.skeleton.CustomGrid container sink="true" spacing={0} sx={{ /*border: 'thin solid red',*/ padding: '0px' }}>
                                                                                            <props.skeleton.CustomGrid item sink="true" xs={12} sx={{ /*border: 'thin dashed green',*/ paddingTop: '10px', paddingBottom: '10px' }}>
                                                                                                <props.skeleton.CustomStack direction={'row'} alignItems={'center'}>
                                                                                                    <props.skeleton.CustomItem sink="true">
                                                                                                        <props.skeleton.CustomFormControl sx={{ m: 1, minWidth: 120 }} size="small">
                                                                                                            <InputLabel id="visibility-select-small-label">Visibility</InputLabel>
                                                                                                            <props.skeleton.CustomSelect
                                                                                                                labelId="visibility-select-small-label"
                                                                                                                name="visibility"
                                                                                                                value={resources?.currentSlice?.visibility || ''}
                                                                                                                label="Visibility"
                                                                                                                onChange={handleChangeVisibility}
                                                                                                                disabled={resources.SliceFormIsDisabled}
                                                                                                            >
                                                                                                                <MenuItem value="public">Public</MenuItem>
                                                                                                                <MenuItem value="friendsonly">Friends Only</MenuItem>
                                                                                                                <MenuItem value="private">Private</MenuItem>
                                                                                                            </props.skeleton.CustomSelect>
                                                                                                        </props.skeleton.CustomFormControl>                                                              
                                                                                                    </props.skeleton.CustomItem>
                                                                                                    <props.skeleton.CustomItem sink="true">
                                                                                                        
                                                                                                    </props.skeleton.CustomItem>
                                                                                                </props.skeleton.CustomStack>
                                                                                            </props.skeleton.CustomGrid>
                                                                                        </props.skeleton.CustomGrid>
                                                                                    </props.skeleton.CustomItem>
                                                                                    <props.skeleton.CustomItem>
                                                                                        <props.skeleton.CustomGrid container sink="true" spacing={0} sx={{ /*border: 'thin solid red',*/ padding: '0px' }}>
                                                                                            <props.skeleton.CustomGrid item sink="true" xs={9} sx={{ /*border: 'thin dashed green',*/ paddingTop: '10px', paddingBottom: '10px' }}>
                                                                                                <props.skeleton.CustomStack direction={'row'} alignItems={'center'}>
                                                                                                    <props.skeleton.CustomItem sink="true">
                                                                                                        Create: Immediately                                                               
                                                                                                    </props.skeleton.CustomItem>
                                                                                                    <props.skeleton.CustomItem sink="true">
                                                                                                        
                                                                                                    </props.skeleton.CustomItem>
                                                                                                </props.skeleton.CustomStack>
                                                                                            </props.skeleton.CustomGrid>
                                                                                            <props.skeleton.CustomGrid item sink="true" xs={3} sx={{ /*border: 'thin dashed green',*/ paddingTop: '0px', paddingBottom: '0px', justifyContent: 'center' }}>
                                                                                                
                                                                                            </props.skeleton.CustomGrid>
                                                                                        </props.skeleton.CustomGrid>
                                                                                    </props.skeleton.CustomItem>
                                                                                    {
                                                                                        (currentUser?.role === "superadmin" || currentUser?.role === "admin" || currentUser?.role === "editor")
                                                                                        ? (
                                                                                            <props.skeleton.CustomItem sink="true">
                                                                                                <props.skeleton.CustomGrid container sink="true" spacing={0} sx={{ /*border: 'thin solid red',*/ padding: '0px' }}>
                                                                                                    <props.skeleton.CustomGrid item sink="true" xs={9} sx={{ /*border: 'thin dashed green',*/ paddingTop: '10px', paddingBottom: '10px' }}>
                                                                                                        <props.skeleton.CustomStack direction={'row'} alignItems={'center'}>
                                                                                                            <props.skeleton.CustomItem sink="true">
                                                                                                                Create Globally (?):                                                               
                                                                                                            </props.skeleton.CustomItem>
                                                                                                            <props.skeleton.CustomItem sink="true">
                                                                                                                
                                                                                                            </props.skeleton.CustomItem>
                                                                                                        </props.skeleton.CustomStack>
                                                                                                    </props.skeleton.CustomGrid>
                                                                                                    <props.skeleton.CustomGrid item sink="true" xs={3} sx={{ /*border: 'thin dashed green',*/ paddingTop: '0px', paddingBottom: '0px', justifyContent: 'center' }}>
                                                                                                        <props.skeleton.CustomSwitch
                                                                                                            sink="true"
                                                                                                            checked={(resources?.currentSlice?.namespace === "global") ? true : false}
                                                                                                            onChange={handlePublishGlobally}
                                                                                                            disabled={resources.SliceFormIsDisabled}
                                                                                                            name="namespace"
                                                                                                            inputProps={{ 'aria-label': 'controlled' }}
                                                                                                        />
                                                                                                    </props.skeleton.CustomGrid>
                                                                                                </props.skeleton.CustomGrid>
                                                                                            </props.skeleton.CustomItem>
                                                                                        )
                                                                                        : null
                                                                                    }
                                                                                    <props.skeleton.CustomItem>
                                                                                        <props.skeleton.CustomGrid container sink="true" spacing={0} sx={{ /*border: 'thin solid red',*/ padding: '0px' }}>
                                                                                            <props.skeleton.CustomGrid item sink="true" xs={9} sx={{ /*border: 'thin dashed green',*/ paddingTop: '0px', paddingBottom: '0px' }}>
                                                                                                &nbsp;
                                                                                            </props.skeleton.CustomGrid>
                                                                                            <props.skeleton.CustomGrid item sink="true" xs={3} sx={{ border: '', paddingTop: '0px', paddingBottom: '0px', justifyContent: 'center' }}>
                                                                                                
                                                                                            </props.skeleton.CustomGrid>
                                                                                        </props.skeleton.CustomGrid>
                                                                                    </props.skeleton.CustomItem>
                                                                                    <props.skeleton.CustomItem>
                                                                                        <props.skeleton.CustomGrid container sink="true" spacing={0} sx={{ /*border: 'thin solid red',*/ padding: '0px' }}>
                                                                                            <props.skeleton.CustomGrid item sink="true" xs={9} sx={{ /*border: 'thin dashed green',*/ paddingTop: '0px', paddingBottom: '0px' }}>
                                                                                                
                                                                                            </props.skeleton.CustomGrid>
                                                                                            <props.skeleton.CustomGrid item sink="true" xs={3} sx={{ border: '', paddingTop: '0px', paddingBottom: '0px', justifyContent: 'center' }}>
                                                                                                
                                                                                            </props.skeleton.CustomGrid>
                                                                                        </props.skeleton.CustomGrid>
                                                                                    </props.skeleton.CustomItem>
                                                                                    <props.skeleton.CustomItem>
                                                                                        <props.skeleton.CustomGrid container sink="true" spacing={0} sx={{ /*border: 'thin solid red',*/ padding: '0px' }}>
                                                                                            <props.skeleton.CustomGrid item sink="true" xs={9} sx={{ /*border: 'thin dashed green',*/ paddingTop: '0px', paddingBottom: '0px' }}>
                                                                                                                                                                        </props.skeleton.CustomGrid>
                                                                                            <props.skeleton.CustomGrid item sink="true" xs={3} sx={{ border: '', paddingTop: '0px', paddingBottom: '0px', justifyContent: 'center' }}>
                                                                                                
                                                                                            </props.skeleton.CustomGrid>
                                                                                        </props.skeleton.CustomGrid>
                                                                                    </props.skeleton.CustomItem>
                                                                                    <props.skeleton.CustomItem>
                                                                                        <props.skeleton.CustomGrid container sink="true" spacing={0} sx={{ /*border: 'thin solid red',*/ padding: '0px' }}>
                                                                                            <props.skeleton.CustomGrid item sink="true" xs={9} sx={{ /*border: 'thin dashed green',*/ paddingTop: '0px', paddingBottom: '0px' }}>
                                                                                                
                                                                                            </props.skeleton.CustomGrid>
                                                                                            <props.skeleton.CustomGrid item sink="true" xs={3} sx={{ border: '', paddingTop: '0px', paddingBottom: '0px', justifyContent: 'center' }}>
                                                                                                
                                                                                            </props.skeleton.CustomGrid>
                                                                                        </props.skeleton.CustomGrid>
                                                                                    </props.skeleton.CustomItem>
                                                                                    <props.skeleton.CustomItem>
                                                                                        <props.skeleton.CustomGrid container sink="true" spacing={0} sx={{ /*border: 'thin solid red',*/ padding: '0px' }}>
                                                                                            <props.skeleton.CustomGrid item sink="true" xs={1} sx={{ /*border: 'thin dashed green',*/ paddingTop: '0px', paddingBottom: '0px' }}>
                                                                                                <props.skeleton.Bar center="true" sink="true" danger="true" onClick={(e) => (resources.isUpdatingSlice) ? handleMoveToTrash() : null}><MoveToTrashIcon /></props.skeleton.Bar>
                                                                                            </props.skeleton.CustomGrid>
                                                                                            <props.skeleton.CustomGrid item sink="true" xs={2} sx={{ /*border: 'thin dashed green',*/ paddingTop: '0px', paddingBottom: '0px' }}>
                                                                                                
                                                                                            </props.skeleton.CustomGrid>
                                                                                            <props.skeleton.CustomGrid item sink="true" xs={6} sx={{ /*border: 'thin dashed green',*/ paddingTop: '0px', paddingBottom: '0px' }}>
                                                                                                
                                                                                            </props.skeleton.CustomGrid>
                                                                                            <props.skeleton.CustomGrid item sink="true" xs={3} sx={{ border: '', paddingTop: '0px', paddingBottom: '0px', justifyContent: 'center' }}>
                                                                                                <props.skeleton.Bar button="true" padding5lronly="true" center="true" sink="true" onClick={
                                                                                                    (e) => handleSendSlice(
                                                                                                        resources.currentSlice?.status
                                                                                                    )
                                                                                                    }>{
                                                                                                        (slice !== "Posts") 
                                                                                                        ? (
                                                                                                            (resources.currentSlice?.status === "inactive")
                                                                                                            ? "Save Inactive" 
                                                                                                            : "Update"
                                                                                                        )
                                                                                                        : (
                                                                                                            (resources.currentSlice?.status === "draft")
                                                                                                            ? "Save Draft"
                                                                                                            : "Update"
                                                                                                        )
                                                                                                    }
                                                                                                </props.skeleton.Bar>
                                                                                            </props.skeleton.CustomGrid>
                                                                                        </props.skeleton.CustomGrid>
                                                                                    </props.skeleton.CustomItem>
                                                                                </props.skeleton.CustomStack>
                                                                            </props.skeleton.CustomGrid>
                                                                            </>
                                                                        )
                                                                        : null
                                                                        // #endregion --------------------------------------------------------------------------------------------------
                                                                    }

                                                                    {
                                                                        // #region --------------[ base: TopGizmoNoVisibility ]---------------------------------------------------------------
                                                                        (
                                                                            Modules?.base?.TopGizmoNoVisibility
                                                                            && Modules?.base?.TopGizmoNoVisibility?.use 
                                                                            && Modules?.base?.TopGizmoNoVisibility?.sections?.indexOf(slice) > -1
                                                                        )
                                                                        ? (
                                                                            <>
                                                                            <props.skeleton.CustomGrid item sink="true"xs={12} sx={{ border: 'thin solid #83B1B5', padding: '10px', marginBottom: '10px' }}>
                                                                                <props.skeleton.CustomStack direction={'column'}>
                                                                                    <props.skeleton.CustomItem>
                                                                                        <props.skeleton.CustomGrid container spacing={0} sx={{ /*border: 'thin solid red',*/ padding: '0px' }}>
                                                                                            <props.skeleton.CustomGrid item sink="true"xs={9} sx={{ /*border: 'thin dashed green',*/ paddingTop: '10px', paddingBottom: '10px' }}>
                                                                                                Update
                                                                                            </props.skeleton.CustomGrid>
                                                                                            <props.skeleton.CustomGrid item sink="true"xs={3} sx={{ /*border: 'thin dashed green',*/ paddingTop: '10px', paddingBottom: '10px', justifyContent: 'center', overflow: 'hidden' }}>
                                                                                                [Controls?]
                                                                                            </props.skeleton.CustomGrid>
                                                                                        </props.skeleton.CustomGrid>
                                                                                    </props.skeleton.CustomItem>
                                                                                    <props.skeleton.CustomItem>
                                                                                        <props.skeleton.CustomGrid container spacing={0} sx={{ /*border: 'thin solid red',*/ padding: '0px' }}>
                                                                                            <props.skeleton.CustomGrid item sink="true"xs={3} sx={{ /*border: 'thin dashed green',*/ paddingTop: '0px', paddingBottom: '0px' }}>
                                                                                                <props.skeleton.Bar button="true" padding5lronly="true" center="true" sink="true" onClick={(e) => handlePreview()}>Preview</props.skeleton.Bar>
                                                                                            </props.skeleton.CustomGrid>
                                                                                            <props.skeleton.CustomGrid item sink="true"xs={6} sx={{ /*border: 'thin dashed green',*/ paddingTop: '0px', paddingBottom: '0px' }}>
                                                                                                
                                                                                            </props.skeleton.CustomGrid>
                                                                                            <props.skeleton.CustomGrid item sink="true"xs={3} sx={{ border: '', paddingTop: '0px', paddingBottom: '0px', justifyContent: 'center' }}>
                                                                                                <props.skeleton.Bar button="true" padding5lronly="true" center="true" sink="true" onClick={
                                                                                                    (e) => handleSendSlice(
                                                                                                        (slice !== "Posts")
                                                                                                        ? "inactive"
                                                                                                        : "draft"
                                                                                                    )
                                                                                                    }>{
                                                                                                        (slice !== "Posts") 
                                                                                                        ? "Save Inactive"
                                                                                                        : "Save Draft"                                                                                                
                                                                                                    }
                                                                                                </props.skeleton.Bar>
                                                                                            </props.skeleton.CustomGrid>
                                                                                        </props.skeleton.CustomGrid>
                                                                                    </props.skeleton.CustomItem>
                                                                                    <props.skeleton.CustomItem>
                                                                                        <props.skeleton.CustomGrid container spacing={0} sx={{ /*border: 'thin solid red',*/ padding: '0px' }}>
                                                                                            <props.skeleton.CustomGrid item sink="true"xs={9} sx={{ /*border: 'thin dashed green',*/ paddingTop: '0px', paddingBottom: '0px' }}>
                                                                                                <props.skeleton.CustomStack direction={'row'} alignItems={'center'}>
                                                                                                    <props.skeleton.CustomItem>
                                                                                                        Save as {(slice !== "Posts") ? "Inactive" : "Draft"}?                                                                    
                                                                                                    </props.skeleton.CustomItem>
                                                                                                    <props.skeleton.CustomItem>
                                                                                                        
                                                                                                    </props.skeleton.CustomItem>
                                                                                                </props.skeleton.CustomStack>
                                                                                            </props.skeleton.CustomGrid>
                                                                                            <props.skeleton.CustomGrid item sink="true"xs={3} sx={{ /*border: 'thin dashed green',*/ paddingTop: '0px', paddingBottom: '0px', justifyContent: 'center' }}>
                                                                                                <props.skeleton.CustomSwitch
                                                                                                    sink="true"
                                                                                                    checked={(slice !== "Posts" ? ((resources?.currentSlice?.status === "inactive") ? true : false) : ((resources?.currentSlice?.status === "draft") ? true : false))}
                                                                                                    onChange={handleChangeStatus}
                                                                                                    disabled={resources.SliceFormIsDisabled}
                                                                                                    name="status"
                                                                                                    inputProps={{ 'aria-label': 'controlled' }}
                                                                                                />
                                                                                            </props.skeleton.CustomGrid>
                                                                                        </props.skeleton.CustomGrid>
                                                                                    </props.skeleton.CustomItem>
                                                                                    <props.skeleton.CustomItem>
                                                                                        <props.skeleton.CustomGrid container spacing={0} sx={{ /*border: 'thin solid red',*/ padding: '0px' }}>
                                                                                            <props.skeleton.CustomGrid item sink="true"xs={9} sx={{ /*border: 'thin dashed green',*/ paddingTop: '10px', paddingBottom: '10px' }}>
                                                                                                <props.skeleton.CustomStack direction={'row'} alignItems={'center'}>
                                                                                                    <props.skeleton.CustomItem>
                                                                                                        Status: { (slice !== "Posts") ? (resources?.currentSlice?.status === "inactive") ? "Inactive" : "Active" : (resources?.currentSlice?.status === "draft") ? "Draft" : "Published" }                                                                   
                                                                                                    </props.skeleton.CustomItem>
                                                                                                </props.skeleton.CustomStack>
                                                                                            </props.skeleton.CustomGrid>
                                                                                            <props.skeleton.CustomGrid item sink="true"xs={3} sx={{ /*border: 'thin dashed green',*/ paddingTop: '10px', paddingBottom: '10px', justifyContent: 'center' }}>
                                                                                                
                                                                                            </props.skeleton.CustomGrid>
                                                                                        </props.skeleton.CustomGrid>
                                                                                    </props.skeleton.CustomItem>
                                                                                    <props.skeleton.CustomItem>
                                                                                        <props.skeleton.CustomGrid container spacing={0} sx={{ /*border: 'thin solid red',*/ padding: '0px' }}>
                                                                                            <props.skeleton.CustomGrid item sink="true"xs={9} sx={{ /*border: 'thin dashed green',*/ paddingTop: '10px', paddingBottom: '10px' }}>
                                                                                                <props.skeleton.CustomStack direction={'row'} alignItems={'center'}>
                                                                                                    <props.skeleton.CustomItem>
                                                                                                        Create: Immediately                                                               
                                                                                                    </props.skeleton.CustomItem>
                                                                                                    <props.skeleton.CustomItem>
                                                                                                        
                                                                                                    </props.skeleton.CustomItem>
                                                                                                </props.skeleton.CustomStack>
                                                                                            </props.skeleton.CustomGrid>
                                                                                            <props.skeleton.CustomGrid item sink="true"xs={3} sx={{ /*border: 'thin dashed green',*/ paddingTop: '0px', paddingBottom: '0px', justifyContent: 'center' }}>
                                                                                                
                                                                                            </props.skeleton.CustomGrid>
                                                                                        </props.skeleton.CustomGrid>
                                                                                    </props.skeleton.CustomItem>
                                                                                    {
                                                                                        (currentUser?.role === "superadmin" || currentUser?.role === "admin" || currentUser?.role === "editor")
                                                                                        ? (
                                                                                            <props.skeleton.CustomItem>
                                                                                                <props.skeleton.CustomGrid container spacing={0} sx={{ /*border: 'thin solid red',*/ padding: '0px' }}>
                                                                                                    <props.skeleton.CustomGrid item sink="true"xs={9} sx={{ /*border: 'thin dashed green',*/ paddingTop: '10px', paddingBottom: '10px' }}>
                                                                                                        <props.skeleton.CustomStack direction={'row'} alignItems={'center'}>
                                                                                                            <props.skeleton.CustomItem>
                                                                                                                Create Globally (?):                                                               
                                                                                                            </props.skeleton.CustomItem>
                                                                                                            <props.skeleton.CustomItem>
                                                                                                                
                                                                                                            </props.skeleton.CustomItem>
                                                                                                        </props.skeleton.CustomStack>
                                                                                                    </props.skeleton.CustomGrid>
                                                                                                    <props.skeleton.CustomGrid item sink="true"xs={3} sx={{ /*border: 'thin dashed green',*/ paddingTop: '0px', paddingBottom: '0px', justifyContent: 'center' }}>
                                                                                                        <props.skeleton.CustomSwitch
                                                                                                            sink="true"
                                                                                                            checked={(resources?.currentSlice?.namespace === "global") ? true : false}
                                                                                                            onChange={handlePublishGlobally}
                                                                                                            disabled={resources.SliceFormIsDisabled}
                                                                                                            name="namespace"
                                                                                                            inputProps={{ 'aria-label': 'controlled' }}
                                                                                                        />
                                                                                                    </props.skeleton.CustomGrid>
                                                                                                </props.skeleton.CustomGrid>
                                                                                            </props.skeleton.CustomItem>
                                                                                        )
                                                                                        : null
                                                                                    }
                                                                                    <props.skeleton.CustomItem>
                                                                                        <props.skeleton.CustomGrid container spacing={0} sx={{ /*border: 'thin solid red',*/ padding: '0px' }}>
                                                                                            <props.skeleton.CustomGrid item sink="true"xs={9} sx={{ /*border: 'thin dashed green',*/ paddingTop: '0px', paddingBottom: '0px' }}>
                                                                                                &nbsp;
                                                                                            </props.skeleton.CustomGrid>
                                                                                            <props.skeleton.CustomGrid item sink="true"xs={3} sx={{ border: '', paddingTop: '0px', paddingBottom: '0px', justifyContent: 'center' }}>
                                                                                                
                                                                                            </props.skeleton.CustomGrid>
                                                                                        </props.skeleton.CustomGrid>
                                                                                    </props.skeleton.CustomItem>
                                                                                    <props.skeleton.CustomItem>
                                                                                        <props.skeleton.CustomGrid container spacing={0} sx={{ /*border: 'thin solid red',*/ padding: '0px' }}>
                                                                                            <props.skeleton.CustomGrid item sink="true"xs={9} sx={{ /*border: 'thin dashed green',*/ paddingTop: '0px', paddingBottom: '0px' }}>
                                                                                                
                                                                                            </props.skeleton.CustomGrid>
                                                                                            <props.skeleton.CustomGrid item sink="true"xs={3} sx={{ border: '', paddingTop: '0px', paddingBottom: '0px', justifyContent: 'center' }}>
                                                                                                
                                                                                            </props.skeleton.CustomGrid>
                                                                                        </props.skeleton.CustomGrid>
                                                                                    </props.skeleton.CustomItem>
                                                                                    <props.skeleton.CustomItem>
                                                                                        <props.skeleton.CustomGrid container spacing={0} sx={{ /*border: 'thin solid red',*/ padding: '0px' }}>
                                                                                            <props.skeleton.CustomGrid item sink="true"xs={9} sx={{ /*border: 'thin dashed green',*/ paddingTop: '0px', paddingBottom: '0px' }}>
                                                                                                                                                                        </props.skeleton.CustomGrid>
                                                                                            <props.skeleton.CustomGrid item sink="true"xs={3} sx={{ border: '', paddingTop: '0px', paddingBottom: '0px', justifyContent: 'center' }}>
                                                                                                
                                                                                            </props.skeleton.CustomGrid>
                                                                                        </props.skeleton.CustomGrid>
                                                                                    </props.skeleton.CustomItem>
                                                                                    <props.skeleton.CustomItem>
                                                                                        <props.skeleton.CustomGrid container spacing={0} sx={{ /*border: 'thin solid red',*/ padding: '0px' }}>
                                                                                            <props.skeleton.CustomGrid item sink="true"xs={9} sx={{ /*border: 'thin dashed green',*/ paddingTop: '0px', paddingBottom: '0px' }}>
                                                                                                
                                                                                            </props.skeleton.CustomGrid>
                                                                                            <props.skeleton.CustomGrid item sink="true"xs={3} sx={{ border: '', paddingTop: '0px', paddingBottom: '0px', justifyContent: 'center' }}>
                                                                                                
                                                                                            </props.skeleton.CustomGrid>
                                                                                        </props.skeleton.CustomGrid>
                                                                                    </props.skeleton.CustomItem>
                                                                                    <props.skeleton.CustomItem>
                                                                                        <props.skeleton.CustomGrid container spacing={0} sx={{ /*border: 'thin solid red',*/ padding: '0px' }}>
                                                                                            <props.skeleton.CustomGrid item sink="true"xs={1} sx={{ /*border: 'thin dashed green',*/ paddingTop: '0px', paddingBottom: '0px' }}>
                                                                                                <props.skeleton.Bar buttonerror="true" padding5lronly="true" center="true" sink="true" onClick={(e) => (resources.isUpdatingSlice) ? handleMoveToTrash() : null}><MoveToTrashIcon /></props.skeleton.Bar>
                                                                                            </props.skeleton.CustomGrid>
                                                                                            <props.skeleton.CustomGrid item sink="true"xs={2} sx={{ /*border: 'thin dashed green',*/ paddingTop: '0px', paddingBottom: '0px' }}>
                                                                                                
                                                                                            </props.skeleton.CustomGrid>
                                                                                            <props.skeleton.CustomGrid item sink="true"xs={6} sx={{ /*border: 'thin dashed green',*/ paddingTop: '0px', paddingBottom: '0px' }}>
                                                                                                
                                                                                            </props.skeleton.CustomGrid>
                                                                                            <props.skeleton.CustomGrid item sink="true"xs={3} sx={{ border: '', paddingTop: '0px', paddingBottom: '0px', justifyContent: 'center' }}>
                                                                                                <props.skeleton.Bar button="true" padding5lronly="true" center="true" sink="true" onClick={
                                                                                                    (e) => handleSendSlice(
                                                                                                        resources.currentSlice?.status
                                                                                                    )
                                                                                                    }>{
                                                                                                        (slice !== "Posts") 
                                                                                                        ? (
                                                                                                            (resources.currentSlice?.status === "inactive")
                                                                                                            ? "Save Inactive" 
                                                                                                            : "Update"
                                                                                                        )
                                                                                                        : (
                                                                                                            (resources.currentSlice?.status === "draft")
                                                                                                            ? "Save Draft"
                                                                                                            : "Update"
                                                                                                        )
                                                                                                    }
                                                                                                </props.skeleton.Bar>
                                                                                            </props.skeleton.CustomGrid>
                                                                                        </props.skeleton.CustomGrid>
                                                                                    </props.skeleton.CustomItem>
                                                                                </props.skeleton.CustomStack>
                                                                            </props.skeleton.CustomGrid>
                                                                            </>
                                                                        )
                                                                        : null
                                                                    }
                                                                    
                                                                    {
                                                                        // #region --------------[ base: TopGizmoNoVisibilityNoNamespace ]---------------------------------------------------------------
                                                                        (
                                                                            Modules?.base?.TopGizmoNoVisibilityNoNamespace 
                                                                            && Modules?.base?.TopGizmoNoVisibilityNoNamespace?.use 
                                                                            && Modules?.base?.TopGizmoNoVisibilityNoNamespace?.sections?.indexOf(slice) > -1
                                                                        )
                                                                        ? (
                                                                            <>
                                                                            <props.skeleton.CustomGrid item sink="true"xs={12} sx={{ border: 'thin solid #83B1B5', padding: '10px', marginBottom: '10px' }}>
                                                                                <props.skeleton.CustomStack direction={'column'}>
                                                                                    <props.skeleton.CustomItem>
                                                                                        <props.skeleton.CustomGrid container spacing={0} sx={{ /*border: 'thin solid red',*/ padding: '0px' }}>
                                                                                            <props.skeleton.CustomGrid item sink="true"xs={9} sx={{ /*border: 'thin dashed green',*/ paddingTop: '10px', paddingBottom: '10px' }}>
                                                                                                Update
                                                                                            </props.skeleton.CustomGrid>
                                                                                            <props.skeleton.CustomGrid item sink="true"xs={3} sx={{ /*border: 'thin dashed green',*/ paddingTop: '10px', paddingBottom: '10px', justifyContent: 'center', overflow: 'hidden' }}>
                                                                                                [Controls?]
                                                                                            </props.skeleton.CustomGrid>
                                                                                        </props.skeleton.CustomGrid>
                                                                                    </props.skeleton.CustomItem>
                                                                                    <props.skeleton.CustomItem>
                                                                                        <props.skeleton.CustomGrid container spacing={0} sx={{ /*border: 'thin solid red',*/ padding: '0px' }}>
                                                                                            <props.skeleton.CustomGrid item sink="true"xs={3} sx={{ /*border: 'thin dashed green',*/ paddingTop: '0px', paddingBottom: '0px' }}>
                                                                                                <props.skeleton.Bar button="true" padding5lronly="true" center="true" onClick={(e) => handlePreview()}>Preview</props.skeleton.Bar>
                                                                                            </props.skeleton.CustomGrid>
                                                                                            <props.skeleton.CustomGrid item sink="true"xs={6} sx={{ /*border: 'thin dashed green',*/ paddingTop: '0px', paddingBottom: '0px' }}>
                                                                                                
                                                                                            </props.skeleton.CustomGrid>
                                                                                            <props.skeleton.CustomGrid item sink="true"xs={3} sx={{ border: '', paddingTop: '0px', paddingBottom: '0px', justifyContent: 'center' }}>
                                                                                                <props.skeleton.Bar button="true" padding5lronly="true" center="true" onClick={
                                                                                                    (e) => handleSendSlice(
                                                                                                        (slice !== "Posts")
                                                                                                        ? "inactive"
                                                                                                        : "draft"
                                                                                                    )
                                                                                                    }>{
                                                                                                        (slice !== "Posts") 
                                                                                                        ? (
                                                                                                            (resources.currentSlice?.status === "inactive")
                                                                                                            ? "Save Inactive" 
                                                                                                            : "Update"
                                                                                                        )
                                                                                                        : (
                                                                                                            (resources.currentSlice?.status === "draft")
                                                                                                            ? "Save Draft"
                                                                                                            : "Update"
                                                                                                        )
                                                                                                    }
                                                                                                </props.skeleton.Bar>
                                                                                            </props.skeleton.CustomGrid>
                                                                                        </props.skeleton.CustomGrid>
                                                                                    </props.skeleton.CustomItem>
                                                                                    <props.skeleton.CustomItem>
                                                                                        <props.skeleton.CustomGrid container spacing={0} sx={{ /*border: 'thin solid red',*/ padding: '0px' }}>
                                                                                            <props.skeleton.CustomGrid item sink="true"xs={9} sx={{ /*border: 'thin dashed green',*/ paddingTop: '0px', paddingBottom: '0px' }}>
                                                                                                <props.skeleton.CustomStack direction={'row'} alignItems={'center'}>
                                                                                                    <props.skeleton.CustomItem>
                                                                                                        Save as {
                                                                                                        (slice !== "Posts") 
                                                                                                        ? "Inactive" 
                                                                                                        : "Draft"
                                                                                                        }?
                                                                                                    </props.skeleton.CustomItem>
                                                                                                    <props.skeleton.CustomItem>
                                                                                                        
                                                                                                    </props.skeleton.CustomItem>
                                                                                                </props.skeleton.CustomStack>
                                                                                            </props.skeleton.CustomGrid>
                                                                                            <props.skeleton.CustomGrid item sink="true"xs={3} sx={{ /*border: 'thin dashed green',*/ paddingTop: '0px', paddingBottom: '0px', justifyContent: 'center' }}>
                                                                                                <props.skeleton.CustomSwitch
                                                                                                    sink="true"
                                                                                                    checked={(slice !== "Posts" ? ((resources?.currentSlice?.status === "inactive") ? true : false) : ((resources?.currentSlice?.status === "draft") ? true : false))}
                                                                                                    onChange={handleChangeStatus}
                                                                                                    disabled={resources.SliceFormIsDisabled}
                                                                                                    name="status"
                                                                                                    inputProps={{ 'aria-label': 'controlled' }}
                                                                                                />
                                                                                            </props.skeleton.CustomGrid>
                                                                                        </props.skeleton.CustomGrid>
                                                                                    </props.skeleton.CustomItem>
                                                                                    <props.skeleton.CustomItem>
                                                                                        <props.skeleton.CustomGrid container spacing={0} sx={{ /*border: 'thin solid red',*/ padding: '0px' }}>
                                                                                            <props.skeleton.CustomGrid item sink="true"xs={9} sx={{ /*border: 'thin dashed green',*/ paddingTop: '10px', paddingBottom: '10px' }}>
                                                                                                <props.skeleton.CustomStack direction={'row'} alignItems={'center'}>
                                                                                                    <props.skeleton.CustomItem>
                                                                                                        Status: { 
                                                                                                        (slice !== "Posts") 
                                                                                                        ? (resources?.currentSlice?.status === "inactive") 
                                                                                                            ? "Inactive" 
                                                                                                            : "Active" 
                                                                                                        : (resources?.currentSlice?.status === "draft") 
                                                                                                            ? "Draft" 
                                                                                                            : "Published" 
                                                                                                        }
                                                                                                    </props.skeleton.CustomItem>
                                                                                                </props.skeleton.CustomStack>
                                                                                            </props.skeleton.CustomGrid>
                                                                                            <props.skeleton.CustomGrid item sink="true"xs={3} sx={{ /*border: 'thin dashed green',*/ paddingTop: '10px', paddingBottom: '10px', justifyContent: 'center' }}>
                                                                                                
                                                                                            </props.skeleton.CustomGrid>
                                                                                        </props.skeleton.CustomGrid>
                                                                                    </props.skeleton.CustomItem>                                                                            
                                                                                    <props.skeleton.CustomItem>
                                                                                        <props.skeleton.CustomGrid container spacing={0} sx={{ /*border: 'thin solid red',*/ padding: '0px' }}>
                                                                                            <props.skeleton.CustomGrid item sink="true"xs={9} sx={{ /*border: 'thin dashed green',*/ paddingTop: '10px', paddingBottom: '10px' }}>
                                                                                                <props.skeleton.CustomStack direction={'row'} alignItems={'center'}>
                                                                                                    <props.skeleton.CustomItem>
                                                                                                        Create: Immediately                                                               
                                                                                                    </props.skeleton.CustomItem>
                                                                                                    <props.skeleton.CustomItem>
                                                                                                        
                                                                                                    </props.skeleton.CustomItem>
                                                                                                </props.skeleton.CustomStack>
                                                                                            </props.skeleton.CustomGrid>
                                                                                            <props.skeleton.CustomGrid item sink="true"xs={3} sx={{ /*border: 'thin dashed green',*/ paddingTop: '0px', paddingBottom: '0px', justifyContent: 'center' }}>
                                                                                                
                                                                                            </props.skeleton.CustomGrid>
                                                                                        </props.skeleton.CustomGrid>
                                                                                    </props.skeleton.CustomItem>                                                                            
                                                                                    <props.skeleton.CustomItem>
                                                                                        <props.skeleton.CustomGrid container spacing={0} sx={{ /*border: 'thin solid red',*/ padding: '0px' }}>
                                                                                            <props.skeleton.CustomGrid item sink="true"xs={9} sx={{ /*border: 'thin dashed green',*/ paddingTop: '0px', paddingBottom: '0px' }}>
                                                                                                &nbsp;
                                                                                            </props.skeleton.CustomGrid>
                                                                                            <props.skeleton.CustomGrid item sink="true"xs={3} sx={{ border: '', paddingTop: '0px', paddingBottom: '0px', justifyContent: 'center' }}>
                                                                                                
                                                                                            </props.skeleton.CustomGrid>
                                                                                        </props.skeleton.CustomGrid>
                                                                                    </props.skeleton.CustomItem>
                                                                                    <props.skeleton.CustomItem>
                                                                                        <props.skeleton.CustomGrid container spacing={0} sx={{ /*border: 'thin solid red',*/ padding: '0px' }}>
                                                                                            <props.skeleton.CustomGrid item sink="true"xs={9} sx={{ /*border: 'thin dashed green',*/ paddingTop: '0px', paddingBottom: '0px' }}>
                                                                                                
                                                                                            </props.skeleton.CustomGrid>
                                                                                            <props.skeleton.CustomGrid item sink="true"xs={3} sx={{ border: '', paddingTop: '0px', paddingBottom: '0px', justifyContent: 'center' }}>
                                                                                                
                                                                                            </props.skeleton.CustomGrid>
                                                                                        </props.skeleton.CustomGrid>
                                                                                    </props.skeleton.CustomItem>
                                                                                    <props.skeleton.CustomItem>
                                                                                        <props.skeleton.CustomGrid container spacing={0} sx={{ /*border: 'thin solid red',*/ padding: '0px' }}>
                                                                                            <props.skeleton.CustomGrid item sink="true"xs={9} sx={{ /*border: 'thin dashed green',*/ paddingTop: '0px', paddingBottom: '0px' }}>
                                                                                                                                                                        </props.skeleton.CustomGrid>
                                                                                            <props.skeleton.CustomGrid item sink="true"xs={3} sx={{ border: '', paddingTop: '0px', paddingBottom: '0px', justifyContent: 'center' }}>
                                                                                                
                                                                                            </props.skeleton.CustomGrid>
                                                                                        </props.skeleton.CustomGrid>
                                                                                    </props.skeleton.CustomItem>
                                                                                    <props.skeleton.CustomItem>
                                                                                        <props.skeleton.CustomGrid container spacing={0} sx={{ /*border: 'thin solid red',*/ padding: '0px' }}>
                                                                                            <props.skeleton.CustomGrid item sink="true"xs={9} sx={{ /*border: 'thin dashed green',*/ paddingTop: '0px', paddingBottom: '0px' }}>
                                                                                                
                                                                                            </props.skeleton.CustomGrid>
                                                                                            <props.skeleton.CustomGrid item sink="true"xs={3} sx={{ border: '', paddingTop: '0px', paddingBottom: '0px', justifyContent: 'center' }}>
                                                                                                
                                                                                            </props.skeleton.CustomGrid>
                                                                                        </props.skeleton.CustomGrid>
                                                                                    </props.skeleton.CustomItem>
                                                                                    <props.skeleton.CustomItem>
                                                                                        <props.skeleton.CustomGrid container spacing={0} sx={{ /*border: 'thin solid red',*/ padding: '0px' }}>
                                                                                            <props.skeleton.CustomGrid item sink="true"xs={1} sx={{ /*border: 'thin dashed green',*/ paddingTop: '0px', paddingBottom: '0px' }}>
                                                                                                <props.skeleton.Bar buttonerror="true" padding5lronly="true" center="true" onClick={(e) => (resources.isUpdatingSlice) ? handleMoveToTrash() : null}><MoveToTrashIcon /></props.skeleton.Bar>
                                                                                            </props.skeleton.CustomGrid>
                                                                                            <props.skeleton.CustomGrid item sink="true"xs={2} sx={{ /*border: 'thin dashed green',*/ paddingTop: '0px', paddingBottom: '0px' }}>
                                                                                                
                                                                                            </props.skeleton.CustomGrid>
                                                                                            <props.skeleton.CustomGrid item sink="true"xs={6} sx={{ /*border: 'thin dashed green',*/ paddingTop: '0px', paddingBottom: '0px' }}>
                                                                                                
                                                                                            </props.skeleton.CustomGrid>
                                                                                            <props.skeleton.CustomGrid item sink="true"xs={3} sx={{ border: '', paddingTop: '0px', paddingBottom: '0px', justifyContent: 'center' }}>
                                                                                                <props.skeleton.Bar button="true" padding5lronly="true" center="true" onClick={
                                                                                                    (e) => handleSendSlice(
                                                                                                        resources.currentSlice?.status
                                                                                                    )
                                                                                                    }>{
                                                                                                        (slice !== "Posts") 
                                                                                                        ? (
                                                                                                            (resources.currentSlice?.status === "inactive")
                                                                                                            ? "Save Inactive" 
                                                                                                            : "Update"
                                                                                                        )
                                                                                                        : (
                                                                                                            (resources.currentSlice?.status === "draft")
                                                                                                            ? "Save Draft"
                                                                                                            : "Update"
                                                                                                        )
                                                                                                    }
                                                                                                </props.skeleton.Bar>
                                                                                            </props.skeleton.CustomGrid>
                                                                                        </props.skeleton.CustomGrid>
                                                                                    </props.skeleton.CustomItem>
                                                                                </props.skeleton.CustomStack>
                                                                            </props.skeleton.CustomGrid>
                                                                            </>
                                                                        )
                                                                        : null
                                                                        // #endregion --------------------------------------------------------------------------------------------------
                                                                    }

                                                                    {
                                                                        // #region --------------[ base: Category ]------------------------------------------------------------------
                                                                        (
                                                                            Modules?.base?.Category
                                                                            && Modules?.base?.Category?.use 
                                                                            && Modules?.base?.Category?.sections?.indexOf(slice) > -1
                                                                        )
                                                                        ? (
                                                                            <>
                                                                            <props.skeleton.CustomGrid item sink="true"xs={12} sx={{ border: 'thin solid #83B1B5', padding: '10px', marginBottom: '10px' }}>
                                                                                <props.skeleton.CustomStack direction={'column'}>
                                                                                    <props.skeleton.CustomItem>
                                                                                        <props.skeleton.CustomGrid container spacing={0} sx={{ /*border: 'thin solid red',*/ padding: '0px' }}>
                                                                                            <props.skeleton.CustomGrid item sink="true"xs={9} sx={{ /*border: 'thin dashed green',*/ paddingTop: '10px', paddingBottom: '10px' }}>
                                                                                                Category
                                                                                            </props.skeleton.CustomGrid>
                                                                                            <props.skeleton.CustomGrid item sink="true"xs={3} sx={{ /*border: 'thin dashed green',*/ paddingTop: '10px', paddingBottom: '10px', justifyContent: 'center', overflow: 'hidden' }}>
                                                                                                [Controls?]
                                                                                            </props.skeleton.CustomGrid>
                                                                                        </props.skeleton.CustomGrid>
                                                                                    </props.skeleton.CustomItem>
                                                                                    <props.skeleton.CustomItem>
                                                                                        <props.skeleton.CustomGrid container spacing={0} sx={{ /*border: 'thin solid red',*/ padding: '0px' }}>
                                                                                            <props.skeleton.CustomGrid item sink="true"xs={12} sx={{ /*border: 'thin dashed green',*/ paddingTop: '0px', paddingBottom: '0px' }}>
                                                                                                <props.skeleton.CustomFormControl sx={{  }}>
                                                                                                    <InputLabel id="category-multiple-checkbox-label">Category</InputLabel>
                                                                                                    <props.skeleton.CustomSelect
                                                                                                        labelId="category-multiple-checkbox-label"
                                                                                                        id="category-multiple-checkbox"
                                                                                                        multiple
                                                                                                        value={selectedCategories || false}
                                                                                                        disabled={resources.SliceFormIsDisabled}
                                                                                                        onChange={handleCategoryChange}
                                                                                                        input={<OutlinedInput label="Category" />}
                                                                                                        renderValue={(selected) => selected?.join(', ')}
                                                                                                        MenuProps={MenuProps}
                                                                                                    >
                                                                                                        {loadedcategories.map((cat) => (
                                                                                                            <MenuItem key={cat} value={cat}>
                                                                                                            <Checkbox checked={selectedCategories?.indexOf(cat) > -1} />
                                                                                                            <ListItemText primary={cat} />
                                                                                                            </MenuItem>
                                                                                                        ))}
                                                                                                    </props.skeleton.CustomSelect>
                                                                                                </props.skeleton.CustomFormControl>
                                                                                            </props.skeleton.CustomGrid>
                                                                                        </props.skeleton.CustomGrid>
                                                                                    </props.skeleton.CustomItem>
                                                                                    <props.skeleton.CustomItem>
                                                                                        <props.skeleton.CustomGrid container spacing={0} sx={{ /*border: 'thin solid red',*/ padding: '0px' }}>
                                                                                            <props.skeleton.CustomGrid item sink="true"xs={9} sx={{ /*border: 'thin dashed green',*/ paddingTop: '0px', paddingBottom: '0px' }}>
                                                                                                <props.skeleton.CustomStack direction={'row'} alignItems={'center'}>
                                                                                                    <props.skeleton.CustomItem>
                                                                                                                                                                            
                                                                                                    </props.skeleton.CustomItem>
                                                                                                    <props.skeleton.CustomItem>
                                                                                                        
                                                                                                    </props.skeleton.CustomItem>
                                                                                                </props.skeleton.CustomStack>
                                                                                            </props.skeleton.CustomGrid>
                                                                                            <props.skeleton.CustomGrid item sink="true"xs={3} sx={{ /*border: 'thin dashed green',*/ paddingTop: '0px', paddingBottom: '0px', justifyContent: 'center' }}>
                                                                                                
                                                                                            </props.skeleton.CustomGrid>
                                                                                        </props.skeleton.CustomGrid>
                                                                                    </props.skeleton.CustomItem>
                                                                                    <props.skeleton.CustomItem>
                                                                                        <props.skeleton.CustomGrid container spacing={0} sx={{ /*border: 'thin solid red',*/ padding: '0px' }}>
                                                                                            <props.skeleton.CustomGrid item sink="true"xs={9} sx={{ /*border: 'thin dashed green',*/ paddingTop: '10px', paddingBottom: '10px' }}>
                                                                                                <props.skeleton.CustomStack direction={'row'} alignItems={'center'}>
                                                                                                    <props.skeleton.CustomItem>
                                                                                                                                                                    
                                                                                                    </props.skeleton.CustomItem>
                                                                                                    <props.skeleton.CustomItem>
                                                                                                        
                                                                                                    </props.skeleton.CustomItem>
                                                                                                </props.skeleton.CustomStack>
                                                                                            </props.skeleton.CustomGrid>
                                                                                            <props.skeleton.CustomGrid item sink="true"xs={3} sx={{ /*border: 'thin dashed green',*/ paddingTop: '0px', paddingBottom: '0px', justifyContent: 'center' }}>
                                                                                                
                                                                                            </props.skeleton.CustomGrid>
                                                                                        </props.skeleton.CustomGrid>
                                                                                    </props.skeleton.CustomItem>
                                                                                    <props.skeleton.CustomItem>
                                                                                        <props.skeleton.CustomGrid container spacing={0} sx={{ /*border: 'thin solid red',*/ padding: '0px' }}>
                                                                                            <props.skeleton.CustomGrid item sink="true"xs={9} sx={{ /*border: 'thin dashed green',*/ paddingTop: '0px', paddingBottom: '0px' }}>
                                                                                                
                                                                                            </props.skeleton.CustomGrid>
                                                                                            <props.skeleton.CustomGrid item sink="true"xs={3} sx={{ border: '', paddingTop: '0px', paddingBottom: '0px', justifyContent: 'center' }}>
                                                                                                
                                                                                            </props.skeleton.CustomGrid>
                                                                                        </props.skeleton.CustomGrid>
                                                                                    </props.skeleton.CustomItem>
                                                                                    <props.skeleton.CustomItem>
                                                                                        <props.skeleton.CustomGrid container spacing={0} sx={{ /*border: 'thin solid red',*/ padding: '0px' }}>
                                                                                            <props.skeleton.CustomGrid item sink="true"xs={9} sx={{ /*border: 'thin dashed green',*/ paddingTop: '0px', paddingBottom: '0px' }}>
                                                                                                
                                                                                            </props.skeleton.CustomGrid>
                                                                                            <props.skeleton.CustomGrid item sink="true"xs={3} sx={{ border: '', paddingTop: '0px', paddingBottom: '0px', justifyContent: 'center' }}>
                                                                                                
                                                                                            </props.skeleton.CustomGrid>
                                                                                        </props.skeleton.CustomGrid>
                                                                                    </props.skeleton.CustomItem>
                                                                                    <props.skeleton.CustomItem>
                                                                                        <props.skeleton.CustomGrid container spacing={0} sx={{ /*border: 'thin solid red',*/ padding: '0px' }}>
                                                                                            <props.skeleton.CustomGrid item sink="true"xs={9} sx={{ /*border: 'thin dashed green',*/ paddingTop: '0px', paddingBottom: '0px' }}>
                                                                                                
                                                                                            </props.skeleton.CustomGrid>
                                                                                            <props.skeleton.CustomGrid item sink="true"xs={3} sx={{ border: '', paddingTop: '0px', paddingBottom: '0px', justifyContent: 'center' }}>
                                                                                                
                                                                                            </props.skeleton.CustomGrid>
                                                                                        </props.skeleton.CustomGrid>
                                                                                    </props.skeleton.CustomItem>
                                                                                    <props.skeleton.CustomItem>
                                                                                        <props.skeleton.CustomGrid container spacing={0} sx={{ /*border: 'thin solid red',*/ padding: '0px' }}>
                                                                                            <props.skeleton.CustomGrid item sink="true"xs={9} sx={{ /*border: 'thin dashed green',*/ paddingTop: '0px', paddingBottom: '0px' }}>
                                                                                                
                                                                                            </props.skeleton.CustomGrid>
                                                                                            <props.skeleton.CustomGrid item sink="true"xs={3} sx={{ border: '', paddingTop: '0px', paddingBottom: '0px', justifyContent: 'center' }}>
                                                                                                
                                                                                            </props.skeleton.CustomGrid>
                                                                                        </props.skeleton.CustomGrid>
                                                                                    </props.skeleton.CustomItem>
                                                                                    <props.skeleton.CustomItem>
                                                                                        <props.skeleton.CustomGrid container spacing={0} sx={{ /*border: 'thin solid red',*/ padding: '0px' }}>
                                                                                            <props.skeleton.CustomGrid item sink="true"xs={9} sx={{ /*border: 'thin dashed green',*/ paddingTop: '0px', paddingBottom: '0px' }}>
                                                                                                
                                                                                            </props.skeleton.CustomGrid>
                                                                                            <props.skeleton.CustomGrid item sink="true"xs={3} sx={{ border: '', paddingTop: '0px', paddingBottom: '0px', justifyContent: 'center' }}>
                                                                                                <props.skeleton.Bar button="true" padding5lronly="true" center="true" sx={{ fontSize: '14px' }} onClick={(e) => handleCreateNewCategory()}>Add New</props.skeleton.Bar>
                                                                                            </props.skeleton.CustomGrid>
                                                                                        </props.skeleton.CustomGrid>
                                                                                    </props.skeleton.CustomItem>

                                                                                </props.skeleton.CustomStack>
                                                                            </props.skeleton.CustomGrid>
                                                                            </>
                                                                        )
                                                                        : null
                                                                        // #endregion --------------------------------------------------------------------------------------------------
                                                                    }

                                                                    {
                                                                        // #region --------------[ base: Tag ]-------------------------------------------------------------------------
                                                                        (
                                                                            Modules?.base?.Tag
                                                                            && Modules?.base?.Tag?.use 
                                                                            && Modules?.base?.Tag?.sections?.indexOf(slice) > -1
                                                                        )
                                                                        ? (
                                                                            <>
                                                                            <props.skeleton.CustomGrid item sink="true"xs={12} sx={{ border: 'thin solid #83B1B5', padding: '10px', marginBottom: '10px' }}>
                                                                                <props.skeleton.CustomStack direction={'column'}>
                                                                                    <props.skeleton.CustomItem>
                                                                                        <props.skeleton.CustomGrid container spacing={0} sx={{ /*border: 'thin solid red',*/ padding: '0px' }}>
                                                                                            <props.skeleton.CustomGrid item sink="true"xs={9} sx={{ /*border: 'thin dashed green',*/ paddingTop: '10px', paddingBottom: '10px' }}>
                                                                                                Tags
                                                                                            </props.skeleton.CustomGrid>
                                                                                            <props.skeleton.CustomGrid item sink="true"xs={3} sx={{ /*border: 'thin dashed green',*/ paddingTop: '10px', paddingBottom: '10px', justifyContent: 'center', overflow: 'hidden' }}>
                                                                                                [Controls?]
                                                                                            </props.skeleton.CustomGrid>
                                                                                        </props.skeleton.CustomGrid>
                                                                                    </props.skeleton.CustomItem>
                                                                                    <props.skeleton.CustomItem>
                                                                                        <props.skeleton.CustomGrid container spacing={0} sx={{ /*border: 'thin solid red',*/ padding: '0px' }}>
                                                                                            <props.skeleton.CustomGrid item sink="true"xs={12} sx={{ /*border: 'thin dashed green',*/ paddingTop: '0px', paddingBottom: '0px' }}>
                                                                                                <props.skeleton.CustomFormControl sx={{  }}>
                                                                                                    <InputLabel id="tags-multiple-checkbox-label">Tags</InputLabel>
                                                                                                    <props.skeleton.CustomSelect
                                                                                                        labelId="tags-multiple-checkbox-label"
                                                                                                        id="tags-multiple-checkbox"
                                                                                                        multiple
                                                                                                        value={selectedTags || false}
                                                                                                        disabled={resources.SliceFormIsDisabled}
                                                                                                        onChange={handleTagsChange}
                                                                                                        input={<OutlinedInput label="Tags" />}
                                                                                                        renderValue={(selected) => selected?.join(', ')}
                                                                                                        MenuProps={MenuProps}
                                                                                                    >
                                                                                                        {loadedtags.map((tag) => (
                                                                                                            <MenuItem key={tag} value={tag}>
                                                                                                            <Checkbox checked={selectedTags?.indexOf(tag) > -1} />
                                                                                                            <ListItemText primary={tag} />
                                                                                                            </MenuItem>
                                                                                                        ))}
                                                                                                    </props.skeleton.CustomSelect>
                                                                                                </props.skeleton.CustomFormControl>
                                                                                            </props.skeleton.CustomGrid>
                                                                                        </props.skeleton.CustomGrid>
                                                                                    </props.skeleton.CustomItem>
                                                                                    <props.skeleton.CustomItem>
                                                                                        <props.skeleton.CustomGrid container spacing={0} sx={{ /*border: 'thin solid red',*/ padding: '0px' }}>
                                                                                            <props.skeleton.CustomGrid item sink="true"xs={9} sx={{ /*border: 'thin dashed green',*/ paddingTop: '0px', paddingBottom: '0px' }}>
                                                                                                <props.skeleton.CustomStack direction={'row'} alignItems={'center'}>
                                                                                                    <props.skeleton.CustomItem>
                                                                                                                                                                            
                                                                                                    </props.skeleton.CustomItem>
                                                                                                    <props.skeleton.CustomItem>
                                                                                                        
                                                                                                    </props.skeleton.CustomItem>
                                                                                                </props.skeleton.CustomStack>
                                                                                            </props.skeleton.CustomGrid>
                                                                                            <props.skeleton.CustomGrid item sink="true"xs={3} sx={{ /*border: 'thin dashed green',*/ paddingTop: '0px', paddingBottom: '0px', justifyContent: 'center' }}>
                                                                                                
                                                                                            </props.skeleton.CustomGrid>
                                                                                        </props.skeleton.CustomGrid>
                                                                                    </props.skeleton.CustomItem>
                                                                                    <props.skeleton.CustomItem>
                                                                                        <props.skeleton.CustomGrid container spacing={0} sx={{ /*border: 'thin solid red',*/ padding: '0px' }}>
                                                                                            <props.skeleton.CustomGrid item sink="true"xs={9} sx={{ /*border: 'thin dashed green',*/ paddingTop: '10px', paddingBottom: '10px' }}>
                                                                                                <props.skeleton.CustomStack direction={'row'} alignItems={'center'}>
                                                                                                    <props.skeleton.CustomItem>
                                                                                                                                                                    
                                                                                                    </props.skeleton.CustomItem>
                                                                                                    <props.skeleton.CustomItem>
                                                                                                        
                                                                                                    </props.skeleton.CustomItem>
                                                                                                </props.skeleton.CustomStack>
                                                                                            </props.skeleton.CustomGrid>
                                                                                            <props.skeleton.CustomGrid item sink="true"xs={3} sx={{ /*border: 'thin dashed green',*/ paddingTop: '0px', paddingBottom: '0px', justifyContent: 'center' }}>
                                                                                                
                                                                                            </props.skeleton.CustomGrid>
                                                                                        </props.skeleton.CustomGrid>
                                                                                    </props.skeleton.CustomItem>
                                                                                    <props.skeleton.CustomItem>
                                                                                        <props.skeleton.CustomGrid container spacing={0} sx={{ /*border: 'thin solid red',*/ padding: '0px' }}>
                                                                                            <props.skeleton.CustomGrid item sink="true"xs={9} sx={{ /*border: 'thin dashed green',*/ paddingTop: '0px', paddingBottom: '0px' }}>
                                                                                                
                                                                                            </props.skeleton.CustomGrid>
                                                                                            <props.skeleton.CustomGrid item sink="true"xs={3} sx={{ border: '', paddingTop: '0px', paddingBottom: '0px', justifyContent: 'center' }}>
                                                                                                
                                                                                            </props.skeleton.CustomGrid>
                                                                                        </props.skeleton.CustomGrid>
                                                                                    </props.skeleton.CustomItem>
                                                                                    <props.skeleton.CustomItem>
                                                                                        <props.skeleton.CustomGrid container spacing={0} sx={{ /*border: 'thin solid red',*/ padding: '0px' }}>
                                                                                            <props.skeleton.CustomGrid item sink="true"xs={9} sx={{ /*border: 'thin dashed green',*/ paddingTop: '0px', paddingBottom: '0px' }}>
                                                                                                
                                                                                            </props.skeleton.CustomGrid>
                                                                                            <props.skeleton.CustomGrid item sink="true"xs={3} sx={{ border: '', paddingTop: '0px', paddingBottom: '0px', justifyContent: 'center' }}>
                                                                                                
                                                                                            </props.skeleton.CustomGrid>
                                                                                        </props.skeleton.CustomGrid>
                                                                                    </props.skeleton.CustomItem>
                                                                                    <props.skeleton.CustomItem>
                                                                                        <props.skeleton.CustomGrid container spacing={0} sx={{ /*border: 'thin solid red',*/ padding: '0px' }}>
                                                                                            <props.skeleton.CustomGrid item sink="true"xs={9} sx={{ /*border: 'thin dashed green',*/ paddingTop: '0px', paddingBottom: '0px' }}>
                                                                                                
                                                                                            </props.skeleton.CustomGrid>
                                                                                            <props.skeleton.CustomGrid item sink="true"xs={3} sx={{ border: '', paddingTop: '0px', paddingBottom: '0px', justifyContent: 'center' }}>
                                                                                                
                                                                                            </props.skeleton.CustomGrid>
                                                                                        </props.skeleton.CustomGrid>
                                                                                    </props.skeleton.CustomItem>
                                                                                    <props.skeleton.CustomItem>
                                                                                        <props.skeleton.CustomGrid container spacing={0} sx={{ /*border: 'thin solid red',*/ padding: '0px' }}>
                                                                                            <props.skeleton.CustomGrid item sink="true"xs={9} sx={{ /*border: 'thin dashed green',*/ paddingTop: '0px', paddingBottom: '0px' }}>
                                                                                                
                                                                                            </props.skeleton.CustomGrid>
                                                                                            <props.skeleton.CustomGrid item sink="true"xs={3} sx={{ border: '', paddingTop: '0px', paddingBottom: '0px', justifyContent: 'center' }}>
                                                                                                
                                                                                            </props.skeleton.CustomGrid>
                                                                                        </props.skeleton.CustomGrid>
                                                                                    </props.skeleton.CustomItem>
                                                                                    <props.skeleton.CustomItem>
                                                                                        <props.skeleton.CustomGrid container spacing={0} sx={{ /*border: 'thin solid red',*/ padding: '0px' }}>
                                                                                            <props.skeleton.CustomGrid item sink="true"xs={9} sx={{ /*border: 'thin dashed green',*/ paddingTop: '0px', paddingBottom: '0px' }}>
                                                                                                
                                                                                            </props.skeleton.CustomGrid>
                                                                                            <props.skeleton.CustomGrid item sink="true"xs={3} sx={{ border: '', paddingTop: '0px', paddingBottom: '0px', justifyContent: 'center' }}>
                                                                                                <props.skeleton.Bar button="true" padding5lronly="true" center="true" sx={{ fontSize: '14px' }} onClick={(e) => handleCreateNewTag()}>Add New</props.skeleton.Bar>
                                                                                            </props.skeleton.CustomGrid>
                                                                                        </props.skeleton.CustomGrid>
                                                                                    </props.skeleton.CustomItem>

                                                                                </props.skeleton.CustomStack>
                                                                            </props.skeleton.CustomGrid>
                                                                            </>
                                                                        )
                                                                        : null
                                                                        // #endregion --------------------------------------------------------------------------------------------------
                                                                    }

                                                                    {
                                                                        // #region --------------[ base: FeaturedImage ]------------------------------------------------------------------
                                                                        (
                                                                            Modules?.base?.FeaturedImage 
                                                                            && Modules?.base?.FeaturedImage?.use 
                                                                            && Modules?.base?.FeaturedImage?.sections?.indexOf(slice) > -1
                                                                        )
                                                                        ? (
                                                                            <>
                                                                            <props.skeleton.CustomGrid item sink="true"xs={12} sx={{ border: 'thin solid #83B1B5', padding: '10px', marginBottom: '10px' }}>
                                                                                <props.skeleton.CustomStack direction={'column'}>
                                                                                    <props.skeleton.CustomItem>
                                                                                        <props.skeleton.CustomGrid container spacing={0} sx={{ /*border: 'thin solid red',*/ padding: '0px' }}>
                                                                                            <props.skeleton.CustomGrid item sink="true"xs={9} sx={{ /*border: 'thin dashed green',*/ paddingTop: '10px', paddingBottom: '10px' }}>
                                                                                                Featured Image
                                                                                            </props.skeleton.CustomGrid>
                                                                                            <props.skeleton.CustomGrid item sink="true"xs={3} sx={{ /*border: 'thin dashed green',*/ paddingTop: '10px', paddingBottom: '10px', justifyContent: 'center', overflow: 'hidden' }}>
                                                                                                [Controls?]
                                                                                            </props.skeleton.CustomGrid>
                                                                                        </props.skeleton.CustomGrid>
                                                                                    </props.skeleton.CustomItem>
                                                                                    <props.skeleton.CustomItem>
                                                                                        <props.skeleton.CustomGrid container spacing={0} sx={{ /*border: 'thin solid red',*/ padding: '0px' }}>
                                                                                            <props.skeleton.CustomGrid item sink="true"xs={12} sx={{ /*border: 'thin dashed green',*/ paddingTop: '0px', paddingBottom: '0px' }}>                                                                                                                                                        
                                                                                            {
                                                                                                (
                                                                                                    (resources.sliceAuthor) 
                                                                                                    ? (
                                                                                                        <UploadDragZone 
                                                                                                            skeleton={props.skeleton} 
                                                                                                            constraints={{ height: '25vh', width: '97%' }}
                                                                                                            query={query} 
                                                                                                            section={query}
                                                                                                            what="featuredimage"
                                                                                                            postslug={resources?.currentSlice?.slug}
                                                                                                            resources={resources} 
                                                                                                            user={resources.sliceAuthor} 
                                                                                                            img={resources?.currentSlice?.imageUrl}                                                                                                     
                                                                                                            settingServerAPIBaseUrl={props.settingServerAPIBaseUrl}
                                                                                                        />
                                                                                                    )
                                                                                                    : "loading..."
                                                                                                )
                                                                                            }
                                                                                            </props.skeleton.CustomGrid>
                                                                                        </props.skeleton.CustomGrid>
                                                                                    </props.skeleton.CustomItem>
                                                                                    <props.skeleton.CustomItem>
                                                                                        <props.skeleton.CustomGrid container spacing={0} sx={{ /*border: 'thin solid red',*/ padding: '0px' }}>
                                                                                            <props.skeleton.CustomGrid item sink="true"xs={9} sx={{ /*border: 'thin dashed green',*/ paddingTop: '0px', paddingBottom: '0px' }}>
                                                                                                <props.skeleton.CustomStack direction={'row'} alignItems={'center'}>
                                                                                                    <props.skeleton.CustomItem>
                                                                                                                                                                            
                                                                                                    </props.skeleton.CustomItem>
                                                                                                    <props.skeleton.CustomItem>
                                                                                                        
                                                                                                    </props.skeleton.CustomItem>
                                                                                                </props.skeleton.CustomStack>
                                                                                            </props.skeleton.CustomGrid>
                                                                                            <props.skeleton.CustomGrid item sink="true"xs={3} sx={{ /*border: 'thin dashed green',*/ paddingTop: '0px', paddingBottom: '0px', justifyContent: 'center' }}>
                                                                                                
                                                                                            </props.skeleton.CustomGrid>
                                                                                        </props.skeleton.CustomGrid>
                                                                                    </props.skeleton.CustomItem>
                                                                                    <props.skeleton.CustomItem>
                                                                                        <props.skeleton.CustomGrid container spacing={0} sx={{ /*border: 'thin solid red',*/ padding: '0px' }}>
                                                                                            <props.skeleton.CustomGrid item sink="true"xs={9} sx={{ /*border: 'thin dashed green',*/ paddingTop: '10px', paddingBottom: '10px' }}>
                                                                                                <props.skeleton.CustomStack direction={'row'} alignItems={'center'}>
                                                                                                    <props.skeleton.CustomItem>
                                                                                                                                                                    
                                                                                                    </props.skeleton.CustomItem>
                                                                                                    <props.skeleton.CustomItem>
                                                                                                        
                                                                                                    </props.skeleton.CustomItem>
                                                                                                </props.skeleton.CustomStack>
                                                                                            </props.skeleton.CustomGrid>
                                                                                            <props.skeleton.CustomGrid item sink="true"xs={3} sx={{ /*border: 'thin dashed green',*/ paddingTop: '0px', paddingBottom: '0px', justifyContent: 'center' }}>
                                                                                                
                                                                                            </props.skeleton.CustomGrid>
                                                                                        </props.skeleton.CustomGrid>
                                                                                    </props.skeleton.CustomItem>
                                                                                    <props.skeleton.CustomItem>
                                                                                        <props.skeleton.CustomGrid container spacing={0} sx={{ /*border: 'thin solid red',*/ padding: '0px' }}>
                                                                                            <props.skeleton.CustomGrid item sink="true"xs={9} sx={{ /*border: 'thin dashed green',*/ paddingTop: '0px', paddingBottom: '0px' }}>
                                                                                                
                                                                                            </props.skeleton.CustomGrid>
                                                                                            <props.skeleton.CustomGrid item sink="true"xs={3} sx={{ border: '', paddingTop: '0px', paddingBottom: '0px', justifyContent: 'center' }}>
                                                                                                
                                                                                            </props.skeleton.CustomGrid>
                                                                                        </props.skeleton.CustomGrid>
                                                                                    </props.skeleton.CustomItem>
                                                                                    <props.skeleton.CustomItem>
                                                                                        <props.skeleton.CustomGrid container spacing={0} sx={{ /*border: 'thin solid red',*/ padding: '0px' }}>
                                                                                            <props.skeleton.CustomGrid item sink="true"xs={9} sx={{ /*border: 'thin dashed green',*/ paddingTop: '0px', paddingBottom: '0px' }}>
                                                                                                
                                                                                            </props.skeleton.CustomGrid>
                                                                                            <props.skeleton.CustomGrid item sink="true"xs={3} sx={{ border: '', paddingTop: '0px', paddingBottom: '0px', justifyContent: 'center' }}>
                                                                                                
                                                                                            </props.skeleton.CustomGrid>
                                                                                        </props.skeleton.CustomGrid>
                                                                                    </props.skeleton.CustomItem>
                                                                                    
                                                                                </props.skeleton.CustomStack>
                                                                            </props.skeleton.CustomGrid>
                                                                            </>
                                                                        )
                                                                        : null
                                                                        // #endregion --------------------------------------------------------------------------------------------------
                                                                    }

                                                                    {
                                                                        /* PLUGIN LOADING? IDK */
                                                                    }

                                                                    {
                                                                        // #region ----------[ plugin: LoremIpsum  ]--------------------------------------------------------------------
                                                                        (
                                                                            Modules?.plugins?.LoremIpsum 
                                                                            && Modules?.plugins?.LoremIpsum?.use 
                                                                            && Modules?.plugins?.LoremIpsum?.sections?.indexOf(slice) > -1
                                                                        )
                                                                        ? (
                                                                            <>
                                                                            <props.skeleton.CustomGrid item sink="true"xs={12} sx={{ border: 'thin solid #83B1B5', padding: '10px' }}>
                                                                                <props.skeleton.CustomStack direction={'column'}>
                                                                                    <props.skeleton.CustomItem>
                                                                                        <props.skeleton.CustomGrid container spacing={0} sx={{ /*border: 'thin solid red',*/ padding: '0px' }}>
                                                                                            <props.skeleton.CustomGrid item sink="true"xs={9} sx={{ /*border: 'thin dashed green',*/ paddingTop: '10px', paddingBottom: '10px' }}>
                                                                                                LoremImpsum Generate
                                                                                            </props.skeleton.CustomGrid>
                                                                                            <props.skeleton.CustomGrid item sink="true"xs={3} sx={{ /*border: 'thin dashed green',*/ paddingTop: '10px', paddingBottom: '10px', justifyContent: 'center', overflow: 'hidden' }}>
                                                                                                [Controls?]
                                                                                            </props.skeleton.CustomGrid>
                                                                                        </props.skeleton.CustomGrid>
                                                                                    </props.skeleton.CustomItem>
                                                                                    <props.skeleton.CustomItem>
                                                                                        <props.skeleton.CustomGrid container spacing={0} sx={{ /*border: 'thin solid red',*/ padding: '0px' }}>
                                                                                            <props.skeleton.CustomGrid item sink="true"xs={12} sx={{ /*border: 'thin dashed green',*/ paddingTop: '0px', paddingBottom: '0px' }}>
                                                                                                <FormControlLabel align='left' 
                                                                                                    control={
                                                                                                        <Checkbox 
                                                                                                            checked={onGenerate}
                                                                                                            disabled={resources.SliceFormIsDisabled}
                                                                                                            onChange={() => setOnGenerate(!onGenerate)}
                                                                                                        />
                                                                                                    } label='Auto Generate' 
                                                                                                />
                                                                                            </props.skeleton.CustomGrid>
                                                                                        </props.skeleton.CustomGrid>
                                                                                    </props.skeleton.CustomItem>
                                                                                    <props.skeleton.CustomItem>
                                                                                        <props.skeleton.CustomGrid container spacing={0} sx={{ /*border: 'thin solid red',*/ padding: '0px' }}>
                                                                                            <props.skeleton.CustomGrid item sink="true"xs={12} sx={{ /*border: 'thin dashed green',*/ paddingTop: '0px', paddingBottom: '0px' }}>
                                                                                                <props.skeleton.CustomStack direction={'row'} alignItems={'center'}>
                                                                                                    <props.skeleton.CustomItem>
                                                                                                        <Transition 
                                                                                                            timeout={1000} in={onGenerate} mountOnEnter unmountOnExit
                                                                                                            onEntering={(node) => {
                                                                                                                gsap.from(node, {
                                                                                                                    y: -50,
                                                                                                                    autoAlpha: onGenerate ? 0 : 1,
                                                                                                                    duration: 0.5
                                                                                                                })
                                                                                                            }}
                                                                                                            addEndListener={(node, done) => {
                                                                                                                gsap.to(node, {
                                                                                                                    y: onGenerate ? 0 : -50,
                                                                                                                    autoAlpha: onGenerate ? 1 : 0,
                                                                                                                    onComplete: done
                                                                                                                })
                                                                                                            }}
                                                                                                        >   
                                                                                                            <props.skeleton.CustomStack direction="column" justifyContent="flex-start" alignItems="space-evenly">
                                                                                                                <props.skeleton.CustomItem>
                                                                                                                    <props.skeleton.Heading h7>Words per sentence</props.skeleton.Heading>
                                                                                                                </props.skeleton.CustomItem>
                                                                                                                <props.skeleton.CustomItem>
                                                                                                                    <Slider  
                                                                                                                        marks={[
                                                                                                                            {value: loremOptions.minWordPerSentence, label: loremOptions.minWordPerSentence},
                                                                                                                            {value: loremOptions.maxWordPerSentence, label: loremOptions.maxWordPerSentence},
                                                                                                                        ]}
                                                                                                                        min={loremOptions.minWordPerSentence} 
                                                                                                                        max={loremOptions.maxWordPerSentence} 
                                                                                                                        value={loremOptions.wordPerSentence}
                                                                                                                        onChange={(e, value) => setLoremOptions({...loremOptions, wordPerSentence: value})}
                                                                                                                    />
                                                                                                                </props.skeleton.CustomItem>
                                                                                                                <props.skeleton.CustomItem>
                                                                                                                    <props.skeleton.Heading h7>Sentences Per Paragraphs</props.skeleton.Heading>
                                                                                                                </props.skeleton.CustomItem>
                                                                                                                <props.skeleton.CustomItem>
                                                                                                                    <Slider  
                                                                                                                        marks={[
                                                                                                                            {value: loremOptions.minSentencePerParagraph, label: loremOptions.minWordPerSentence},
                                                                                                                            {value: loremOptions.maxSentencePerParagraph, 
                                                                                                                                label: loremOptions.maxSentencePerParagraph
                                                                                                                            },
                                                                                                                        ]}
                                                                                                                        min={loremOptions.minSentencePerParagraph} 
                                                                                                                        max={loremOptions.maxSentencePerParagraph} 
                                                                                                                        value={loremOptions.sentencePerParagraph}
                                                                                                                        onChange={(e, value) => setLoremOptions({...loremOptions, sentencePerParagraph: value})}
                                                                                                                    />
                                                                                                                </props.skeleton.CustomItem>
                                                                                                                <props.skeleton.CustomItem>
                                                                                                                    <props.skeleton.Heading h7>Paragraphs Per Post</props.skeleton.Heading>
                                                                                                                </props.skeleton.CustomItem>
                                                                                                                <props.skeleton.CustomItem>
                                                                                                                    <Slider  
                                                                                                                        marks={[
                                                                                                                            {value: loremOptions.minParagraphPerPost, label: loremOptions.minParagraphPerPost},
                                                                                                                            {value: loremOptions.maxParagraphPerPost, label: loremOptions.maxParagraphPerPost},
                                                                                                                        ]}
                                                                                                                        min={loremOptions.minParagraphPerPost} 
                                                                                                                        max={loremOptions.maxParagraphPerPost} 
                                                                                                                        value={loremOptions.paragraphPerPost}
                                                                                                                        onChange={(e, value) => setLoremOptions({...loremOptions, paragraphPerPost: value})}
                                                                                                                    />
                                                                                                                </props.skeleton.CustomItem>
                                                                                                            </props.skeleton.CustomStack>
                                                                                                        </Transition>                                                                
                                                                                                    </props.skeleton.CustomItem>
                                                                                                    <props.skeleton.CustomItem>
                                                                                                        
                                                                                                    </props.skeleton.CustomItem>
                                                                                                </props.skeleton.CustomStack>
                                                                                            </props.skeleton.CustomGrid>
                                                                                            <props.skeleton.CustomGrid item sink="true"xs={12} sx={{ /*border: 'thin dashed green',*/ paddingTop: '0px', paddingBottom: '0px', justifyContent: 'flex-end' }}>
                                                                                                <Transition 
                                                                                                        timeout={1000} in={onGenerate} mountOnEnter unmountOnExit
                                                                                                        onEntering={(node) => {
                                                                                                            gsap.from(node, {
                                                                                                                y: -50,
                                                                                                                autoAlpha: onGenerate ? 0 : 1,
                                                                                                                duration: 0.5
                                                                                                            })
                                                                                                        }}
                                                                                                        addEndListener={(node, done) => {
                                                                                                            gsap.to(node, {
                                                                                                                y: onGenerate ? 0 : -50,
                                                                                                                autoAlpha: onGenerate ? 1 : 0,
                                                                                                                onComplete: done
                                                                                                            })
                                                                                                        }}
                                                                                                    >
                                                                                                    <props.skeleton.CustomItem>
                                                                                                        <props.skeleton.Bar button="true" center="true" onClick={handleGenerate}>Generate</props.skeleton.Bar>
                                                                                                    </props.skeleton.CustomItem>  
                                                                                                </Transition> 
                                                                                            </props.skeleton.CustomGrid>
                                                                                        </props.skeleton.CustomGrid>
                                                                                    </props.skeleton.CustomItem>
                                                                                    <props.skeleton.CustomItem>
                                                                                        <props.skeleton.CustomGrid container spacing={0} sx={{ /*border: 'thin solid red',*/ padding: '0px' }}>
                                                                                            <props.skeleton.CustomGrid item sink="true"xs={9} sx={{ /*border: 'thin dashed green',*/ paddingTop: '10px', paddingBottom: '10px' }}>
                                                                                                <props.skeleton.CustomStack direction={'row'} alignItems={'center'}>
                                                                                                    <props.skeleton.CustomItem>
                                                                                                                                                                    
                                                                                                    </props.skeleton.CustomItem>
                                                                                                    <props.skeleton.CustomItem>
                                                                                                        
                                                                                                    </props.skeleton.CustomItem>
                                                                                                </props.skeleton.CustomStack>
                                                                                            </props.skeleton.CustomGrid>
                                                                                            <props.skeleton.CustomGrid item sink="true"xs={3} sx={{ /*border: 'thin dashed green',*/ paddingTop: '0px', paddingBottom: '0px', justifyContent: 'center' }}>
                                                                                                
                                                                                            </props.skeleton.CustomGrid>
                                                                                        </props.skeleton.CustomGrid>
                                                                                    </props.skeleton.CustomItem>
                                                                                    <props.skeleton.CustomItem>
                                                                                        <props.skeleton.CustomGrid container spacing={0} sx={{ /*border: 'thin solid red',*/ padding: '0px' }}>
                                                                                            <props.skeleton.CustomGrid item sink="true"xs={9} sx={{ /*border: 'thin dashed green',*/ paddingTop: '0px', paddingBottom: '0px' }}>
                                                                                                
                                                                                            </props.skeleton.CustomGrid>
                                                                                            <props.skeleton.CustomGrid item sink="true"xs={3} sx={{ border: '', paddingTop: '0px', paddingBottom: '0px', justifyContent: 'center' }}>
                                                                                                
                                                                                            </props.skeleton.CustomGrid>
                                                                                        </props.skeleton.CustomGrid>
                                                                                    </props.skeleton.CustomItem>
                                                                                    <props.skeleton.CustomItem>
                                                                                        <props.skeleton.CustomGrid container spacing={0} sx={{ /*border: 'thin solid red',*/ padding: '0px' }}>
                                                                                            <props.skeleton.CustomGrid item sink="true"xs={9} sx={{ /*border: 'thin dashed green',*/ paddingTop: '0px', paddingBottom: '0px' }}>
                                                                                                
                                                                                            </props.skeleton.CustomGrid>
                                                                                            <props.skeleton.CustomGrid item sink="true"xs={3} sx={{ border: '', paddingTop: '0px', paddingBottom: '0px', justifyContent: 'center' }}>
                                                                                                
                                                                                            </props.skeleton.CustomGrid>
                                                                                        </props.skeleton.CustomGrid>
                                                                                    </props.skeleton.CustomItem>
                                                                                    
                                                                                </props.skeleton.CustomStack>
                                                                            </props.skeleton.CustomGrid>
                                                                            </>
                                                                        )
                                                                        : null

                                                                        // #endregion ----------------------------------------------------------------------------------------------------
                                                                    }
                                                                    </>
                                                                }

                                                            </props.skeleton.CustomGrid>
                                                        </props.skeleton.CustomGrid>
                                                    </props.skeleton.CustomGrid>
                                                    {
                                                        /*
                                                        ===============================================================================================
                                                        #endregion - Long Post (ie. Blog post for global or personal page)
                                                        ===============================================================================================
                                                        */
                                                    }
                                                </TabPanel>
                                                <TabPanel value="2" sx={{ width: '100%'}}>
                                                {
                                                        /*
                                                        ===============================================================================================
                                                        #region - Short Post (ie. "Tweet" for personal page)
                                                        ===============================================================================================
                                                        */
                                                    }
                                                    <props.skeleton.CustomGrid container spacing={2}>
                                                        <props.skeleton.CustomGrid item sink="true"xs={12}>
                                                            <props.skeleton.CustomStack direction="column" spacing={2}>
                                                                <props.skeleton.CustomItem>
                                                                    <props.skeleton.CustomTextField
                                                                        multiline   
                                                                        rows={3}  
                                                                        name='message'
                                                                        label="What's on your mind?"
                                                                        sx={{
                                                                            height: "100%"
                                                                        }}
                                                                        disabled={isDisabledSPost}
                                                                        value={sPost?.message || ''}
                                                                        onChange={(e) => setSPost({...sPost, message: e.target.value})}
                                                                    />
                                                                </props.skeleton.CustomItem>
                                                                <props.skeleton.CustomItem>
                                                                    <props.skeleton.CustomGrid container spacing={0}>
                                                                        <props.skeleton.CustomGrid item sink="true"xs={4}>
                                                                            <props.skeleton.CustomStack direction="row" alignItems="flex-start" spacing={1}>
                                                                                <props.skeleton.CustomItem>
                                                                                    <props.skeleton.Bar button="true" padding10lronly="true" center="true" onClick={(e) => {}}>Image</props.skeleton.Bar>
                                                                                </props.skeleton.CustomItem>

                                                                                <props.skeleton.CustomItem>
                                                                                    <props.skeleton.Bar button="true" padding10lronly="true" center="true" onClick={(e) => {}}>GIF</props.skeleton.Bar>
                                                                                </props.skeleton.CustomItem>

                                                                                <props.skeleton.CustomItem>
                                                                                    <props.skeleton.Bar button="true" padding10lronly="true" center="true" onClick={(e) => {}}>Poll</props.skeleton.Bar>
                                                                                </props.skeleton.CustomItem>
                                                                                
                                                                                <props.skeleton.CustomItem>
                                                                                    <props.skeleton.Bar button="true" padding10lronly="true" center="true" onClick={(e) => {}}>Smiley</props.skeleton.Bar>
                                                                                </props.skeleton.CustomItem>
                                                                                
                                                                                <props.skeleton.CustomItem>
                                                                                    <props.skeleton.Bar button="true" padding10lronly="true" center="true" onClick={(e) => {}}>Schedule</props.skeleton.Bar>
                                                                                </props.skeleton.CustomItem>
                                                                            </props.skeleton.CustomStack>
                                                                        </props.skeleton.CustomGrid>
                                                                        <props.skeleton.CustomGrid item sink="true"xs={7.4} sx={{ paddingRight: '8px' }}>
                                                                            <props.skeleton.CustomStack direction="column" alignItems="flex-end" justifyContent="center">
                                                                                <props.skeleton.CustomItem>
                                                                                    <props.skeleton.Heading nobg>Character count: {sPost?.message.length} | Word count: {sPost?.message.split(' ').length}</props.skeleton.Heading>                                    
                                                                                </props.skeleton.CustomItem>
                                                                            </props.skeleton.CustomStack>
                                                                        </props.skeleton.CustomGrid>
                                                                        <props.skeleton.CustomGrid item sink="true"xs={0.6}>
                                                                            <props.skeleton.CustomStack direction="column" alignItems="flex-end">
                                                                                <props.skeleton.CustomItem>
                                                                                    <props.skeleton.Bar sx={{ fontSize: '18px' }} button="true" padding10lronly="true" center="true" onClick={(e) => handleSendShortSlice('published')}>Send</props.skeleton.Bar>
                                                                                </props.skeleton.CustomItem>
                                                                            </props.skeleton.CustomStack>
                                                                        </props.skeleton.CustomGrid>
                                                                    </props.skeleton.CustomGrid>
                                                                </props.skeleton.CustomItem>
                                                            </props.skeleton.CustomStack>
                                                        </props.skeleton.CustomGrid>                                                
                                                    </props.skeleton.CustomGrid>

                                                    {
                                                        /*
                                                        ===============================================================================================
                                                        #endregion - Short Post (ie. "Tweet" for personal page)
                                                        ===============================================================================================
                                                        */
                                                    }
                                                </TabPanel>
                                                <TabPanel value="3" sx={{ width: '100%'}}>
                                                {
                                                        /*
                                                        ===============================================================================================
                                                        #region - Still Post (ie. photo upload like instagram for personal page)
                                                        ===============================================================================================
                                                        */
                                                    }
                                                    
                                                    {
                                                        /*
                                                        ===============================================================================================
                                                        #endregion - Still Post (ie. photo upload like instagram for personal page)
                                                        ===============================================================================================
                                                        */                                                
                                                    }
                                                </TabPanel>
                                                <TabPanel value="4" sx={{ width: '100%'}}>
                                                {
                                                    /*
                                                    ===============================================================================================
                                                    #region - Video Post (ie. like Tiktok or Youtube for personal page)
                                                    ===============================================================================================
                                                    */                                               
                                                    }
                                                    
                                                    {
                                                        /*
                                                        ===============================================================================================
                                                        #endregion - Video Post (ie. like Tiktok or Youtube for personal page)
                                                        ===============================================================================================
                                                        */
                                                    }
                                                </TabPanel>                                        
                                            </TabContext>                               
                                        </props.skeleton.CustomStack>
                                    </props.skeleton.CustomGrid>                            
                                    /*
                                    -- #endregion -----------------------------------------------------------------------------------------------------                    
                                    */
                                }                      
                                {/* #endregion -----[ NOUGAT ]---------------------------------------------------------------------*/}
                            </props.skeleton.CustomGrid>                            
                        </props.skeleton.CustomGrid>
                    </props.skeleton.CustomGrid>                              
                </props.skeleton.CustomGrid>
            </props.skeleton.CustomStack>
        </props.skeleton.InnerContent>
        </>
    )
}
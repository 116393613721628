export const menu = {
    base: {
        background: "pink",
        color: "black"
    }, 
    arrow: {
        base: {
            background: "pink",        
        },
        params: {
            height: "10px",
            right: "14px",
            top: "0px",     
            transform: {
                rotate: "45deg",
                translatey: "-50%"
            }         
        }
    },
    item: {
        base: {
            background: "cyan",
            backgroundHover: "cyan",
            color: "black",
            colorHover: "black"
        },
        params: {
            padding: "10px"
        }
    },
    params: {
        minwidth: "150px",
        padding: "0px 10px 5px 10px" // top right bottom left
    }
}
export const typography = {
    base: {
        background: "pink",
        color: "black",
        fontfamily: "Rubik"
    },
    badge: {
        base: {
            background: "lime",
            color: "black",
        },
        permissiontier0: {
            background: "#c0c0c0",
            color: "black",
        },
        permissiontier1: {
            background: "#808080",
            color: "white",
        },
        permissiontier2: {
            background: "#00cccc",
            color: "black",
        },
        permissiontier3: {
            background: "#e6c200",
            color: "black",
        }
    },
    divider1: {
        background: "#ffd9e0",
        color: "white",
    }, 
    divider2: {
        background: "#ffd3db",
        color: "white",
    }, 
    divider3: {
        background: "#ffcdd5",
        color: "white",
    },
    drawer: {
        base: {
            background: "#191919",
            color: "cyan"
        },
        params: {
            fontfamily: "Rubik",
            fontsize: "20px",
            paddingbottom: "15px",
            paddingleft: "5px",
            paddingright: "5px",
            paddingtop: "15px"
        }
    },
    footer: {
        background: "red",
        color: "white",
        fontfamily: "Borel"
    }, 
    gem: {
        h1: {
            fontfamily: "Rubik"
        },
        h6: {
            fontfamily: "Borel"
        }
    },
    grayed: {
        base: {
            background: "transparent",
            color: "#737373",
        }
    },
    navbar: {
        background: "red",
        color: "white",
        fontfamily: "Rubik"
    },
    userinfotextarea: {
        base: {
            background: "transparent",
            color: "#999999",
        }
    },
}
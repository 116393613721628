export const header = {
    base: {
        background: "red",
        color: "white",
        fontsize: "25px"
    },
    button: {
        background: 'red',
        backgroundHover: 'white',
        backgroundActive: 'white',
        color: 'black',
        colorHover: 'black',
        colorActive: 'black'
    },
    item: {
        base: {
            background: 'red',
            backgroundHover: 'black',
            backgroundActive: 'black',
            color: 'black',
            colorHover: 'black',
            colorActive: 'black'
        },
        full: {
            background: 'darkred',
            backgroundHover: 'black',
            backgroundActive: 'black',
            color: 'white',
            colorHover: 'black',
            colorActive: 'black'
        }
    }
}
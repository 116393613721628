// [][][][][][][][][][][][][][][][][][][][][ START IMPORTS ][][][][][][][][][][][][][][][][][][][][][][][][][][][]
// ####################### GLOBAL ############################################################################
// Import from React
// eslint-disable-next-line
import { useState, useEffect } from 'react'

// Import axios
//import axios from 'axios'

// Import Toast
// eslint-disable-next-line
import { toast } from 'react-toastify'

// Import Auth
import { useAuth } from '../../middleware/contextHooks'

// Import reactDOM
import { useNavigate } from 'react-router-dom'
// ####################### END OF GLOBAL #####################################################################

// ####################### SPECIFIC ##########################################################################
// Import globalStyles elements
import { /*CustomContainer as Container*/ } from '../../themes/Darwinian2023/skeleton/global'

// Import from MUI
import {
    //Paper,
    //Button,
} from '@mui/material'

// eslint-disable-next-line
import Masonry from '@mui/lab/Masonry'

/*
// Import mainStyles
import { 
    //Bar,
     // eslint-disable-next-line
    Card,
    // eslint-disable-next-line
    CardActions,
    // eslint-disable-next-line
    CardContent,
    // eslint-disable-next-line
    CardHeader,
     // eslint-disable-next-line
    CustomCardMedia as CardMedia,
    // eslint-disable-next-line
    CustomGrid as Grid,
    //CustomBox,
    CustomLink,
    //CustomButton as Button,
    //CustomGrid as Grid,
    CustomItem as Item,
    //CustomLink,
    //CustomStack as Stack,
    Heading,
    //Para,    
    //CustomTextField as TextField
} from '../../themes/Darwinian2023/skeleton/main'
*/

//import ReactHtmlParser from 'react-html-parser'
// ####################### END OF SPECIFIC ##################################################################
// [][][][][][][][][][][][][][][][][][][][][ END IMPORTS ][][][][][][][][][][][][][][][][][][][][][][][][][][][][]

export function Spacer (props) { 
    // [][][][][][][][][][][][][][][][][][][][][ START SETUP ][][][][][][][][][][][][][][][][][][][][][][][][][][]
    // ####################### GLOBAL ############################################################################
    // #region -------------[ Variables ]-------------------------------------------------------------------------
    // Get user logged
    // eslint-disable-next-line
    const { 
        
    } = useAuth()   

    // Set navigate
    // eslint-disable-next-line
    const navigate = useNavigate()

    // #region --------------( FUNCTIONS )------------------------------------------------------------------------
    
    // #endregion ------------------------------------------------------------------------------------------------
    // ####################### END OF GLOBAL #####################################################################

    // ####################### SPECIFIC ##########################################################################
    // #region -------------[ Variables ]-------------------------------------------------------------------------
    
    // #endregion -----------------------------------------------------------------------------------------------
    // #region -------------[ Functions ]------------------------------------------------------------------------   
    // State Rendering
    useEffect(() => {
        
    }, [ ])
    // #endregion -----------------------------------------------------------------------------------------------
    // ####################### END OF SPECIFIC ##################################################################
    // [][][][][][][][][][][][][][][][][][][][][ ENDSETUP ][][][][][][][][][][][][][][][][][][][][][][][][][][][]

    // Render
    return (
        <>
        {
            (props.length) 
            ? (
                // Shoutout to: https://www.reddit.com/r/reactjs/comments/ow0292/comment/h7dwgqh/?utm_source=share&utm_medium=web2x&context=3
                // for this answer
                Array.from({length: props.length}, (_, index) => <br key={index} />)
            )
            : "<br /><br /><br />"
        }
        {
            (props.useHR)
            ? <hr />
            : null
        }
        </>
    )
}
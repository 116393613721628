// [][][][][][][][][][][][][][][][][][][][][ START IMPORTS ][][][][][][][][][][][][][][][][][][][][][][][][][][][]
// ####################### GLOBAL ############################################################################
// Import from React
// eslint-disable-next-line
import { useState, useEffect } from 'react'

// Import Toast
// eslint-disable-next-line
import { toast } from 'react-toastify'

// Import Auth
import { useAuth/*, useSetting*/ } from '../../middleware/contextHooks'

// Import reactDOM
import { useNavigate } from 'react-router-dom'

// Import utils
//import { AuthCheck } from '../../middleware/utils'
// ####################### END OF GLOBAL #####################################################################

// ####################### SPECIFIC ##########################################################################
// Import globalStyles elements
//import { /*CustomContainer as Container*/ } from '../../themes/Darwinian2023/skeleton/global'

// Import from MUI
import {
    //Container,
} from '@mui/material'

// Import components
//import { CallToAction } from '../../components/Addon/CallToAction'
import { Singlet } from '../../components/Addon/Singlet'

/*
// Import mainStyles
import { 
    //Bar,
    //CustomBox,
    //CustomButton as Button,
    //CustomGrid as Grid,
    //CustomLink,
    CustomStack as Stack,
    Heading,
    Para,    
} from '../../themes/Darwinian2023/skeleton/main'
*/

// Import components
import { BreadCrumbs } from '../../components/BreadCrumbs'
import { Spacer } from '../../components/Addon/Spacer'
//import { QueryList } from '../../components/Addon/QueryList'

// Import Post
import { usePost } from '../../middleware/contextHooks'

// ####################### END OF SPECIFIC ##################################################################
// [][][][][][][][][][][][][][][][][][][][][ END IMPORTS ][][][][][][][][][][][][][][][][][][][][][][][][][][][][]

export default function About (props) { 
    // [][][][][][][][][][][][][][][][][][][][][ START SETUP ][][][][][][][][][][][][][][][][][][][][][][][][][][]
    // ####################### GLOBAL ############################################################################
    // #region -------------[ Variables ]-------------------------------------------------------------------------
    // Get user logged
    // eslint-disable-next-line
    const {clearErrors, currentUser, getProfile, getGuestProfile, getPublicProfile, isAuthenticated, toasts} = useAuth()   

    // Set navigate
    // eslint-disable-next-line
    const navigate = useNavigate()

    // #region --------------( FUNCTIONS )------------------------------------------------------------------------
    
    // #endregion ------------------------------------------------------------------------------------------------
    // ####################### END OF GLOBAL #####################################################################

    // ####################### SPECIFIC ##########################################################################
    // #region -------------[ Variables ]-------------------------------------------------------------------------
    // Set up breadcrumbs here
    const BCItems = [
        {
            'name': 'Home',
            'type': 'link',
            'grayed': true,
            "url": '/',
        }, 
        {
            'name': 'Tags',
            'type': 'text',
            'grayed': true,
            'url': ''
        },
    ]

    // Use Post
    const {       
        // Variables
        currentSlice,
        featuredslices,   
        
        // Functions
        getAllFeaturedSlices,
        setCurrentSlice
    } = usePost()
    // #endregion -----------------------------------------------------------------------------------------------
    // #region -------------[ Functions ]------------------------------------------------------------------------
    //const {
        // Functions        
    //} = useSetting()

    // State management for getting the posts
    useEffect(() => {
        // DEPRECATED < 2.6.8 : Honestly, featuredslices being there will use what was previously retrieved; we only want the limited amount here and not all posts
        //if (!featuredslices) {
            // Get all posts
            getAllFeaturedSlices('anyposts', 6, 'global')
        //}
        
        // Update 2.6.4 : Fix for currentSlice not updating til the next render
        if (currentSlice) {
            setCurrentSlice(null)
        }
    }, [ currentSlice, getAllFeaturedSlices, featuredslices, setCurrentSlice ])
    // #endregion -----------------------------------------------------------------------------------------------
    // ####################### END OF SPECIFIC ##################################################################
    // [][][][][][][][][][][][][][][][][][][][][ ENDSETUP ][][][][][][][][][][][][][][][][][][][][][][][][][][][]

    // Render
    return (
        <>
        <Spacer length={5} />

        <BreadCrumbs list={BCItems} skeleton={props.skeleton} />

        <Singlet skeleton={props.skeleton} 
            color={'secondary'} // options: primary, secondary, tertiary, quarternary
            content={`Tags are a part of this system and this page is a WIP.`}
            padding={'50px 0px 50px 0px'} // Top Right Bottom Left
            textalign={'center'}
            title={`Tags`}
        />

        
        </>
    )
}
// [][][][][][][][][][][][][][][][][][][][][ START IMPORTS ][][][][][][][][][][][][][][][][][][][][][][][][][][][]
// ####################### GLOBAL ############################################################################
// Import from React
// eslint-disable-next-line
import { useState, useEffect } from 'react'

// Import axios
//import axios from 'axios'

// Import Toast
// eslint-disable-next-line
import { toast } from 'react-toastify'

// Import Auth
import { useAuth } from '../../middleware/contextHooks'

// Import reactDOM
import { useNavigate } from 'react-router-dom'

// Import utils
// eslint-disable-next-line
import { DeTemps } from '../../middleware/utils'
// ####################### END OF GLOBAL #####################################################################

// ####################### SPECIFIC ##########################################################################
// Import globalStyles elements
//import { /*CustomContainer as Container*/ } from '../themes/Darwinian2023/skeleton/global'

// Import from MUI
import {
    Button,
    Paper
} from '@mui/material'

//import Carousel from 'react-material-ui-carousel'

/*
// Import mainStyles
import { 
    //Bar,
    Card,
    CardActions,
    CardContent,
    CardHeader,
    //CustomBox,
    //CustomButton as Button,
    //CustomGrid as Grid,
    CustomLink,
    //CustomStack as Stack,
    Heading,
    //Para,    
    //CustomTextField as TextField
} from '../themes/Darwinian2023/skeleton/main'
*/

// Import icons
import FavoriteIcon from '@mui/icons-material/Favorite'
import ShareIcon from '@mui/icons-material/Share'
//import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import TelegramIcon from '@mui/icons-material/Telegram'

// ####################### END OF SPECIFIC ##################################################################
// [][][][][][][][][][][][][][][][][][][][][ END IMPORTS ][][][][][][][][][][][][][][][][][][][][][][][][][][][][]

function loadSliceImg (userName, query, image, settingServerAPIBaseUrl) {
    if (userName !== undefined) {
        return settingServerAPIBaseUrl+query+"/file/byuser/"+userName+"/"+image
    }
}

// eslint-disable-next-line
function item (props) {
    return (
        <Paper>
            <h2>{props.item.name}</h2>
            <p>{props.item.description}</p>

            <Button className="CheckButton">
                Check it out!
            </Button>
        </Paper>
    )
}

// eslint-disable-next-line
function Item (props) {
    return (
        <>
        <h2>{props.item.name}</h2>
        <p>{props.item.description}</p>

        <Button className="CheckButton">
            Check it out!
        </Button>
        </>
    )
}

export function PostCard (props) { 
    // [][][][][][][][][][][][][][][][][][][][][ START SETUP ][][][][][][][][][][][][][][][][][][][][][][][][][][]
    // ####################### GLOBAL ############################################################################
    // #region -------------[ Variables ]-------------------------------------------------------------------------
    // Get user logged
    // eslint-disable-next-line
    const {
        //clearErrors, currentUser, getProfile, getGuestProfile, getPublicProfile, isAuthenticated, toasts
    } = useAuth()   

    // Set navigate
    // eslint-disable-next-line
    const navigate = useNavigate()

    // For carousel
    // eslint-disable-next-line
    var itemstest = [
        {
            name: "Random Name #1",
            description: "Probably the most random thing you have ever seen!"
        },
        {
            name: "Random Name #2",
            description: "Hello World!"
        },
        {
            name: "Random Name #3",
            description: "Never gunna give you up..."
        },
        {
            name: "Random Name #4",
            description: "You lost the game"
        },
        {
            name: "Random Name #5",
            description: "Remember, licking doorknobs on other planets is illegal."
        }
    ]

    // #region -----------------[ State Variables ]---------------------------------------------------------------
    // Set Authed
    //const [ Authed, setAuthed ] = useState(false)

    // Set LoggingIn
    // eslint-disable-next-line
    //const [ LoggingIn, setLoggingIn ] = useState(false)

    // Set profile
    // eslint-disable-next-line
    //const [profile, setProfile] = useState({})
    const [ gemLetter, setGemLetter ] = useState(`...`)

    // Set Updating
    // eslint-disable-next-line
    //const [ Updating, setUpdating ] = useState(false)
    // #end region -----------------[ State Variables ]-----------------------------------------------------------

    // #endregion ------------------------------------------------------------------------------------------------
    // #region --------------( FUNCTIONS )------------------------------------------------------------------------
    const handleChangeText = (text) => {
        setGemLetter(text)
    }
    // #endregion ------------------------------------------------------------------------------------------------
    // ####################### END OF GLOBAL #####################################################################

    // ####################### SPECIFIC ##########################################################################
    // #region -------------[ Variables ]-------------------------------------------------------------------------
    
    // #endregion -----------------------------------------------------------------------------------------------
    // #region -------------[ Functions ]------------------------------------------------------------------------

    // State Rendering 
    useEffect(() => {
        setGemLetter(props.data?.authorDetails[0]?.userName?.substr(0,1).toUpperCase())
    }, [ ])
    // #endregion -----------------------------------------------------------------------------------------------
    // ####################### END OF SPECIFIC ##################################################################
    // [][][][][][][][][][][][][][][][][][][][][ ENDSETUP ][][][][][][][][][][][][][][][][][][][][][][][][][][][]

    // Render
    return (
        <>
        <props.skeleton.CustomCard 
            metaui={'<dark|light>.main.modules.gem.card'}
            sx={{ 
                //minWidth: '100%', // 345            
                //border: 'thin solid black' 
        }}>
            <props.skeleton.CustomCardHeader
                avatar={
                    <props.skeleton.CustomAvatarLetter onMouseOver={() => handleChangeText(<TelegramIcon sx={{ fontWeight: '900', fontSize: '24px', alignContent: 'center', display: 'flex' }} />)} onMouseOut={() => handleChangeText(props.data?.authorDetails[0]?.userName?.substr(0,1).toUpperCase())} onClick={() => navigate('/@/'+props.data?.authorDetails[0]?.userName)} aria-label="author profile">
                        <props.skeleton.CustomTooltip title={`Visit ${props.data.authorDetails?.[0].userName}'s profile`}>
                            {gemLetter}
                        </props.skeleton.CustomTooltip>
                    </props.skeleton.CustomAvatarLetter>
                }
                action={
                    <props.skeleton.CustomIconButton onClick={() => toast.info('WIP: Open side menu', {theme: 'dark'}) }>
                        <MoreVertIcon />
                    </props.skeleton.CustomIconButton>
                }
                title={(props.data.title.length > 22) ? props.data.title.substr(0,15)+"..." : props.data.title}
                subheader={DeTemps("timeFrom", props.data.createdAt)}
            />

            <props.skeleton.CustomCardMedia onClick={() => navigate('/'+props.uriMap+'/'+props.data.slug)}
                component="img"
                sx={{ 
                    minHeight: { xs: 400, sm: 300 },
                    maxHeight: 200                    
                }}
                image={(props.data?.imageUrl !== "no-featuredimage.png" && props.data?.imageUrl !== "") 
                ? (
                    loadSliceImg(props.data?.authorDetails?.[0].userName, props.data?.type, props.data?.imageUrl, props.settingServerAPIBaseUrl) 
                )
                : loadSliceImg(props.data?.authorDetails?.[0].userName, props.data?.type, "no-featuredimage.png", props.settingServerAPIBaseUrl)  
                }
                alt="Post image"
            />

            <props.skeleton.CustomCardContent sx={{ userSelect: 'none' }}>
                <props.skeleton.Heading h4 nobg gemnougat metaui={'<dark|light>.main.modules.typography.gem.color'}>
                    {(props.data.snippet.length > 35) ? props.data.snippet.substr(0,50)+"..." : props.data.snippet}
                </props.skeleton.Heading>

                <br />

                <props.skeleton.Bar gem="true" sx={{ fontSize: '20px', paddingTop: '20px' }}>
                    { 
                        " "+(props.data.comments?.length || 0) + ((props.data.comments?.length === 1) ? " comment" : " comments")+" "
                    } 
                    <br />                    
                    { 
                        " "+(props.data.likes?.length || 0) + ((props.data.likes?.length === 1) ? " like" : " likes")+" "
                    } 
                    {
                        " "+(props.data.unlikes?.length || 0) + ((props.data.unlikes?.length === 1) ? " unlike" : " unlikes")+" "
                    }
                    <br />                     
                    { 
                        " "+(props.data.shares?.length || 0) + ((props.data.shares?.length === 1) ? " share" : " shares")+" "
                    } 
                    <br />                 
                    { 
                        " "+((props.data.views?.length || 0) + (props.data.viewsVisitors?.length || 0))?.toLocaleString() + (((props.data.views?.length) === 1 ? " view" : " views" )) 
                    } 
                </props.skeleton.Bar>
            </props.skeleton.CustomCardContent>

            <props.skeleton.CustomCardActions disableSpacing>
                <props.skeleton.CustomIconButton
                    color="secondary"
                    aria-label="add to favorites" 
                    onClick={() => toast.info('WIP: Like post or add to favorites', {theme: 'dark'}) }
                >
                    <FavoriteIcon />
                </props.skeleton.CustomIconButton>
                <props.skeleton.CustomIconButton 
                    color="secondary"
                    aria-label="share" 
                    onClick={() => toast.info('WIP: Sharing???', {theme: 'dark'}) }
                >
                    <ShareIcon />
                </props.skeleton.CustomIconButton>                            
            </props.skeleton.CustomCardActions>
        </props.skeleton.CustomCard>
        </>
    )
}
export const bar = {
    background: "#FFF",
    button: {
        base: {
            background: "#009999",
            backgroundHover: "#00b3b3",  
            backgroundActive: "#00b3b3",
            color: "black",
            colorHover: "white",
            colorActive: "white"
        },
        nobg: {
            background: "black",
            backgroundHover: "black",  
            backgroundActive: "black",
            color: "white",
            colorHover: "white",
            colorActive: "white"
        },
        error: {
            background: "red",
            backgroundHover: "cyan",  
            backgroundActive: "cyan",
            color: "white",
            colorHover: "black",
            colorActive: "black"
        }
    }
}
import {Navigate, Outlet} from 'react-router-dom'

// eslint-disable-next-line
import {  useMemo } from 'react'

//import { Buffer } from 'buffer'

// Import Auth
import { useAuth } from '../middleware/contextHooks'

export default function PrivateRoute() {
    const {
        currentUser,
        getProfile
    } = useAuth()

    const token = localStorage.getItem('token')
    var isAuthenticated = false

    // Version 2.3.51 Update - Authentication Check and Log out
    // #region ------------[ Check for expiration -------------------------------------------------------------------
    // Legacy - atob is deprecated but will still work
    if (token) {
        const decode = JSON?.parse(atob(token?.split('.')[1]))
        // Does work...yet
        //const decode = Buffer.from(token, 'utf8').toString('base64')

        // Debug: Display decode contents
        //console.log(decode)

        // Check for actual expiration
        if (decode) {
            if (decode.exp * 1000 < new Date().getTime())
            {
                isAuthenticated = false

                // Remove token
                localStorage.removeItem('token')
            } else {
                // Set authed
                isAuthenticated = true

                // 
                if (!currentUser) {
                    getProfile()
                }

            }
        }
    }

    // #endregion ---------------------------------------------------------------------------------------------------

    return !isAuthenticated
    ? <Navigate to="/login" />
    : <Outlet />  
}

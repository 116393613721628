export const customlink = {
    base: {
        background: "violet",
        backgroundHover: "pink",
        backgroundActive: "pink",
        color: "black",
        colorGray: 'gray',
        colorHover: "black",
        colorActive: "black",
        params: {
            padding: '10px'
        }
    },
    copyrightlink: {
        base: {
            background: "violet",
            backgroundHover: "pink",
            backgroundActive: "pink",
            color: "black",
            colorGray: 'gray',
            colorHover: "black",
            colorActive: "black",
            params: {
                padding: '0px 0px 0px 0px' // Top Right Bottom Left
            }
        }
    },
    richtable: {
        base: {
            background: "#00e6e6",
            backgroundHover: "#00a1a1",
            backgroundActive: "#00a1a1",
            borderradius: "10px",
            color: "black",
            colorGray: 'gray',
            colorHover: "white",
            colorActive: "white"
        },
        params: {
            padding: "2px 10px 2px 10px" // top right bottom left
        }
    }
}
import * as dark from './config/dark/index.ts'
import * as light from './config/light/index.ts'

export const darkTheme = Object.assign({}, dark)
export const lightTheme = Object.assign({}, light)

/*
export const darkTheme = {
    background: "#0A0A0A",                  // Used in: Background of site
    button: "#0A0A0A",                      // Used in: Button Background
    buttonColor: "#48CAE4",                 // Used in: Link Button Text Color
    buttonColorActive: "#fff",              // Used in: Link Button Active Text Color
    buttonHover: "#6564DB",                 // Used in: Button Hover Background
    buttonHoverColor: "#FFF",               // Used in: Button Hover Text Color
    buttonSecondary: "#FFFFFF",             // Used in: Button Background under secondary background
    buttonSecondaryColor: "#0A0A0A",        // Used in: Link Button Text Color under secondary background
    buttonSecondaryColorActive: "#fff",     // Used in: Link Button Active Text Color under secondary background
    buttonSecondaryHover: "#6564DB",        // Used in: Button Hover Background under secondary background
    buttonErrorColor: "#000",               // Used in: Link Button Error Text Color
    buttonErrorColorActive: "red",          // Used in: Button Error color
    buttonError: "red",                     // Used in: Delete Account button on dashboard/account
    buttonErrorHover: "#A95858",            // Used in: Button error (on hover) Background 
    buttonGreyedOut: "#2F3239",             // Used in: Register Form button when disabled
    buttonSwitch: "#48CAE4",                // Used in: Gizmo Switch Background
    checkbox: "#0A0A0A",                    // Used in: Tables where a checkbox is
    checkboxChecked: "#B6B6B6",             // Used in: Tables where a checkbos is selected
    copyrightlinkBackground: "#000000",              // Used in: Copyright Link Background
    copyrightlinkColor: "#48CAE4",                   // Used in: Copyright Link Text Color        
    copyrightlinkBackgroundHover: "#000000",         // Used in: Copyright Link Button Hover Background
    copyrightlinkHoverColor: "#48CAE4",                 // Used in: Copyright Link Button Hover Text Color
    copyrightlinkBackgroundActive: "#FFF",           // Used in: Copyright Link Active Text Color
    copyrightlinkBackgroundActiveColor: "#EF476F",   // Used in: Copyright Link  Active Text Color
    drawerbackground: "#222222",            // Used in: Background color of the drawer (really not used?)
    drawergizmo: "#FFFFFF",                 // Used in: Drawer gizmo background
    drawergizmocolor: "#0A0A0A",            // Used in: Drawer gizmo color
    drawergizmoinputcolor: "#0A0A0A",       // Used in: Drawer gizmo status input color
    drawerNavButton: "#B8B8B8",             // Used in: Drawer Nav Button on the drawer
    drawerNavButtonColor: "#0A0A0A",        // Used in: Drawer Nav Button Text Color
    drawerNavButtonHover: "#48CAE4",        // Used in: Drawer Navbar Button Hover Background
    drawerNavButtonHoverColor: "#0A0A0A",   // Used in: Drawer Navbar Button Hover Text Color
    drawerNavButtonActiveColor: "#EF476F",  // Used in: Drawer Navbar Button Active Text Color
    drawerNavHeading: "#CCCCCC",            // Used in: Drawer Nav Heading Background
    drawerNavHeadingColor: "#0A0A0A",       // Used in: Drawer Nav Heading Color
    drawerNestedBorder: "#48CAE4",          // Used in: Drawer Multi-item Border color
    drawerNestBorWidth: "0em 0em 0em .15em",// Used in: Drawer Nest item border width
    iconButton: "#0A0A0A",                  // Used in: Icon button "color"         
    iconButtonDarkened: "#FFFFFF",          // Used in: Icon button where "darkened" is added
    innerdrawerbackground: "#CCCCCC",       // Used in: Inner Drawer background color
    inputBackground: "#48CAE4",             // Used in: Background of inputs
    inputColor: "#48CAE4",                  // Used in: Color text of inputs
    linkBackground: "#0A0A0A",              // Used in: Link Background
    linkColor: "#48CAE4",                   // Used in: Link Text Color        
    linkBackgroundHover: "#48CAE4",            // Used in: Link Button Hover Background
    linkHoverColor: "#0A0A0A",              // Used in: Link Button Hover Text Color
    linkBackgroundActive: "#FFF",           // Used in: Link  Active Text Color
    linkBackgroundActiveColor: "#EF476F",   // Used in: Link  Active Text Color
    nestedBackground: "#FFFFFF",            // Used in: Drawer Multi-item Background
    nestedBorder: "#FFFFFF",                // Used in: Drawer Multi-item Border color
    navButton: "#0A0A0A",                   // Used in: Nav Button BG
    navButtonColor: "#48CAE4",              // Used in: Nav Button Text Color
    navButtonHover: "#FFF",                 // Used in: Navbar Button Hover Background
    navButtonHoverColor: "#0A0A0A",         // Used in: Navbar Button Hover Text Color
    navButtonActiveColor: "#EF476F",        // Used in: Navbar Button Active Text Color
    navBarHeight: "16px",                   // Used in: Header, sets the height of the navBar (a calculation of the "height")
    quartinaryBackground: "#48CAE4",        // Used in: Quartinary backgrounds
                                            //          Input border
    secondaryBackground: "#0A0A0A",         // Used in: Gizmo Switch Button Background
                                            //          Header Navbar BG
                                            //          Info box component
    tertiaryBackground: "#141414",          // Used in: Navbar header item BG
    text: "#48CAE4",                           // Used in: Body text color
    textGrayed: "#6b7280",                  // Used in: Grayed out text
    textNested: "#0A0A0A",                  // Used in: Drawer Multi-item text color
    textError: "#F00",                      // Used in: ???
    textSecondary: "#FFFFFF",               // Used in: Input Label Color
                                            //          Text on secondaryBackground
    textTerinary: "#0A0A0A",                // Used in: NavBar Heading Text color
    textQuartinary: "#0A0A0A"               // Used in: ???
}
*/

//export const lightTheme = Object.assign({}, light)

/* DEPRECATED < 2.7.0
export const lightTheme = {
    //background: "#FFF",                   // Used in: Background of site
    button: "#48CAE4",                      // Used in: Button Background
    buttonColor: "#000",                    // Used in: Button Text Color
    buttonColorActive: "#fff",              // Used in: Button Active Text Color
    buttonHover: "#6564DB",                 // Used in: Button Hover Background
    buttonHoverColor: "#FFF",               // Used in: Button Hover Text Color
    buttonSecondary: "#000000",             // Used in: Button Background under secondary background
    buttonSecondaryColor: "#FFFFFF",        // Used in: Link Button Text Color under secondary background
    buttonSecondaryColorActive: "#fff",     // Used in: Link Button Active Text Color under secondary background
    buttonSecondaryHover: "#6564DB",        // Used in: Button Hover Background under secondary background
    buttonErrorColor: "#000",               // Used in: Button Error Text Color
    buttonErrorColorActive: "red",          // Used in: Button Error color
    buttonError: "red",                     // Used in: Delete Account button on dashboard/account
    buttonErrorHover: "#A95858",            // Used in: Button error (on hover) Background 
    buttonGreyedOut: "#2F3239",             // Used in: Register Form button when disabled
    buttonSwitch: "#000",                   // Used in: Gizmo Switch Background
    checkbox: "#000",                       // Used in: Tables where a checkbox is
    checkboxChecked: "#B6B6B6",             // Used in: Tables where a checkbos is selected
    copyrightlinkBackground: "#48CAE4",              // Used in: Copyright Link Background
    copyrightlinkColor: "#000000",                   // Used in: Copyright Link Text Color        
    copyrightlinkBackgroundHover: "#48CAE4",         // Used in: Copyright Link Button Hover Background
    copyrightlinkHoverColor: "#000",                 // Used in: Copyright Link Button Hover Text Color
    copyrightlinkBackgroundActive: "#FFF",           // Used in: Copyright Link Active Text Color
    copyrightlinkBackgroundActiveColor: "#EF476F",   // Used in: Copyright Link  Active Text Color
    drawerbackground: "#222222",            // Used in: Background color of the drawer
    drawergizmo: "#222222",                 // Used in: Drawer gizmo background
    drawergizmocolor: "#FFFFFF",            // Used in: Drawer gizmo color
    drawergizmoinputcolor: "#48CAE4",       // Used in: Drawer gizmo status input color
    drawerNavButton: "#333333",             // Used in: Drawer Nav Button on the drawer
    drawerNavButtonColor: "#FFF",           // Used in: Drawer Nav Button Text Color
    drawerNavButtonHover: "#48CAE4",        // Used in: Drawer Navbar Button Hover Background
    drawerNavButtonHoverColor: "#000",      // Used in: Drawer Navbar Button Hover Text Color
    drawerNavButtonActiveColor: "#EF476F",  // Used in: Drawer Navbar Button Active Text Color
    drawerNavHeading: "#222222",            // Used in: Drawer Nav Heading Background
    drawerNavHeadingColor: "#FFFFFF",       // Used in: Drawer Nav Heading Color
    drawerNestedBorder: "#48CAE4",          // Used in: Drawer Multi-item Border color
    drawerNestBorWidth: "0em 0em 0em .15em",// Used in: Drawer Nest item border width
    iconButton: "#FFFFFF",                  // Used in: Icon button "color"
    iconButtonDarkened: "#000000",          // Used in: Icon button where "darkened" is added
    innerdrawerbackground: "#222222",       // Used in: Inner Drawer background color
    inputBackground: "#000",                // Used in: Background of inputs
    inputColor: "#000",                     // Used in: Color text of inputs
    linkBackground: "#FFFFFF",              // Used in: Link Background
    linkColor: "#000000",                   // Used in: Link Text Color        
    linkBackgroundHover: "#48CAE4",         // Used in: Link Button Hover Background
    linkHoverColor: "#000",                 // Used in: Link Button Hover Text Color
    linkBackgroundActive: "#FFF",           // Used in: Link Active Text Color
    linkBackgroundActiveColor: "#EF476F",   // Used in: Link  Active Text Color
    nestedBackground: "red",                // Used in: Deprecated: Drawer Multi-item Background
    navButton: "#48CAE4",                   // Used in: Nav Button BG
    navButtonColor: "#000",                 // Used in: Nav Button Text Color
    navButtonHover: "#FFF",                 // Used in: Navbar Button Hover Background
    navButtonHoverColor: "#000",            // Used in: Navbar Button Hover Text Color
    navButtonActiveColor: "#EF476F",        // Used in: Navbar Button Active Text Color
    navBarHeight: "16px",                   // Used in: Header, sets the height of the navBar (a calculation of the "height")
    quartinaryBackground: "#000",           // Used in: Quartinary backgrounds
    secondaryBackground: "#48CAE4",         // Used in: Gizmo Switch Button Background
                                            //          Header Navbar BG
                                            //          Info box component
    tertiaryBackground: "#B8EBF4",          // Used in: Navbar header item BG
    text: "#000",                           // Used in: Body text color
    textGrayed: "#6b7280",                  // Used in: Grayed out text
    textNested: "#000000",                  // Used in: Drawer Multi-item text color
    textError: "#F00",                      // Used in: ???
    textSecondary: "#FFFFFF",               // Used in: Input Label Color
                                            //          Text on secondaryBackground
    textTerinary: "#000",                   // Used in: NavBar Heading Text color
    textQuartinary: "#FFFFFF"               // Used in: ???
}
*/
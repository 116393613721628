// [][][][][][][][][][][][][][][][][][][][][ START IMPORTS ][][][][][][][][][][][][][][][][][][][][][][][][][][][]
// ####################### GLOBAL ############################################################################
// Import from React
// eslint-disable-next-line
import { useState, useEffect } from 'react'

// Import Toast
// eslint-disable-next-line
import { toast } from 'react-toastify'

// Import Auth
import { useAuth } from '../../middleware/contextHooks'

// Import reactDOM
import { useNavigate } from 'react-router-dom'

// Import middleware
//import { AuthCheck } from '../../middleware/utils'
import { Greetings, GreetingIO } from '../../middleware/GreetingIO'

// ####################### END OF GLOBAL #####################################################################

// ####################### SPECIFIC ##########################################################################
// Import globalStyles elements
//import { /*CustomContainer as Container*/ } from '../../themes/Darwinian2023/skeleton/global'

// Import Icons
//import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined'
//import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined'

// Import from MUI
import {
    //Container, Typography,
    //FormGroup,
    //FormControl,
    //FormControlLabel,
    //FormLabel,
    //InputAdornment,
    //Switch,
} from '@mui/material'

/*
// Import mainStyles
import { 
    //Bar,
    //CustomBox,
    //CustomButton as Button,
    CustomGrid as Grid,
    CustomItem as Item,
    //CustomLink,
    CustomStack as Stack,
    //Heading,
    //Para,    
    //CustomTextField as TextField
} from '../../themes/Darwinian2023/skeleton/dash'
*/

// Import components
import { BreadCrumbs } from '../../components/BreadCrumbs'
import { Spacer } from '../../components/Addon/Spacer'
// ####################### END OF SPECIFIC ##################################################################
// [][][][][][][][][][][][][][][][][][][][][ END IMPORTS ][][][][][][][][][][][][][][][][][][][][][][][][][][][][]

export default function Overview (props) { 
    // [][][][][][][][][][][][][][][][][][][][][ START SETUP ][][][][][][][][][][][][][][][][][][][][][][][][][][]
    // ####################### GLOBAL ############################################################################
    // #region -------------[ Variables ]-------------------------------------------------------------------------
    // Get user logged
    // eslint-disable-next-line
    const {clearErrors, currentUser, getProfile, getGuestProfile, getPublicProfile, isAuthenticated, toasts} = useAuth() 

    // Set navigate
    // eslint-disable-next-line
    const navigate = useNavigate()

    // #region -----------------[ State Variables ]---------------------------------------------------------------
    // Set Authed
    //const [ Authed, setAuthed ] = useState(false)

    // Set LoggingIn
    // eslint-disable-next-line
    //const [ LoggingIn, setLoggingIn ] = useState(false)

    // Set profile
    // eslint-disable-next-line
    //const [profile, setProfile] = useState({})

    // Set Updating
    // eslint-disable-next-line
    //const [ Updating, setUpdating ] = useState(false)
    // #end region -----------------[ State Variables ]-----------------------------------------------------------

    // #endregion ------------------------------------------------------------------------------------------------
    // #region --------------( FUNCTIONS )------------------------------------------------------------------------
    
    // #endregion ------------------------------------------------------------------------------------------------
    // ####################### END OF GLOBAL #####################################################################

    // ####################### SPECIFIC ##########################################################################
    // #region -------------[ Variables ]-------------------------------------------------------------------------
    // Set up breadcrumbs here
    const BCItems = [
        {
            'name': 'Dashboard',
            'type': 'link',
            'grayed': true,
            "url": '/dashboard',
        }, 
        {
            'name': 'Overview',
            'type': 'text',
            'grayed': false,
            'url': '/dashboard/overview'
        },
    ]

    // Set up state for greeting
    const [ Greeting, setGreeting ] = useState(null)
    
    // Set up BounceRate
    const [ BounceRate, setBounceRate ] = useState(null)

    // Set up SiteVisits
    const [ SiteVisits, setSiteVisits ] = useState(null)
    // #endregion -----------------------------------------------------------------------------------------------
    // #region -------------[ Functions ]------------------------------------------------------------------------
    // State rendering for BounceRate
    useEffect(() => {
        setBounceRate(Math.floor(Math.random() * 100))
    }, [  ])
    // State rendering for SiteVisits
    useEffect(() => {
        setSiteVisits(Math.floor(Math.random() * 999999))
    }, [  ])

    // State rendering for greeting
    useEffect(() => {
        setGreeting(GreetingIO(Greetings))
    }, [ ])
    // #endregion -----------------------------------------------------------------------------------------------
    // ####################### END OF SPECIFIC ##################################################################
    // [][][][][][][][][][][][][][][][][][][][][ ENDSETUP ][][][][][][][][][][][][][][][][][][][][][][][][][][][]

    // Render
    return (
        <>
        <props.skeleton.InnerContent>
            <Spacer length={4} />
            <props.skeleton.CustomStack spacing={0}>
                <BreadCrumbs list={BCItems} skeleton={props.skeleton} />
                    <props.skeleton.CustomGrid container spacing={0}>
                        <props.skeleton.CustomGrid item xs={12}>
                            &nbsp;
                        </props.skeleton.CustomGrid>    
                        <props.skeleton.CustomGrid item xs={12}>
                            <props.skeleton.Heading nobg h1>{Greeting} {currentUser?.userName}!</props.skeleton.Heading>
                        </props.skeleton.CustomGrid>
                        <props.skeleton.CustomGrid item xs={12}>
                            &nbsp;
                        </props.skeleton.CustomGrid>                        
                        <props.skeleton.CustomGrid item xs={12}>
                            <props.skeleton.CustomGrid container spacing={0} sx={{ border: 'thin solid black', padding: '10px' }}>
                                <props.skeleton.CustomGrid item xs={10}>
                                    {
                                    /*
                                    ====================================================================================================
                                    ==== #region =====[ MataGrid (displays graph of interactions, ... ]=================================
                                    ====================================================================================================
                                    */
                                    }
                                    
                                    <props.skeleton.Heading h4 nobg>Interactions Grid</props.skeleton.Heading>

                                    {
                                    /*
                                    ====================================================================================================
                                    ==== #endregion =====[ MataGrid (displays graph of interactions, ... ]==============================
                                    ====================================================================================================
                                    */
                                    }
                                </props.skeleton.CustomGrid>
                                <props.skeleton.CustomGrid item xs={2}>
                                    <props.skeleton.CustomStack direction={'column'} spacing={2} alignItems={'flex-start'} sx={{ textAlign: 'left' }}>
                                        <props.skeleton.CustomItem>
                                            <props.skeleton.Heading nobg h5>{SiteVisits}</props.skeleton.Heading>
                                            Site Visits
                                            <br />
                                            Grid
                                        </props.skeleton.CustomItem>
                                        <props.skeleton.CustomItem>
                                            <props.skeleton.Heading nobg h5>{BounceRate}%</props.skeleton.Heading>
                                            Bounce Rate
                                            <br />
                                            Grid
                                        </props.skeleton.CustomItem>
                                        <props.skeleton.CustomItem>
                                            <props.skeleton.Heading nobg h5>04:39</props.skeleton.Heading>
                                            Average time
                                            <br />
                                            Grid
                                        </props.skeleton.CustomItem>
                                    </props.skeleton.CustomStack>
                                </props.skeleton.CustomGrid>
                            </props.skeleton.CustomGrid>
                        </props.skeleton.CustomGrid>      

                        <props.skeleton.CustomGrid item xs={4}>
                            <props.skeleton.CustomGrid container spacing={0} sx={{ border: 'thin solid black', padding: '10px' }}>
                                <props.skeleton.CustomGrid item xs={12}>
                                    Devices
                                </props.skeleton.CustomGrid>
                            </props.skeleton.CustomGrid>                            
                        </props.skeleton.CustomGrid>    

                        <props.skeleton.CustomGrid item xs={4}>
                            <props.skeleton.CustomGrid container spacing={0} sx={{ border: 'thin solid black', padding: '10px' }}>
                                <props.skeleton.CustomGrid item xs={12}>
                                    Demographics
                                </props.skeleton.CustomGrid>
                            </props.skeleton.CustomGrid>
                        </props.skeleton.CustomGrid>

                        <props.skeleton.CustomGrid item xs={4}>
                            <props.skeleton.CustomGrid container spacing={0} sx={{ border: 'thin solid black', padding: '10px' }}>
                                <props.skeleton.CustomGrid item xs={12}>
                                    New Users
                                </props.skeleton.CustomGrid>
                            </props.skeleton.CustomGrid>
                        </props.skeleton.CustomGrid> 

                        <props.skeleton.CustomGrid item xs={6}>
                            <props.skeleton.CustomGrid container spacing={0} sx={{ border: 'thin solid black', padding: '10px' }}>
                                <props.skeleton.CustomGrid item xs={12}>
                                    Acquisition
                                </props.skeleton.CustomGrid>
                            </props.skeleton.CustomGrid>
                        </props.skeleton.CustomGrid>

                        <props.skeleton.CustomGrid item xs={6}>
                            <props.skeleton.CustomGrid container spacing={0} sx={{ border: 'thin solid black', padding: '10px' }}>
                                <props.skeleton.CustomGrid item xs={12}>
                                    <props.skeleton.CustomStack alignItems={'flex-start'}>
                                        <props.skeleton.CustomItem>
                                            Location
                                        </props.skeleton.CustomItem>
                                        <props.skeleton.CustomItem>
                                            Some display of percentage
                                        </props.skeleton.CustomItem>
                                    </props.skeleton.CustomStack>
                                </props.skeleton.CustomGrid>
                            </props.skeleton.CustomGrid>
                        </props.skeleton.CustomGrid>       
                    </props.skeleton.CustomGrid>

                {
                    /*
                    <Para paragraph>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
                        tempor incididunt ut labore et dolore magna aliqua. Rhoncus dolor purus non
                        enim praesent elementum facilisis leo vel. Risus at ultrices mi tempus
                        imperdiet. Semper risus in hendrerit gravida rutrum quisque non tellus.
                        Convallis convallis tellus id interdum velit laoreet id donec ultrices.
                        Odio morbi quis commodo odio aenean sed adipiscing. Amet nisl suscipit
                        adipiscing bibendum est ultricies integer quis. Cursus euismod quis viverra
                        nibh cras. Metus vulputate eu scelerisque felis imperdiet proin fermentum
                        leo. Mauris commodo quis imperdiet massa tincidunt. Cras tincidunt lobortis
                        feugiat vivamus at augue. At augue eget arcu dictum varius duis at
                        consectetur lorem. Velit sed ullamcorper morbi tincidunt. Lorem donec massa
                        sapien faucibus et molestie ac.
                    </Para>
                    <Para>
                        Consequat mauris nunc congue nisi vitae suscipit. Fringilla est ullamcorper
                        eget nulla facilisi etiam dignissim diam. Pulvinar elementum integer enim
                        neque volutpat ac tincidunt. Ornare suspendisse sed nisi lacus sed viverra
                        tellus. Purus sit amet volutpat consequat mauris. Elementum eu facilisis
                        sed odio morbi. Euismod lacinia at quis risus sed vulputate odio. Morbi
                        tincidunt ornare massa eget egestas purus viverra accumsan in. In hendrerit
                        gravida rutrum quisque non tellus orci ac. Pellentesque nec nam aliquam sem
                        et tortor. Habitant morbi tristique senectus et. Adipiscing elit duis
                        tristique sollicitudin nibh sit. Ornare aenean euismod elementum nisi quis
                        eleifend. Commodo viverra maecenas accumsan lacus vel facilisis. Nulla
                        posuere sollicitudin aliquam ultrices sagittis orci a.
                    </Para>
                    <Para>
                        Consequat mauris nunc congue nisi vitae suscipit. Fringilla est ullamcorper
                        eget nulla facilisi etiam dignissim diam. Pulvinar elementum integer enim
                        neque volutpat ac tincidunt. Ornare suspendisse sed nisi lacus sed viverra
                        tellus. Purus sit amet volutpat consequat mauris. Elementum eu facilisis
                        sed odio morbi. Euismod lacinia at quis risus sed vulputate odio. Morbi
                        tincidunt ornare massa eget egestas purus viverra accumsan in. In hendrerit
                        gravida rutrum quisque non tellus orci ac. Pellentesque nec nam aliquam sem
                        et tortor. Habitant morbi tristique senectus et. Adipiscing elit duis
                        tristique sollicitudin nibh sit. Ornare aenean euismod elementum nisi quis
                        eleifend. Commodo viverra maecenas accumsan lacus vel facilisis. Nulla
                        posuere sollicitudin aliquam ultrices sagittis orci a.
                    </Para>
                    <Para paragraph>
                        Consequat mauris nunc congue nisi vitae suscipit. Fringilla est ullamcorper
                        eget nulla facilisi etiam dignissim diam. Pulvinar elementum integer enim
                        neque volutpat ac tincidunt. Ornare suspendisse sed nisi lacus sed viverra
                        tellus. Purus sit amet volutpat consequat mauris. Elementum eu facilisis
                        sed odio morbi. Euismod lacinia at quis risus sed vulputate odio. Morbi
                        tincidunt ornare massa eget egestas purus viverra accumsan in. In hendrerit
                        gravida rutrum quisque non tellus orci ac. Pellentesque nec nam aliquam sem
                        et tortor. Habitant morbi tristique senectus et. Adipiscing elit duis
                        tristique sollicitudin nibh sit. Ornare aenean euismod elementum nisi quis
                        eleifend. Commodo viverra maecenas accumsan lacus vel facilisis. Nulla
                        posuere sollicitudin aliquam ultrices sagittis orci a.
                    </Para>
                    <Para paragraph>
                        Consequat mauris nunc congue nisi vitae suscipit. Fringilla est ullamcorper
                        eget nulla facilisi etiam dignissim diam. Pulvinar elementum integer enim
                        neque volutpat ac tincidunt. Ornare suspendisse sed nisi lacus sed viverra
                        tellus. Purus sit amet volutpat consequat mauris. Elementum eu facilisis
                        sed odio morbi. Euismod lacinia at quis risus sed vulputate odio. Morbi
                        tincidunt ornare massa eget egestas purus viverra accumsan in. In hendrerit
                        gravida rutrum quisque non tellus orci ac. Pellentesque nec nam aliquam sem
                        et tortor. Habitant morbi tristique senectus et. Adipiscing elit duis
                        tristique sollicitudin nibh sit. Ornare aenean euismod elementum nisi quis
                        eleifend. Commodo viverra maecenas accumsan lacus vel facilisis. Nulla
                        posuere sollicitudin aliquam ultrices sagittis orci a.
                    </Para>
                    <Para paragraph>
                        Consequat mauris nunc congue nisi vitae suscipit. Fringilla est ullamcorper
                        eget nulla facilisi etiam dignissim diam. Pulvinar elementum integer enim
                        neque volutpat ac tincidunt. Ornare suspendisse sed nisi lacus sed viverra
                        tellus. Purus sit amet volutpat consequat mauris. Elementum eu facilisis
                        sed odio morbi. Euismod lacinia at quis risus sed vulputate odio. Morbi
                        tincidunt ornare massa eget egestas purus viverra accumsan in. In hendrerit
                        gravida rutrum quisque non tellus orci ac. Pellentesque nec nam aliquam sem
                        et tortor. Habitant morbi tristique senectus et. Adipiscing elit duis
                        tristique sollicitudin nibh sit. Ornare aenean euismod elementum nisi quis
                        eleifend. Commodo viverra maecenas accumsan lacus vel facilisis. Nulla
                        posuere sollicitudin aliquam ultrices sagittis orci a.
                    </Para>
                    <Para paragraph>
                        Consequat mauris nunc congue nisi vitae suscipit. Fringilla est ullamcorper
                        eget nulla facilisi etiam dignissim diam. Pulvinar elementum integer enim
                        neque volutpat ac tincidunt. Ornare suspendisse sed nisi lacus sed viverra
                        tellus. Purus sit amet volutpat consequat mauris. Elementum eu facilisis
                        sed odio morbi. Euismod lacinia at quis risus sed vulputate odio. Morbi
                        tincidunt ornare massa eget egestas purus viverra accumsan in. In hendrerit
                        gravida rutrum quisque non tellus orci ac. Pellentesque nec nam aliquam sem
                        et tortor. Habitant morbi tristique senectus et. Adipiscing elit duis
                        tristique sollicitudin nibh sit. Ornare aenean euismod elementum nisi quis
                        eleifend. Commodo viverra maecenas accumsan lacus vel facilisis. Nulla
                        posuere sollicitudin aliquam ultrices sagittis orci a.
                    </Para>
                    */
                }
            </props.skeleton.CustomStack>
        </props.skeleton.InnerContent>
        </>
    )
}
// [][][][][][][][][][][][][][][][][][][][][ START IMPORTS ][][][][][][][][][][][][][][][][][][][][][][][][][][][]
// ####################### GLOBAL ############################################################################
// Import from React
// eslint-disable-next-line
import { useState, useEffect } from 'react'

// Import Toast
// eslint-disable-next-line
import { toast } from 'react-toastify'

// Import Auth
import { useAuth } from '../middleware/contextHooks'

// Import reactDOM
//import { useNavigate } from 'react-router-dom'
// ####################### END OF GLOBAL #####################################################################

// ####################### SPECIFIC ##########################################################################
// Import from React
//import { useContext } from 'react'

// Import the GlobalContext
//import { GlobalContext } from '../context/globalContext'

// Import globalStyles elements
//import { CustomContainer as Container, Flex } from '../themes/Darwinian2023/skeleton/global'

// Import MUI components
import {  
    
} from '@mui/material'

/*
// Import mainStyles
import { 
    CustomDataGrid as DataGrid,
} from '../themes/Darwinian2023/skeleton/dash'
*/

// #region -----------------------[ Functions ]-------------------------------------------------------------------

// #endregion ----------------------------------------------------------------------------------------------------

// #region -------------------[ VARIABLES ]----------------------------------------------------

// #endregion ---------------------------------------------------------------------------------
// ####################### END OF SPECIFIC #######################################################################
// [][][][][][][][][][][][][][][][][][][][][ END IMPORTS ][][][][][][][][][][][][][][][][][][][][][][][][][][][][]

export default function RichDataGrid (props) { 
    // [][][][][][][][][][][][][][][][][][][][][ START SETUP ][][][][][][][][][][][][][][][][][][][][][][][][][][]
    // ####################### GLOBAL ############################################################################
    // #region -------------[ Variables ]-------------------------------------------------------------------------
    // Get user logged
    // eslint-disable-next-line
    const {clearErrors, currentUser, getProfile, getGuestProfile, getPublicProfile, isAuthenticated, toasts} = useAuth()   

    // Set navigate
    // eslint-disable-next-line
    //const navigate = useNavigate()

    // #region -----------------[ State Variables ]---------------------------------------------------------------
    // Set Authed
    //const [ Authed, setAuthed ] = useState(false)

    // Set LoggingIn
    // eslint-disable-next-line
    //const [ LoggingIn, setLoggingIn ] = useState(false)

    // Set profile
    // eslint-disable-next-line
    //const [profile, setProfile] = useState({})

    // Set Updating
    // eslint-disable-next-line
    //const [ Updating, setUpdating ] = useState(false)
    // #end region -----------------[ State Variables ]-----------------------------------------------------------
    // #endregion ------------------------------------------------------------------------------------------------
    // #region --------------( FUNCTIONS )------------------------------------------------------------------------
    
    // #endregion ------------------------------------------------------------------------------------------------
    // ####################### END OF GLOBAL #####################################################################

    // ####################### SPECIFIC ##########################################################################
    // #region -------------[ Variables ]-------------------------------------------------------------------------
    
    // #endregion -----------------------------------------------------------------------------------------------
    // #region -------------[ Functions ]------------------------------------------------------------------------ 
    
    // #endregion -----------------------------------------------------------------------------------------------
    // ####################### END OF SPECIFIC ##################################################################
    // [][][][][][][][][][][][][][][][][][][][][ ENDSETUP ][][][][][][][][][][][][][][][][][][][][][][][][][][][]

    // Render
    return (
        <>          
        <props.skeleton.CustomDataGrid
            initialState={{
                columns: {
                    columnVisibilityModel: {
                        // Hide columns here
                        _id: false,
                    }
                },
                pagination: {
                    paginationModel: { pageSize: props.pageSize || 5 }
                }
            }}
            components={{
                NoRowsOverlay: () => (
                    <props.skeleton.CustomStack height={'100%'} alignItems={'center'} justifyContent={'center'}>
                        <props.skeleton.Heading h3 center padding10>Nothing here but pixels. Click 'Create New' to start your journey!</props.skeleton.Heading>
                    </props.skeleton.CustomStack>
                ),
                NoResultsOverlay: () => (
                    <props.skeleton.CustomStack height={'100%'} alignItems={'center'} justifyContent={'center'}>
                        <props.skeleton.Heading h3 center padding10>Local filter returns no result</props.skeleton.Heading>
                    </props.skeleton.CustomStack>
                ),
            }}
            getRowId={(row) => row._id || row.slug}
            getRowHeight={() => 'auto'}
            rows={props.rows}
            columns={props.columns}
            pageSize={props.pageSize || 5}
            pageSizeOptions={[ 5, 10, 25, 50 ]}
            rowsPerPageOptions={[props.pageSize || 5]}
            checkboxSelection
        />
        </>
    )
}
export const customlink = {
    base: {
        background: "green",
        backgroundHover: "lime",
        backgroundActive: "lime",
        color: "white",
        colorGray: 'gray',
        colorHover: "black",
        colorActive: "black",
        params: {
            padding: '10px'
        }
    },
    copyrightlink: {
        base: {
            background: "green",
            backgroundHover: "lime",
            backgroundActive: "lime",
            color: "white",
            colorGray: 'gray',
            colorHover: "black",
            colorActive: "black",
            params: {
                padding: '0px 0px 0px 0px' // Top Right Bottom Left
            }
        }
    }
}
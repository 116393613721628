import * as ActionTypes from '../ContextActions'

// eslint-disable-next-line import/no-anonymous-default-export
export default (state, action) => {
    switch (action.type) {

        case ActionTypes.CLEAR_CURRENT_SETTING:
            return {
                ...state,
                currentSetting: null,
                settingCreated: false
            }
        case ActionTypes.CLEAR_ERRORS_SETTING:
            return {
                ...state,
                toastsSetting: null
            }
        case ActionTypes.CLEAR_SETTINGS:
            return {
                ...state,
                thesettings: null,
                currentSetting: null,
                settingCreated: false,
                toastsSetting: null
            }    
        case ActionTypes.GET_SETTINGS:
            //console.log(action.payload[0].object.settings)
            return {
                ...state,
                settings: action.payload[0].object.settings,
                toastsSetting: action.payload
            }
            
        case ActionTypes.GET_SETTINGS_FAIL:
            return {
                ...state,
                settings: null,
                toastsSetting: null
            }

        case ActionTypes.GET_SETTING_BYNAME:
            //console.log(action.payload[0]?.object?.setting[0])
            return {
                ...state,
                currentSetting: action.payload[0]?.object?.setting[0],
                settingCreated: true,
                toastsSetting: action.payload
            }
            
        case ActionTypes.GET_SETTING_BYNAME_FAIL:
            return {
                ...state,
                currentSetting: null,
                settingCreated: false,
                toastsSetting: null
            }
            
        case ActionTypes.UPDATE_SETTING:
            return {
                ...state,
                //currentSetting: action.payload,
                //SettingFormIsDisabled: true,
                toastsSetting: action.payload
            }
            
        case ActionTypes.UPDATE_SETTING_FAIL:
            //console.log(action.payload)
            return {
                ...state,
                //thesettings: action.payload,
                toastsSetting: action.payload
            }

        case ActionTypes.UPDATE_SLICEITEMDISPLAY:
            return {
                ...state,
                //AccountUpdatingSent: true,
                toasts: action.payload             
            }
            
        case ActionTypes.UPDATE_SLICEITEMDISPLAY_ERROR:
            return {
                ...state,
                //AccountUpdatingSent: true,
                toasts: action.payload,
            }
            
        case ActionTypes.UPDATE_SLICEITEMPOSTTYPEDISPLAY:
            return {
                ...state,
                //AccountUpdatingSent: true,
                toasts: action.payload             
            }

        case ActionTypes.UPDATE_SLICEITEMPOSTTYPEDISPLAY_ERROR:
            return {
                ...state,
                //AccountUpdatingSent: true,
                toasts: action.payload,
            }

        // #region -------------------[ SETTERS ]---------------------------------------------------------------------------------------------------
        case ActionTypes.SET_SETTINGS_EDITING:
            return {
                ...state,
                SettingsEditing: action.payload                
            }
            
        case ActionTypes.SET_SETTINGS_IS_UPDATING_SETTING_SENT:
            return {
                ...state,
                isUpdatingSettingSent: action.payload                
            }
            
        case ActionTypes.SET_SETTINGS_FORM_IS_DISABLED:
            return {
                ...state,
                SettingsFormIsDisabled: action.payload                
            }

        case ActionTypes.SET_SLICEITEMDISPLAY:
            return {
                ...state,
                sliceItemDisplay: action.payload,
                currentSetting: null,
                //settingCreated: false // don't need to do this as the state will keep turning off and on this and the useEffect() on the Board will keep triggering causing a loop
            }

        case ActionTypes.SET_SLICEITEMPOSTTYPEDISPLAY:
            return {
                ...state,
                sliceItemPostTypeDisplay: action.payload,
                currentSetting: null,
                //settingCreated: false // don't need to do this as the state will keep turning off and on this and the useEffect() on the Board will keep triggering causing a loop
            }

        case ActionTypes.SET_VARIABLES:
            //console.log(action.payload)

            return {
                ...state,
                variables: [...state.variables, action.payload],
                //settingCreated: false // don't need to do this as the state will keep turning off and on this and the useEffect() on the Board will keep triggering causing a loop
            }
        // #endregion ------------------------------------------------------------------------------------------------------------------------------
        
        default:
            return state;
    }
}
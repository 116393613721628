// [][][][][][][][][][][][][][][][][][][][][ START IMPORTS ][][][][][][][][][][][][][][][][][][][][][][][][][][][]
// ####################### GLOBAL ############################################################################
// Import from React
import { useState, useEffect } from 'react'

// Import Toast
// eslint-disable-next-line
import { toast } from 'react-toastify'

// Import Auth
import { useAuth } from '../../middleware/contextHooks'

// Import reactDOM
import { useNavigate } from 'react-router-dom'

// Import utils
// eslint-disable-next-line
import { DeTemps, settingLookup } from '../../middleware/utils'
// ####################### END OF GLOBAL #####################################################################

// ####################### SPECIFIC ##########################################################################
// Import Auth
import { useSetting } from '../../middleware/contextHooks'

// Import globalStyles elements
//import { /*CustomContainer as Container*/ } from '../../themes/Darwinian2023/skeleton/global'

// Import Icons
//import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined'
//import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined'

// Import from MUI
import {
    //Avatar,
    //Container,
    //InputAdornment,
    //Switch,
    //Typography
    //Box,
    Checkbox,
    //Container,
    // eslint-disable-next-line
    //FormGroup,
    //InputAdornment,
    InputLabel,
    ListItemText,
    //Paper,
    MenuItem,
    OutlinedInput,
    //Toolbar,
    //Typography
} from '@mui/material'

/*
// Import mainStyles
import { 
    Bar,
    CustomBox as Box,
    CustomGrid as Grid,
    CustomItem as Item,
    //CustomLink,
    CustomStack as Stack,
    CustomMuiTelInput as MuiTelInput,
    //Heading,
    //Para,
    CustomTab as Tab,
    CustomTabs as Tabs,
    CustomTextField as TextField
} from '../../themes/Darwinian2023/skeleton/dash'
*/

// Import BreadCrumbs
//import { BreadCrumbs } from '../../components/BreadCrumbs'

// Import proptypes for tabs
import { PropTypes } from 'prop-types'

// Import DragZone component
//import { UploadDragZone } from '../../components/UploadDragZone'
import { Spacer } from '../../components/Addon/Spacer'

import { BreadCrumbs } from '../../components/BreadCrumbs'

import { themes } from '../../themes/Themes'
// ####################### END OF SPECIFIC #######################################################################
// [][][][][][][][][][][][][][][][][][][][][ END IMPORTS ][][][][][][][][][][][][][][][][][][][][][][][][][][][][]

// #region ----------------[ Outside Functions ]------------------------------------------------------------------
// Function for creating tabpanel
function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`horizontal.tabpanel-${index}`}
        aria-labelledby={`horizontal.tab-${index}`}
        {...other}
      >
        {value === index && (
          <props.skeleton.CustomBox sx={{ p: 0, width: '100%' }}>
            <props.skeleton.CustomBox sx={{ width: '100%' }}>{children}</props.skeleton.CustomBox>
          </props.skeleton.CustomBox>
        )}
      </div>
    )
}

// Set the propsTypes for tabpanel
TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
}
// #endregion -------------[ Outside Functions ]------------------------------------------------------------------

// #region ----------------[ Outside Variables ]------------------------------------------------------------------
const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8

const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250
        }
    }
}

const tabsections = [
    {
        id: 0,
        title: 'Site Configuration',
        name: 'site_configuration'
    },
    {
        id: 1,
        title: 'Application/Server',
        name: 'application_server'
    },
    {
        id: 2,
        title: 'Theme',
        name: 'theme'
    },
    {
        id: 3,
        title: 'System',
        name: 'system'
    },
    {
        id: 4,
        title: 'Security',
        name: 'security'
    }
]
// #endregion -------------[ Outside Variables ]------------------------------------------------------------------

export default function SettingsUser (props) { 
    // [][][][][][][][][][][][][][][][][][][][][ START SETUP ][][][][][][][][][][][][][][][][][][][][][][][][][][]
    // ####################### GLOBAL ############################################################################
    // #region -------------[ Variables ]-------------------------------------------------------------------------
    // Get user logged
    // eslint-disable-next-line
    const {
        // Variables
        currentUser, 

        // Functions        
    } = useAuth()   

    // Set navigate
    // eslint-disable-next-line
    const navigate = useNavigate()

    // #region -----------------[ State Variables ]---------------------------------------------------------------
    // Set Authed
    //const [ Authed, setAuthed ] = useState(false)

    // Set LoggingIn
    //const [ LoggingIn, setLoggingIn ] = useState(false)

    // Set profile - set from the props to control things here
    //const [profile, setProfile] = useState({})

    // Set Updating
    //const [ Updating, setUpdating ] = useState(false)
    // #endregion -----------------[ State Variables ]------------------------------------------------------------
    // eslint-disable-next-line
    const [ phone, setPhone ] = useState('')
    // #endregion ------------------------------------------------------------------------------------------------
    // #region --------------( FUNCTIONS )------------------------------------------------------------------------
    // eslint-disable-next-line
    const handleTelChange = (newPhone) => {
        setPhone(newPhone)

        // Set the user state
        setTemp({...temp, phone: newPhone})
    }
    // #endregion ------------------------------------------------------------------------------------------------
    // ####################### END OF GLOBAL #####################################################################

    // ####################### SPECIFIC ##########################################################################
    // #region -------------[ Variables ]-------------------------------------------------------------------------
    
    const {
        // functions
        setIsUpdatingSettingSent,
        setSettingsEditing,    
        setSettingsFormIsDisabled,  
        updateSetting,
        
        // variables
        SettingsEditing,
        SettingsFormIsDisabled,
        settings,
        variables
    } = useSetting()

    // #region -----------------[ State Variables ]---------------------------------------------------------------    
    // Set state for theme
    const [ themeUsing, setThemeUsing ] = useState(settingLookup('themeName', variables))

    // Set state for profile image
    // eslint-disable-next-line
    //const [ files, setFiles ] = useState([])

    // Set imageUrl
    // eslint-disable-next-line
    //const [image, setImage] = useState(null)

    // Set isDisabled for form state - DEPRECATED
    //const [isDisabled, setIsDisabled] = useState(true)

    // Set password field states
    //const [showCPassword, setShowCPassword] = useState(false)
    //const [showNPassword, setShowNPassword] = useState(false)
    //const [showCNPassword, setShowCNPassword] = useState(false)

    // Set private switch state
    // eslint-disable-next-line
    const [privateSwitchChecked, setPrivateSwitchChecked] = useState(false)

    // Set tabValue
    const [tabValue, setTabValue] = useState(0)

    // Set a temp state
    const [temp, setTemp] = useState([])
    // #end region -----------------[ State Variables ]------------------------------------------------------------
    // #end region -----------------[ Variables ]--------------------------------------------------------------------------------------------

    // UPDATE 2.4.71 : Load contexts "conditionally"
    // Set up the slice
    var slice = props.tertiary

    // Set up Modules
    // eslint-disable-next-line
    const Modules = props.Modules

    // Set up variables
    let resources
    let query

    // Set all the resources from the contexts
    const resourcesSetting = useSetting()

    // Conditional setting resources
    switch (slice) {
        case 'General':
            // eslint-disable-next-line
            query="setting"
            resources=resourcesSetting
        break
        default: 
            /* ??? No resource; what are we doing here? */
        break
    }

    // Set up breadcrumbs here
    const BCItems = [
        {
            'name': 'Dashboard',
            'type': 'link',
            'grayed': true,
            "url": '/dashboard',
        }, 
        {
            'name': 'Settings',
            'type': 'link',
            'grayed': true,
            'url': '/dashboard/settings/user'
        },
        {
            'name': 'User',
            'type': 'text',
            'grayed': false,
            'url': ''
        },
    ]
    
    // Set up dropzone
    /*const { getRootProps, getInputProps } = useDropzone({
        accept: {
            "image/*": ['.jpeg','.jpg','.png']
        },
        onDrop: (acceptedFiles) => {
            setFiles(
                acceptedFiles.map((file) => Object.assign(file, {
                    preview: URL.createObjectURL(file)
                }))
            )
        }
    })
    */

    // Create images for dropzone
    /*const images = files.map((file) => (
        <div key={file.name}>
            <div>
                <img src={file.preview} style={{ width: "200px"}} alt="preview" />
            </div>
        </div>
    ))
    */

    // handle for theme change
    const handleThemeChange = (section, name, value) => {
        setThemeUsing(value)

        // Tell the system that we have sent the post to be updated
        setIsUpdatingSettingSent(true)

        // send to backend
        updateSetting(section, name, value)
    }

    // Handle for changing tab
    const handleTabChange = (e, newValue) => {
        setTabValue(newValue)
    }

    // eslint-disable-next-line
    const handlePrivateSwitchChange = e => {
        //profile.isPrivate = e.target.checked // Version 1.3.8: Issue/Enhancement Implementation: https://github.com/serafirim/MERNStackStarter/issues/5
        setTemp({...temp, isPrivate: e.target.checked})
        //alert(profile.isPrivate)
        setPrivateSwitchChecked(e.target.checked)
    }
    
    const handleCancel = e => {
        setSettingsEditing(false)
        setSettingsFormIsDisabled(true)
    }

    const handleEditing = e => {
        // Set editing mode
        setSettingsEditing(true)

        // Disable form
        setSettingsFormIsDisabled(false)

        let tempArray = []
        let element

        // Push to temp
        Array.from(settings).forEach((ele) => {
            // Pushing each element as addition to array resulting in 
            // 0: {variablename: ""}
            // 1: {variablename2: ""}
            // ...
            tempArray.push({[ele.name]: ele.value})

            // What we want is to push each setting to one array
            //[Object.keys(temp[index])]: e.target.value

            //setTemp(tempArray)
        })

        //console.log(tempArray)

        setTemp(tempArray)
    }

    const handleDone = e => {
        // Disable form
        setSettingsFormIsDisabled(true)

        // No longer editing
        setSettingsEditing(false)

        // Set temp to null
        //setTemp(null)
    }

    const handleUpdateSetting = (section, name, value) => {
        // Display a nice toast
        //toast.info(`Updated setting: ${name}`, {theme: 'dark'})

        // Tell the system that we have sent the post to be updated
        setIsUpdatingSettingSent(true)

        // send to backend
        updateSetting(section, name, value)
    }

    // Get userLogged
    //const {updateUser} = useAuth()    

    // #endregion ------------------------------------------------------------------------------------------------
    // #region -------------[ Functions ]-------------------------------------------------------------------------
    
    // Do I need this? Used for tab panel
    function allyProps(index) {
        return {
          id: `horizontal.tab-${index}`,
          'aria-controls': `horizontal-tabpanel-${index}`,
        }
    }

    // State rendering for switches
    useEffect(() => {
        if (temp) {
            setPrivateSwitchChecked(temp?.isPrivate)
        } else {
            setPrivateSwitchChecked(currentUser?.isPrivate)
        }
        // eslint-disable-next-line
    }, [ currentUser?.isPrivate ])

    // State rendering for debugging
    useEffect(() => {
        //console.log("---[ Debugging ]---")
        //if (settings) console.log(settings)
        //console.log("SettingsEditing: "+SettingsEditing)
        //if (temp) console.log(temp)
        //console.log("SettingsFormIsDisabled: "+SettingsFormIsDisabled)
        //console.log("AccountUpdating: "+AccountUpdating)
        //console.log("AccountUpdatingSent: "+AccountUpdatingSent)
        //console.log("image: "+image)
        //console.log("isDisabled: "+isDisabled)
        //console.log("Profile: "+profile.firstName)   
        //console.log("profile image: "+temp?.image)     
        //console.log("Toasts: "+toasts)
        //console.log("Updating: "+Updating)
        //console.log("UpdateSent: "+UpdateSent)  
    }, [ 
        //settings
        //SettingsEditing, 
        //temp
        //SettingsFormIsDisabled, AccountUpdating, AccountUpdatingSent 
    ])

    // #endregion -----------------------------------------------------------------------------------------------
    // ####################### END OF SPECIFIC ##################################################################
    // [][][][][][][][][][][][][][][][][][][][][ ENDSETUP ][][][][][][][][][][][][][][][][][][][][][][][][][][][]

    // Render
    return (
        <>
        <props.skeleton.InnerContent paddingleft20paddingright10>
            <Spacer length={4} />

            <props.skeleton.CustomStack spacing={0}>
                <BreadCrumbs list={BCItems} skeleton={props.skeleton} />

                {
                    (currentUser)
                    ? (
                        <props.skeleton.CustomGrid container spacing={2} sx={{  }}>
                            <props.skeleton.CustomGrid item xs={12}>
                                <props.skeleton.CustomGrid container justify={'flex-start'} alignItems={'flex-start'} spacing={0} sx={{  }}>
                                    <props.skeleton.CustomGrid item xs={12} md={12}  sx={{  }}>
                                        <props.skeleton.CustomBox sx={{ width: {xs: '100%', sm: '100%', md: '100%', lg: '100%'}, height: '100%' }}>
                                            <props.skeleton.CustomStack>
                                                <props.skeleton.CustomStack sx={{  }} direction="column" alignItems="center">
                                                    <props.skeleton.CustomItem nobg="true">
                                                        &nbsp;
                                                    </props.skeleton.CustomItem>
                                                    <props.skeleton.CustomItem nobg="true">
                                                        &nbsp;
                                                    </props.skeleton.CustomItem>
                                                    
                                                    <props.skeleton.CustomItem nobg="true">
                                                        &nbsp;
                                                    </props.skeleton.CustomItem>
                                                    <props.skeleton.CustomItem nobg="true">
                                                        &nbsp;
                                                    </props.skeleton.CustomItem>                                    
                                                </props.skeleton.CustomStack>

                                                {/* #region ---------[ Tabs - Desktop ]--------------------------------------------------------------- */}
                                                <props.skeleton.CustomStack direction="column" sx={{ display: { xs: 'none', sm: 'none', md: 'flex', lg: 'flex'} }}>
                                                    <props.skeleton.CustomItem nobg="true">
                                                        <props.skeleton.CustomTabs
                                                            orientation="horizontal"
                                                            variant="scrollable"
                                                            value={tabValue}
                                                            onChange={handleTabChange}
                                                            aria-label="Horizontal tab"
                                                            sx={{ borderRight: 1, borderColor: 'divider' }}
                                                        >
                                                            <props.skeleton.CustomTab label="Site Configuration" {...allyProps(0)} />
                                                            <props.skeleton.CustomTab label="Application/Server" {...allyProps(1)} />
                                                            <props.skeleton.CustomTab label="Theme" {...allyProps(2)} />
                                                            <props.skeleton.CustomTab label="System Settings" {...allyProps(3)} />
                                                            <props.skeleton.CustomTab label="Security" {...allyProps(4)} />
                                                            
                                                        </props.skeleton.CustomTabs>
                                                    </props.skeleton.CustomItem>
                                                </props.skeleton.CustomStack>
                                                {/* -------------------------------------------------------------------------------------------------- */}

                                                {/* #region ---------[ Tabs - Mobile ]---------------------------------------------------------------- */}
                                                <props.skeleton.CustomTabs 
                                                    sx={{ display: { xs: 'none', sm: 'flex', md: 'none', lg: 'none' } }}
                                                    value={tabValue} 
                                                    onChange={handleTabChange} 
                                                    centered
                                                    variant={'fullWidth'}
                                                    aria-label="Horizontal tab"
                                                >
                                                    <props.skeleton.CustomTab label="Site Configuration" {...allyProps(0)} />
                                                    <props.skeleton.CustomTab label="Application/Server" {...allyProps(1)} />
                                                    <props.skeleton.CustomTab label="Theme" {...allyProps(2)} />
                                                    <props.skeleton.CustomTab label="System Settings" {...allyProps(3)} />
                                                    <props.skeleton.CustomTab label="Security" {...allyProps(4)} />
                                                </props.skeleton.CustomTabs>
                                                {/* --------------------------------------------------------------------------------------------- */}

                                                {/* #region ---------[ Tabs - Mobile small ]----------------------------------------------------- */}
                                                <props.skeleton.CustomTabs 
                                                    sx={{ display: { xs: 'flex', sm: 'none', md: 'none', lg: 'none' } }}
                                                    value={tabValue} 
                                                    onChange={handleTabChange} 
                                                    variant={'scrollable'}
                                                    aria-label="Horizontal tab"
                                                >
                                                    <props.skeleton.CustomTab label="Site Configuration" {...allyProps(0)} />
                                                    <props.skeleton.CustomTab label="Application/Server" {...allyProps(1)} />
                                                    <props.skeleton.CustomTab label="Theme" {...allyProps(2)} />
                                                    <props.skeleton.CustomTab label="System Settings" {...allyProps(3)} />
                                                    <props.skeleton.CustomTab label="Security" {...allyProps(4)} />
                                                </props.skeleton.CustomTabs>
                                                {/* --------------------------------------------------------------------------------------------- */}
                                            </props.skeleton.CustomStack>
                                        </props.skeleton.CustomBox>
                                    </props.skeleton.CustomGrid>

                                    <props.skeleton.CustomGrid item xs={12} md={12}>
                                        <props.skeleton.CustomBox sx={{ width: '98%', height: '100%', alignItems: 'flex-start', justifyContent: 'flex-start' }}>
                                            <form>
                                            {
                                                Array.from(tabsections).map((ele, index) => {
                                                    return (
                                                        <TabPanel key={ele+index} value={tabValue} index={index} skeleton={props.skeleton}>
                                                            <props.skeleton.CustomGrid container spacing={2} sx={{ textAlign: 'left' }}>
                                                                <props.skeleton.CustomGrid item xs={12} md={12}>
                                                                    <props.skeleton.CustomGrid container spacing={0}>
                                                                        <props.skeleton.CustomGrid item xs={12} md={6}>
                                                                            <props.skeleton.CustomTypography variant='h4'>{ele.title} Settings</props.skeleton.CustomTypography> 
                                                                            {!SettingsFormIsDisabled && <props.skeleton.CustomTypography variant='h6'>Tip: Press Ctrl+A (select all), then start typing.<br />Revert to original by clearing field.</props.skeleton.CustomTypography> }
                                                                        </props.skeleton.CustomGrid>

                                                                        <props.skeleton.CustomGrid item xs={12} md={6}>
                                                                            <props.skeleton.CustomStack direction="row" justifyContent="flex-end" alignContent="flex-end" spacing={1}>                                                    
                                                                                <props.skeleton.CustomItem>
                                                                                    {!SettingsFormIsDisabled
                                                                                        ?   <>
                                                                                                {/*<props.skeleton.Bar buttonerror="true" padding5lronly="true" center="true" onClick={handleCancel}>Cancel</props.skeleton.Bar*/}
                                                                                            </>
                                                                                        : null
                                                                                    }
                                                                                </props.skeleton.CustomItem>
                                                                                <props.skeleton.CustomItem>
                                                                                    {SettingsFormIsDisabled
                                                                                        ? <props.skeleton.Bar center="true" button="true" padding5lronly="true" onClick={handleEditing}>Edit</props.skeleton.Bar>
                                                                                        : <props.skeleton.Bar center="true" button="true" padding5lronly="true" onClick={handleDone}>Done</props.skeleton.Bar>
                                                                                    }                                                            
                                                                                </props.skeleton.CustomItem>
                                                                            </props.skeleton.CustomStack>
                                                                        </props.skeleton.CustomGrid>
                                                                    </props.skeleton.CustomGrid>
                                                                </props.skeleton.CustomGrid>   
                                                                {/* #region -----[ Map the settings dynamically ]---------------------------------------------------------------------- */}                                     
                                                                {
                                                                    (settings && settings.length > 1) 
                                                                    ? (
                                                                        Array.from(settings).map((elem, index) => {
                                                                            return (
                                                                                (elem.section === ele.name)
                                                                                ? (                                                                           
                                                                                    // Display user settings
                                                                                    (elem.namespace === "local")
                                                                                    && (
                                                                                        <props.skeleton.CustomGrid key={ele+index} item xs={12} md={6}>
                                                                                            {SettingsFormIsDisabled
                                                                                                ? <props.skeleton.CustomTypography><b>{(elem.title) ? elem.title : elem.name}</b><br />{elem.value}</props.skeleton.CustomTypography>
                                                                                                : (
                                                                                                    (elem.type === "list")
                                                                                                    ? (
                                                                                                        <>
                                                                                                        {
                                                                                                        /* INPUT
                                                                                                        <props.skeleton.CustomTextField
                                                                                                            name={ elem.name } 
                                                                                                            label={ elem.name }
                                                                                                            value={ temp[Object.keys(temp[index])] || elem.value } 
                                                                                                            disabled={SettingsFormIsDisabled}
                                                                                                            type="text"
                                                                                                            onChange={(e) => setTemp({...temp, [Object.keys(temp[index])]: e.target.value})}
                                                                                                            onBlur={(e) => handleUpdateSetting(ele.name, elem.name, e.target.value)}
                                                                                                        />
                                                                                                        */
                                                                                                        }

                                                                                                        <props.skeleton.CustomFormControl fullWidth>
                                                                                                            <InputLabel id="theme-simple-select-label">Current Theme</InputLabel>
                                                                                                            <props.skeleton.CustomSelect
                                                                                                                labelId="theme-simple-select-label"
                                                                                                                id="theme-simple-select"
                                                                                                                value={themeUsing}
                                                                                                                label="Theme"
                                                                                                                onChange={(e) => handleThemeChange(ele.name, elem.name, e.target.value)}
                                                                                                            >
                                                                                                                {
                                                                                                                    (themes.map((elem, index) => {
                                                                                                                        return (
                                                                                                                            <MenuItem key={elem+index} value={elem.name}>{elem.name}</MenuItem>
                                                                                                                        )    
                                                                                                                    }))
                                                                                                                }
                                                                                                            </props.skeleton.CustomSelect>
                                                                                                        </props.skeleton.CustomFormControl>
                                                                                                        </>
                                                                                                    )
                                                                                                    : (
                                                                                                        <props.skeleton.CustomTextField
                                                                                                            name={ elem.name } 
                                                                                                            label={ elem.name }
                                                                                                            value={ temp[Object?.keys(temp[index])] || elem.value } 
                                                                                                            disabled={SettingsFormIsDisabled}
                                                                                                            type="text"
                                                                                                            onChange={(e) => setTemp({...temp, [Object?.keys(temp[index])]: e.target.value})}
                                                                                                            onBlur={(e) => handleUpdateSetting(ele.name, elem.name, e.target.value)}
                                                                                                        />
                                                                                                    )
                                                                                                )
                                                                                            }
                                                                                        </props.skeleton.CustomGrid>
                                                                                    )
                                                                                )
                                                                                : null                                                                                
                                                                            )
                                                                        })
                                                                    )
                                                                    : <props.skeleton.Heading h1 nobg padding={'10px 0px 0px 15px'}>No Settings...</props.skeleton.Heading>
                                                                }
                                                                {/* #endregion -------------------------------------------------------------------------------------------------------- */}
                                                                
                                                            </props.skeleton.CustomGrid>
                                                        </TabPanel>
                                                    )
                                                })
                                            }
                                            </form>
                                        </props.skeleton.CustomBox>                            
                                    </props.skeleton.CustomGrid>
                                </props.skeleton.CustomGrid>
                            </props.skeleton.CustomGrid>
                        </props.skeleton.CustomGrid>
                    )
                    : (
                        <props.skeleton.CustomGrid container spacing={2} sx={{  }}>
                            <props.skeleton.CustomGrid item xs={12}>
                                <props.skeleton.Heading h2>
                                    
                                </props.skeleton.Heading>
                            </props.skeleton.CustomGrid>
                            <props.skeleton.CustomGrid item xs={12}>
                                <props.skeleton.Heading nobg h2>
                                    You do not have permission to see this.
                                </props.skeleton.Heading>
                            </props.skeleton.CustomGrid>
                        </props.skeleton.CustomGrid>
                    )
                }
            </props.skeleton.CustomStack>
        </props.skeleton.InnerContent>
        </>
    )
}
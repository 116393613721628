/*
// Import globalStyles
import { 
    
} from '../themes/Darwinian2023/skeleton/global'
*/

export const BreadCrumbs = (props) => {  
    return (
        <props.skeleton.CustomBreadcrumbs aria-label="breadcrumb">
            {
                props.list.map((item, index) => {
                    return (
                        <div key={index}>
                            {
                                (item.type === "link" && item.grayed) &&
                                <props.skeleton.CustomLink breadcrumb padding5 fontheading1 grayed href={item.url}>
                                    {item.name}
                                </props.skeleton.CustomLink>    
                            }

                            {
                                (item.type === "link" && !item.grayed) &&
                                <props.skeleton.CustomLink breadcrumb padding5 fontheading1 href={item.url}>
                                    {item.name}
                                </props.skeleton.CustomLink>    
                            }

                            {
                                (item.type === "text" && item.grayed) &&
                                <props.skeleton.Heading nobg="true" breadcrumb h7 fontheading1 grayed>
                                    {item.name}
                                </props.skeleton.Heading> 
                            }           

                            {
                                (item.type === "text" && !item.grayed) &&
                                <props.skeleton.Heading nobg="true" breadcrumb fontheading1 h7>
                                    {item.name}
                                </props.skeleton.Heading> 
                            }           
                        </div>                        
                    )                    
                })
            }            
        </props.skeleton.CustomBreadcrumbs>
    )
}
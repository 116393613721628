export const bar = {
    background: "#FFF",
    button: {
        base: {
            background: "lime",
            backgroundHover: "white",  
            backgroundActive: "white",
            color: "black",
            colorHover: "black",
            colorActive: "black"
        }
    }
}
export const grid = {
    base: {
        primary: {
            background: "#fff9fa",
            color: "black"
        }, 
        secondary: {
            background: "#ff8080",
        },
        tertiary: {
            background: "#ffb3b3",
        },
        quarternary: {
            background: "white",
        }
    }, 
    button: {
        base: {
            background: "white",
            backgroundHover: "#8b0000",
            backgroundActive: "white",
            color: "#ff4d4d",
            colorHover: "white",
            colorActive: "white",
        },
        primary: {
            background: "#860000",
            backgroundHover: "pink",
            backgroundActive: "pink",
            color: "white",
            colorHover: "#ff4d4d",
            colorActive: "#ff4d4d",
        },
        secondary: {
            background: "violet",
            backgroundHover: "pink",
            backgroundActive: "pink",
            color: "white",
            colorHover: "black",
            colorActive: "black",
        },
        tertiary: {
            background: "lime",
            backgroundHover: "black",
            backgroundActive: "black",
            color: "black",
            colorHover: "lime",
            colorActive: "lime",
        },
        quarternary: {
            background: "white",
            backgroundHover: "cyan",
            backgroundActive: "cyan",
            color: "black",
            colorHover: "black",
            colorActive: "black",
        }
    },
    params: {
        fontsize: "16px"
    },
    richtable: {
        header: {
            background: '#191919',
            color: 'white'
        },
        rows: {
            background: '#303030',
            backgroundHover: '#00b3b3',
            color: 'white',
            colorHover: 'black'
        }
    },
    richtablenougat: {
        base: {
            background: '#191919'
        },
        topbar: {
            background: '#191919'
        }
    },
    sink: {
        background: "#191919",
        color: "white"
    }, 
}
export const Greetings = [    
    // #region -----[ 'name' Pack ]-------------------------------------------------------------------------------
    /* TEMPLATE
    { 
        "name": "greetin-name",
        "content": "The Greeting",
        "timeSensitive": false,
        "WhatTime": ""
    },
    */
    // #endregion ------------------------------------------------------------------------------------------------

    // #region -----[ Cultural Pack ]-----------------------------------------------------------------------------
    { 
        "name": "bonjour",
        "content": "Bonjour",
        "timeSensitive": false,
        "WhatTime": ""
    },
    { 
        "name": "ciao",
        "content": "ciao",
        "timeSensitive": false,
        "WhatTime": ""
    },
    { 
        "name": "gutentag",
        "content": "guten tag",
        "timeSensitive": false,
        "WhatTime": ""
    },
    { 
        "name": "hello",
        "content": "Hello",
        "timeSensitive": false,
        "WhatTime": ""
    },
    { 
        "name": "hola",
        "content": "hola",
        "timeSensitive": false,
        "WhatTime": ""
    }, 
    { 
        "name": "hyalo",
        "content": "hyālō",
        "timeSensitive": false,
        "WhatTime": ""
    },    
    { 
        "name": "konichiwa",
        "content": "kon’nichiwa",
        "timeSensitive": false,
        "WhatTime": ""
    },    
    { 
        "name": "marhaban",
        "content": "márhaban",
        "timeSensitive": false,
        "WhatTime": ""
    },    
    { 
        "name": "namaste",
        "content": "namaste",
        "timeSensitive": false,
        "WhatTime": ""
    },    
    { 
        "name": "nihao",
        "content": "nǐ hǎo",
        "timeSensitive": false,
        "WhatTime": ""
    },
    { 
        "name": "olaoialo",
        "content": "olá, oi, alô",
        "timeSensitive": false,
        "WhatTime": ""
    },
    { 
        "name": "privet",
        "content": "privet",
        "timeSensitive": false,
        "WhatTime": ""
    },
    { 
        "name": "salam",
        "content": "salām",
        "timeSensitive": false,
        "WhatTime": ""
    },
    // #endregion ------------------------------------------------------------------------------------------------
    
    // #region -----[ Funny Pack ]--------------------------------------------------------------------------------
    { 
        "name": "ahoy",
        "content": "Ahoy",
        "timeSensitive": false,
        "WhatTime": ""
    },
    { 
        "name": "ello",
        "content": "Ello",
        "timeSensitive": false,
        "WhatTime": ""
    },
    { 
        "name": "howdy",
        "content": "Howdy",
        "timeSensitive": false,
        "WhatTime": ""
    },
    { 
        "name": "peekaboo",
        "content": "Peek-a-boo",
        "timeSensitive": false,
        "WhatTime": ""
    },
    { 
        "name": "sup",
        "content": "Sup",
        "timeSensitive": false,
        "WhatTime": ""
    },
    { 
        "name": "whaddup",
        "content": "Whaddup",
        "timeSensitive": false,
        "WhatTime": ""
    },
    { 
        "name": "whatskickin",
        "content": "What's kickin",
        "timeSensitive": false,
        "WhatTime": ""
    },
    // #endregion ------------------------------------------------------------------------------------------------

    // #region -----[ General Pack ]------------------------------------------------------------------------------
    { 
        "name": "salutations",
        "content": "Salutations",
        "timeSensitive": false,
        "WhatTime": ""
    },
    { 
        "name": "welcome",
        "content": "Welcome",
        "timeSensitive": false,
        "WhatTime": ""
    },
    // #endregion ------------------------------------------------------------------------------------------------

    // #region -----[ TimeSensitive Pack ]------------------------------------------------------------------------
    { 
        "name": "goodafternoon",
        "content": "Good Afternoon",
        "timeSensitive": true,
        "WhatTime": "afternoon"
    },
    { 
        "name": "goodevening",
        "content": "Good Evening",
        "timeSensitive": true,
        "WhatTime": "evening"
    },
    { 
        "name": "goodmorning",
        "content": "Good Morning",
        "timeSensitive": true,
        "WhatTime": "morning"
    },
    // #endregion ------------------------------------------------------------------------------------------------
]

export function GreetingIO(GreetingsPalette) {
    /* OLD: VERSION 2.7.0
    // Check to see if it's time sensitive
    if (Greetings[randGreetingNum].timeSensitive) {
        // Check for time
        var isTime = false
        
        // Conditional
        if (isTime) {
            // Good
            randGreetingNum = Math.floor(Math.random() * Greetings.length)

            setGreeting(`${Greetings[randGreetingNum].content}, `)
        } else {
            // Not in time\
            // eslint-disable-next-line
            randGreetingNum = Math.floor(Math.random() * Greetings.length)

            setGreeting(`${Greetings[randGreetingNum].content}, `)
        }
    }
    */
    
    // #region ----[ SETUP ]---------------------------------------------------------------------------------------
    // Pick a random greeting
    var randGreetingNum = Math.floor(Math.random() * Greetings.length)
    let result = "Greetings, "
    const dateandtime = new Date()
    //const day = dateandtime.getDay()
    const hour = dateandtime.getHours()
    //const minute = dateandtime.getMinutes()
    // #endregion -------------------------------------------------------------------------------------------------

    // #region ----[ COMPUTE ]-------------------------------------------------------------------------------------
    // Determine if greeting is time sensitive
    if (GreetingsPalette[randGreetingNum].timeSensitive) {
        // --- Determine if it's within the time ------------------------------------------------------------------
        //console.log(GreetingsPalette[randGreetingNum].WhatTime)
        switch (GreetingsPalette[randGreetingNum].WhatTime) {
            case 'morning':
                if (hour >= 0 && hour < 12) {
                    result = `${GreetingsPalette[randGreetingNum].content}, `
                }
            break

            case 'afternoon':
                if (hour >= 12 && hour < 18) {
                    result = `${GreetingsPalette[randGreetingNum].content}, `
                }
            break

            case 'evening':
                if (hour >= 18 && hour < 21) {
                    result = `${GreetingsPalette[randGreetingNum].content}, `
                }
            break  

            default: 
                // Night time; normal greeting                
                result = `${GreetingsPalette[randGreetingNum].content}, `
            break
        }
        // --------------------------------------------------------------------------------------------------------        
    } else {
        result = `${GreetingsPalette[randGreetingNum].content}, `
    }
    // #endregion -------------------------------------------------------------------------------------------------

    // #region ----[ RETURN ]--------------------------------------------------------------------------------------
    // Return the greeting with greeting number chosen randomly
    //return `Greetings (${randGreetingNum}), `

    // Return the greeting
    return result
    // #endregion ------------------------------------------------------------------------------------------------- 
}
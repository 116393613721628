// eslint-disable-next-line
import { useEffect } from 'react'
import parse from 'html-react-parser'

export function NatchParse(data) {
    // Bold Selector - Find what's in between **, replace with <b></b>, remove remaining *
    data = data?.replace(/(?<=\*)(.*?)(?=\*)/ig, '<b>$1</b>').replaceAll('*', '')

    // Break Selector - Find double space, replace with <br />
    data = data?.replace(/\s\s/ig, '<br />')

    // Underline Selector - Find what's in between _, replace with <u></u>, remove remaining _
    // eslint-disable-next-line
    data = data?.replace(/(?<=\_)(.*?)(?=\_)/ig, '<span style=\'text-decoration: underline;\'>$1</span>').replaceAll('_', '')

    // list('personal space', '...personal space', 'don't get in my personal space, 'I don't remember the other two')
    // WIP

    // #region --------------------[ URL Selector ]----------------------------------------------------------------------------
    // Set up variables
    // eslint-disable-next-line
    const URLSelector = data // save data as a variable to avoid mutating (???)
    const searchKeyword = /\[(.*?)\]\((.+?)\)/g // search for this    

    data = data?.replace(searchKeyword, '<a href=\'$2\' target=\'_blank\'>$1</a>')
    // #endregion -------------------------------------------------------------------------------------------------------------

    if (data) {
        data = parse(data)
    }

    // Return the slab!
    return data
}
// [][][][][][][][][][][][][][][][][][][][][ START IMPORTS ][][][][][][][][][][][][][][][][][][][][][][][][][][][]
// ####################### GLOBAL ############################################################################
// Import from React
// eslint-disable-next-line
import { useState, useEffect } from 'react'

// Import the GlobalContext
import { GlobalContext } from '../context/globalContext'

// Import Toast
// eslint-disable-next-line
import { toast } from 'react-toastify'

// Import Auth
import { useAuth } from '../middleware/contextHooks'

// Import reactDOM
import { useNavigate } from 'react-router-dom'
// ####################### END OF GLOBAL #####################################################################

// ####################### SPECIFIC ##########################################################################
// Import from React
import { useContext } from 'react'

// Import from MUI
import {
    
} from '@mui/material'

import {Zoom, Slide, Bounce, Flip} from 'react-toastify'

// #region -----------------------[ Functions ]-------------------------------------------------------------------
function transitionAnimation () {
    const list = [Zoom, Slide, Bounce, Flip];
    return list[Math.floor(Math.random() * list.length)]
}

function transitionPosition () {
    const list = ['top-right', 'top-center', 'top-left']
    return list[Math.floor(Math.random() * list.length)]
}
// #endregion ----------------------------------------------------------------------------------------------------
// ####################### END OF SPECIFIC ##################################################################
// [][][][][][][][][][][][][][][][][][][][][ END IMPORTS ][][][][][][][][][][][][][][][][][][][][][][][][][][][][]

export default function HeaderOnlyTS (props) { 
    // [][][][][][][][][][][][][][][][][][][][][ START SETUP ][][][][][][][][][][][][][][][][][][][][][][][][][][]
    // ####################### GLOBAL ############################################################################
    // #region -------------[ Variables ]-------------------------------------------------------------------------
    
    // Get user logged
    // eslint-disable-next-line
    const {
        
    } = useAuth()   

    // Set navigate
    // eslint-disable-next-line
    const navigate = useNavigate()

    // #endregion ------------------------------------------------------------------------------------------------
    // #region --------------( FUNCTIONS )------------------------------------------------------------------------
    
    // #endregion ------------------------------------------------------------------------------------------------
    // ####################### END OF GLOBAL #####################################################################

    // ####################### SPECIFIC ##########################################################################
    // #region -------------[ Variables ]-------------------------------------------------------------------------
    // ThemeSystem
    const { theme, themeSwitchHandler } = useContext(GlobalContext)
    // #endregion -----------------------------------------------------------------------------------------------
    // #region -------------[ Functions ]------------------------------------------------------------------------
    // State Rendering
    useEffect(() => {
        // Save to local storage
        window.localStorage.setItem("theme", theme)
    }, [ theme ])
    // #endregion -----------------------------------------------------------------------------------------------
    // ####################### END OF SPECIFIC ##################################################################
    // [][][][][][][][][][][][][][][][][][][][][ ENDSETUP ][][][][][][][][][][][][][][][][][][][][][][][][][][][]

    // Render
    return (
        <>
        <props.skeleton.CustomToastContainer
            position={transitionPosition()} autoClose={2000}
            hideProgressBar={false} newestOnTop closeOnClick
            rtl={false} pauseOnFocusLoss={false} draggable pauseOnHover
            transition={transitionAnimation()}
        />
        <props.skeleton.NavBar stickytop>
            <props.globalskeleton.CustomContainer>
                <props.skeleton.Bar>
                    <props.globalskeleton.Flex center="true">
                        {
                            // #region ----------------------[ 2. The onChange prop of the switch button toggles the theme between dark and light ]-------------------------------
                            // NOTE: Better way would be to create a separate button to call for different themes as we already have a switch-case statement to select the theme in
                            //       Layout.js
                            <props.skeleton.SwitchButton>
                                <input
                                    type='checkbox'
                                    onChange={
                                        () => themeSwitchHandler(theme === "dark" ? "light" : "dark")
                                    }
                                    checked={
                                        (theme === "dark") ? "checked" : ""
                                    }
                                    
                                />
                                <span></span>                                
                            </props.skeleton.SwitchButton>
                            // #endregion ----------------------------------------------------------------------------------------------------------------------------------------
                        }
                    </props.globalskeleton.Flex>
                </props.skeleton.Bar>
            </props.globalskeleton.CustomContainer>
        </props.skeleton.NavBar>
        </>
    )
}
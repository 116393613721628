// [][][][][][][][][][][][][][][][][][][][][ START IMPORTS ][][][][][][][][][][][][][][][][][][][][][][][][][][][]
// ####################### GLOBAL ############################################################################
// Import from React
// eslint-disable-next-line
import { useState, useEffect } from 'react'

// Import axios
//import axios from 'axios'

// Import Toast
// eslint-disable-next-line
import { toast } from 'react-toastify'

// Import Auth
import { useAuth } from '../../middleware/contextHooks'

// Import reactDOM
import { useNavigate } from 'react-router-dom'
// ####################### END OF GLOBAL #####################################################################

// ####################### SPECIFIC ##########################################################################
// Import globalStyles elements
//import { /*CustomContainer as Container*/ } from '../../themes/Darwinian2023/skeleton/global'

// Import from MUI
import {
    //Paper,
    //Button,
} from '@mui/material'

// eslint-disable-next-line
import Masonry from '@mui/lab/Masonry'

/*
// Import mainStyles
import { 
    //Bar,
     // eslint-disable-next-line
    Card,
    // eslint-disable-next-line
    CardActions,
    // eslint-disable-next-line
    CardContent,
    // eslint-disable-next-line
    CardHeader,
     // eslint-disable-next-line
    CustomCardMedia as CardMedia,
    // eslint-disable-next-line
    CustomGrid as Grid,
    //CustomBox,
    CustomLink,
    //CustomButton as Button,
    //CustomGrid as Grid,
    CustomItem as Item,
    //CustomLink,
    //CustomStack as Stack,
    Heading,
    //Para,    
    //CustomTextField as TextField
} from '../../themes/Darwinian2023/skeleton/main'
*/

// Import from Components
//import { Col } from '../../components/Col'
//import { InnerCol } from '../../components/InnerCol'
//import { Row } from '../../components/Row'
//import { Spacer } from './Spacer' 

//import ReactHtmlParser from 'react-html-parser'
// ####################### END OF SPECIFIC ##################################################################
// [][][][][][][][][][][][][][][][][][][][][ END IMPORTS ][][][][][][][][][][][][][][][][][][][][][][][][][][][][]

const parse = require('html-react-parser')

export function Triplet (props) { 
    // [][][][][][][][][][][][][][][][][][][][][ START SETUP ][][][][][][][][][][][][][][][][][][][][][][][][][][]
    // ####################### GLOBAL ############################################################################
    // #region -------------[ Variables ]-------------------------------------------------------------------------
    // Get user logged
    // eslint-disable-next-line
    const { 
        
    } = useAuth()   

    // Set navigate
    // eslint-disable-next-line
    const navigate = useNavigate()

    // #region --------------( FUNCTIONS )------------------------------------------------------------------------
    
    // #endregion ------------------------------------------------------------------------------------------------
    // ####################### END OF GLOBAL #####################################################################

    // ####################### SPECIFIC ##########################################################################
    // #region -------------[ Variables ]-------------------------------------------------------------------------

    // #endregion -----------------------------------------------------------------------------------------------
    // #region -------------[ Functions ]------------------------------------------------------------------------   
    // State Rendering
    useEffect(() => {
        
    }, [ ])
    // #endregion -----------------------------------------------------------------------------------------------
    // ####################### END OF SPECIFIC ##################################################################
    // [][][][][][][][][][][][][][][][][][][][][ ENDSETUP ][][][][][][][][][][][][][][][][][][][][][][][][][][][]

    // Render
    return (
        <>
        <props.skeleton.CustomGrid container gem="true" 
            color={props.color}
            padding={props.padding}        
        >
            <props.skeleton.CustomGrid item xs={12}>
                <props.skeleton.Heading nobg="true" h1 center padding10>Triple-Action (three-column) layout</props.skeleton.Heading>
            </props.skeleton.CustomGrid>

            <props.skeleton.CustomGrid item xs={12} sm={12} md={4} lg={4} gem="true" color={props.color}
                sx={{
                    //border: 'thin solid black',
                    padding: '10px'
                }}
            >
                {parse(props.leftcolumncontent) || "No content"}
            </props.skeleton.CustomGrid>
            <props.skeleton.CustomGrid item xs={12} sm={12} md={4} lg={4} gem="true" color={props.color}
                sx={{
                    //border: 'thin solid black',
                    padding: '10px',
                    textAlign: 'center'
                }}
            >
                {parse(props.centercolumncontent) || "No content"}
            </props.skeleton.CustomGrid>
            
            <props.skeleton.CustomGrid item xs={12} sm={12} md={4} lg={4} gem="true" color={props.color}
                sx={{
                    //border: 'thin solid black',
                    padding: '10px',
                    textAlign: 'right'
                }}
            >
                {parse(props.rightcolumncontent) || "No content"}
            </props.skeleton.CustomGrid>
        </props.skeleton.CustomGrid>
        </>
    )
}
// [][][][][][][][][][][][][][][][][][][][][ START IMPORTS ][][][][][][][][][][][][][][][][][][][][][][][][][][][]
// ####################### GLOBAL ############################################################################
// Import from React
// eslint-disable-next-line
import { useState, useEffect, useCallback } from 'react'

// Import Toast
// eslint-disable-next-line
import { toast } from 'react-toastify'

// Import Auth
import { useAuth } from '../../middleware/contextHooks'

// Import reactDOM
import { useNavigate } from 'react-router-dom'
// ####################### END OF GLOBAL #####################################################################

// ####################### SPECIFIC ##########################################################################
// Import Icons
//import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined'
//import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined'
import LongSliceIcon from '@mui/icons-material/PostAdd'
import ShortSliceIcon from '@mui/icons-material/Message'
import StillPostIcon from '@mui/icons-material/Image'
import VideoPostIcon from '@mui/icons-material/Videocam'

// Import from MUI
import {
    Checkbox,
    FormControlLabel,
    InputLabel,
    ListItemText,
    MenuItem,
    Slider,
    OutlinedInput,
    Typography,
} from '@mui/material'

// Import from MUI lab
import {
    TabContext,
    TabPanel
} from '@mui/lab'

// Import components
import { BreadCrumbs } from '../../components/BreadCrumbs'

// Import gsap (???)
import gsap from 'gsap'

// Import Lorem Ipsum
import {LoremIpsum} from 'lorem-ipsum'

// Import Post
import { useCategory, useCode, usePost, useTag } from '../../middleware/contextHooks'

// Import Transition
import { Transition } from 'react-transition-group'

// Import DragZone component
import { UploadDragZone } from '../../components/UploadDragZone'
import { Spacer } from '../../components/Addon/Spacer'
// ####################### END OF SPECIFIC ##################################################################
// [][][][][][][][][][][][][][][][][][][][][ END IMPORTS ][][][][][][][][][][][][][][][][][][][][][][][][][][][][]

// Variables
const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250
        }
    }
}

// Setup for loading cats and tags
let loadedcategories = []
let loadedtags = []
// ---------------------------------------------------------------------------------------------------------------

export default function New (props) { 
    // [][][][][][][][][][][][][][][][][][][][][ START SETUP ][][][][][][][][][][][][][][][][][][][][][][][][][][]
    // ####################### GLOBAL ############################################################################
    // #region -------------[ Variables ]-------------------------------------------------------------------------
    // Get user logged
    const {
        // Variables
        currentUser,  

        // Functions
    } = useAuth() 

    // Set navigate
    // eslint-disable-next-line
    const navigate = useNavigate()

    // #region -----------------[ State Variables ]---------------------------------------------------------------
    
    // #end region -----------------[ State Variables ]-----------------------------------------------------------

    // #endregion ------------------------------------------------------------------------------------------------
    // #region --------------( FUNCTIONS )------------------------------------------------------------------------
    
    // #endregion ------------------------------------------------------------------------------------------------
    // ####################### END OF GLOBAL #####################################################################

    // ####################### SPECIFIC ##########################################################################
    // #region -------------[ Variables ]-------------------------------------------------------------------------
    // Get userLogged
    //const {updateUser} = useAuth()

    // Set tabstates
    const [LongSlice, setLongSlice] = useState(true)
    // eslint-disable-next-line
    const [ShortSlice, setShortSlice] = useState(false)
    // eslint-disable-next-line
    const [StillPost, setStillPost] = useState(false)
    // eslint-disable-next-line
    const [VideoPost, setVideoPost] = useState(false)

    // Set currentSnippetLength
    const [currentSnippetLength, setCurrentSnippetLength] = useState(0)

    // Handle the changing of the tabs event
    const handleTabChange = (e, newValue) => {
        // Change tab state (to not mix functionality between the different posting types)
        // NOTE: If you don't, the browser will scream at you repeatedly in a weird language :P
        switch(newValue) {
            case '1':
                // Turn on: LongSlice
                setLongSlice(true)

                // Turn off everything else
                setShortSlice(false)
                setStillPost(false)
                setVideoPost(false)
            break

            case '2':
                // Turn on: ShortSlice
                setShortSlice(true)
                
                // Turn off everything else
                setLongSlice(false)
                setStillPost(false)
                setVideoPost(false)
            break

            case '3':
                // Turn on: StillPost
                setStillPost(true)

                // Turn off everything else
                setLongSlice(false)
                setShortSlice(false)
                setVideoPost(false)
            break

            case '4':
                // Turn on: LongSlice
                setVideoPost(true)

                // Turn off everything else
                setLongSlice(false)
                setShortSlice(false)
                setStillPost(false)
            break

            default:             
                setLongSlice(false)
                setShortSlice(false)
                setStillPost(false)
                setVideoPost(false)
            break
        }

        setTabValue(newValue)
    }

    /* DEPRECATED < 2.4.71 : load context "conditionally"
    // Use the post context
    const {        
        // Variables
        isPosting,
        isPostingSent,
        NewPostSlug,
        PostFormIsDisabled,
        PostingSent,
        
        //postCreated, 
        //posts,

        // Functions
        createPost,
        setIsPosting,
        //setIsPostingSent,
        setNewLongSliceSlug,
        setPostFormIsDisabled,
    } = usePost()
    */

    // UPDATE 2.4.71 : Load contexts "conditionally"
    // Set up the slice
    var slice = props.tertiary

    // Set up Modules
    const Modules = props.Modules

    // Set up variables
    let query
    let queryCapital
    let resources

    // Set all the resources from the contexts
    const resourcesCategory = useCategory()
    const resourcesCode = useCode()
    const resourcesPost = usePost()
    const resourcesTag = useTag()

    // Conditional setting resources
    switch (slice) {
        case 'Categories':
            query="category"
            queryCapital="Category"
            resources=resourcesCategory
        break
        case 'Codes':
            query="code"
            queryCapital="Code"
            resources=resourcesCode
        break
        case 'Posts':
            query="longpost"
            queryCapital="Post"
            resources=resourcesPost
        break
        case 'Tags':
            query="tag"
            queryCapital="Tag"
            resources=resourcesTag
        break
        default: 
            /* ??? No resource; what are we doing here? */
        break
    }

    // Set up breadcrumbs here
    const BCItems = [
        {
            'name': 'Dashboard',
            'type': 'link',
            'grayed': true,
            "url": '/dashboard',
        }, 
        {
            'name': `${slice}`,
            'type': 'link',
            'grayed': true,
            'url': `/dashboard/${slice.toLowerCase()}`
        },
        {
            'name': `Add New ${queryCapital}`,
            'type': 'text',
            'grayed': false,
            'url': '/dashboard/posts/new'
        },
    ]

    // ######region ################[ LongSlice ]##################################################################
    
    // Post type    
    // eslint-disable-next-line
    const handlePostTypeChange = (event) => {
        //setPostType(event.target.value)
        toast.info(`wip`, { theme: 'dark' })
    }
    
    // Set status state
    const [pstatus, setPstatus] = useState(true)

    // State variables for newLongSlice
    const [newLongSlice, setNewLongSlice] = useState({
        category: '', 
        content: '', 
        namespace: 'local', 
        imageUrl: '', 
        slug: '', 
        snippet: '', 
        status: (
            (slice !== "Posts")
            ? (
                (pstatus)
                ? 'active'
                : 'inactive'
            )
            : (
                (pstatus)
                ? 'published'
                : 'draft'
            )
        ), 
        tags: '', 
        title: ''
    })
    
    // #region --------------------[ PLUGIN: LoremImpsum Generate ]----------------------------------------------
    // onGenerate
    const [ onGenerate, setOnGenerate ] = useState(false)

    // Lorem Ipsum options
    const [loremOptions, setLoremOptions] = useState({
        minWordPerSentence: 3,
        maxWordPerSentence: 16,
        wordPerSentence: 4,

        minSentencePerParagraph: 4,
        maxSentencePerParagraph: 20,
        sentencePerParagraph: 5,

        minParagraphPerPost: 2,
        maxParagraphPerPost: 10,
        paragraphPerPost: 3,
    })
    // #endregion -----------------------------------------------------------------------------------------------

    // Get Category
    const {
        // Functions
        getAllCategories, 
        getAllCategoriesPublically,
        getMyCategories, 

        // Variables
        categories,
    } = useCategory()

    // Get Category
    const {
        // Functions
        getAllTags,
        getAllTagsPublically, 
        getMyTags, 

        // Variables
        tags
    } = useTag()

    // Set state of tabs
    const [tabValue, setTabValue ] = useState('1')

    // Set state of publishGlobally
    const [ publishGlobally, setPublishGlobally ] = useState(false)
    
    // Set category
    const [selectedCategories, setSelectedCategories] = useState([])

    // Set tags state
    const [selectedTags, setSelectedTags] = useState([])

    // Set visiblity
    const [visibility, setVisibility] = useState('public')

    // Set imageUrl
    // eslint-disable-next-line
    const [imageUrl, setImageUrl] = useState(null)

    // Set state for post image
    // eslint-disable-next-line
    //const [ files, setFiles ] = useState([])

    /*
    // Set up dropzone
    const { getRootProps, getInputProps } = useDropzone({
        accept: {
            "image/*": ['.jpeg','.jpg','.png']
        },
        onDrop: (acceptedFiles) => {
            setFiles(
                acceptedFiles.map((file) => Object.assign(file, {
                    preview: URL.createObjectURL(file)
                }))
            )

            // Set imageUrl state
            setImageUrl(files[0]?.name)

            // Save to the newLongSlice
            setNewLongSlice({...newLongSlice, imageUrl: files[0]?.name})
        }
    })

    // Create images for dropzone
    const images = files.map((file) => (
        <div key={file.name}>
            <div>
                <img src={file.preview} style={{ width: "200px"}} alt="preview" />
            </div>
        </div>
    ))
    */

    // #endregion -----------------------------------------------------------------------------------------------
    // #region -------------[ Functions ]------------------------------------------------------------------------
    // Handle changing categories
    const handleCategoryChange = (e) => {
        // Get switch input
        const {
            target: { value },
        } = e

        //console.log("value: "+value)

        // Set category state
        setSelectedCategories(
            // On autofill we get a stringified value.
            typeof value === 'string' ? value.split(',') : value,
            //value
        )

        // Update NewPost state
        setNewLongSlice({...newLongSlice, category: selectedCategories})
    }
    
    // Handle publishGlobally switch
    const handleChangeStatus = (e) => {
        setPstatus(!e.target.checked)

        // Update the NewPost state
        setNewLongSlice({
            ...newLongSlice, 
            status: (
                (slice !== "Posts")
                ? (
                    (!pstatus) 
                    ? "inactive" 
                    : "active"
                )
                : (
                    (!pstatus) 
                    ? "draft" 
                    : "published"
                )
                
            )
        })
    }

    // Handle visibility dropdown
    const handleChangeVisibility = (e) => {
        setVisibility(e.target.value)

        // Update the post state
        setNewLongSlice({...newLongSlice, visibility: visibility})
    }
    
    // Handle Create New Category
    const handleCreateNewCategory = () => {
        //alert('wip')
        navigate("../../dashboard/categories/new")
    }

    // Handle Create New Tag
    const handleCreateNewTag = () => {
        //alert('wip')
        navigate("../../dashboard/tags/new")
    }

    // Handle function for preview
    const handlePreview = () => {
        toast.info(`WIP: Preview post.`, {theme: 'dark'})
        //createPost(newLongSlice)
    }

    // Handle function for saving longslice
    const handleSendLongSlice = () => {
        // Set Posting
        resources.setIsCreatingSlice(true)

        // Set form disabled
        resources.setSliceFormIsDisabled(true)

        // Set NewSliceSlug
        if (slice === "Posts") {
            resources.setNewLongSliceSlug(newLongSlice?.slug?.toLowerCase())
        } else {
            resources.setNewSliceSlug(newLongSlice?.slug?.toLowerCase())
        }
    }

    // Set from title field 
    const handleSetSlugFieldFromTitle = () => {
        // Set up a variable to hold our generated slug from title
        let generatedSlug

        if (LongSlice) { generatedSlug = newLongSlice.title || newLongSlice.code }
        if (ShortSlice) { generatedSlug = newLongSlice.content }

        // Replace
        generatedSlug = generatedSlug?.replace(/\s/g, '-')
        generatedSlug = generatedSlug?.replace('#', '--Num--')
        generatedSlug = generatedSlug?.replace('!', '--Ex--')
        generatedSlug = generatedSlug?.replace('/', '--Slash--')
        generatedSlug = generatedSlug?.replace(',', '--Comma--')

        if (ShortSlice) {
            generatedSlug = generatedSlug?.replace('?', '--QuesMar--')
        }


        generatedSlug = generatedSlug?.toLowerCase()

        if (ShortSlice) {
            generatedSlug = generatedSlug + "-short-post"
        }        

        if (LongSlice) {
            // Set the state of slug
            setNewLongSlice({...newLongSlice, slug: generatedSlug})
        } else if (ShortSlice) {
            // Set the state of slug
            setNewShortSlice({...newShortSlice, slug: generatedSlug})
        }
    }
    
    // Set SlugField itself
    const handleSetSlugField = () => {
        // Set up a variable to hold our generated slug from the inputted slug
        let generatedSlug = newLongSlice.slug

        // Replace
        generatedSlug = generatedSlug?.replace(/\s/g, '-')
        generatedSlug = generatedSlug?.replace('#', '--Num--')
        generatedSlug = generatedSlug?.replace('!', '--Ex--')
        generatedSlug = generatedSlug?.replace('/', '--Slash--')
        generatedSlug = generatedSlug?.replace(',', '--Comma--')
        
        if (ShortSlice) {
            generatedSlug = generatedSlug?.replace('?', '--QuesMar--')
        }
        
        generatedSlug = generatedSlug?.toLowerCase()
        
        if (ShortSlice) {
            generatedSlug = generatedSlug + "-short-post"
        }

        if (LongSlice) {
            // Set the state of slug
            setNewLongSlice({...newLongSlice, slug: generatedSlug})
        } else if (ShortSlice) {
            // Set the state of slug
            setNewShortSlice({...newShortSlice, slug: generatedSlug})
        }
    }

    // #region --------------------[ PLUGIN: LoremImpsum Generate ]----------------------------------------------
    const handleGenerate = () => {
        const lorem = new LoremIpsum({
            sentencesPerParagraph:{
                max: loremOptions.maxSentencePerParagraph,
                min: loremOptions.minSentencePerParagraph
            },
            wordsPerSentence: {
                max: loremOptions.maxWordPerSentence,
                min: loremOptions.minWordPerSentence
            }
        })

        setNewLongSlice({
            title: lorem.generateSentences(1),
            content: lorem.generateParagraphs(loremOptions.paragraphPerPost),
        })     
    }
    // #endregion -----------------------------------------------------------------------------------------------

    // Handle publishGlobally switch
    const handlePublishGlobally = (e) => {
        setPublishGlobally(e.target.checked)

        // Update the NewSlice state
        setNewLongSlice({...newLongSlice, namespace: (!publishGlobally) ? "global" : "local"})
    }

    // Handle changing tags
    const handleTagsChange = (e) => {
        // Get switch input
        const {
            target: { value },
        } = e

        //console.log("value: "+value)

        // Set category state
        setSelectedTags(
            // On autofill we get a stringified value.
            typeof value === 'string' ? value.split(',') : value,
        )

        // Update NewSlice state
        setNewLongSlice({...newLongSlice, tags: selectedTags})

        //console.log("newLongSlice.tags = "+newLongSlice.tags)
    }

    // State management for getting categories
    useEffect(() => {
        if (LongSlice) {
            //console.log("LongSlice detected")

            // 
            if (currentUser) {
                if (currentUser.role) {
                    switch (currentUser.role) {
                        case 'superadmin': 
                            //console.log('getting categories using superadmin permission')
                            getAllCategories(null, 'any')         
                            getAllTags(null, 'any')                
                        break
                        
                        case 'admin':
                            //console.log('getting categories using admin permission')
                            getAllCategories(null, 'any')
                            getAllTags(null, 'any')
                        break
    
                        case 'editor':
                            //console.log('getting categories using editor permission')
                            getAllCategoriesPublically(null, 'any')
                            getAllTagsPublically(null, 'any')
                        break
    
                        case 'user':
                            //console.log('getting categories using user permission')
                            getMyCategories()
                            getMyTags()
                        break
    
                        default: 
                            //console.log('getting categories using user (default) permission')
                            getMyCategories()
                            getMyTags()
                        break
                    }
                }
            }
        }
        // eslint-disable-next-line
    }, [
        // TEMP FIX: Leave commented out of the dependancy as it will keep getting the data and remove the selection
        //categories,
        //tags
    ])

    // State management for setting up categories and tags
    useEffect(() => {
        // Setup variables
        let categoryInDBString = ""
        let categoriesIndex = 1        
        let tagsInDBString = ""
        let tagsIndex = 1       
        let trueLoadedCategories = [] 
        let trueLoadedTags = []

        // only if categories are there
        if (categories) {
            // DEBUG: Display categories array
            //console.log("there are: "+categories)

            // Parse through array of categories
            Array.from(categories).forEach((ele) => {

                // Push to loadedcategories
                loadedcategories.push(
                    [
                        {
                            'title': ele?.title, 
                            'type': ele?.namespace,
                            'author': ele?.authorDetails[0]?.userName
                        }
                    ]
                )

                // Version 2.3.2 Update - if category loaded (ele.title) is in the post's category array (currentSlice[0].category), select it?
                //setSlice({...post, category: loadedcategories[ele].title})
                //console.log(resources.currentSlice)
                if (resources.currentSlice) { 
                    //console.log(resources.currentSlice)                
                    //console.log(ele.title)
                    //console.log(resources.currentSlice.category.indexOf(ele.title))
                    
                    // Check for categories in the slice
                    if (resources.currentSlice?.category?.indexOf(ele.title) > -1) {
                        // Found category in post's array
                        //console.log(ele.title + " is found in the post's category array")

                        // Add category to a comma separated list
                        //console.log("categoriesIndex: "+categoriesIndex)
                        //console.log("resources.currentSlice?.category.length: "+ resources.currentSlice.category.length)
                        if (categoriesIndex < resources.currentSlice?.category?.length) {
                            categoryInDBString += ele.title+","
                        } else {
                            categoryInDBString += ele.title
                        }  
                    }


                }
            })

            // DEBUG: Display loadedcategories
            //console.log(loadedcategories)

            // DEBUG: Display categoryInDBString
            //console.log(categoryInDBString)

            // Filter categories by global and local based on permission
            Array.from(loadedcategories).forEach((ele) => {
                if (ele[0].type === "global") {
                    // Remap
                    trueLoadedCategories.push(ele[0].title)
                }
                
                if (ele[0].type === "local") {
                    // --- Determine if the category is owned by user ------------------------------------------
                    // Set up variables
                    let ownedCat = false

                    // Compare author to logged user
                    if (ele[0].author === currentUser.userName) {
                        ownedCat = true
                    }

                    // Do they own this category?
                    if (ownedCat) {
                        // Remap 
                        trueLoadedCategories.push(ele[0].title)
                    }
                    // -----------------------------------------------------------------------------------------
                }
            })

            // Set the loadedcategories
            loadedcategories = trueLoadedCategories

            // Set category state (emulate like the category dropdown)
            setSelectedCategories(
                // On autofill we get a stringified value.
                typeof categoryInDBString === 'string' ? categoryInDBString.split(',') : categoryInDBString,
                    //value
            )

            //console.log(selectedCategories)

            //console.log(loadedcategories)
        }

        // only if tags are there
        if (tags) {
            // DEBUG: Display tags array
            //console.log("there are: "+tags)

            // Parse through array of categories
            Array.from(tags).forEach((ele) => {

                // Push to loadedcategories
                loadedtags.push(
                    [
                        {
                            'title': ele?.title, 
                            'type': ele?.namespace,
                            'author': ele?.authorDetails[0]?.userName
                        }
                    ]
                )

                // Version 2.3.2 Update - if category loaded (ele.title) is in the post's category array (currentSlice[0].category), select it?
                //setSlice({...post, category: loadedcategories[ele].title})
                //console.log(resources.currentSlice)
                if (resources.currentSlice) { 
                    //console.log(resources.currentSlice)                
                    //console.log(ele.title)
                    //console.log(resources.currentSlice.category.indexOf(ele.title))
                    
                    // Check for tags in the slice
                    if (resources.currentSlice?.tags?.indexOf(ele.title) > -1) {
                        // Found tag in post's array
                        //console.log(ele.title + " is found in the post's tag array")

                        // Add category to a comma separated list
                        //console.log("tagsIndex: "+categoriesIndex)
                        //console.log("resources.currentSlice?.tags.length: "+ resources.currentSlice.tags.length)
                        if (tagsIndex < resources.currentSlice?.tags?.length) {
                            tagsInDBString += ele.title+","
                        } else {
                            tagsInDBString += ele.title
                        }  
                    }


                }
            })

            // DEBUG: Display loadedcategories
            //console.log(loadedtags)

            // DEBUG: Display tagsInDBString
            //console.log(tagsInDBString)

            // Filter tags by global and local based on permission
            Array.from(loadedtags).forEach((ele) => {
                if (ele[0].type === "global") {
                    // Remap
                    trueLoadedTags.push(ele[0].title)
                }
                
                if (ele[0].type === "local") {
                    // --- Determine if the tag is owned by user ------------------------------------------
                    // Set up variables
                    let ownedCat = false

                    // Compare author to logged user
                    if (ele[0].author === currentUser.userName) {
                        ownedCat = true
                    }

                    // Do they own this tag?
                    if (ownedCat) {
                        // Remap 
                        trueLoadedTags.push(ele[0].title)
                    }
                    // -----------------------------------------------------------------------------------------
                }
            })

            // Set the loadedtags
            loadedtags = trueLoadedTags

            // Set tags state (emulate like the category dropdown)
            setSelectedTags(
                // On autofill we get a stringified value.
                typeof tagsInDBString === 'string' ? tagsInDBString.split(',') : tagsInDBString,
                    //value
            )

            //console.log(selectedTags)

            //console.log(loadedtags)
        }
        // eslint-disable-next-line
    }, [
        categories,
        tags
    ])

    /* DEPRECATED < 2.7.6
    // State Management for loading categories
    useEffect(() => {
        // Determine 
        if (LongSlice) {
            // Load categories into loadedcategories array
            if (!categories && !categoriesCreated) {
                // Categories not loaded; get the categories by currentUser.role
                if (currentUser) {
                    if (currentUser.role) {
                        switch (currentUser.role) {
                            case 'admin':
                                getAllCategories(null, 'any')
                            break

                            case 'editor':
                                getAllCategoriesPublically(null, 'any')
                            break

                            case 'user':
                                getMyCategories()
                            break

                            default: 
                                getMyCategories()
                            break
                        }
                    }
                }
            } else {
                // Categories loaded; Push to array
                if (loadedcategories.length < 1) {
                    // Set up variables
                    let categoriesInDBString = "Uncategorized"

                    // Parse through array of categories
                    Array.from(categories).forEach((ele) => {
                        // Push to loadedcategories
                        loadedcategories.push(ele?.title)
                    })

                    // Set category state (emulate like the category dropdown)
                    setSelectedCategories(
                        // On autofill we get a stringified value.
                        typeof categoriesInDBString === 'string' ? categoriesInDBString.split(',') : categoriesInDBString,                        
                    )
                }
            }
        }
    }, [ 
        categories, 
        categoriesCreated, 
        currentUser, 
        getAllCategories, 
        getAllCategoriesPublically, 
        getMyCategories, 
        LongSlice
    ])

    // State Management for loading tags
    useEffect(() => {
        // Determine 
        if (LongSlice) {
            // Load tags into loadedtags array
            if (!tags && !tagsCreated) {
                // Tags not loaded; get the tags by currentUser.role
                if (currentUser) {
                    if (currentUser.role) {
                        switch (currentUser.role) {
                            case 'admin':
                                getAllTags(null, 'any')
                            break

                            case 'editor':
                                getAllTagsPublically(null, 'any')
                            break

                            case 'user':
                                getMyTags()
                            break

                            default: 
                                getMyTags()
                            break
                        }
                    }
                }
            } else {
                // Tags loaded; Push to array
                if (loadedtags.length < 1) {
                    // Set up variables
                    let tagsInDBString = "Untagged"
                    
                    // Parse through array of categories
                    Array.from(tags).forEach((ele) => {
                        // Push to loadedcategories
                        loadedtags.push(ele?.title)
                    })

                    // Set category state (emulate like the category dropdown)
                    setSelectedTags(
                        // On autofill we get a stringified value.
                        typeof tagsInDBString === 'string' ? tagsInDBString.split(',') : tagsInDBString,                        
                    )
                }
            }
        }
    }, [ 
        tags, 
        tagsCreated,
        currentUser, 
        getAllTags, 
        getAllTagsPublically,
        getMyTags, 
        LongSlice, 
        setSelectedTags 
    ])
    */

    /* DEPRECATED < 2.4.72
    // State Management for loading categories
    useEffect(() => {
        if (LongSlice) {
            // Load categories into loadedcategories array
            if (!categories) {
                // Check what role
                if (currentUser) {
                    if (currentUser.role) {
                        switch (currentUser.role) {
                            case 'admin':
                                getAllCategories(null, 'any')
                            break
        
                            case 'editor':
                                getAllCategories(null, 'any')
                            break
        
                            case 'user':
                                getMyCategories()
                            break
        
                            default: 
                                getMyCategories()
                            break
                        }
                    }
                }
            }
        }
    }, [ categories, currentUser, currentUser?.role, getAllCategories, getMyCategories, LongSlice ])

    // State Management for setting categories
    useEffect(() => {
        //console.log("isDoneLoadingCategories: "+isDoneLoadingCategories)
        if (categories && !isDoneLoadingCategories ) {
            Array.from(categories).forEach(ele => {
                // push to loadedcategories
                loadedcategories.push(ele.title)
                //console.log(ele.title)
            })

            setIsDoneLoadingCategories(true)
        }
    }, [ categories, isDoneLoadingCategories, setIsDoneLoadingCategories ])
    */

    /* DEPRECATED < 2.4.72
    // State Management for loading categories
    useEffect(() => {
        if (LongSlice) {
            // Load categories into loadedcategories array
            if (!tags) {
                // Check what role
                if (currentUser) {
                    if (currentUser.role) {
                        switch (currentUser.role) {
                            case 'admin':
                                getAllTags(null, 'any')
                            break
        
                            case 'editor':
                                getAllTags(null, 'any')
                            break
        
                            case 'user':
                                getMyTags()
                            break
        
                            default: 
                                getMyTags()
                            break
                        }
                    }
                }
            }
        }
    }, [ tags, currentUser, currentUser?.role, getAllTags, getMyTags, LongSlice ])

    // State Management for setting categories
    useEffect(() => {
        //console.log("isDoneLoadingTags: "+isDoneLoadingTags)
        if (tags && !isDoneLoadingTags ) {
            Array.from(tags).forEach(ele => {
                // push to loadedtags
                loadedtags.push(ele.title)
                //console.log(ele.title)
            })

            setIsDoneLoadingTags(true)
        }
    }, [ tags, isDoneLoadingTags, setIsDoneLoadingTags ])
    */

    // State management for categories, tags, and uploadFiles
    // NOTE: This is required to send the right category and tags to the api backend
    useEffect(() => {
        if ((selectedCategories && selectedCategories.length > 0) || (selectedTags && selectedTags.length > 0) || (resources.uploadFiles && resources.uploadFiles.length > 0)) {
            setNewLongSlice({ ...newLongSlice, 
                category: selectedCategories, 
                imageUrl: (resources.uploadFiles) ? resources.uploadFiles[0]?.name : (imageUrl) ? imageUrl : null, 
                tags: selectedTags, 
                visibility: visibility 
            })
        }
    // eslint-disable-next-line
    }, [ resources, selectedCategories, resources.uploadFiles, selectedTags, imageUrl, visibility, resources.uploadFiles ])

    // State management for creating post
    useEffect(() => {
        if (LongSlice) {
            if (resources.isCreatingSlice && !resources.isCreatingSliceSent) {
                // Create the post
                //console.log(newLongSlice)
                
                resources.createSlice(query, newLongSlice)

                // Set isCreatingSliceSent - deprecated (handle on create post context)
                //setIsCreatingSliceSent(true)
            }
        }
    // eslint-disable-next-line
    }, [ query, resources.isCreatingSlice, resources.isCreatingSliceSent ])

    // State management for SliceFormIsDisabled
    useEffect(() => {
        if (resources.SliceFormIsDisabled) {
            resources.setSliceFormIsDisabled(false)
        }
    }, [ resources ])

    // State rendering for image adding
    useEffect(() => {
        if (resources.featuredImage) {
            // DEBUG: Display profileImage
            //console.log("featuredImage: "+resources.featuredImage)

            // Set the temp
            setNewLongSlice({ ...newLongSlice, imageUrl: resources.featuredImage })
        }
    }, [ newLongSlice, resources, setNewLongSlice ])

    // State for debugging
    useEffect(() => {
        if (LongSlice) {
            //console.log("featured image: "+newLongSlice.imageUrl)
            //console.log("--- DEBUG Variables ---") 
            //console.log("categories: "+categories)
            //console.log("tags:"+tags)  
            //console.log("selectedCategories: "+selectedCategories)
            //console.log("newLongSlice categories: "+newLongSlice.category)
            //console.log("selectedTags: "+selectedTags)
            //console.log("newLongSlice tags: "+newLongSlice.tags)
            //console.log("SliceFormIsDisabled: "+resources.SliceFormIsDisabled)
            //console.log("NewSliceSlug: "+NewSliceSlug)
            //console.log("isCreatingSlice: "+isCreatingSlice)
            //console.log("isCreatingSliceSent: "+isCreatingSliceSent)
            //console.log("Visibility: "+visibility)
            //console.log("-----------------------")
        }
    // eslint-disable-next-line
    }, [ 
        //categories, 
        LongSlice, 
        //newLongSlice, 
        resources, 
        //resources.isCreatingSlice, 
        //resources.isCreatingSlice, 
        //resources.NewSliceSlug,         
        //resources.isCreatingSliceSent, 
        //resources.uploadFiles,
        resources.SliceFormIsDisabled, 
        //tags, 
        //visibility 
    ])
    // #endregion -----------------------------------------------------------------------------------------------

    // ##### endregion ###############[ LongSlice ]###############################################################



    // ##### region ##################[ ShortSlice ]##############################################################
    // State variables for newShortSlice
    //const [newShortSlice, setNewShortSlice] = useState({ content: '' })

    // Handle function for saving
    const handleSendShortSlice = () => {
        // Set Posting
        resources.setIsCreatingSlice(true)

        // Set form disabled
        resources.setSliceFormIsDisabled(true)

        // -- Set the slug from the content
        // Set up a variable to hold our generated slug from the inputted slug
        var generatedSlug = newShortSlice.content

        // Replace
        generatedSlug = generatedSlug?.replace(/\s/g, '-')
        //generatedSlug = generatedSlug?.replace('#', '--Num--')
        generatedSlug = generatedSlug?.replace('!', '--Ex--')
        generatedSlug = generatedSlug?.replace('/', '--Slash--')
        generatedSlug = generatedSlug?.replace(',', '--Comma--')
        generatedSlug = generatedSlug?.replace('?', '--QuesMar--')

        // Remove tags
        generatedSlug = generatedSlug?.replace(/#.*/i, "")

        

        generatedSlug = generatedSlug?.toLowerCase()
        
        generatedSlug = generatedSlug + "-short-post"

        // Set the state of slug
        setNewShortSlice({...newShortSlice, slug: generatedSlug})
        // --------------------------------------------------------------

        // Set NewSliceSlug
        resources.setNewShortSliceSlug(newShortSlice?.slug?.toLowerCase())
    }

    // State variables for newShortSlice
    const [newShortSlice, setNewShortSlice] = useState({
        content: '', 
        namespace: 'local', 
        slug: '', 
        snippet: 'A shortpost creating from the client on the dashboard and nothing but a good time',
        title: 'Client-created short post generated via the dashboard',
        status: (
            (slice !== "Posts")
            ? (
                (pstatus)
                ? 'active'
                : 'inactive'
            )
            : (
                (pstatus)
                ? 'published'
                : 'draft'
            )
        ),
        type: 'shortpost'
    })


    // State management for creating shortslice
    useEffect(() => {
        if (ShortSlice) {
            if (resources.isCreatingSlice && !resources.isCreatingSliceSent) {
                // Create the post
                //console.log(newShortSlice)

                //toast.info(`WIP: Sent Short Post`, { theme: 'dark'})
                
                resources.createSlice('shortpost', newShortSlice)

                // Set isCreatingSliceSent - deprecated (handle on create post context)
                //setIsCreatingSliceSent(true)
            }           
        }
    // eslint-disable-next-line
    }, [ query, resources.isCreatingSlice, resources.isCreatingSliceSent ])
    // ##### endregion ###############[ ShortSlice ]##############################################################



    // ##### region ##################[ StillPost ]##############################################################

    // ##### endregion ###############[ StillPost ]##############################################################



    // ##### region ##################[ VideoPost ]##############################################################

    // ##### endregion ###############[ VideoPost ]##############################################################

    // ####################### END OF SPECIFIC ##################################################################
    // [][][][][][][][][][][][][][][][][][][][][ ENDSETUP ][][][][][][][][][][][][][][][][][][][][][][][][][][][]

    // Render
    return (
        <>
        <props.skeleton.InnerContent>
            <Spacer length={4} />
            <props.skeleton.CustomStack spacing={0}>
                <BreadCrumbs list={BCItems} skeleton={props.skeleton} />
                <props.skeleton.CustomGrid container spacing={0}>
                    <props.skeleton.CustomGrid item xs={12}>
                        <props.skeleton.CustomGrid container spacing={0} sx={{ 
                            //border: 'thin solid black', 
                            padding: '10px' 
                            }}>
                            <props.skeleton.CustomGrid item xs={12}>
                                {/* #region -----[ NOUGAT ]------------------------------------------------------------------------*/}
                                {
                                    /* 
                                    -- #region - Full column layout --------------------------------------------------------------------------------
                                    */
                                    <props.skeleton.CustomGrid item xs={12} sm={12} sx={{ height: '100%' }}>
                                        <props.skeleton.CustomStack direction="column" sx={{ alignItems: "center", justifyContent: "flex-start" }}>
                                            <TabContext value={tabValue}>
                                                {
                                                    // #region --------------[ base: TabMan ]---------------------------------------------------------------
                                                    (
                                                        Modules?.base?.TabMan 
                                                        && Modules?.base?.TabMan?.use 
                                                        && Modules?.base?.TabMan?.sections?.indexOf(slice) > -1
                                                    )
                                                    ? (
                                                        <props.skeleton.CustomBox sx={{ borderBottom: 1, borderColor: 'divider', maxwidth: 310 }} bgteritary="true">
                                                            <props.skeleton.CustomTabList stickybottom="true" centered onChange={handleTabChange} aria-label="PostTabs" sx={{display: {xs: 'none', sm: 'flex', md: 'flex', lg: 'flex'} }}>
                                                                <props.skeleton.CustomTab label="Long Post" value="1" />
                                                                <props.skeleton.CustomTab label="Short Post" value="2" />
                                                                <props.skeleton.CustomTab label="Still Post" value="3" />
                                                                <props.skeleton.CustomTab label="Video Post" value="4" />
                                                            </props.skeleton.CustomTabList>

                                                            <props.skeleton.CustomTabList stickybottom="true" centered onChange={handleTabChange} aria-label="PostTabs" sx={{ display: {xs: 'flex', sm: 'none', md: 'none', lg: 'none'} }}>
                                                                <props.skeleton.CustomTab icon={<LongSliceIcon />} value="1" />
                                                                <props.skeleton.CustomTab icon={<ShortSliceIcon />} value="2" />
                                                                <props.skeleton.CustomTab icon={<StillPostIcon />} value="3" />
                                                                <props.skeleton.CustomTab icon={<VideoPostIcon />} value="4" />
                                                            </props.skeleton.CustomTabList>
                                                        </props.skeleton.CustomBox>
                                                    )
                                                    : null
                                                    // #endregion --------------------------------------------------------------------------------------------------                                                             
                                                }

                                                <TabPanel value="1" sx={{ width: '100%'}}>
                                                    {
                                                        /*
                                                        ===============================================================================================
                                                        #region - Long Post (ie. Blog post for global or personal page)
                                                        ===============================================================================================
                                                        */
                                                    }
                                                    <props.skeleton.CustomGrid container spacing={0}>
                                                        <props.skeleton.CustomGrid item xs={12} sm={12} md={9} sx={{ padding: '0px' }}>
                                                            <props.skeleton.CustomStack direction={'column'} spacing={2}>
                                                                {
                                                                    // #region --------------[ template: template ]------------------------------------------------------------------
                                                                    //(
                                                                        //Modules?.base?.<name> 
                                                                        //&& Modules?.base?.<name>?.use 
                                                                        //&& Modules?.base?.<name>?.<child>?.indexOf(slice) > -1
                                                                    //)
                                                                    //? (
                                                                        
                                                                    //)
                                                                    //: null
                                                                    // #endregion --------------------------------------------------------------------------------------------------
                                                                }
                                                                
                                                                {
                                                                    // #region --------------[ base: Code ]------------------------------------------------------------------
                                                                    (
                                                                        Modules?.base?.Code 
                                                                        && Modules?.base?.Code?.use 
                                                                        && Modules?.base?.Code?.sections?.indexOf(slice) > -1
                                                                    )
                                                                    ? (
                                                                        <props.skeleton.CustomItem>
                                                                            <props.skeleton.CustomTextField
                                                                                name='code'
                                                                                label="Add Code"
                                                                                value={newLongSlice.code || ''}
                                                                                disabled={resources.SliceFormIsDisabled}
                                                                                onChange={(e) => setNewLongSlice({...newLongSlice, code: e.target.value})}
                                                                                onBlur={(e) => handleSetSlugFieldFromTitle()}
                                                                            />
                                                                        </props.skeleton.CustomItem>
                                                                    )
                                                                    : null
                                                                    // #endregion --------------------------------------------------------------------------------------------------                                                             
                                                                }

                                                                {
                                                                    // #region --------------[ base: CodeType ]------------------------------------------------------------------
                                                                    (
                                                                        Modules?.base?.CodeType 
                                                                        && Modules?.base?.CodeType?.use 
                                                                        && Modules?.base?.CodeType?.sections?.indexOf(slice) > -1
                                                                    )
                                                                    ? (
                                                                        <props.skeleton.CustomItem>
                                                                            <props.skeleton.CustomTextField
                                                                                name='type'
                                                                                label="Type"
                                                                                value={newLongSlice.type || ''}
                                                                                disabled={resources.SliceFormIsDisabled}
                                                                                onChange={(e) => setNewLongSlice({...newLongSlice, type: e.target.value})}
                                                                                onBlur={(e) => handleSetSlugFieldFromTitle()}
                                                                            />
                                                                        </props.skeleton.CustomItem>
                                                                    )
                                                                    : null
                                                                    // #endregion --------------------------------------------------------------------------------------------------                                                             
                                                                }

                                                                {
                                                                    // #region --------------[ base: CodeUses ]------------------------------------------------------------------
                                                                    (
                                                                        Modules?.base?.CodeUses 
                                                                        && Modules?.base?.CodeUses?.use 
                                                                        && Modules?.base?.CodeUses?.sections?.indexOf(slice) > -1
                                                                    )
                                                                    ? (
                                                                        <props.skeleton.CustomItem>
                                                                            <props.skeleton.CustomTextField
                                                                                name='uses'
                                                                                label="Uses"
                                                                                value={newLongSlice.uses || ''}
                                                                                disabled={resources.SliceFormIsDisabled}
                                                                                onChange={(e) => setNewLongSlice({...newLongSlice, uses: e.target.value})}
                                                                                onBlur={(e) => handleSetSlugFieldFromTitle()}
                                                                            />
                                                                        </props.skeleton.CustomItem>
                                                                    )
                                                                    : null
                                                                    // #endregion --------------------------------------------------------------------------------------------------                                                             
                                                                }
                                                                
                                                                {
                                                                    // #region --------------[ base: Title ]------------------------------------------------------------------
                                                                    (
                                                                        Modules?.base?.Title 
                                                                        && Modules?.base?.Title?.use 
                                                                        && Modules?.base?.Title?.sections?.indexOf(slice) > -1
                                                                    )
                                                                    ? (
                                                                    
                                                                        <props.skeleton.CustomItem>
                                                                            <props.skeleton.CustomTextField
                                                                                name='title'
                                                                                label="Add Title"
                                                                                value={newLongSlice.title || ''}
                                                                                disabled={resources.SliceFormIsDisabled}
                                                                                onChange={(e) => setNewLongSlice({...newLongSlice, title: e.target.value})}
                                                                                //onBlur={(e) => handleSetSlugFieldFromTitle()}
                                                                            />  
                                                                        </props.skeleton.CustomItem>                                                                
                                                                    )
                                                                    : null
                                                                    // #endregion --------------------------------------------------------------------------------------------------                                                             
                                                                }
                                                                
                                                                {
                                                                    // #region --------------[ base: Slug ]------------------------------------------------------------------
                                                                    (
                                                                        Modules?.base?.Slug 
                                                                        && Modules?.base?.Slug?.use 
                                                                        && Modules?.base?.Slug?.sections?.indexOf(slice) > -1
                                                                    )
                                                                    ? (
                                                                        <props.skeleton.CustomItem>
                                                                            <props.skeleton.CustomTextField
                                                                                name='slug'
                                                                                label="Slug"
                                                                                value={newLongSlice.slug || ''}
                                                                                disabled={resources.SliceFormIsDisabled}
                                                                                placeholder='Your title but clean. You can click on title, change it, and it will set.'
                                                                                onChange={(e) => setNewLongSlice({...newLongSlice, slug: e.target.value})}
                                                                                onBlur={(e) => handleSetSlugField()}
                                                                            />
                                                                            <props.skeleton.Bar button="true" padding10="true" onClick={() => handleSetSlugFieldFromTitle()}>Convert Title to Slug</props.skeleton.Bar>
                                                                        </props.skeleton.CustomItem>
                                                                    )
                                                                    : null
                                                                    // #endregion --------------------------------------------------------------------------------------------------                                                             
                                                                }
                                                                
                                                                {
                                                                    // #region --------------[ base: Snippet ]------------------------------------------------------------------
                                                                    (
                                                                        Modules?.base?.Snippet 
                                                                        && Modules?.base?.Snippet?.use 
                                                                        && Modules?.base?.Snippet?.sections?.indexOf(slice) > -1
                                                                    )
                                                                    ? (
                                                                        <props.skeleton.CustomItem>
                                                                            <props.skeleton.CustomTextField
                                                                                name='snippet'
                                                                                label="Snippet"
                                                                                value={newLongSlice.snippet || ''}
                                                                                placeholder='Describe your post in a few words'
                                                                                disabled={resources.SliceFormIsDisabled}
                                                                                onChange={
                                                                                    (e) => {
                                                                                        // ---  Version 2.8.0 - Set length of snippet to show how long they have currently -----------
                                                                                        if (currentSnippetLength < 97) {
                                                                                            setNewLongSlice({...newLongSlice, snippet: e.target.value})
                                                                                            setCurrentSnippetLength(e.target.value.length)
                                                                                        } else {
                                                                                            // You can limit the amount if you would like. Just uncomment this: 
                                                                                            //setNewLongSlice({...newLongSlice, snippet: e.target.value.substr(0, 96)})

                                                                                            // And comment this out
                                                                                            setNewLongSlice({...newLongSlice, snippet: e.target.value})


                                                                                            setCurrentSnippetLength(e.target.value.length)
                                                                                        }
                                                                                        // -------------------------------------------------------------------------------------------
                                                                                    }
                                                                                }
                                                                                InputProps={{
                                                                                    endAdornment: <props.skeleton.CustomInputAdornment disableTypography position="end" variant="standard">
                                                                                        {currentSnippetLength} / 97
                                                                                    </props.skeleton.CustomInputAdornment>
                                                                                }}
                                                                            />
                                                                        </props.skeleton.CustomItem>
                                                                    )
                                                                    : null
                                                                    // #endregion --------------------------------------------------------------------------------------------------                                                             
                                                                }

                                                                {
                                                                    // #region --------------[ base: Content ]------------------------------------------------------------------
                                                                    (
                                                                        Modules?.base?.Content 
                                                                        && Modules?.base?.Content?.use 
                                                                        && Modules?.base?.Content?.sections?.indexOf(slice) > -1
                                                                    )
                                                                    ? (
                                                                        <>
                                                                        <props.skeleton.CustomItem>
                                                                            <props.skeleton.CustomTextField
                                                                                multiline 
                                                                                rows={20}  
                                                                                variant={'outlined'}
                                                                                name='content'
                                                                                label="Content"
                                                                                sx={{
                                                                                    height: "100%",
                                                                                }}
                                                                                disabled={resources.SliceFormIsDisabled}
                                                                                value={newLongSlice.content || ''}
                                                                                onChange={(e) => setNewLongSlice({...newLongSlice, content: e.target.value})}
                                                                                InputProps={{
                                                                                    endAdornment: <props.skeleton.CustomInputAdornment disableTypography position="end" variant="standard">
                                                                                        0 / 97
                                                                                    </props.skeleton.CustomInputAdornment>
                                                                                }}
                                                                            />
                                                                        </props.skeleton.CustomItem>
                                                                        <props.skeleton.CustomItem xs={12} sm={12} md={12} lg={12}>
                                                                            <props.skeleton.Heading nobg>Character count: {newLongSlice.content.length} | Word count: {newLongSlice.content.split(' ').length}</props.skeleton.Heading>                                    
                                                                        </props.skeleton.CustomItem>
                                                                        </>
                                                                    )
                                                                    : null
                                                                    // #endregion --------------------------------------------------------------------------------------------------                                                             
                                                                }
                                                                
                                                            </props.skeleton.CustomStack>
                                                        </props.skeleton.CustomGrid>
                                                        <props.skeleton.CustomGrid item xs={12} sm={12} md={3} sx={{ paddingLeft: '10px' }}>
                                                            <props.skeleton.CustomGrid container alignContent={'flex-start'}>
                                                                {
                                                                    <>
                                                                    {
                                                                        // #region --------------[ template: template ]------------------------------------------------------------------
                                                                        //(
                                                                            //Modules?.base?.<name> 
                                                                            //&& Modules?.base?.<name>?.use 
                                                                            //&& Modules?.base?.<name>?.<child>?.indexOf(slice) > -1
                                                                        //)
                                                                        //? (
                                                                            <>

                                                                            </>
                                                                        //)
                                                                        //: null
                                                                        // #endregion --------------------------------------------------------------------------------------------------
                                                                    }

                                                                    {
                                                                        // #region --------------[ base: TopGizmo ]---------------------------------------------------------------------
                                                                        (
                                                                            Modules?.base?.TopGizmo 
                                                                            && Modules?.base?.TopGizmo?.use 
                                                                            && Modules?.base?.TopGizmo?.sections?.indexOf(slice) > -1
                                                                        )
                                                                        ? (
                                                                            <>
                                                                            <props.skeleton.CustomGrid item sink="true" xs={12} sx={{ border: 'thin solid #83B1B5', padding: '10px', marginBottom: '10px' }}>
                                                                                <props.skeleton.CustomStack direction={'column'}>
                                                                                    <props.skeleton.CustomItem>
                                                                                        <props.skeleton.CustomGrid container spacing={0} sink="true" sx={{ /*border: 'thin solid red',*/ padding: '0px' }}>
                                                                                            <props.skeleton.CustomGrid item sink="true" xs={9} sx={{ /*border: 'thin dashed green',*/ paddingTop: '10px', paddingBottom: '10px' }}>
                                                                                                Publish
                                                                                            </props.skeleton.CustomGrid>
                                                                                            <props.skeleton.CustomGrid item sink="true" xs={3} sx={{ /*border: 'thin dashed green',*/ paddingTop: '10px', paddingBottom: '10px', justifyContent: 'center', overflow: 'hidden' }}>
                                                                                                [Controls?]
                                                                                            </props.skeleton.CustomGrid>
                                                                                        </props.skeleton.CustomGrid>
                                                                                    </props.skeleton.CustomItem>
                                                                                    <props.skeleton.CustomItem>
                                                                                        <props.skeleton.CustomGrid container spacing={0} sink="true" sx={{ /*border: 'thin solid red',*/ padding: '0px' }}>
                                                                                            <props.skeleton.CustomGrid item sink="true" xs={3} sx={{ /*border: 'thin dashed green',*/ paddingTop: '0px', paddingBottom: '0px' }}>
                                                                                                <props.skeleton.Bar button="true" padding5lronly="true" center="true" sink="true" onClick={(e) => handlePreview()}>Preview</props.skeleton.Bar>
                                                                                            </props.skeleton.CustomGrid>
                                                                                            <props.skeleton.CustomGrid item sink="true" xs={6} sx={{ /*border: 'thin dashed green',*/ paddingTop: '0px', paddingBottom: '0px' }}>
                                                                                                
                                                                                            </props.skeleton.CustomGrid>
                                                                                            <props.skeleton.CustomGrid item sink="true" xs={3} sx={{ border: '', paddingTop: '0px', paddingBottom: '0px', justifyContent: 'center' }}>
                                                                                                <props.skeleton.Bar button="true" padding5lronly="true" center="true" sink="true" onClick={
                                                                                                    (e) => handleSendLongSlice(
                                                                                                        (slice !== "Posts")
                                                                                                        ? "inactive"
                                                                                                        : "draft"
                                                                                                    )
                                                                                                    }>{
                                                                                                        (slice !== "Posts") 
                                                                                                        ? "Save Inactive" 
                                                                                                        : "Save Draft"                                                                                                  
                                                                                                    }
                                                                                                </props.skeleton.Bar>
                                                                                            </props.skeleton.CustomGrid>
                                                                                        </props.skeleton.CustomGrid>
                                                                                    </props.skeleton.CustomItem>
                                                                                    <props.skeleton.CustomItem>
                                                                                        <props.skeleton.CustomGrid container sink="true" spacing={0} sx={{ /*border: 'thin solid red',*/ padding: '0px' }}>
                                                                                            <props.skeleton.CustomGrid item sink="true" xs={9} sx={{ /*border: 'thin dashed green',*/ paddingTop: '0px', paddingBottom: '0px' }}>
                                                                                                <props.skeleton.CustomStack direction={'row'} alignItems={'center'}>
                                                                                                    <props.skeleton.CustomItem item sink="true">
                                                                                                        Save as {
                                                                                                        (slice !== "Posts") 
                                                                                                        ? "Inactive" 
                                                                                                        : "Draft"
                                                                                                        }?
                                                                                                    </props.skeleton.CustomItem>
                                                                                                    <props.skeleton.CustomItem>
                                                                                                        
                                                                                                    </props.skeleton.CustomItem>
                                                                                                </props.skeleton.CustomStack>
                                                                                            </props.skeleton.CustomGrid>
                                                                                            <props.skeleton.CustomGrid item sink="true" xs={3} sx={{ /*border: 'thin dashed green',*/ paddingTop: '0px', paddingBottom: '0px', justifyContent: 'center' }}>
                                                                                                <props.skeleton.CustomSwitch
                                                                                                    sink='true'
                                                                                                    checked={!pstatus}
                                                                                                    onChange={handleChangeStatus}
                                                                                                    disabled={resources.SliceFormIsDisabled}
                                                                                                    name="status"
                                                                                                    inputProps={{ 'aria-label': 'controlled' }}
                                                                                                />
                                                                                            </props.skeleton.CustomGrid>
                                                                                        </props.skeleton.CustomGrid>
                                                                                    </props.skeleton.CustomItem>
                                                                                    <props.skeleton.CustomItem>
                                                                                        <props.skeleton.CustomGrid container sink="true" spacing={0} sx={{ /*border: 'thin solid red',*/ padding: '0px' }}>
                                                                                            <props.skeleton.CustomGrid item sink="true" xs={9} sx={{ /*border: 'thin dashed green',*/ paddingTop: '10px', paddingBottom: '10px' }}>
                                                                                                <props.skeleton.CustomStack direction={'row'} alignItems={'center'}>
                                                                                                    <props.skeleton.CustomItem sink="true">
                                                                                                        Status: { 
                                                                                                        (slice !== "Posts") 
                                                                                                        ? (!pstatus) 
                                                                                                            ? "Inactive" 
                                                                                                            : "Active" 
                                                                                                        : (!pstatus) 
                                                                                                            ? "Draft" 
                                                                                                            : "Published" 
                                                                                                        }
                                                                                                    </props.skeleton.CustomItem>
                                                                                                </props.skeleton.CustomStack>
                                                                                            </props.skeleton.CustomGrid>
                                                                                            <props.skeleton.CustomGrid item sink="true" xs={3} sx={{ /*border: 'thin dashed green',*/ paddingTop: '10px', paddingBottom: '10px', justifyContent: 'center' }}>
                                                                                                
                                                                                            </props.skeleton.CustomGrid>
                                                                                        </props.skeleton.CustomGrid>
                                                                                    </props.skeleton.CustomItem>
                                                                                    <props.skeleton.CustomItem>
                                                                                        <props.skeleton.CustomGrid container sink="true" spacing={0} sx={{ /*border: 'thin solid red',*/ padding: '0px' }}>
                                                                                            <props.skeleton.CustomGrid item sink="true" xs={12} sx={{ /*border: 'thin dashed green',*/ paddingTop: '10px', paddingBottom: '10px' }}>
                                                                                                <props.skeleton.CustomStack direction={'row'} alignItems={'center'}>
                                                                                                    <props.skeleton.CustomItem sink="true">
                                                                                                        <props.skeleton.CustomFormControl sx={{ minWidth: 190, maxWidth: 200, justifyContent: 'left' }} size="small">
                                                                                                            <InputLabel id="visibility-select-small-label">Visibility</InputLabel>
                                                                                                            <props.skeleton.CustomSelect
                                                                                                                labelId="visibility-select-small-label"
                                                                                                                name="visibility"
                                                                                                                value={visibility  || ''}
                                                                                                                label="Visibility"
                                                                                                                onChange={handleChangeVisibility}
                                                                                                                disabled={resources.SliceFormIsDisabled}
                                                                                                            >
                                                                                                                <MenuItem value="public">Public</MenuItem>
                                                                                                                <MenuItem value="friendsonly">Friends Only</MenuItem>
                                                                                                                <MenuItem value="private">Private</MenuItem>
                                                                                                            </props.skeleton.CustomSelect>
                                                                                                        </props.skeleton.CustomFormControl>                                                                   
                                                                                                    </props.skeleton.CustomItem>
                                                                                                    <props.skeleton.CustomItem>
                                                                                                        
                                                                                                    </props.skeleton.CustomItem>
                                                                                                </props.skeleton.CustomStack>
                                                                                            </props.skeleton.CustomGrid>
                                                                                        </props.skeleton.CustomGrid>
                                                                                    </props.skeleton.CustomItem>
                                                                                    
                                                                                    {
                                                                                        (currentUser?.role === "editor" || currentUser?.role === "admin" || currentUser?.role === "superadmin")
                                                                                        &&
                                                                                        (
                                                                                            <props.skeleton.CustomItem>
                                                                                                <props.skeleton.CustomGrid container sink="true" spacing={0} sx={{ /*border: 'thin solid red',*/ padding: '0px' }}>
                                                                                                    <props.skeleton.CustomGrid item sink="true" xs={9} sx={{ /*border: 'thin dashed green',*/ paddingTop: '10px', paddingBottom: '10px' }}>
                                                                                                        <props.skeleton.CustomStack direction={'row'} alignItems={'center'}>
                                                                                                            <props.skeleton.CustomItem sink="true">
                                                                                                                Publish Globally (?):                                                               
                                                                                                            </props.skeleton.CustomItem>
                                                                                                            <props.skeleton.CustomItem>
                                                                                                                
                                                                                                            </props.skeleton.CustomItem>
                                                                                                        </props.skeleton.CustomStack>
                                                                                                    </props.skeleton.CustomGrid>
                                                                                                    <props.skeleton.CustomGrid item sink="true" xs={3} sx={{ /*border: 'thin dashed green',*/ paddingTop: '0px', paddingBottom: '0px', justifyContent: 'center' }}>
                                                                                                        <props.skeleton.CustomSwitch
                                                                                                            sink='true'
                                                                                                            checked={publishGlobally}
                                                                                                            onChange={handlePublishGlobally}
                                                                                                            disabled={resources.SliceFormIsDisabled}
                                                                                                            name="namespace"
                                                                                                            inputProps={{ 'aria-label': 'controlled' }}
                                                                                                        />
                                                                                                    </props.skeleton.CustomGrid>
                                                                                                </props.skeleton.CustomGrid>
                                                                                            </props.skeleton.CustomItem>
                                                                                        )
                                                                                    }

                                                                                    <props.skeleton.CustomItem>
                                                                                        <props.skeleton.CustomGrid container sink="true" spacing={0} sx={{ /*border: 'thin solid red',*/ padding: '0px' }}>
                                                                                            <props.skeleton.CustomGrid item sink="true" xs={9} sx={{ /*border: 'thin dashed green',*/ paddingTop: '0px', paddingBottom: '0px' }}>
                                                                                                &nbsp;
                                                                                            </props.skeleton.CustomGrid>
                                                                                            <props.skeleton.CustomGrid item sink="true" xs={3} sx={{ border: '', paddingTop: '0px', paddingBottom: '0px', justifyContent: 'center' }}>
                                                                                                
                                                                                            </props.skeleton.CustomGrid>
                                                                                        </props.skeleton.CustomGrid>
                                                                                    </props.skeleton.CustomItem>
                                                                                    <props.skeleton.CustomItem>
                                                                                        <props.skeleton.CustomGrid container sink="true" spacing={0} sx={{ /*border: 'thin solid red',*/ padding: '0px' }}>
                                                                                            <props.skeleton.CustomGrid item sink="true" xs={9} sx={{ /*border: 'thin dashed green',*/ paddingTop: '0px', paddingBottom: '0px' }}>
                                                                                                
                                                                                            </props.skeleton.CustomGrid>
                                                                                            <props.skeleton.CustomGrid item sink="true" xs={3} sx={{ border: '', paddingTop: '0px', paddingBottom: '0px', justifyContent: 'center' }}>
                                                                                                
                                                                                            </props.skeleton.CustomGrid>
                                                                                        </props.skeleton.CustomGrid>
                                                                                    </props.skeleton.CustomItem>
                                                                                    <props.skeleton.CustomItem>
                                                                                        <props.skeleton.CustomGrid container sink="true" spacing={0} sx={{ /*border: 'thin solid red',*/ padding: '0px' }}>
                                                                                            <props.skeleton.CustomGrid item sink="true" xs={9} sx={{ /*border: 'thin dashed green',*/ paddingTop: '0px', paddingBottom: '0px' }}>
                                                                                                                                                                        </props.skeleton.CustomGrid>
                                                                                            <props.skeleton.CustomGrid item sink="true" xs={3} sx={{ border: '', paddingTop: '0px', paddingBottom: '0px', justifyContent: 'center' }}>
                                                                                                
                                                                                            </props.skeleton.CustomGrid>
                                                                                        </props.skeleton.CustomGrid>
                                                                                    </props.skeleton.CustomItem>
                                                                                    <props.skeleton.CustomItem>
                                                                                        <props.skeleton.CustomGrid container sink="true" spacing={0} sx={{ /*border: 'thin solid red',*/ padding: '0px' }}>
                                                                                            <props.skeleton.CustomGrid item sink="true" xs={9} sx={{ /*border: 'thin dashed green',*/ paddingTop: '0px', paddingBottom: '0px' }}>
                                                                                                
                                                                                            </props.skeleton.CustomGrid>
                                                                                            <props.skeleton.CustomGrid item sink="true" xs={3} sx={{ border: '', paddingTop: '0px', paddingBottom: '0px', justifyContent: 'center' }}>
                                                                                                
                                                                                            </props.skeleton.CustomGrid>
                                                                                        </props.skeleton.CustomGrid>
                                                                                    </props.skeleton.CustomItem>
                                                                                    <props.skeleton.CustomItem>
                                                                                        <props.skeleton.CustomGrid container sink="true" spacing={0} sx={{ /*border: 'thin solid red',*/ padding: '0px' }}>
                                                                                            <props.skeleton.CustomGrid item sink="true" xs={9} sx={{ /*border: 'thin dashed green',*/ paddingTop: '0px', paddingBottom: '0px' }}>
                                                                                                
                                                                                            </props.skeleton.CustomGrid>
                                                                                            <props.skeleton.CustomGrid item sink="true" xs={3} sx={{ border: '', paddingTop: '0px', paddingBottom: '0px', justifyContent: 'center' }}>
                                                                                                <props.skeleton.Bar button="true" padding5lronly="true" center="true" sink="true" onClick={
                                                                                                    (e) => handleSendLongSlice(
                                                                                                        (slice !== "Posts")
                                                                                                        ? (
                                                                                                            (pstatus) 
                                                                                                            ? "active"
                                                                                                            : "inactive"
                                                                                                        )
                                                                                                        : (
                                                                                                            (pstatus) 
                                                                                                            ? "published"
                                                                                                            : "draft"
                                                                                                        )
                                                                                                    )
                                                                                                    }>{
                                                                                                        (slice !== "Posts") 
                                                                                                        ? (
                                                                                                            (!pstatus)
                                                                                                            ? "Save Inactive" 
                                                                                                            : "Save Active"
                                                                                                        )
                                                                                                        : (
                                                                                                            (!pstatus)
                                                                                                            ? "Save Draft"
                                                                                                            : "Publish"
                                                                                                        )
                                                                                                    }
                                                                                                </props.skeleton.Bar>
                                                                                            </props.skeleton.CustomGrid>
                                                                                        </props.skeleton.CustomGrid>
                                                                                    </props.skeleton.CustomItem>
                                                                                </props.skeleton.CustomStack>
                                                                            </props.skeleton.CustomGrid>
                                                                            </>
                                                                        )
                                                                        : null
                                                                    }
                                                                    
                                                                    {
                                                                        // #region --------------[ base: TopGizmoNoVisibility ]---------------------------------------------------------------
                                                                        (
                                                                            Modules?.base?.TopGizmoNoVisibility
                                                                            && Modules?.base?.TopGizmoNoVisibility?.use 
                                                                            && Modules?.base?.TopGizmoNoVisibility?.sections?.indexOf(slice) > -1
                                                                        )
                                                                        ? (
                                                                            <>
                                                                            <props.skeleton.CustomGrid item sink="true" xs={12} sx={{ border: 'thin solid #83B1B5', padding: '10px', marginBottom: '10px' }}>
                                                                                <props.skeleton.CustomStack direction={'column'}>
                                                                                    <props.skeleton.CustomItem sink="true">
                                                                                        <props.skeleton.CustomGrid container sink="true" spacing={0} sx={{ /*border: 'thin solid red',*/ padding: '0px' }}>
                                                                                            <props.skeleton.CustomGrid item sink="true" xs={9} sx={{ /*border: 'thin dashed green',*/ paddingTop: '10px', paddingBottom: '10px' }}>
                                                                                                Publish
                                                                                            </props.skeleton.CustomGrid>
                                                                                            <props.skeleton.CustomGrid item sink="true" xs={3} sx={{ /*border: 'thin dashed green',*/ paddingTop: '10px', paddingBottom: '10px', justifyContent: 'center', overflow: 'hidden' }}>
                                                                                                [Controls?]
                                                                                            </props.skeleton.CustomGrid>
                                                                                        </props.skeleton.CustomGrid>
                                                                                    </props.skeleton.CustomItem>
                                                                                    <props.skeleton.CustomItem sink="true">
                                                                                        <props.skeleton.CustomGrid container sink="true" spacing={0} sx={{ /*border: 'thin solid red',*/ padding: '0px' }}>
                                                                                            <props.skeleton.CustomGrid item sink="true" xs={3} sx={{ /*border: 'thin dashed green',*/ paddingTop: '0px', paddingBottom: '0px' }}>
                                                                                                <props.skeleton.Bar button="true" padding5lronly="true" center="true" sink="true" onClick={(e) => handlePreview()}>Preview</props.skeleton.Bar>
                                                                                            </props.skeleton.CustomGrid>
                                                                                            <props.skeleton.CustomGrid item sink="true" xs={6} sx={{ /*border: 'thin dashed green',*/ paddingTop: '0px', paddingBottom: '0px' }}>
                                                                                                
                                                                                            </props.skeleton.CustomGrid>
                                                                                            <props.skeleton.CustomGrid item sink="true" xs={3} sx={{ border: '', paddingTop: '0px', paddingBottom: '0px', justifyContent: 'center' }}>
                                                                                                <props.skeleton.Bar button="true" padding5lronly="true" center="true" sink="true" onClick={
                                                                                                    (e) => handleSendLongSlice(
                                                                                                        (slice !== "Posts")
                                                                                                        ? "inactive"
                                                                                                        : "draft"
                                                                                                    )
                                                                                                    }>{
                                                                                                        (slice !== "Posts") 
                                                                                                        ? "Save Inactive" 
                                                                                                        : "Save Draft"
                                                                                                    }
                                                                                                </props.skeleton.Bar>
                                                                                            </props.skeleton.CustomGrid>
                                                                                        </props.skeleton.CustomGrid>
                                                                                    </props.skeleton.CustomItem>
                                                                                    <props.skeleton.CustomItem>
                                                                                        <props.skeleton.CustomGrid container sink="true" spacing={0} sx={{ /*border: 'thin solid red',*/ padding: '0px' }}>
                                                                                            <props.skeleton.CustomGrid item sink="true" xs={9} sx={{ /*border: 'thin dashed green',*/ paddingTop: '0px', paddingBottom: '0px' }}>
                                                                                                <props.skeleton.CustomStack direction={'row'} alignItems={'center'}>
                                                                                                    <props.skeleton.CustomItem sink="true">
                                                                                                        Save as {
                                                                                                        (slice !== "Posts") 
                                                                                                        ? "Inactive" 
                                                                                                        : "Draft"
                                                                                                        }?
                                                                                                    </props.skeleton.CustomItem>
                                                                                                    <props.skeleton.CustomItem sink="true">
                                                                                                        
                                                                                                    </props.skeleton.CustomItem>
                                                                                                </props.skeleton.CustomStack>
                                                                                            </props.skeleton.CustomGrid>
                                                                                            <props.skeleton.CustomGrid item sink="true" xs={3} sx={{ /*border: 'thin dashed green',*/ paddingTop: '0px', paddingBottom: '0px', justifyContent: 'center' }}>
                                                                                                <props.skeleton.CustomSwitch
                                                                                                    sink='true'
                                                                                                    checked={!pstatus}
                                                                                                    onChange={handleChangeStatus}
                                                                                                    disabled={resources.SliceFormIsDisabled}
                                                                                                    name="status"
                                                                                                    inputProps={{ 'aria-label': 'controlled' }}
                                                                                                />
                                                                                            </props.skeleton.CustomGrid>
                                                                                        </props.skeleton.CustomGrid>
                                                                                    </props.skeleton.CustomItem>
                                                                                    <props.skeleton.CustomItem>
                                                                                        <props.skeleton.CustomGrid container sink="true" spacing={0} sx={{ /*border: 'thin solid red',*/ padding: '0px' }}>
                                                                                            <props.skeleton.CustomGrid item sink="true" xs={9} sx={{ /*border: 'thin dashed green',*/ paddingTop: '10px', paddingBottom: '10px' }}>
                                                                                                <props.skeleton.CustomStack direction={'row'} alignItems={'center'}>
                                                                                                    <props.skeleton.CustomItem sink="true">
                                                                                                        Status: { 
                                                                                                        (slice !== "Posts") 
                                                                                                        ? (!pstatus) 
                                                                                                            ? "Inactive" 
                                                                                                            : "Active" 
                                                                                                        : (!pstatus) 
                                                                                                            ? "Draft" 
                                                                                                            : "Published" 
                                                                                                        }
                                                                                                    </props.skeleton.CustomItem>
                                                                                                </props.skeleton.CustomStack>
                                                                                            </props.skeleton.CustomGrid>
                                                                                            <props.skeleton.CustomGrid item sink="true" xs={3} sx={{ /*border: 'thin dashed green',*/ paddingTop: '10px', paddingBottom: '10px', justifyContent: 'center' }}>
                                                                                                
                                                                                            </props.skeleton.CustomGrid>
                                                                                        </props.skeleton.CustomGrid>
                                                                                    </props.skeleton.CustomItem>    
                                                                                    
                                                                                    {
                                                                                        (currentUser?.role === "editor" || currentUser?.role === "admin" || currentUser?.role === "superadmin")
                                                                                        &&
                                                                                        (
                                                                                            <props.skeleton.CustomItem>
                                                                                                <props.skeleton.CustomGrid container sink="true" spacing={0} sx={{ /*border: 'thin solid red',*/ padding: '0px' }}>
                                                                                                    <props.skeleton.CustomGrid item sink="true" xs={9} sx={{ /*border: 'thin dashed green',*/ paddingTop: '10px', paddingBottom: '10px' }}>
                                                                                                        <props.skeleton.CustomStack direction={'row'} alignItems={'center'}>
                                                                                                            <props.skeleton.CustomItem sink="true">
                                                                                                                Publish Globally (?):                                                               
                                                                                                            </props.skeleton.CustomItem>
                                                                                                            <props.skeleton.CustomItem>
                                                                                                                
                                                                                                            </props.skeleton.CustomItem>
                                                                                                        </props.skeleton.CustomStack>
                                                                                                    </props.skeleton.CustomGrid>
                                                                                                    <props.skeleton.CustomGrid item sink="true" xs={3} sx={{ /*border: 'thin dashed green',*/ paddingTop: '0px', paddingBottom: '0px', justifyContent: 'center' }}>
                                                                                                        <props.skeleton.CustomSwitch
                                                                                                            sink='true'
                                                                                                            checked={publishGlobally}
                                                                                                            onChange={handlePublishGlobally}
                                                                                                            disabled={resources.SliceFormIsDisabled}
                                                                                                            name="namespace"
                                                                                                            inputProps={{ 'aria-label': 'controlled' }}
                                                                                                        />
                                                                                                    </props.skeleton.CustomGrid>
                                                                                                </props.skeleton.CustomGrid>
                                                                                            </props.skeleton.CustomItem>
                                                                                        )
                                                                                    }

                                                                                    <props.skeleton.CustomItem sink="true">
                                                                                        <props.skeleton.CustomGrid container sink="true" spacing={0} sx={{ /*border: 'thin solid red',*/ padding: '0px' }}>
                                                                                            <props.skeleton.CustomGrid item sink="true" xs={9} sx={{ /*border: 'thin dashed green',*/ paddingTop: '0px', paddingBottom: '0px' }}>
                                                                                                &nbsp;
                                                                                            </props.skeleton.CustomGrid>
                                                                                            <props.skeleton.CustomGrid item sink="true" xs={3} sx={{ border: '', paddingTop: '0px', paddingBottom: '0px', justifyContent: 'center' }}>
                                                                                                
                                                                                            </props.skeleton.CustomGrid>
                                                                                        </props.skeleton.CustomGrid>
                                                                                    </props.skeleton.CustomItem>
                                                                                    <props.skeleton.CustomItem sink="true">
                                                                                        <props.skeleton.CustomGrid container sink="true" spacing={0} sx={{ /*border: 'thin solid red',*/ padding: '0px' }}>
                                                                                            <props.skeleton.CustomGrid item sink="true" xs={9} sx={{ /*border: 'thin dashed green',*/ paddingTop: '0px', paddingBottom: '0px' }}>
                                                                                                
                                                                                            </props.skeleton.CustomGrid>
                                                                                            <props.skeleton.CustomGrid item sink="true" xs={3} sx={{ border: '', paddingTop: '0px', paddingBottom: '0px', justifyContent: 'center' }}>
                                                                                                
                                                                                            </props.skeleton.CustomGrid>
                                                                                        </props.skeleton.CustomGrid>
                                                                                    </props.skeleton.CustomItem>
                                                                                    <props.skeleton.CustomItem sink="true">
                                                                                        <props.skeleton.CustomGrid container sink="true" spacing={0} sx={{ /*border: 'thin solid red',*/ padding: '0px' }}>
                                                                                            <props.skeleton.CustomGrid item sink="true" xs={9} sx={{ /*border: 'thin dashed green',*/ paddingTop: '0px', paddingBottom: '0px' }}>
                                                                                                                                                                        </props.skeleton.CustomGrid>
                                                                                            <props.skeleton.CustomGrid item sink="true" xs={3} sx={{ border: '', paddingTop: '0px', paddingBottom: '0px', justifyContent: 'center' }}>
                                                                                                
                                                                                            </props.skeleton.CustomGrid>
                                                                                        </props.skeleton.CustomGrid>
                                                                                    </props.skeleton.CustomItem>
                                                                                    <props.skeleton.CustomItem>
                                                                                        <props.skeleton.CustomGrid container sink="true" spacing={0} sx={{ /*border: 'thin solid red',*/ padding: '0px' }}>
                                                                                            <props.skeleton.CustomGrid item sink="true" xs={9} sx={{ /*border: 'thin dashed green',*/ paddingTop: '0px', paddingBottom: '0px' }}>
                                                                                                
                                                                                            </props.skeleton.CustomGrid>
                                                                                            <props.skeleton.CustomGrid item sink="true" xs={3} sx={{ border: '', paddingTop: '0px', paddingBottom: '0px', justifyContent: 'center' }}>
                                                                                                
                                                                                            </props.skeleton.CustomGrid>
                                                                                        </props.skeleton.CustomGrid>
                                                                                    </props.skeleton.CustomItem>
                                                                                    <props.skeleton.CustomItem sink="true">
                                                                                        <props.skeleton.CustomGrid container sink="true" spacing={0} sx={{ /*border: 'thin solid red',*/ padding: '0px' }}>
                                                                                            <props.skeleton.CustomGrid item sink="true" xs={9} sx={{ /*border: 'thin dashed green',*/ paddingTop: '0px', paddingBottom: '0px' }}>
                                                                                                
                                                                                            </props.skeleton.CustomGrid>
                                                                                            <props.skeleton.CustomGrid item sink="true" xs={3} sx={{ border: '', paddingTop: '0px', paddingBottom: '0px', justifyContent: 'center' }}>
                                                                                                <props.skeleton.Bar button="true" padding5lronly="true" center="true" sink="true" onClick={
                                                                                                    (e) => handleSendLongSlice(
                                                                                                        (slice !== "Posts")
                                                                                                        ? (
                                                                                                            (pstatus) 
                                                                                                            ? "active"
                                                                                                            : "inactive"
                                                                                                        )
                                                                                                        : (
                                                                                                            (pstatus) 
                                                                                                            ? "published"
                                                                                                            : "draft"
                                                                                                        )
                                                                                                    )
                                                                                                    }>{
                                                                                                        (slice !== "Posts") 
                                                                                                        ? (
                                                                                                            (!pstatus)
                                                                                                            ? "Save Inactive" 
                                                                                                            : "Save Active"
                                                                                                        )
                                                                                                        : (
                                                                                                            (!pstatus)
                                                                                                            ? "Save Draft"
                                                                                                            : "Publish"
                                                                                                        )
                                                                                                    }
                                                                                                </props.skeleton.Bar>
                                                                                            </props.skeleton.CustomGrid>
                                                                                        </props.skeleton.CustomGrid>
                                                                                    </props.skeleton.CustomItem>
                                                                                </props.skeleton.CustomStack>
                                                                            </props.skeleton.CustomGrid>
                                                                            </>
                                                                        )
                                                                        : null
                                                                    }

                                                                    {
                                                                        // #region --------------[ base: TopGizmoNoVisibilityNoNamespace ]---------------------------------------------------------------
                                                                        (
                                                                            Modules?.base?.TopGizmoNoVisibilityNoNamespace 
                                                                            && Modules?.base?.TopGizmoNoVisibilityNoNamespace?.use 
                                                                            && Modules?.base?.TopGizmoNoVisibilityNoNamespace?.sections?.indexOf(slice) > -1
                                                                        )
                                                                        ? (
                                                                            <>
                                                                            <props.skeleton.CustomGrid item sink="true" xs={12} sx={{ border: 'thin solid #83B1B5', padding: '10px', marginBottom: '10px' }}>
                                                                                <props.skeleton.CustomStack direction={'column'}>
                                                                                    <props.skeleton.CustomItem sink="true">
                                                                                        <props.skeleton.CustomGrid container sink="true" spacing={0} sx={{ /*border: 'thin solid red',*/ padding: '0px' }}>
                                                                                            <props.skeleton.CustomGrid item sink="true" xs={9} sx={{ /*border: 'thin dashed green',*/ paddingTop: '10px', paddingBottom: '10px' }}>
                                                                                                Publish
                                                                                            </props.skeleton.CustomGrid>
                                                                                            <props.skeleton.CustomGrid item sink="true" xs={3} sx={{ /*border: 'thin dashed green',*/ paddingTop: '10px', paddingBottom: '10px', justifyContent: 'center', overflow: 'hidden' }}>
                                                                                                [Controls?]
                                                                                            </props.skeleton.CustomGrid>
                                                                                        </props.skeleton.CustomGrid>
                                                                                    </props.skeleton.CustomItem>
                                                                                    <props.skeleton.CustomItem sink="true">
                                                                                        <props.skeleton.CustomGrid container sink="true" spacing={0} sx={{ /*border: 'thin solid red',*/ padding: '0px' }}>
                                                                                            <props.skeleton.CustomGrid item sink="true" xs={3} sx={{ /*border: 'thin dashed green',*/ paddingTop: '0px', paddingBottom: '0px' }}>
                                                                                                <props.skeleton.Bar button="true" padding5lronly="true" center="true" sink="true" onClick={(e) => handlePreview()}>Preview</props.skeleton.Bar>
                                                                                            </props.skeleton.CustomGrid>
                                                                                            <props.skeleton.CustomGrid item sink="true" xs={6} sx={{ /*border: 'thin dashed green',*/ paddingTop: '0px', paddingBottom: '0px' }}>
                                                                                                
                                                                                            </props.skeleton.CustomGrid>
                                                                                            <props.skeleton.CustomGrid item sink="true" xs={3} sx={{ border: '', paddingTop: '0px', paddingBottom: '0px', justifyContent: 'center' }}>
                                                                                                <props.skeleton.Bar button="true" padding5lronly="true" center="true" sink="true" onClick={
                                                                                                    (e) => handleSendLongSlice(
                                                                                                        (slice !== "Posts")
                                                                                                        ? "inactive"
                                                                                                        : "draft"
                                                                                                    )
                                                                                                    }>{
                                                                                                        (slice !== "Posts") 
                                                                                                        ? "Save Inactive" 
                                                                                                        : "Save Draft"
                                                                                                    }
                                                                                                </props.skeleton.Bar>
                                                                                            </props.skeleton.CustomGrid>
                                                                                        </props.skeleton.CustomGrid>
                                                                                    </props.skeleton.CustomItem>
                                                                                    <props.skeleton.CustomItem sink="true">
                                                                                        <props.skeleton.CustomGrid container sink="true" spacing={0} sx={{ /*border: 'thin solid red',*/ padding: '0px' }}>
                                                                                            <props.skeleton.CustomGrid item sink="true" xs={9} sx={{ /*border: 'thin dashed green',*/ paddingTop: '0px', paddingBottom: '0px' }}>
                                                                                                <props.skeleton.CustomStack direction={'row'} alignItems={'center'}>
                                                                                                    <props.skeleton.CustomItem sink="true">
                                                                                                        Save as {
                                                                                                        (slice !== "Posts") 
                                                                                                        ? "Inactive" 
                                                                                                        : "Draft"
                                                                                                        }?
                                                                                                    </props.skeleton.CustomItem>
                                                                                                    <props.skeleton.CustomItem>
                                                                                                        
                                                                                                    </props.skeleton.CustomItem>
                                                                                                </props.skeleton.CustomStack>
                                                                                            </props.skeleton.CustomGrid>
                                                                                            <props.skeleton.CustomGrid item sink="true" xs={3} sx={{ /*border: 'thin dashed green',*/ paddingTop: '0px', paddingBottom: '0px', justifyContent: 'center' }}>
                                                                                                <props.skeleton.CustomSwitch
                                                                                                    sink='true'
                                                                                                    checked={!pstatus}
                                                                                                    onChange={handleChangeStatus}
                                                                                                    disabled={resources.SliceFormIsDisabled}
                                                                                                    name="status"
                                                                                                    inputProps={{ 'aria-label': 'controlled' }}
                                                                                                />
                                                                                            </props.skeleton.CustomGrid>
                                                                                        </props.skeleton.CustomGrid>
                                                                                    </props.skeleton.CustomItem>
                                                                                    <props.skeleton.CustomItem sink="true">
                                                                                        <props.skeleton.CustomGrid container sink="true" spacing={0} sx={{ /*border: 'thin solid red',*/ padding: '0px' }}>
                                                                                            <props.skeleton.CustomGrid item sink="true" xs={9} sx={{ /*border: 'thin dashed green',*/ paddingTop: '10px', paddingBottom: '10px' }}>
                                                                                                <props.skeleton.CustomStack direction={'row'} alignItems={'center'}>
                                                                                                    <props.skeleton.CustomItem sink="true">
                                                                                                        Status: { 
                                                                                                        (slice !== "Posts") 
                                                                                                        ? (!pstatus) 
                                                                                                            ? "Inactive" 
                                                                                                            : "Active" 
                                                                                                        : (!pstatus) 
                                                                                                            ? "Draft" 
                                                                                                            : "Published" 
                                                                                                        }
                                                                                                    </props.skeleton.CustomItem>
                                                                                                </props.skeleton.CustomStack>
                                                                                            </props.skeleton.CustomGrid>
                                                                                            <props.skeleton.CustomGrid item sink="true" xs={3} sx={{ /*border: 'thin dashed green',*/ paddingTop: '10px', paddingBottom: '10px', justifyContent: 'center' }}>
                                                                                                
                                                                                            </props.skeleton.CustomGrid>
                                                                                        </props.skeleton.CustomGrid>
                                                                                    </props.skeleton.CustomItem>                                                                                                                                                        
                                                                                    <props.skeleton.CustomItem sink="true">
                                                                                        <props.skeleton.CustomGrid container sink="true" spacing={0} sx={{ /*border: 'thin solid red',*/ padding: '0px' }}>
                                                                                            <props.skeleton.CustomGrid item sink="true" xs={9} sx={{ /*border: 'thin dashed green',*/ paddingTop: '0px', paddingBottom: '0px' }}>
                                                                                                &nbsp;
                                                                                            </props.skeleton.CustomGrid>
                                                                                            <props.skeleton.CustomGrid item sink="true" xs={3} sx={{ border: '', paddingTop: '0px', paddingBottom: '0px', justifyContent: 'center' }}>
                                                                                                
                                                                                            </props.skeleton.CustomGrid>
                                                                                        </props.skeleton.CustomGrid>
                                                                                    </props.skeleton.CustomItem>
                                                                                    <props.skeleton.CustomItem sink="true">
                                                                                        <props.skeleton.CustomGrid container sink="true" spacing={0} sx={{ /*border: 'thin solid red',*/ padding: '0px' }}>
                                                                                            <props.skeleton.CustomGrid item sink="true" xs={9} sx={{ /*border: 'thin dashed green',*/ paddingTop: '0px', paddingBottom: '0px' }}>
                                                                                                
                                                                                            </props.skeleton.CustomGrid>
                                                                                            <props.skeleton.CustomGrid item sink="true" xs={3} sx={{ border: '', paddingTop: '0px', paddingBottom: '0px', justifyContent: 'center' }}>
                                                                                                
                                                                                            </props.skeleton.CustomGrid>
                                                                                        </props.skeleton.CustomGrid>
                                                                                    </props.skeleton.CustomItem>
                                                                                    <props.skeleton.CustomItem sink="true">
                                                                                        <props.skeleton.CustomGrid container sink="true" spacing={0} sx={{ /*border: 'thin solid red',*/ padding: '0px' }}>
                                                                                            <props.skeleton.CustomGrid item sink="true" xs={9} sx={{ /*border: 'thin dashed green',*/ paddingTop: '0px', paddingBottom: '0px' }}>
                                                                                                                                                                        </props.skeleton.CustomGrid>
                                                                                            <props.skeleton.CustomGrid item sink="true" xs={3} sx={{ border: '', paddingTop: '0px', paddingBottom: '0px', justifyContent: 'center' }}>
                                                                                                
                                                                                            </props.skeleton.CustomGrid>
                                                                                        </props.skeleton.CustomGrid>
                                                                                    </props.skeleton.CustomItem>
                                                                                    <props.skeleton.CustomItem sink="true">
                                                                                        <props.skeleton.CustomGrid container sink="true" spacing={0} sx={{ /*border: 'thin solid red',*/ padding: '0px' }}>
                                                                                            <props.skeleton.CustomGrid item sink="true" xs={9} sx={{ /*border: 'thin dashed green',*/ paddingTop: '0px', paddingBottom: '0px' }}>
                                                                                                
                                                                                            </props.skeleton.CustomGrid>
                                                                                            <props.skeleton.CustomGrid item sink="true" xs={3} sx={{ border: '', paddingTop: '0px', paddingBottom: '0px', justifyContent: 'center' }}>
                                                                                                
                                                                                            </props.skeleton.CustomGrid>
                                                                                        </props.skeleton.CustomGrid>
                                                                                    </props.skeleton.CustomItem>
                                                                                    <props.skeleton.CustomItem sink="true">
                                                                                        <props.skeleton.CustomGrid container sink="true" spacing={0} sx={{ /*border: 'thin solid red',*/ padding: '0px' }}>
                                                                                            <props.skeleton.CustomGrid item sink="true" xs={9} sx={{ /*border: 'thin dashed green',*/ paddingTop: '0px', paddingBottom: '0px' }}>
                                                                                                
                                                                                            </props.skeleton.CustomGrid>
                                                                                            <props.skeleton.CustomGrid item sink="true" xs={3} sx={{ border: '', paddingTop: '0px', paddingBottom: '0px', justifyContent: 'center' }}>
                                                                                                <props.skeleton.Bar button="true" padding5lronly="true" center="true" sink="true" onClick={
                                                                                                    (e) => handleSendLongSlice(
                                                                                                        (slice !== "Posts")
                                                                                                        ? (
                                                                                                            (pstatus) 
                                                                                                            ? "active"
                                                                                                            : "inactive"
                                                                                                        )
                                                                                                        : (
                                                                                                            (pstatus) 
                                                                                                            ? "published"
                                                                                                            : "draft"
                                                                                                        )
                                                                                                    )
                                                                                                    }>{
                                                                                                        (slice !== "Posts") 
                                                                                                        ? (
                                                                                                            (!pstatus)
                                                                                                            ? "Save Inactive" 
                                                                                                            : "Save Active"
                                                                                                        )
                                                                                                        : (
                                                                                                            (!pstatus)
                                                                                                            ? "Save Draft"
                                                                                                            : "Publish"
                                                                                                        )
                                                                                                    }
                                                                                                </props.skeleton.Bar>
                                                                                            </props.skeleton.CustomGrid>
                                                                                        </props.skeleton.CustomGrid>
                                                                                    </props.skeleton.CustomItem>
                                                                                </props.skeleton.CustomStack>
                                                                            </props.skeleton.CustomGrid>
                                                                            </>
                                                                        )
                                                                        : null
                                                                    }

                                                                    {
                                                                        // #region --------------[ base: Category ]------------------------------------------------------------------
                                                                        (
                                                                            Modules?.base?.Category
                                                                            && Modules?.base?.Category?.use 
                                                                            && Modules?.base?.Category?.sections?.indexOf(slice) > -1
                                                                        )
                                                                        ? (
                                                                            <props.skeleton.CustomGrid item sink="true" xs={12} sx={{ border: 'thin solid #83B1B5', padding: '10px', marginBottom: '10px' }}>
                                                                                <props.skeleton.CustomStack direction={'column'}>
                                                                                    <props.skeleton.CustomItem sink="true">
                                                                                        <props.skeleton.CustomGrid container sink="true" spacing={0} sx={{ /*border: 'thin solid red',*/ padding: '0px' }}>
                                                                                            <props.skeleton.CustomGrid item sink="true" xs={9} sx={{ /*border: 'thin dashed green',*/ paddingTop: '10px', paddingBottom: '10px' }}>
                                                                                                Category
                                                                                            </props.skeleton.CustomGrid>
                                                                                            <props.skeleton.CustomGrid item sink="true" xs={3} sx={{ /*border: 'thin dashed green',*/ paddingTop: '10px', paddingBottom: '10px', justifyContent: 'center', overflow: 'hidden' }}>
                                                                                                [Controls?]
                                                                                            </props.skeleton.CustomGrid>
                                                                                        </props.skeleton.CustomGrid>
                                                                                    </props.skeleton.CustomItem>
                                                                                    <props.skeleton.CustomItem sink="true">
                                                                                        <props.skeleton.CustomGrid container sink="true" spacing={0} sx={{ /*border: 'thin solid red',*/ padding: '0px' }}>
                                                                                            <props.skeleton.CustomGrid item sink="true" xs={12} sx={{ /*border: 'thin dashed green',*/ paddingTop: '0px', paddingBottom: '0px' }}>
                                                                                                <props.skeleton.CustomFormControl sx={{  }}>
                                                                                                    <InputLabel id="category-multiple-checkbox-label">Category</InputLabel>
                                                                                                    <props.skeleton.CustomSelect
                                                                                                        labelId="category-multiple-checkbox-label"
                                                                                                        id="category-multiple-checkbox"
                                                                                                        multiple
                                                                                                        value={selectedCategories || ''}
                                                                                                        disabled={resources.SliceFormIsDisabled}
                                                                                                        onChange={handleCategoryChange}
                                                                                                        input={<OutlinedInput label="Category" />}
                                                                                                        renderValue={(selected) => selected.join(', ')}
                                                                                                        MenuProps={MenuProps}
                                                                                                    >
                                                                                                        {loadedcategories.map((cat) => (
                                                                                                            <MenuItem key={cat} value={cat}>
                                                                                                            <Checkbox checked={selectedCategories.indexOf(cat) > -1} />
                                                                                                            <ListItemText primary={cat} />
                                                                                                            </MenuItem>
                                                                                                        ))}
                                                                                                    </props.skeleton.CustomSelect>
                                                                                                </props.skeleton.CustomFormControl>
                                                                                            </props.skeleton.CustomGrid>
                                                                                        </props.skeleton.CustomGrid>
                                                                                    </props.skeleton.CustomItem>
                                                                                    <props.skeleton.CustomItem sink="true">
                                                                                        <props.skeleton.CustomGrid container sink="true" spacing={0} sx={{ /*border: 'thin solid red',*/ padding: '0px' }}>
                                                                                            <props.skeleton.CustomGrid item sink="true" xs={9} sx={{ /*border: 'thin dashed green',*/ paddingTop: '0px', paddingBottom: '0px' }}>
                                                                                                <props.skeleton.CustomStack direction={'row'} alignItems={'center'}>
                                                                                                    <props.skeleton.CustomItem sink="true">
                                                                                                                                                                            
                                                                                                    </props.skeleton.CustomItem>
                                                                                                    <props.skeleton.CustomItem sink="true">
                                                                                                        
                                                                                                    </props.skeleton.CustomItem>
                                                                                                </props.skeleton.CustomStack>
                                                                                            </props.skeleton.CustomGrid>
                                                                                            <props.skeleton.CustomGrid item sink="true" xs={3} sx={{ /*border: 'thin dashed green',*/ paddingTop: '0px', paddingBottom: '0px', justifyContent: 'center' }}>
                                                                                                
                                                                                            </props.skeleton.CustomGrid>
                                                                                        </props.skeleton.CustomGrid>
                                                                                    </props.skeleton.CustomItem>
                                                                                    <props.skeleton.CustomItem>
                                                                                        <props.skeleton.CustomGrid container sink="true" spacing={0} sx={{ /*border: 'thin solid red',*/ padding: '0px' }}>
                                                                                            <props.skeleton.CustomGrid item sink="true" xs={9} sx={{ /*border: 'thin dashed green',*/ paddingTop: '10px', paddingBottom: '10px' }}>
                                                                                                <props.skeleton.CustomStack direction={'row'} alignItems={'center'}>
                                                                                                    <props.skeleton.CustomItem sink="true">
                                                                                                                                                                    
                                                                                                    </props.skeleton.CustomItem>
                                                                                                    <props.skeleton.CustomItem sink="true">
                                                                                                        
                                                                                                    </props.skeleton.CustomItem>
                                                                                                </props.skeleton.CustomStack>
                                                                                            </props.skeleton.CustomGrid>
                                                                                            <props.skeleton.CustomGrid item sink="true" xs={3} sx={{ /*border: 'thin dashed green',*/ paddingTop: '0px', paddingBottom: '0px', justifyContent: 'center' }}>
                                                                                                
                                                                                            </props.skeleton.CustomGrid>
                                                                                        </props.skeleton.CustomGrid>
                                                                                    </props.skeleton.CustomItem>
                                                                                    <props.skeleton.CustomItem sink="true">
                                                                                        <props.skeleton.CustomGrid container sink="true" spacing={0} sx={{ /*border: 'thin solid red',*/ padding: '0px' }}>
                                                                                            <props.skeleton.CustomGrid item sink="true" xs={9} sx={{ /*border: 'thin dashed green',*/ paddingTop: '0px', paddingBottom: '0px' }}>
                                                                                                
                                                                                            </props.skeleton.CustomGrid>
                                                                                            <props.skeleton.CustomGrid item sink="true" xs={3} sx={{ border: '', paddingTop: '0px', paddingBottom: '0px', justifyContent: 'center' }}>
                                                                                                
                                                                                            </props.skeleton.CustomGrid>
                                                                                        </props.skeleton.CustomGrid>
                                                                                    </props.skeleton.CustomItem>
                                                                                    <props.skeleton.CustomItem sink="true">
                                                                                        <props.skeleton.CustomGrid container sink="true" spacing={0} sx={{ /*border: 'thin solid red',*/ padding: '0px' }}>
                                                                                            <props.skeleton.CustomGrid item sink="true" xs={9} sx={{ /*border: 'thin dashed green',*/ paddingTop: '0px', paddingBottom: '0px' }}>
                                                                                                
                                                                                            </props.skeleton.CustomGrid>
                                                                                            <props.skeleton.CustomGrid item sink="true" xs={3} sx={{ border: '', paddingTop: '0px', paddingBottom: '0px', justifyContent: 'center' }}>
                                                                                                
                                                                                            </props.skeleton.CustomGrid>
                                                                                        </props.skeleton.CustomGrid>
                                                                                    </props.skeleton.CustomItem>
                                                                                    <props.skeleton.CustomItem sink="true">
                                                                                        <props.skeleton.CustomGrid container sink="true" spacing={0} sx={{ /*border: 'thin solid red',*/ padding: '0px' }}>
                                                                                            <props.skeleton.CustomGrid item sink="true" xs={9} sx={{ /*border: 'thin dashed green',*/ paddingTop: '0px', paddingBottom: '0px' }}>
                                                                                                
                                                                                            </props.skeleton.CustomGrid>
                                                                                            <props.skeleton.CustomGrid item xs={3} sx={{ border: '', paddingTop: '0px', paddingBottom: '0px', justifyContent: 'center' }}>
                                                                                                
                                                                                            </props.skeleton.CustomGrid>
                                                                                        </props.skeleton.CustomGrid>
                                                                                    </props.skeleton.CustomItem>
                                                                                    <props.skeleton.CustomItem sink="true">
                                                                                        <props.skeleton.CustomGrid container sink="true" spacing={0} sx={{ /*border: 'thin solid red',*/ padding: '0px' }}>
                                                                                            <props.skeleton.CustomGrid item sink="true" xs={9} sx={{ /*border: 'thin dashed green',*/ paddingTop: '0px', paddingBottom: '0px' }}>
                                                                                                
                                                                                            </props.skeleton.CustomGrid>
                                                                                            <props.skeleton.CustomGrid item sink="true" xs={3} sx={{ border: '', paddingTop: '0px', paddingBottom: '0px', justifyContent: 'center' }}>
                                                                                                
                                                                                            </props.skeleton.CustomGrid>
                                                                                        </props.skeleton.CustomGrid>
                                                                                    </props.skeleton.CustomItem>
                                                                                    <props.skeleton.CustomItem sink="true">
                                                                                        <props.skeleton.CustomGrid container sink="true" spacing={0} sx={{ /*border: 'thin solid red',*/ padding: '0px' }}>
                                                                                            <props.skeleton.CustomGrid item sink="true" xs={9} sx={{ /*border: 'thin dashed green',*/ paddingTop: '0px', paddingBottom: '0px' }}>
                                                                                                
                                                                                            </props.skeleton.CustomGrid>
                                                                                            <props.skeleton.CustomGrid item sink="true" xs={3} sx={{ border: '', paddingTop: '0px', paddingBottom: '0px', justifyContent: 'center' }}>
                                                                                                <props.skeleton.Bar button="true" padding5lronly="true" center="true" sink="true" sx={{ fontSize: '14px' }} onClick={(e) => handleCreateNewCategory()}>Add New</props.skeleton.Bar>
                                                                                            </props.skeleton.CustomGrid>
                                                                                        </props.skeleton.CustomGrid>
                                                                                    </props.skeleton.CustomItem>

                                                                                </props.skeleton.CustomStack>
                                                                            </props.skeleton.CustomGrid>
                                                                        )
                                                                        : null
                                                                    }

                                                                    {
                                                                        // #region --------------[ base: Tag ]-------------------------------------------------------------------------
                                                                        (
                                                                            Modules?.base?.Tag
                                                                            && Modules?.base?.Tag?.use 
                                                                            && Modules?.base?.Tag?.sections?.indexOf(slice) > -1
                                                                        )
                                                                        ? (
                                                                            <props.skeleton.CustomGrid item sink="true" xs={12} sx={{ border: 'thin solid #83B1B5', padding: '10px', marginBottom: '10px' }}>
                                                                                <props.skeleton.CustomStack direction={'column'}>
                                                                                    <props.skeleton.CustomItem sink="true">
                                                                                        <props.skeleton.CustomGrid container sink="true" spacing={0} sx={{ /*border: 'thin solid red',*/ padding: '0px' }}>
                                                                                            <props.skeleton.CustomGrid item sink="true" xs={9} sx={{ /*border: 'thin dashed green',*/ paddingTop: '10px', paddingBottom: '10px' }}>
                                                                                                Tags
                                                                                            </props.skeleton.CustomGrid>
                                                                                            <props.skeleton.CustomGrid item sink="true" xs={3} sx={{ /*border: 'thin dashed green',*/ paddingTop: '10px', paddingBottom: '10px', justifyContent: 'center', overflow: 'hidden' }}>
                                                                                                [Controls?]
                                                                                            </props.skeleton.CustomGrid>
                                                                                        </props.skeleton.CustomGrid>
                                                                                    </props.skeleton.CustomItem>
                                                                                    <props.skeleton.CustomItem sink="true">
                                                                                        <props.skeleton.CustomGrid container sink="true" spacing={0} sx={{ /*border: 'thin solid red',*/ padding: '0px' }}>
                                                                                            <props.skeleton.CustomGrid item sink="true" xs={12} sx={{ /*border: 'thin dashed green',*/ paddingTop: '0px', paddingBottom: '0px' }}>
                                                                                                <props.skeleton.CustomFormControl sx={{  }}>
                                                                                                    <InputLabel id="tags-multiple-checkbox-label">Tags</InputLabel>
                                                                                                    <props.skeleton.CustomSelect
                                                                                                        labelId="tags-multiple-checkbox-label"
                                                                                                        id="tags-multiple-checkbox"
                                                                                                        multiple
                                                                                                        value={selectedTags || ''}
                                                                                                        disabled={resources.SliceFormIsDisabled}
                                                                                                        onChange={handleTagsChange}
                                                                                                        input={<OutlinedInput label="Tags" />}
                                                                                                        renderValue={(selected) => selected.join(', ')}
                                                                                                        MenuProps={MenuProps}
                                                                                                    >
                                                                                                        {loadedtags.map((tag) => (
                                                                                                            <MenuItem key={tag} value={tag}>
                                                                                                            <Checkbox checked={selectedTags.indexOf(tag) > -1} />
                                                                                                            <ListItemText primary={tag} />
                                                                                                            </MenuItem>
                                                                                                        ))}
                                                                                                    </props.skeleton.CustomSelect>
                                                                                                </props.skeleton.CustomFormControl>
                                                                                            </props.skeleton.CustomGrid>
                                                                                        </props.skeleton.CustomGrid>
                                                                                    </props.skeleton.CustomItem>
                                                                                    <props.skeleton.CustomItem sink="true">
                                                                                        <props.skeleton.CustomGrid container sink="true" spacing={0} sx={{ /*border: 'thin solid red',*/ padding: '0px' }}>
                                                                                            <props.skeleton.CustomGrid item sink="true" xs={9} sx={{ /*border: 'thin dashed green',*/ paddingTop: '0px', paddingBottom: '0px' }}>
                                                                                                <props.skeleton.CustomStack direction={'row'} alignItems={'center'}>
                                                                                                    <props.skeleton.CustomItem sink="true">
                                                                                                                                                                            
                                                                                                    </props.skeleton.CustomItem>
                                                                                                    <props.skeleton.CustomItem sink="true">
                                                                                                        
                                                                                                    </props.skeleton.CustomItem>
                                                                                                </props.skeleton.CustomStack>
                                                                                            </props.skeleton.CustomGrid>
                                                                                            <props.skeleton.CustomGrid item sink="true" xs={3} sx={{ /*border: 'thin dashed green',*/ paddingTop: '0px', paddingBottom: '0px', justifyContent: 'center' }}>
                                                                                                
                                                                                            </props.skeleton.CustomGrid>
                                                                                        </props.skeleton.CustomGrid>
                                                                                    </props.skeleton.CustomItem>
                                                                                    <props.skeleton.CustomItem sink="true">
                                                                                        <props.skeleton.CustomGrid container sink="true" spacing={0} sx={{ /*border: 'thin solid red',*/ padding: '0px' }}>
                                                                                            <props.skeleton.CustomGrid item sink="true" xs={9} sx={{ /*border: 'thin dashed green',*/ paddingTop: '10px', paddingBottom: '10px' }}>
                                                                                                <props.skeleton.CustomStack direction={'row'} alignItems={'center'}>
                                                                                                    <props.skeleton.CustomItem sink="true">
                                                                                                                                                                    
                                                                                                    </props.skeleton.CustomItem>
                                                                                                    <props.skeleton.CustomItem sink="true">
                                                                                                        
                                                                                                    </props.skeleton.CustomItem>
                                                                                                </props.skeleton.CustomStack>
                                                                                            </props.skeleton.CustomGrid>
                                                                                            <props.skeleton.CustomGrid item sink="true" xs={3} sx={{ /*border: 'thin dashed green',*/ paddingTop: '0px', paddingBottom: '0px', justifyContent: 'center' }}>
                                                                                                
                                                                                            </props.skeleton.CustomGrid>
                                                                                        </props.skeleton.CustomGrid>
                                                                                    </props.skeleton.CustomItem>
                                                                                    <props.skeleton.CustomItem sink="true">
                                                                                        <props.skeleton.CustomGrid container sink="true" spacing={0} sx={{ /*border: 'thin solid red',*/ padding: '0px' }}>
                                                                                            <props.skeleton.CustomGrid item sink="true" xs={9} sx={{ /*border: 'thin dashed green',*/ paddingTop: '0px', paddingBottom: '0px' }}>
                                                                                                
                                                                                            </props.skeleton.CustomGrid>
                                                                                            <props.skeleton.CustomGrid item sink="true" xs={3} sx={{ border: '', paddingTop: '0px', paddingBottom: '0px', justifyContent: 'center' }}>
                                                                                                
                                                                                            </props.skeleton.CustomGrid>
                                                                                        </props.skeleton.CustomGrid>
                                                                                    </props.skeleton.CustomItem>
                                                                                    <props.skeleton.CustomItem sink="true">
                                                                                        <props.skeleton.CustomGrid container sink="true" spacing={0} sx={{ /*border: 'thin solid red',*/ padding: '0px' }}>
                                                                                            <props.skeleton.CustomGrid item sink="true" xs={9} sx={{ /*border: 'thin dashed green',*/ paddingTop: '0px', paddingBottom: '0px' }}>
                                                                                                
                                                                                            </props.skeleton.CustomGrid>
                                                                                            <props.skeleton.CustomGrid item sink="true" xs={3} sx={{ border: '', paddingTop: '0px', paddingBottom: '0px', justifyContent: 'center' }}>
                                                                                                
                                                                                            </props.skeleton.CustomGrid>
                                                                                        </props.skeleton.CustomGrid>
                                                                                    </props.skeleton.CustomItem>
                                                                                    <props.skeleton.CustomItem sink="true">
                                                                                        <props.skeleton.CustomGrid container sink="true" spacing={0} sx={{ /*border: 'thin solid red',*/ padding: '0px' }}>
                                                                                            <props.skeleton.CustomGrid item sink="true" xs={9} sx={{ /*border: 'thin dashed green',*/ paddingTop: '0px', paddingBottom: '0px' }}>
                                                                                                
                                                                                            </props.skeleton.CustomGrid>
                                                                                            <props.skeleton.CustomGrid item sink="true" xs={3} sx={{ border: '', paddingTop: '0px', paddingBottom: '0px', justifyContent: 'center' }}>
                                                                                                
                                                                                            </props.skeleton.CustomGrid>
                                                                                        </props.skeleton.CustomGrid>
                                                                                    </props.skeleton.CustomItem>
                                                                                    <props.skeleton.CustomItem sink="true">
                                                                                        <props.skeleton.CustomGrid container sink="true" spacing={0} sx={{ /*border: 'thin solid red',*/ padding: '0px' }}>
                                                                                            <props.skeleton.CustomGrid item sink="true" xs={9} sx={{ /*border: 'thin dashed green',*/ paddingTop: '0px', paddingBottom: '0px' }}>
                                                                                                
                                                                                            </props.skeleton.CustomGrid>
                                                                                            <props.skeleton.CustomGrid item sink="true" xs={3} sx={{ border: '', paddingTop: '0px', paddingBottom: '0px', justifyContent: 'center' }}>
                                                                                                
                                                                                            </props.skeleton.CustomGrid>
                                                                                        </props.skeleton.CustomGrid>
                                                                                    </props.skeleton.CustomItem>
                                                                                    <props.skeleton.CustomItem sink="true">
                                                                                        <props.skeleton.CustomGrid container sink="true" spacing={0} sx={{ /*border: 'thin solid red',*/ padding: '0px' }}>
                                                                                            <props.skeleton.CustomGrid item sink="true" xs={9} sx={{ /*border: 'thin dashed green',*/ paddingTop: '0px', paddingBottom: '0px' }}>
                                                                                                
                                                                                            </props.skeleton.CustomGrid>
                                                                                            <props.skeleton.CustomGrid item sink="true" xs={3} sx={{ border: '', paddingTop: '0px', paddingBottom: '0px', justifyContent: 'center' }}>
                                                                                                <props.skeleton.Bar button="true" padding5lronly="true" center="true" sink="true" sx={{ fontSize: '14px' }} onClick={(e) => handleCreateNewTag()}>Add New</props.skeleton.Bar>
                                                                                            </props.skeleton.CustomGrid>
                                                                                        </props.skeleton.CustomGrid>
                                                                                    </props.skeleton.CustomItem>

                                                                                </props.skeleton.CustomStack>
                                                                            </props.skeleton.CustomGrid>
                                                                        )
                                                                        : null
                                                                    }

                                                                    {
                                                                        // #region --------------[ base: FeaturedImage ]------------------------------------------------------------------
                                                                        (
                                                                            Modules?.base?.FeaturedImage 
                                                                            && Modules?.base?.FeaturedImage?.use 
                                                                            && Modules?.base?.FeaturedImage?.sections?.indexOf(slice) > -1
                                                                        )
                                                                        ? (
                                                                            <props.skeleton.CustomGrid item sink="true" xs={12} sx={{ border: 'thin solid #83B1B5', padding: '10px', marginBottom: '10px' }}>
                                                                                <props.skeleton.CustomStack direction={'column'}>
                                                                                    <props.skeleton.CustomItem sink="true">
                                                                                        <props.skeleton.CustomGrid container spacing={0} sink="true" sx={{ /*border: 'thin solid red',*/ padding: '0px' }}>
                                                                                            <props.skeleton.CustomGrid item sink="true" xs={9} sx={{ /*border: 'thin dashed green',*/ paddingTop: '10px', paddingBottom: '10px' }}>
                                                                                                Featured Image
                                                                                            </props.skeleton.CustomGrid>
                                                                                            <props.skeleton.CustomGrid item sink="true" xs={3} sx={{ /*border: 'thin dashed green',*/ paddingTop: '10px', paddingBottom: '10px', justifyContent: 'center', overflow: 'hidden' }}>
                                                                                                [Controls?]
                                                                                            </props.skeleton.CustomGrid>
                                                                                        </props.skeleton.CustomGrid>
                                                                                    </props.skeleton.CustomItem>
                                                                                    <props.skeleton.CustomItem sink="true">
                                                                                        <props.skeleton.CustomGrid container spacing={0} sink="true" sx={{ /*border: 'thin solid red',*/ padding: '0px' }}>
                                                                                            <props.skeleton.CustomGrid item sink="true" xs={12} sx={{ /*border: 'thin dashed green',*/ paddingTop: '0px', paddingBottom: '0px' }}>
                                                                                                <UploadDragZone
                                                                                                    skeleton={props.skeleton} 
                                                                                                    constraints={{ height: '25vh', width: '97%' }}
                                                                                                    query={query} 
                                                                                                    section={query}
                                                                                                    what="featuredimage"
                                                                                                    postslug=""
                                                                                                    resources={resources}
                                                                                                    user={currentUser?.userName}
                                                                                                    img=""
                                                                                                    settingServerAPIBaseUrl={props.settingServerAPIBaseUrl}
                                                                                                />
                                                                                            </props.skeleton.CustomGrid>
                                                                                        </props.skeleton.CustomGrid>
                                                                                    </props.skeleton.CustomItem>
                                                                                    <props.skeleton.CustomItem sink="true">
                                                                                        <props.skeleton.CustomGrid container spacing={0} sink="true" sx={{ /*border: 'thin solid red',*/ padding: '0px' }}>
                                                                                            <props.skeleton.CustomGrid item xs={9} sink="true" sx={{ /*border: 'thin dashed green',*/ paddingTop: '0px', paddingBottom: '0px' }}>
                                                                                                <props.skeleton.CustomStack direction={'row'} alignItems={'center'}>
                                                                                                    <props.skeleton.CustomItem>
                                                                                                                                                                            
                                                                                                    </props.skeleton.CustomItem>
                                                                                                    <props.skeleton.CustomItem sink="true">
                                                                                                        
                                                                                                    </props.skeleton.CustomItem>
                                                                                                </props.skeleton.CustomStack>
                                                                                            </props.skeleton.CustomGrid>
                                                                                            <props.skeleton.CustomGrid item xs={3} sink="true" sx={{ /*border: 'thin dashed green',*/ paddingTop: '0px', paddingBottom: '0px', justifyContent: 'center' }}>
                                                                                                
                                                                                            </props.skeleton.CustomGrid>
                                                                                        </props.skeleton.CustomGrid>
                                                                                    </props.skeleton.CustomItem>
                                                                                    <props.skeleton.CustomItem sink="true">
                                                                                        <props.skeleton.CustomGrid container spacing={0} sink="true" sx={{ /*border: 'thin solid red',*/ padding: '0px' }}>
                                                                                            <props.skeleton.CustomGrid item xs={9} sink="true" sx={{ /*border: 'thin dashed green',*/ paddingTop: '10px', paddingBottom: '10px' }}>
                                                                                                <props.skeleton.CustomStack direction={'row'} alignItems={'center'}>
                                                                                                    <props.skeleton.CustomItem>
                                                                                                                                                                    
                                                                                                    </props.skeleton.CustomItem>
                                                                                                    <props.skeleton.CustomItem sink="true">
                                                                                                        
                                                                                                    </props.skeleton.CustomItem>
                                                                                                </props.skeleton.CustomStack>
                                                                                            </props.skeleton.CustomGrid>
                                                                                            <props.skeleton.CustomGrid item xs={3} sink="true" sx={{ /*border: 'thin dashed green',*/ paddingTop: '0px', paddingBottom: '0px', justifyContent: 'center' }}>
                                                                                                
                                                                                            </props.skeleton.CustomGrid>
                                                                                        </props.skeleton.CustomGrid>
                                                                                    </props.skeleton.CustomItem>
                                                                                    <props.skeleton.CustomItem sink="true">
                                                                                        <props.skeleton.CustomGrid container spacing={0} sink="true" sx={{ /*border: 'thin solid red',*/ padding: '0px' }}>
                                                                                            <props.skeleton.CustomGrid item xs={9} sink="true" sx={{ /*border: 'thin dashed green',*/ paddingTop: '0px', paddingBottom: '0px' }}>
                                                                                                
                                                                                            </props.skeleton.CustomGrid>
                                                                                            <props.skeleton.CustomGrid item xs={3} sink="true" sx={{ border: '', paddingTop: '0px', paddingBottom: '0px', justifyContent: 'center' }}>
                                                                                                
                                                                                            </props.skeleton.CustomGrid>
                                                                                        </props.skeleton.CustomGrid>
                                                                                    </props.skeleton.CustomItem>
                                                                                    <props.skeleton.CustomItem sink="true">
                                                                                        <props.skeleton.CustomGrid container spacing={0} sx={{ /*border: 'thin solid red',*/ padding: '0px' }}>
                                                                                            <props.skeleton.CustomGrid item xs={9} sink="true" sx={{ /*border: 'thin dashed green',*/ paddingTop: '0px', paddingBottom: '0px' }}>
                                                                                                
                                                                                            </props.skeleton.CustomGrid>
                                                                                            <props.skeleton.CustomGrid item xs={3} sink="true" sx={{ border: '', paddingTop: '0px', paddingBottom: '0px', justifyContent: 'center' }}>
                                                                                                
                                                                                            </props.skeleton.CustomGrid>
                                                                                        </props.skeleton.CustomGrid>
                                                                                    </props.skeleton.CustomItem>
                                                                                    
                                                                                </props.skeleton.CustomStack>
                                                                            </props.skeleton.CustomGrid>
                                                                        )
                                                                        : null
                                                                    }

                                                                    {
                                                                        /* PLUGIN LOADING? IDK */
                                                                    }

                                                                    {
                                                                        // #region ----------[ plugin: LoremIpsum  ]--------------------------------------------------------------------
                                                                        (
                                                                            Modules?.plugins?.LoremIpsum 
                                                                            && Modules?.plugins?.LoremIpsum?.use 
                                                                            && Modules?.plugins?.LoremIpsum?.sections?.indexOf(slice) > -1
                                                                        )
                                                                        ? (
                                                                            <props.skeleton.CustomGrid item sink="true" xs={12} sx={{ border: 'thin solid #83B1B5', padding: '10px' }}>
                                                                                <props.skeleton.CustomStack direction={'column'}>
                                                                                    <props.skeleton.CustomItem sink="true">
                                                                                        <props.skeleton.CustomGrid container spacing={0} sink="true" sx={{ /*border: 'thin solid red',*/ padding: '0px' }}>
                                                                                            <props.skeleton.CustomGrid item sink="true" xs={9} sx={{ /*border: 'thin dashed green',*/ paddingTop: '10px', paddingBottom: '10px' }}>
                                                                                                LoremImpsum Generate
                                                                                            </props.skeleton.CustomGrid>
                                                                                            <props.skeleton.CustomGrid item xs={3} sink="true" sx={{ /*border: 'thin dashed green',*/ paddingTop: '10px', paddingBottom: '10px', justifyContent: 'center', overflow: 'hidden' }}>
                                                                                                [Controls?]
                                                                                            </props.skeleton.CustomGrid>
                                                                                        </props.skeleton.CustomGrid>
                                                                                    </props.skeleton.CustomItem>
                                                                                    <props.skeleton.CustomItem sink="true">
                                                                                        <props.skeleton.CustomGrid container spacing={0} sink="true" sx={{ /*border: 'thin solid red',*/ padding: '0px' }}>
                                                                                            <props.skeleton.CustomGrid item xs={12} sink="true" sx={{ /*border: 'thin dashed green',*/ paddingTop: '0px', paddingBottom: '0px' }}>
                                                                                                <FormControlLabel align='left' 
                                                                                                    control={
                                                                                                        <Checkbox 
                                                                                                            checked={onGenerate}
                                                                                                            disabled={resources.SliceFormIsDisabled}
                                                                                                            onChange={() => setOnGenerate(!onGenerate)}
                                                                                                        />
                                                                                                    } label='Auto Generate' 
                                                                                                />
                                                                                            </props.skeleton.CustomGrid>
                                                                                        </props.skeleton.CustomGrid>
                                                                                    </props.skeleton.CustomItem>
                                                                                    <props.skeleton.CustomItem sink="true">
                                                                                        <props.skeleton.CustomGrid container spacing={0} sink="true" sx={{ /*border: 'thin solid red',*/ padding: '0px' }}>
                                                                                            <props.skeleton.CustomGrid item xs={12} sink="true" sx={{ /*border: 'thin dashed green',*/ paddingTop: '0px', paddingBottom: '0px' }}>
                                                                                                <props.skeleton.CustomStack direction={'row'} alignItems={'center'}>
                                                                                                    <props.skeleton.CustomItem sink="true">
                                                                                                        <Transition 
                                                                                                            timeout={1000} in={onGenerate} mountOnEnter unmountOnExit
                                                                                                            onEntering={(node) => {
                                                                                                                gsap.from(node, {
                                                                                                                    y: -50,
                                                                                                                    autoAlpha: onGenerate ? 0 : 1,
                                                                                                                    duration: 0.5
                                                                                                                })
                                                                                                            }}
                                                                                                            addEndListener={(node, done) => {
                                                                                                                gsap.to(node, {
                                                                                                                    y: onGenerate ? 0 : -50,
                                                                                                                    autoAlpha: onGenerate ? 1 : 0,
                                                                                                                    onComplete: done
                                                                                                                })
                                                                                                            }}
                                                                                                        >   
                                                                                                            <props.skeleton.CustomStack direction="column" justifyContent="flex-start" alignItems="space-evenly">
                                                                                                                <props.skeleton.CustomItem sink="true">
                                                                                                                    <props.skeleton.Heading h7>Words per sentence</props.skeleton.Heading>
                                                                                                                </props.skeleton.CustomItem>
                                                                                                                <props.skeleton.CustomItem sink="true">
                                                                                                                    <Slider  
                                                                                                                        marks={[
                                                                                                                            {value: loremOptions.minWordPerSentence, label: loremOptions.minWordPerSentence},
                                                                                                                            {value: loremOptions.maxWordPerSentence, label: loremOptions.maxWordPerSentence},
                                                                                                                        ]}
                                                                                                                        min={loremOptions.minWordPerSentence} 
                                                                                                                        max={loremOptions.maxWordPerSentence} 
                                                                                                                        value={loremOptions.wordPerSentence}
                                                                                                                        onChange={(e, value) => setLoremOptions({...loremOptions, wordPerSentence: value})}
                                                                                                                    />
                                                                                                                </props.skeleton.CustomItem>
                                                                                                                <props.skeleton.CustomItem sink="true">
                                                                                                                    <props.skeleton.Heading h7>Sentences Per Paragraphs</props.skeleton.Heading>
                                                                                                                </props.skeleton.CustomItem>
                                                                                                                <props.skeleton.CustomItem sink="true">
                                                                                                                    <Slider  
                                                                                                                        marks={[
                                                                                                                            {value: loremOptions.minSentencePerParagraph, label: loremOptions.minWordPerSentence},
                                                                                                                            {value: loremOptions.maxSentencePerParagraph, 
                                                                                                                                label: loremOptions.maxSentencePerParagraph
                                                                                                                            },
                                                                                                                        ]}
                                                                                                                        min={loremOptions.minSentencePerParagraph} 
                                                                                                                        max={loremOptions.maxSentencePerParagraph} 
                                                                                                                        value={loremOptions.sentencePerParagraph}
                                                                                                                        onChange={(e, value) => setLoremOptions({...loremOptions, sentencePerParagraph: value})}
                                                                                                                    />
                                                                                                                </props.skeleton.CustomItem>
                                                                                                                <props.skeleton.CustomItem sink="true">
                                                                                                                    <props.skeleton.Heading h7>Paragraphs Per Post</props.skeleton.Heading>
                                                                                                                </props.skeleton.CustomItem>
                                                                                                                <props.skeleton.CustomItem sink="true">
                                                                                                                    <Slider  
                                                                                                                        marks={[
                                                                                                                            {value: loremOptions.minParagraphPerPost, label: loremOptions.minParagraphPerPost},
                                                                                                                            {value: loremOptions.maxParagraphPerPost, label: loremOptions.maxParagraphPerPost},
                                                                                                                        ]}
                                                                                                                        min={loremOptions.minParagraphPerPost} 
                                                                                                                        max={loremOptions.maxParagraphPerPost} 
                                                                                                                        value={loremOptions.paragraphPerPost}
                                                                                                                        onChange={(e, value) => setLoremOptions({...loremOptions, paragraphPerPost: value})}
                                                                                                                    />
                                                                                                                </props.skeleton.CustomItem>
                                                                                                            </props.skeleton.CustomStack>
                                                                                                        </Transition>                                                                
                                                                                                    </props.skeleton.CustomItem>
                                                                                                    <props.skeleton.CustomItem sink="true">
                                                                                                        
                                                                                                    </props.skeleton.CustomItem>
                                                                                                </props.skeleton.CustomStack>
                                                                                            </props.skeleton.CustomGrid>
                                                                                            <props.skeleton.CustomGrid item xs={12} sink="true" sx={{ /*border: 'thin dashed green',*/ paddingTop: '0px', paddingBottom: '0px', justifyContent: 'flex-end' }}>
                                                                                                <Transition 
                                                                                                        timeout={1000} in={onGenerate} mountOnEnter unmountOnExit
                                                                                                        onEntering={(node) => {
                                                                                                            gsap.from(node, {
                                                                                                                y: -50,
                                                                                                                autoAlpha: onGenerate ? 0 : 1,
                                                                                                                duration: 0.5
                                                                                                            })
                                                                                                        }}
                                                                                                        addEndListener={(node, done) => {
                                                                                                            gsap.to(node, {
                                                                                                                y: onGenerate ? 0 : -50,
                                                                                                                autoAlpha: onGenerate ? 1 : 0,
                                                                                                                onComplete: done
                                                                                                            })
                                                                                                        }}
                                                                                                    >
                                                                                                    <props.skeleton.CustomItem>
                                                                                                        <props.skeleton.Bar button="true" center="true" onClick={handleGenerate}>Generate</props.skeleton.Bar>
                                                                                                    </props.skeleton.CustomItem>  
                                                                                                </Transition> 
                                                                                            </props.skeleton.CustomGrid>
                                                                                        </props.skeleton.CustomGrid>
                                                                                    </props.skeleton.CustomItem>
                                                                                    <props.skeleton.CustomItem sink="true">
                                                                                        <props.skeleton.CustomGrid container spacing={0} sink="true" sx={{ /*border: 'thin solid red',*/ padding: '0px' }}>
                                                                                            <props.skeleton.CustomGrid item xs={9} sink="true" sx={{ /*border: 'thin dashed green',*/ paddingTop: '10px', paddingBottom: '10px' }}>
                                                                                                <props.skeleton.CustomStack direction={'row'} alignItems={'center'}>
                                                                                                    <props.skeleton.CustomItem sink="true">
                                                                                                                                                                    
                                                                                                    </props.skeleton.CustomItem>
                                                                                                    <props.skeleton.CustomItem sink="true">
                                                                                                        
                                                                                                    </props.skeleton.CustomItem>
                                                                                                </props.skeleton.CustomStack>
                                                                                            </props.skeleton.CustomGrid>
                                                                                            <props.skeleton.CustomGrid item xs={3} sink="true" sx={{ /*border: 'thin dashed green',*/ paddingTop: '0px', paddingBottom: '0px', justifyContent: 'center' }}>
                                                                                                
                                                                                            </props.skeleton.CustomGrid>
                                                                                        </props.skeleton.CustomGrid>
                                                                                    </props.skeleton.CustomItem>
                                                                                    <props.skeleton.CustomItem sink="true">
                                                                                        <props.skeleton.CustomGrid container spacing={0} sink="true" sx={{ /*border: 'thin solid red',*/ padding: '0px' }}>
                                                                                            <props.skeleton.CustomGrid item xs={9} sink="true" sx={{ /*border: 'thin dashed green',*/ paddingTop: '0px', paddingBottom: '0px' }}>
                                                                                                
                                                                                            </props.skeleton.CustomGrid>
                                                                                            <props.skeleton.CustomGrid item xs={3} sink="true" sx={{ border: '', paddingTop: '0px', paddingBottom: '0px', justifyContent: 'center' }}>
                                                                                                
                                                                                            </props.skeleton.CustomGrid>
                                                                                        </props.skeleton.CustomGrid>
                                                                                    </props.skeleton.CustomItem>
                                                                                    <props.skeleton.CustomItem>
                                                                                        <props.skeleton.CustomGrid container spacing={0} sink="true" sx={{ /*border: 'thin solid red',*/ padding: '0px' }}>
                                                                                            <props.skeleton.CustomGrid item xs={9} sink="true" sx={{ /*border: 'thin dashed green',*/ paddingTop: '0px', paddingBottom: '0px' }}>
                                                                                                
                                                                                            </props.skeleton.CustomGrid>
                                                                                            <props.skeleton.CustomGrid item xs={3} sink="true" sx={{ border: '', paddingTop: '0px', paddingBottom: '0px', justifyContent: 'center' }}>
                                                                                                
                                                                                            </props.skeleton.CustomGrid>
                                                                                        </props.skeleton.CustomGrid>
                                                                                    </props.skeleton.CustomItem>
                                                                                    
                                                                                </props.skeleton.CustomStack>
                                                                            </props.skeleton.CustomGrid>
                                                                        )
                                                                        : null
                                                                    }
                                                                    </>
                                                                }
                                                            </props.skeleton.CustomGrid>
                                                        </props.skeleton.CustomGrid>
                                                    </props.skeleton.CustomGrid>
                                                    {
                                                        /*
                                                        ===============================================================================================
                                                        #endregion - Long Post (ie. Blog post for global or personal page)
                                                        ===============================================================================================
                                                        */
                                                    }
                                                </TabPanel>
                                                <TabPanel value="2" sx={{ width: '100%'}}>
                                                {
                                                        /*
                                                        ===============================================================================================
                                                        #region - Short Post (ie. "Tweet" for personal page)
                                                        ===============================================================================================
                                                        */
                                                    }
                                                    <props.skeleton.CustomGrid container spacing={2}>
                                                        <props.skeleton.CustomGrid item xs={12}>
                                                            <props.skeleton.CustomStack direction="column" spacing={2}>
                                                                {
                                                                    // #region --------------[ base: Content ]------------------------------------------------------------------
                                                                    (
                                                                        Modules?.base?.Content 
                                                                        && Modules?.base?.Content?.use 
                                                                        && Modules?.base?.Content?.sections?.indexOf(slice) > -1
                                                                    )
                                                                    ? (
                                                                        <>
                                                                        <props.skeleton.CustomItem xs={12} sm={12} md={12} lg={12}>
                                                                            <props.skeleton.CustomTextField
                                                                                multiline   
                                                                                rows={3}  
                                                                                name='content'
                                                                                label="What's on your mind?"
                                                                                sx={{
                                                                                    height: "100%"
                                                                                }}
                                                                                disabled={resources.SliceFormIsDisabled}
                                                                                value={newShortSlice.content || ''}
                                                                                onChange={(e) => setNewShortSlice({...newShortSlice, content: e.target.value})}
                                                                            />
                                                                        </props.skeleton.CustomItem>
                                                                        <props.skeleton.CustomItem xs={12} sm={12} md={12} lg={12}>
                                                                            <props.skeleton.Heading nobg>Character count: {newShortSlice.content.length} | Word count: {newShortSlice.content.split(' ').length}</props.skeleton.Heading>                                    
                                                                        </props.skeleton.CustomItem>
                                                                        </>
                                                                    )
                                                                    : null
                                                                    // #endregion --------------------------------------------------------------------------------------------------                                                             
                                                                }
                                                                <props.skeleton.CustomItem>
                                                                    <props.skeleton.CustomGrid container spacing={0}>
                                                                        <props.skeleton.CustomGrid item xs={4}>
                                                                            <props.skeleton.CustomStack direction="row" alignItems="flex-start" spacing={1}>
                                                                                <props.skeleton.CustomItem>
                                                                                    <props.skeleton.Bar button="true" padding10lronly="true" center="true" onClick={(e) => {}}>Image</props.skeleton.Bar>
                                                                                </props.skeleton.CustomItem>

                                                                                <props.skeleton.CustomItem>
                                                                                    <props.skeleton.Bar button="true" padding10lronly="true" center="true" onClick={(e) => {}}>GIF</props.skeleton.Bar>
                                                                                </props.skeleton.CustomItem>

                                                                                <props.skeleton.CustomItem>
                                                                                    <props.skeleton.Bar button="true" padding10lronly="true" center="true" onClick={(e) => {}}>Poll</props.skeleton.Bar>
                                                                                </props.skeleton.CustomItem>
                                                                                
                                                                                <props.skeleton.CustomItem>
                                                                                    <props.skeleton.Bar button="true" padding10lronly="true" center="true" onClick={(e) => {}}>Smiley</props.skeleton.Bar>
                                                                                </props.skeleton.CustomItem>
                                                                                
                                                                                <props.skeleton.CustomItem>
                                                                                    <props.skeleton.Bar button="true" padding10lronly="true" center="true" onClick={(e) => {}}>Schedule</props.skeleton.Bar>
                                                                                </props.skeleton.CustomItem>
                                                                            </props.skeleton.CustomStack>
                                                                        </props.skeleton.CustomGrid>
                                                                        <props.skeleton.CustomGrid item xs={7.4} sx={{ paddingRight: '8px' }}>
                                                                            <props.skeleton.CustomStack direction="column" alignItems="flex-end" justifyContent="center">
                                                                                
                                                                            </props.skeleton.CustomStack>
                                                                        </props.skeleton.CustomGrid>
                                                                        <props.skeleton.CustomGrid item xs={0.6} sx={{ display: { xs: 'none', sm: 'none', md: 'none', lg: 'none' } }}>
                                                                            <props.skeleton.CustomStack direction="column" alignItems="flex-end">
                                                                                <props.skeleton.CustomItem>
                                                                                    <props.skeleton.Bar sx={{ fontSize: '18px' }} button="true" padding10lronly="true" center="true" onClick={(e) => handleSendShortSlice('published')}>Send</props.skeleton.Bar>
                                                                                </props.skeleton.CustomItem>
                                                                            </props.skeleton.CustomStack>
                                                                        </props.skeleton.CustomGrid>

                                                                        <props.skeleton.CustomGrid item xs={12} sx={{ display: { xs: 'block', sm: 'block', md: 'block', lg: 'block' } }}>
                                                                            &nbsp;
                                                                        </props.skeleton.CustomGrid>

                                                                        <props.skeleton.CustomGrid item xs={12} sx={{ display: { xs: 'block', sm: 'block', md: 'block', lg: 'block' } }}>
                                                                            <props.skeleton.Bar sx={{ fontSize: '28px', padding: '20px' }} button="true" center="true" onClick={(e) => handleSendShortSlice('published')}>Send</props.skeleton.Bar>
                                                                        </props.skeleton.CustomGrid>

                                                                    </props.skeleton.CustomGrid>
                                                                </props.skeleton.CustomItem>
                                                            </props.skeleton.CustomStack>
                                                        </props.skeleton.CustomGrid>                                                
                                                    </props.skeleton.CustomGrid>

                                                    {
                                                        /*
                                                        ===============================================================================================
                                                        #endregion - Short Post (ie. "Tweet" for personal page)
                                                        ===============================================================================================
                                                        */
                                                    }
                                                </TabPanel>
                                                <TabPanel value="3" sx={{ width: '100%'}}>
                                                {
                                                        /*
                                                        ===============================================================================================
                                                        #region - Still Post (ie. photo upload like instagram for personal page)
                                                        ===============================================================================================
                                                        */
                                                        <Typography variant='h5'>Coming Update 5</Typography>
                                                    }
                                                    
                                                    {
                                                        /*
                                                        ===============================================================================================
                                                        #endregion - Still Post (ie. photo upload like instagram for personal page)
                                                        ===============================================================================================
                                                        */                                                
                                                    }
                                                </TabPanel>
                                                <TabPanel value="4" sx={{ width: '100%'}}>
                                                {
                                                        /*
                                                        ===============================================================================================
                                                        #region - Video Post (ie. like Tiktok or Youtube for personal page)
                                                        ===============================================================================================
                                                        */      
                                                        <Typography variant='h5'>Coming Update 7</Typography>                                         
                                                    }
                                                    
                                                    {
                                                        /*
                                                        ===============================================================================================
                                                        #endregion - Video Post (ie. like Tiktok or Youtube for personal page)
                                                        ===============================================================================================
                                                        */
                                                    }
                                                </TabPanel>                                        
                                            </TabContext>                               
                                        </props.skeleton.CustomStack>
                                    </props.skeleton.CustomGrid>                            
                                    /*
                                    -- #endregion -----------------------------------------------------------------------------------------------------                    
                                    */
                                }                       
                                {/* #endregion -----[ NOUGAT ]---------------------------------------------------------------------*/}
                            </props.skeleton.CustomGrid>                            
                        </props.skeleton.CustomGrid>
                    </props.skeleton.CustomGrid>                              
                </props.skeleton.CustomGrid>
            </props.skeleton.CustomStack>
        </props.skeleton.InnerContent>
        </>
    )
}
// [][][][][][][][][][][][][][][][][][][][][ START IMPORTS ][][][][][][][][][][][][][][][][][][][][][][][][][][][]
// ####################### GLOBAL ############################################################################
// Import from React
import { useState, useEffect } from 'react'

// Import axios
//import axios from 'axios'

// Import Toast
// eslint-disable-next-line
import { toast } from 'react-toastify'

// Import Auth
import { useAuth } from '../../middleware/contextHooks'

// Import reactDOM
import { useNavigate } from 'react-router-dom'
// ####################### END OF GLOBAL #####################################################################

// ####################### SPECIFIC ##########################################################################
// Import globalStyles elements
import { /*CustomContainer as Container*/ } from '../../themes/Darwinian2023/skeleton/global'

// Import from MUI
import {
    //Avatar,
    //Button,
} from '@mui/material'

/*
// Import mainStyles
import { 
    Bar,
    //Card,
    //CardActions,
    //CardContent,
    //CardHeader,
    //CustomBox,
    //CustomButton as Button,
    CustomGrid as Grid,
    CustomItem as Item,
    //CustomLink,
    CustomStack as Stack,
    CustomTextField as TextField,
    //Heading,
    //Para,    
    //CustomTextField as TextField
} from '../../themes/Darwinian2023/skeleton/main'
*/

//import ReactHtmlParser from 'react-html-parser'

// Import Icons
//import EditIcon from '@mui/icons-material/Edit'
import SendIcon from '@mui/icons-material/Send'

// #region -----------------------[ Functions ]-------------------------------------------------------------------

// #endregion ----------------------------------------------------------------------------------------------------
// ####################### END OF SPECIFIC ##################################################################
// [][][][][][][][][][][][][][][][][][][][][ END IMPORTS ][][][][][][][][][][][][][][][][][][][][][][][][][][][][]

export function StatusChanger (props) { 
    // [][][][][][][][][][][][][][][][][][][][][ START SETUP ][][][][][][][][][][][][][][][][][][][][][][][][][][]
    // ####################### GLOBAL ############################################################################
    // #region -------------[ Variables ]-------------------------------------------------------------------------
    // Get user logged
    // eslint-disable-next-line
    const {
        AccountEditing,
        //AccountFormIsDisabled,
        currentUser, 
        //isAuthenticated, 
        //logoutUser,
        setAccountEditing,
        setAccountFormIsDisabled,
        updateUser
    } = useAuth()   

    // Set navigate
    // eslint-disable-next-line
    const navigate = useNavigate()

    // #region --------------( FUNCTIONS )------------------------------------------------------------------------
    
    // #endregion ------------------------------------------------------------------------------------------------
    // ####################### END OF GLOBAL #####################################################################

    // ####################### SPECIFIC ##########################################################################
    // #region -------------[ Variables ]-------------------------------------------------------------------------
    // eslint-disable-next-line
    const [ updateStatusSent, setUpdateStatusSent ] = useState(false)

    // Set a temp state to be used with updating profile from gizmo
    const [temp, setTemp] = useState(null)
    // #endregion -----------------------------------------------------------------------------------------------
    // #region -------------[ Functions ]------------------------------------------------------------------------  
    const handleEditStatus = e => {
        // Set updating
        setAccountEditing(true)

        // Set temp
        setTemp(currentUser)
    }

    const handleUnknown = (path) => {
        toast.info('I lost the game.', {theme: 'dark'})
    }

    const handleSendStatus = e => {   
        // Disable status field
        setAccountFormIsDisabled(true)

        // Set updateStatusSent
        //setUpdateStatusSent(true)

        // Send notification
        toast.success('WIP: Coming in Update 4!', {theme: 'dark'})
    }

    
    // State Rendering
    useEffect(() => {
        if (updateStatusSent) {
            // Update the user
            //props?.PropsData?.updateUser(temp)

            // Update profile with temp
            //props?.PropsData?.setProfile(temp)

            // Turn off updating
            //props?.PropsData?.setEditing(false)

            // Set updateStatusSent
            //setUpdateStatusSent(false)

            // Set temp to null - we dont need to null temp i guess.
            //setTemp(null)
        }

        // eslint-disable-next-line
    }, [ updateStatusSent, temp ])

    // State management for debugging
    useEffect(() => {
        
    }, [  ])
    // #endregion -----------------------------------------------------------------------------------------------
    // ####################### END OF SPECIFIC ##################################################################
    // [][][][][][][][][][][][][][][][][][][][][ ENDSETUP ][][][][][][][][][][][][][][][][][][][][][][][][][][][]

    // Render
    return (
        <>
        {
        // #region -----[ Module: StatusChanger ]----------------------------------------------------------------------------------------
        }
        <props.skeleton.CustomStack 
            bottomgizmo="true"
            spacing={0}
            direction={'column'}
            justifyContent={'center'}
            alignItems={'stretch'}                        
            sx={{ 
                //border: 'thin solid black',
            }}
        >
            <props.skeleton.CustomItem bottomgizmo>
                <props.skeleton.CustomStack
                    bottomgizmo="true"
                    spacing={1}
                    direction={'row'}
                    justifyContent={'space-between'}
                    alignItems={'stretch'}                        
                    sx={{ 
                        //border: 'thin solid black',
                    }}
                >
                    
                    <props.skeleton.CustomItem bottomgizmo>
                        {
                        // #region -----[ Section: Nougat ]----------------------------------------------------------------------------------------
                        }   
                        <props.skeleton.CustomStack
                            bottomgizmo="true"
                            spacing={0}
                            direction={'row'}
                            justifyContent={'space-between'}
                            alignItems={'stretch'}                        
                            sx={{ 
                                //border: 'thin solid black',
                                width: '101%',
                                maxWidth: '101%'
                            }}
                        >
                            {
                            // #region -----[ Section: Item #1: None ]-----------------------------------------------------------------------------
                            }   
                            <props.skeleton.CustomItem bottomgizmo>
                                <props.skeleton.CustomStack
                                    bottomgizmo="true"
                                    spacing={0}
                                    direction={'column'}
                                    justifyContent={'space-between'}
                                    alignItems={'stretch'}
                                    sx={{ 
                                        //border: 'thin solid black',
                                        height: '100%'
                                    }}
                                >
                                    
                                </props.skeleton.CustomStack>
                            </props.skeleton.CustomItem>
                            {
                            // #endregion -----[ Section: Item #1: None ]--------------------------------------------------------------------------
                            }

                            {
                            // #region -----[ Section: Item #2: Input ]----------------------------------------------------------------------------------
                            }   
                            <props.skeleton.CustomItem justifyContent={'center'} bottomgizmo>
                                <props.skeleton.CustomStack
                                    bottomgizmo="true"
                                    spacing={0}
                                    direction={'column'}
                                    justifyContent={'space-between'}
                                    alignItems={'stretch'}                        
                                    sx={{ 
                                        //border: 'thin solid black',
                                        height: '100%',
                                    }}
                                >
                                    <props.skeleton.CustomTextField
                                        InputLabelProps={{
                                            
                                        }}
                                        size='small'
                                        name='status'                                     
                                        label='My Status'
                                        //disabled={AccountFormIsDisabled}
                                        value={(AccountEditing) ? temp?.status || '' : currentUser?.status || ''} 
                                        type="text"                                                
                                        onChange={(e) => setTemp({...temp, status: e.target.value})}
                                        onFocus={(e) => handleEditStatus}
                                    />
                                </props.skeleton.CustomStack>
                            </props.skeleton.CustomItem>
                            {
                            // #endregion -----[ Section: Item #2: Input ]-------------------------------------------------------------------------------
                            }    
                            
                            {
                            // #region -----[ Section: Item #3: Buttons ]----------------------------------------------------------------------------------
                            } 
                            <props.skeleton.CustomItem justifyContent={'center'} bottomgizmo>
                                <props.skeleton.CustomStack
                                    bottomgizmo="true"
                                    spacing={0}
                                    direction={'row'}
                                    justifyContent={'center'}
                                    alignItems={'center'}                        
                                    sx={{ 
                                        //border: 'thin solid black',
                                        height: '100%'
                                    }}
                                >
                                    <props.skeleton.CustomItem>
                                        <props.skeleton.Bar bottomgizmobutton="true" onClick={() => handleUnknown()}></props.skeleton.Bar>                                        
                                    </props.skeleton.CustomItem>

                                    <props.skeleton.CustomItem>
                                        <props.skeleton.Bar bottomgizmobutton="true" onClick={() => handleUnknown()}></props.skeleton.Bar>                                        
                                    </props.skeleton.CustomItem>

                                    <props.skeleton.CustomItem right>
                                        <props.skeleton.Bar bottomgizmobutton="true" onClick={() => handleSendStatus(currentUser, updateUser)}><SendIcon /></props.skeleton.Bar>                                        
                                    </props.skeleton.CustomItem>
                                </props.skeleton.CustomStack>
                            </props.skeleton.CustomItem>
                            {
                            // #endregion -----[ Section: Item #3: Buttons ]--------------------------------------------------------------------------
                            } 
                        </props.skeleton.CustomStack>
                        {
                        // #endregion -----[ Section: Nougat ]----------------------------------------------------------------------------------------
                        }
                    </props.skeleton.CustomItem>
                </props.skeleton.CustomStack>
            </props.skeleton.CustomItem>
           
        </props.skeleton.CustomStack>
        {
        // #endregion -----[ Module: StatusChanger ]----------------------------------------------------------------------------------------
        }
        </>
     )
}
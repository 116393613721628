export const navbar = {
    base: {
        background: "lime"
    },
    button: {
        background: "lime",
        backgroundHover: "green",
        backgroundActive: "green",
        color: "black",
        colorHover: "white",
        colorActive: "white",
        underbar: {
            params: {
                border: "thin solid ##019900",
                borderwidth: "0em 0em 0.125em .25em"
            }
        }
    },
    drawer: {
        button: {
            background: "lime",
            backgroundHover: "green",
            backgroundActive: "green",
            color: "black",
            colorHover: "white",
            colorActive: "white",
            underbar: {
                params: {
                    border: "thin solid #00cccc",
                    borderwidth: "0em 0em 0.125em .25em"
                }
            }
        },
    },
    params: {
        height: '25px'
    }
}
import {useContext} from 'react'
import { APIEndpointContext } from '../context/apiendpoint_context/APIEndpointState'
import { AuthContext } from '../context/auth_context/AuthState'
import { CategoryContext } from '../context/category_context/CategoryState'
import { CodeContext } from '../context/code_context/CodeState'
import { PageContext } from '../context/page_context/PageState'
import { PostContext } from '../context/post_context/PostState'
import { SettingContext } from '../context/setting_context/SettingState'
import { TagContext } from '../context/tag_context/TagState'

export function useAPIEndpoints() {
    return useContext(APIEndpointContext)
}

export function useAuth() {
    return useContext(AuthContext)
}

export function useCategory() {
    return useContext(CategoryContext)
}

export function useCode() {
    return useContext(CodeContext)
}

export function usePage() {
    return useContext(PageContext)
}

export function usePost() {
    return useContext(PostContext)
}

export function useSetting() {
    return useContext(SettingContext)
}

export function useTag() {
    return useContext(TagContext)
}
// [][][][][][][][][][][][][][][][][][][][][ START IMPORTS ][][][][][][][][][][][][][][][][][][][][][][][][][][][]
// ####################### GLOBAL ############################################################################
// Import from React
// eslint-disable-next-line
import { useState, useEffect, useCallback } from 'react'

//import axios from 'axios'

// Import Toast
// eslint-disable-next-line
import { toast } from 'react-toastify'

// Import Auth
// eslint-disable-next-line
import { useAuth } from '../../middleware/contextHooks'

// Import reactDOM
import { useNavigate } from 'react-router-dom'

// Import utils
import { DeTemps, LikeMod, settingLookup } from '../../middleware/utils'

// Import Natch
import { NatchParse } from '../../middleware/Natch'

// Import components
import { BreadCrumbs } from '../../components/BreadCrumbs'
//import { PartialMissing } from '../../components/PartialMissing'

// Import pagination
import usePagination from '../../components/Pagination'

// Icons
// eslint-disable-next-line
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
// eslint-disable-next-line
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
// ####################### END OF GLOBAL #####################################################################

// ####################### SPECIFIC ##########################################################################
// Import globalStyles elements
//import { /*CustomContainer as Container*/ } from '../../themes/Darwinian2023/skeleton/global'

// Import from MUI
import {
    //Avatar,
    //Box,
    //Container, 
    //ListItem, 
} from '@mui/material'

/*
// Import mainStyles
import { 
    //Bar,
    CustomBreadcrumbs as Breadcrumbs,
    //CustomButton as Button,
    CustomGrid as Grid,
    CustomItem as Item,
    CustomLink,
    CustomStack as Stack,
    CustomTypography as Typography,
    Heading,
    //Para,    
    //CustomTextField as TextField
} from '../../themes/Darwinian2023/skeleton/main'
*/              

// Import Addon components
import { Spacer } from '../../components/Addon/Spacer'
import { GemItem } from '../../components/Addon/GemItem'

// Import Post
import { usePost, useSetting} from '../../middleware/contextHooks'
// ####################### END OF SPECIFIC ##################################################################
// [][][][][][][][][][][][][][][][][][][][][ END IMPORTS ][][][][][][][][][][][][][][][][][][][][][][][][][][][][]

function loadSliceImg (userName, query, image, settingServerAPIBaseUrl) {
    return settingServerAPIBaseUrl+query+"/file/byuser/"+userName+"/"+image
}

function loadUserImg (userName, image, settingServerAPIBaseUrl ) {
    return settingServerAPIBaseUrl+"user/file/byuser/"+userName+"/"+image
}

export default function Post (props) { 
    // [][][][][][][][][][][][][][][][][][][][][ START SETUP ][][][][][][][][][][][][][][][][][][][][][][][][][][]
    // ####################### GLOBAL ############################################################################
    // #region -------------[ Variables ]-------------------------------------------------------------------------
    // Get user logged
    const {
        currentUser, 
    } = useAuth()   

    // Set navigate
    // eslint-disable-next-line
    const navigate = useNavigate()

    // #region -----------------[ State Variables ]---------------------------------------------------------------

    // #end region -----------------[ State Variables ]-----------------------------------------------------------

    // #endregion ------------------------------------------------------------------------------------------------
    // #region --------------( FUNCTIONS )------------------------------------------------------------------------
        
    // #endregion ------------------------------------------------------------------------------------------------
    // ####################### END OF GLOBAL #####################################################################

    // ####################### SPECIFIC ##########################################################################
    // #region -------------[ Before Variables ]------------------------------------------------------------------

    // Bring from context
    const {       
        // Variables
        currentSlice,
        featuredslices,
        getSliceBySlug,
        latestFeaturedSlice,

        // Functions
        getAllFeaturedSlices,
        getLatestFeaturedSlice,
        LikePost
    } = usePost()

    const { 
        variables
    } = useSetting()

    // Handle selecting the post
    //const handleSelectPost = (postSlug) => {
    //    navigate('/post/'+postSlug)
    //}

    // #endregion -----------------------------------------------------------------------------------------------
    // #region -------------[ Functions ]------------------------------------------------------------------------

    // State management for latest featured slice
    useEffect(() => {
        if (props.id === "0") {
            //getLatestFeaturedSlice("anyposts", 1, 'global', 'published', 'DESC', 'createdAt')
            
            // Get the featured slice live
            if (!featuredslices) {
                getLatestFeaturedSlice("anyposts", 1, 'global', 'published', 'DESC', 'createdAt')
            } else {
                setTimeout(() => {
                    getLatestFeaturedSlice("anyposts", 1, 'global', 'published', 'DESC', 'createdAt')
                }, 5000)
            }            
        }
        // eslint-disable-next-line
    }, [ 
        latestFeaturedSlice,
        props.id
    ])

    // State management for featured slices
    useEffect(() => {
        if (props.id === "0") {
            //getAllFeaturedSlices("anyposts", null, 'global', 'published', 'DESC', 'createdAt')
            
            // Get a list of featured slices live
            if (!latestFeaturedSlice) {
                getAllFeaturedSlices("anyposts", null, 'global', 'published', 'DESC', 'createdAt')            
            } else {
                setTimeout(() => {
                    getAllFeaturedSlices("anyposts", null, 'global', 'published', 'DESC', 'createdAt')            
                }, 5000)
            }            
        }
        // eslint-disable-next-line
    }, [ 
        featuredslices,
        props.id
     ])

    // State management for loading currentSlice by the post.id
    useEffect(() => {
        if (props.id !== "0") {
            if (!currentSlice) {
                getSliceBySlug("anypost", props.id)
            } else {
                setTimeout(() => {
                    getSliceBySlug("anypost", props.id)
                }, 5000)
            }
        }
        // eslint-disable-next-line
    }, [
        currentSlice,
        props.id
    ])

    /* DEPRECATED < 2.7.3: This will cause the updates to not be live but without !RetrievedSlice it will cause a RESOURCES issue
                           because getSliceBySlug is in dependancy. That's not how state management is utilized. It's going to manage
                           the state of getSliceBySlug which changes on every render thus the resource issue. 
                           BIGGEST TAKEAWAY: the dependancy is used to keep track of a state variable (such as currentSlice retrieved by
                                             getSliceBySlug())

    // State management for loading currentSlice by the post.id
    useEffect(() => {
        if (props.id && props.id !== "0") {
            if (!currentSlice && !RetrievedSlice) {
                getSliceBySlug("anypost", props.id)

                // Update 2.6.8: Prevent slice from continuing to get retrieved even though we have it
                setRetrievedSlice(true)
            }            
        }                
    }, [
        currentSlice, 
        getSliceBySlug, 
        props, 
        featuredslices, 
        RetrievedSlice, 
        setRetrievedSlice 
    ])
    */

    useEffect(() => {
        //if (theslices) {console.log(theslices)}
        //if (latestFeaturedSlice) {console.log(latestFeaturedSlice)}
        //if (props.id) {console.log(props.id)}
        //if (props.settingServerAPIBaseUrl) {
        //    console.log(props.settingServerAPIBaseUrl)
        //}

        if (currentSlice) {
            //console.log(currentSlice)
        }

        //if (featuredslices) {
        //    console.log(featuredslices)
        //}
    }, [ 
        currentSlice,
        //latestFeaturedSlice
        //props
        //theslices 
        //props.settingServerAPIBaseUrl
    ])

    // Pagination - handle
    const handlePaginationChange = (e, p) => {
        // Set he page
        setPage(p)

        // Jump to page
        _DATA.jump(p)

        const feedSection = document.getElementById("allfeed")

        feedSection.scrollIntoView()
    }
    // #endregion -----------------------------------------------------------------------------------------------

    // #region -----[ State variables ]--------------------------------------------------------------------------
    // Page for pagination
    let [ page, setPage ] = useState(1)
    // ----------------------------------------------------------------------------------------------------------

    // #region -------------[ After Variables ]------------------------------------------------------------------

    // Set up breadcrumbs here
    const BCItems = [
        {
            'name': 'Home',
            'type': 'link',
            'grayed': true,
            "url": '/',
        }, 
        {
            'name': 'Posts',
            'type': 'link',
            'grayed': true,
            'url': '/posts'
        },
        {
            'name': ( (props.id !== "0") ? (currentSlice?.title) ? currentSlice?.title : "Undefined" : "All Posts" ),
            'type': 'text',
            'grayed': true,
            'url': ''
        }
    ]
    
    // Pagination - posts per page
    const PER_PAGE = 7

    // Pagination - count
    const count = Math.ceil((featuredslices?.length / PER_PAGE)) || 10

    // Tell data to use the pagination
    const _DATA = usePagination(featuredslices, PER_PAGE)

    // #endregion -----------------------------------------------------------------------------------------------

    // ####################### END OF SPECIFIC ##################################################################
    // [][][][][][][][][][][][][][][][][][][][][ ENDSETUP ][][][][][][][][][][][][][][][][][][][][][][][][][][][]

    // Render
    let useBorder = false
    let border = 'thin solid red'

    const functions = {
        LikePost
    }

    return (
        <>
        {
            // #region ----[ Display All Posts ]-----------------------------------------------------------------
            (!props.id || props.id === "0")
            ? (
                <>
                <Spacer length={5} />

                <BreadCrumbs list={BCItems} skeleton={props.skeleton} />

                <Spacer length={2} />

                <props.skeleton.CustomGrid container>
                    {
                    // #region ---[ Left Side ]----------------------------------------------------------------------
                    }
                    <props.skeleton.CustomGrid item xs={.75}>
                            
                    </props.skeleton.CustomGrid>
                    {
                    // #endregion -----------------------------------------------------------------------------------
                    }

                    <props.skeleton.CustomGrid item xs={10.5}>
                        {/* #region ---[ Two Column Top ]------------------------------------------------------------ */}
                        <props.skeleton.CustomGrid container spacing={0} sx={{ 
                            border: (useBorder) ? border : 'none', 
                            fontSize: '2em',
                            padding: '10px'                            
                        }}>
                            <props.skeleton.CustomGrid item xs={12} md={8.5}>
                                {/*<BreadCrumbs list={BCItems} skeleton={props.skeleton} />*/}

                                <Spacer length={1} />

                                {/* #region --[ Left - Latest Post ] */}
                                <props.skeleton.Heading h0 nobg="true">Latest Post</props.skeleton.Heading>

                                <Spacer length={1} />

                                <props.skeleton.Heading nobg="true" h2>{settingLookup('postPageTagLine', variables)}</props.skeleton.Heading>

                                <Spacer length={3} />

                                <props.skeleton.CustomStack button="true" onClick={() => navigate(`/post/${latestFeaturedSlice?.slug}`)}>
                                    {/* #region -[ Latest Post ]----------------------------------------------------------------- */}                                
                                        {/* #region [ Featured Image ]----------------------------------------------------------- */}
                                        <props.skeleton.CustomBox
                                            component="img"
                                            sx={{
                                                height: {xs: '220px', sm: '320px', md: '360px', lg: '420px'},
                                                width: { xs: '100%', sm: '100%', md: '97%', lg: '97%' }
                                            }}
                                            alt="Latest Post image"
                                            src={
                                                (latestFeaturedSlice && (latestFeaturedSlice?.imageUrl !== "no-featuredimage.png" && latestFeaturedSlice?.imageUrl !== "")) 
                                                ? (
                                                    loadSliceImg(latestFeaturedSlice?.authorDetails[0]?.userName, latestFeaturedSlice?.type, latestFeaturedSlice?.imageUrl, props.settingServerAPIBaseUrl) 
                                                )
                                                : loadSliceImg(latestFeaturedSlice?.authorDetails[0]?.userName, latestFeaturedSlice?.type, "no-featuredimage.png", props.settingServerAPIBaseUrl) 
                                            }
                                        />            
                                        {/* #endregion -------------------------------------------------------------------------- */}

                                        {/* #region -[ Title ] ------------------------------------------------------------------ */}                                
                                        <props.skeleton.CustomBox leftalign="true" sx={{ padding: '20px'}}>
                                            <props.skeleton.Heading h1 nobg="true">
                                            {
                                                (latestFeaturedSlice?.type === "longpost" || latestFeaturedSlice?.type === "stillpost" || latestFeaturedSlice?.type === "videopost")
                                                ? latestFeaturedSlice?.title
                                                : "ShortPost #" + latestFeaturedSlice?.uuid
                                            }
                                            </props.skeleton.Heading>
                                        </props.skeleton.CustomBox>
                                        {/* #endregion -------------------------------------------------------------------------- */}

                                        {/* #region -[ Snippet ] ---------------------------------------------------------------- */}                                
                                        <props.skeleton.CustomBox leftalign="true" sx={{ paddingLeft: '20px'}}>
                                            <props.skeleton.Heading h4 nobg="true">
                                            {
                                                (latestFeaturedSlice?.type === "longpost" || latestFeaturedSlice?.type === "stillpost" || latestFeaturedSlice?.type === "videopost")
                                                ? (
                                                    <>
                                                    { latestFeaturedSlice?.snippet.substr(0, 96) }
                                                    <Spacer length={4} />
                                                    </>
                                                )
                                                : null                                                
                                            }
                                            </props.skeleton.Heading>
                                        </props.skeleton.CustomBox>
                                        {/* #endregion -------------------------------------------------------------------------- */}

                                        {/* #region -[ InfoTag ] ---------------------------------------------------------------- */}                                
                                        <props.skeleton.CustomBox leftalign="true" sx={{ paddingLeft: '20px'}}>
                                            <props.skeleton.Heading h4 nobg="true">
                                                <props.skeleton.CustomLink bgprimary nopadding href={`/categories/${latestFeaturedSlice?.categoryDetails[0]?.slug}`}>{latestFeaturedSlice?.categoryDetails[0]?.title}</props.skeleton.CustomLink> |&nbsp;
                                                {DeTemps('prettyShort', latestFeaturedSlice?.createdAt)}<br />{DeTemps('timeFrom', latestFeaturedSlice?.createdAt)}                                           
                                            </props.skeleton.Heading>
                                        </props.skeleton.CustomBox>
                                        {/* #endregion -------------------------------------------------------------------------- */}
                                    {/* #endregion ------------------------------------------------------------------------------ */}
                                </props.skeleton.CustomStack>       

                                <Spacer length={1} />                         
                            </props.skeleton.CustomGrid>

                            <props.skeleton.CustomGrid item xs={12} sm={3.5} md={3.5} lg={3.5} sx={{ textAlign: 'right' }}>
                                <Spacer length={7} sx={{ display: { xs: 'block', sm: 'block', md: 'none', lg: 'none' } }} /> 
                                {/* #region --[ Right - Sidebar ] */}
                                <props.skeleton.Heading nobg="true" h1>Explore by category</props.skeleton.Heading>
                                <props.skeleton.Heading nobg="true" h6>WIP</props.skeleton.Heading>
                                <Spacer length={1} />
                                <props.skeleton.Heading nobg="true" h1>Subscribe</props.skeleton.Heading>
                                <props.skeleton.Heading nobg="true" h6>WIP</props.skeleton.Heading>
                                <Spacer length={1} />
                                <props.skeleton.Heading nobg="true" h1>Most popular</props.skeleton.Heading>
                                <props.skeleton.Heading nobg="true" h6>WIP</props.skeleton.Heading>
                                <Spacer length={1} />
                                <props.skeleton.Heading nobg="true" h1>etc</props.skeleton.Heading>
                                <props.skeleton.Heading nobg="true" h6>WIP</props.skeleton.Heading>
                                <Spacer length={5} sx={{ display: { xs: 'block', sm: 'block', md: 'none', lg: 'none' } }} /> 
                                {/* #endregion ---------------------------------------------------------------------------------- */}
                            </props.skeleton.CustomGrid>
                        </props.skeleton.CustomGrid>                        
                        {/* #endregion ------------------------------------------------------------------------------------------ */}                                            

                        <section id="allfeed">
                            
                            <Spacer length={5} />    

                            {/* #region ---[ Rest of Featured Slices ]--------------------------------------------------------------- */}
                            <props.skeleton.CustomGrid container spacing={9}>
                                {
                                    (featuredslices) 
                                    ? (
                                        (featuredslices.length > 1)
                                        ? (
                                            _DATA.currentData().map((item, index) => {
                                                return (
                                                    // We dont want to display the first post as it's already displayed
                                                    (index > 0) 
                                                    ? (
                                                        <GemItem key={index} skeleton={props.skeleton} data={item} settingServerAPIBaseUrl={props.settingServerAPIBaseUrl} user={currentUser} functions={functions} />
                                                    )
                                                    : null
                                                )
                                            })
                                        )
                                        : (
                                            <props.skeleton.CustomGrid item xs={12}>
                                                <props.skeleton.Heading h2 center>... end of posts ...</props.skeleton.Heading>
                                            </props.skeleton.CustomGrid>
                                        )
                                    )
                                    : null
                                }                            
                            </props.skeleton.CustomGrid>
                        </section>
                        {/* #endregion ------------------------------------------------------------------------------------------ */}
                    </props.skeleton.CustomGrid>
                    {
                    // #region ---[ Right Side ]---------------------------------------------------------------------
                    }
                    <props.skeleton.CustomGrid item xs={.75}>
                        
                    </props.skeleton.CustomGrid>
                    {
                    // #endregion -----------------------------------------------------------------------------------
                    }                    
                </props.skeleton.CustomGrid>

                <Spacer length={2} />

                <props.skeleton.CustomGrid container spacing={0} 
                    direction={'column'} 
                    alignItems={'center'} 
                    justifyContent={'center'}
                >
                    <props.skeleton.CustomGrid item xs={12}>
                        {/* #region ---[ Pagination ]-------------------------------------------------------------------------------- */}
                        <props.skeleton.CustomPagination
                            count={count}
                            page={page}
                            variant="outlined"
                            size="large"
                            onChange={handlePaginationChange}                        
                        />
                        {/* #endregion ---------------------------------------------------------------------------------------------- */}
                    </props.skeleton.CustomGrid>
                </props.skeleton.CustomGrid>
                </>
            )
            : null
            // #endregion ---------------------------------------------------------------------------------------
        }

        {
            // #region ----[ Display Single Post ]---------------------------------------------------------------
            <>
            {
                (props.id && props.id !== "0")
                ? (
                    <>
                    <Spacer length={9} />
                    <props.skeleton.CustomGrid container spacing={2}>
                        {/* #region ---[ Left Side ]---------------------------------------------------------------------- */}
                        <props.skeleton.CustomGrid item xs={1} sm={1.1} md={2} lg={2.5} sx={{ 
                            border: (useBorder) ? border : 'none', 
                            fontSize: '2em',
                            paddingBottom: '13px'
                        }}>
                            
                        </props.skeleton.CustomGrid>
                        {/* #endregion ----------------------------------------------------------------------------------- */}

                        <props.skeleton.CustomGrid item xs={10.5} sm={10} md={8} lg={7} sx={{ 
                            border: (useBorder) ? border : 'none',  
                            fontSize: '2em',
                            paddingBottom: '13px',
                            textAlign: 'center'
                        }}>
                            <BreadCrumbs list={BCItems} skeleton={props.skeleton} />

                            {/* #region --[ Title ]----------------------------------------------------------------------- */}
                            {
                                (currentSlice?.type === "longpost" || currentSlice?.type === "stillpost" || currentSlice?.type === "videopost")
                                ? <props.skeleton.Heading nobg h1 fontheading1>{currentSlice?.title}</props.skeleton.Heading>
                                : (
                                    <>
                                    <Spacer length={1} />
                                    <props.skeleton.Heading nobg h8>Viewing:</props.skeleton.Heading>
                                    <props.skeleton.Para fontheading1>ShortPost #{currentSlice?.uuid}</props.skeleton.Para>
                                    </>
                                )
                            }
                            {/* #region ---------------------------------------------------------------------------------- */}

                            <Spacer length={1} />

                            {/* #region --[ InfoBar - cat and dt ]-------------------------------------------------------- */}
                            <props.skeleton.Heading nobg fontheading1="true">
                                {
                                    (currentSlice?.type === "longpost" || currentSlice?.type === "stillpost" || currentSlice?.type === "videopost")
                                    ? (
                                        <>
                                        <props.skeleton.CustomLink bgprimary nopadding href={`/categories/${currentSlice?.categoryDetails[0]?.slug}`}>{currentSlice?.categoryDetails[0]?.title}</props.skeleton.CustomLink> |&nbsp;
                                        {DeTemps('prettyShort', currentSlice?.createdAt)} ({DeTemps('timeFrom', currentSlice?.createdAt)})        
                                        </>
                                    )
                                    : (
                                        <>
                                        <props.skeleton.Heading nobg h8>Posted {DeTemps('prettyShort', currentSlice?.createdAt)} ({DeTemps('timeFrom', currentSlice?.createdAt)})     </props.skeleton.Heading>                                           
                                        </>
                                    )
                                }                            
                            </props.skeleton.Heading>
                            {/* #region ---------------------------------------------------------------------------------- */}

                            {/* #region --[ InfoBar - stats ]------------------------------------------------------------- */}
                            <props.skeleton.Para h2 fontheading1="true">
                                {
                                    (currentSlice)
                                    ? (
                                        currentSlice?.comments?.length + " Comment"+(currentSlice?.comments?.length === 1 ? "" : "s")+" | "
                                        +(
                                            (currentUser) 
                                            ? LikeMod("like","textPhrase", currentUser, currentSlice, currentSlice.likes, currentSlice.unlikes, props.skeleton, functions)
                                            : "? Likes"                                            
                                        )+" | "
                                        +currentSlice?.shares?.length + " Share"+(currentSlice?.shares?.length === 1 ? "" : "s")+" | "
                                        + currentSlice?.views?.length + " View"+(currentSlice?.views?.length === 1 ? "" : "s")
                                    )
                                    : "Loading stats..."
                                }
                            </props.skeleton.Para>
                            {/* #region ---------------------------------------------------------------------------------- */}

                            <Spacer length={1} />

                            {/* #region --[ Featured Image ]-------------------------------------------------------------- */}
                            {
                                (currentSlice?.type === "longpost")
                                ? (
                                    <props.skeleton.CustomBox
                                        component="img"
                                        sx={{
                                            height: {xs: '225px', sm: '300px', md: '400px', lg: '500px'},
                                            width: '95%'
                                        }}
                                        alt="Post image"
                                        src={(currentSlice && currentSlice?.imageUrl !== "no-featuredimage.png" && currentSlice?.imageUrl !== "") 
                                        ? (
                                            loadSliceImg(currentSlice?.authorDetails[0]?.userName, currentSlice?.type, currentSlice?.imageUrl, props.settingServerAPIBaseUrl) 
                                        )
                                        : loadSliceImg(currentSlice.authorDetails[0]?.userName, currentSlice.type, "no-featuredimage.png", props.settingServerAPIBaseUrl)
                                        }
                                    />             
                                )
                                : null
                            }
                            {/* #region ---------------------------------------------------------------------------------- */}

                            {/* #region --[ Featured Image ]-------------------------------------------------------------- */}
                            {
                                (currentSlice?.type === "stillpost")
                                ? (
                                    <props.skeleton.CustomBox
                                        component="img"
                                        sx={{
                                            height: {xs: '225px', sm: '300px', md: '400px', lg: '500px'},
                                            width: '95%'
                                        }}
                                        alt="Post image"
                                        src={(currentSlice && currentSlice?.imageUrl !== "no-featuredimage.png" && currentSlice?.imageUrl !== "") 
                                        ? (
                                            loadSliceImg(currentSlice?.authorDetails[0]?.userName, currentSlice?.type, currentSlice?.imageUrl, props.settingServerAPIBaseUrl) 
                                        )
                                        : window.location.origin+"/anypost/thumbs/no-featuredimage.png"}
                                    />             
                                )
                                : null
                            }
                            {/* #region ---------------------------------------------------------------------------------- */}

                            {/* #region --[ Snippet ]--------------------------------------------------------------------- */}
                            {
                                (currentSlice?.type === "longpost")
                                ? (
                                    <>
                                    <Spacer length={3} />
                                    <props.skeleton.Heading nobg h7 fontheading1="true">{currentSlice?.snippet}</props.skeleton.Heading>
                                    </>
                                )
                                : null
                            }
                            {/* #region ---------------------------------------------------------------------------------- */}

                            <Spacer length={1} />

                            {/* #region --[ Content ]--------------------------------------------------------------------- */}
                            <props.skeleton.Para>{NatchParse(currentSlice?.content)}</props.skeleton.Para>
                            {/* #region ---------------------------------------------------------------------------------- */}

                            <Spacer length={1} />

                            {
                                (currentSlice) 
                                ? (
                                    (currentUser) 
                                    ? LikeMod("like", "button", currentUser, currentSlice, currentSlice?.likes, currentSlice?.unlikes, props.skeleton, functions)
                                    : "You must be logged in to view this"
                                )
                                : "Loading..."
                            }

                            {/* #region --[ Stats ]----------------------------------------------------------------------- */}
                            {/*
                            <props.skeleton.Heading h7 fontheading1="true" center>
                                {currentSlice?.views}250 Views                                
                            </props.skeleton.Heading>
                            <props.skeleton.Heading h7 fontheading1="true" center>
                                {currentSlice?.likes}23 Likes
                            </props.skeleton.Heading>
                            <props.skeleton.Heading h7 fontheading1="true" center>                                
                                {currentSlice?.shares}45 Shares
                            </props.skeleton.Heading>
                            */
                            }
                            {/* #region ---------------------------------------------------------------------------------- */}
                            
                            <Spacer length={1} />

                            {/* #region --[ Sharing ]--------------------------------------------------------------------- */}
                            <props.skeleton.Heading nobg insetmedium fontheading1="true" center>Found this useful? Share with</props.skeleton.Heading>
                            <props.skeleton.Heading nobg insetmedium fontheading1 center>WIP</props.skeleton.Heading>
                            {/* #region ---------------------------------------------------------------------------------- */}

                            <Spacer length={3} />

                            {/* #region --[ Author ]---------------------------------------------------------------------- */}
                            <props.skeleton.CustomStack direction={'row'} spacing={1} justifyContent={'flex-start'} alignItems={'center'} sx={{ padding: '5px' }}>
                                <props.skeleton.CustomItem>
                                    {
                                        (currentSlice) 
                                        ? <props.skeleton.CustomAvatar alt={currentSlice?.authorDetails[0]?.userName} sx={{ width: { xs: 70, sm: 70, md: 90, lg: 150 }, height: { xs: 70, sm: 70, md: 90, lg: 150 } }} src={loadUserImg(currentSlice?.authorDetails[0]?.userName, currentSlice?.authorDetails[0]?.profileImage, props.settingServerAPIBaseUrl)} />
                                        : "loading..."
                                    }
                                </props.skeleton.CustomItem>
                                <props.skeleton.CustomItem>
                                    <props.skeleton.CustomStack direction={'column'} spacing={0} justifyContent={'flex-start'} alignItems={'flex-start'}>
                                        <props.skeleton.CustomItem>
                                            <props.skeleton.Heading h6 nobg><props.skeleton.CustomLink bgprimary nopadding noUnderLine href={`/@/`+currentSlice?.authorDetails[0]?.userName}>{ currentSlice?.authorDetails[0]?.userName }</props.skeleton.CustomLink></props.skeleton.Heading>
                                        </props.skeleton.CustomItem>
                                        <props.skeleton.CustomItem>
                                            <props.skeleton.Para>
                                                {currentSlice?.authorDetails[0]?.bio}
                                            </props.skeleton.Para>
                                        </props.skeleton.CustomItem>
                                    </props.skeleton.CustomStack>
                                </props.skeleton.CustomItem>                                    
                            </props.skeleton.CustomStack> 
                            {/* #region ---------------------------------------------------------------------------------- */}

                            <Spacer length={3} />

                            {/* #region --[ Related ]--------------------------------------------------------------------- */}
                            <props.skeleton.Heading nobg insetlarge fontheading1 center>Related Content</props.skeleton.Heading>
                            <props.skeleton.Heading nobg insetlarge fontheading1 center>WIP</props.skeleton.Heading>
                            {/* #region ---------------------------------------------------------------------------------- */}

                            <Spacer length={1} />

                            {/* #region --[ Comments ]-------------------------------------------------------------------- */}
                            
                            <props.skeleton.Heading nobg insetlarge fontheading1="true" center>Comments (0)</props.skeleton.Heading>
                            <props.skeleton.Heading nobg insetlarge fontheading1 center>WIP</props.skeleton.Heading>

                            {/* #region ---------------------------------------------------------------------------------- */}

                            {/* #region --[ Other Module ]---------------------------------------------------------------- */}

                            {/* #region ---------------------------------------------------------------------------------- */}
                        </props.skeleton.CustomGrid>                        
                        {/* #region ---[ Right Side ]--------------------------------------------------------------------- */}
                        <props.skeleton.CustomGrid item xs={.1} sm={.9} md={2} lg={2.5} sx={{ 
                            border: (useBorder) ? border : 'none',  
                            fontSize: '2em',
                            paddingBottom: '13px',
                            textAlign: 'right'
                        }}>
                            
                        </props.skeleton.CustomGrid>
                        {/* #endregion ----------------------------------------------------------------------------------- */}
                    </props.skeleton.CustomGrid>
                    </>
                )
                : null
            }
            </>
            // #endregion ---------------------------------------------------------------------------------------
        }
        </>
    )
}
import * as dashboard from './dashboard/index.ts'
import * as global from './global/index.ts'
import * as main from './main/index.ts'
import * as sys from './sys/index.ts'

export const theme = {
    ...dashboard,
    ...global,
    ...main,
    ...sys
}
export const pagination = {
    base: {
        background: "lime",
        backgroundHover: "lime",
        backgroundActive: "lime",
        color: "white",
        colorGray: 'gray',
        colorHover: "black",
        colorActive: "black",
        params: {
            padding: '10px'
        }
    },
    button: {
        base: {
            background: "#80ff80",
            backgroundHover: "black",
            backgroundActive: "black",
            color: "black",
            colorGray: 'gray',
            colorHover: "white",
            colorActive: "white",
            params: {
                padding: '0px 0px 0px 0px' // Top Right Bottom Left
            }
        }
    },
    buttonnotselected: {
        base: {
            background: "#009900",
            backgroundHover: "black",
            backgroundActive: "black",
            color: "white",
            colorGray: 'gray',
            colorHover: "white",
            colorActive: "white",
            params: {
                padding: '0px 0px 0px 0px' // Top Right Bottom Left
            }
        }
    }
}
// [][][][][][][][][][][][][][][][][][][][][ START IMPORTS ][][][][][][][][][][][][][][][][][][][][][][][][][][][]
// ####################### GLOBAL ############################################################################
// Import from React
// eslint-disable-next-line
import { useState, useEffect } from 'react'

// Import axios
//import axios from 'axios'

// Import Toast
// eslint-disable-next-line
import { toast } from 'react-toastify'

// Import Auth
import { useAuth } from '../../middleware/contextHooks'

// Import reactDOM
import { useNavigate } from 'react-router-dom'
// ####################### END OF GLOBAL #####################################################################

// ####################### SPECIFIC ##########################################################################
// Import globalStyles elements
import { /*CustomContainer as Container*/ } from '../../themes/Darwinian2023/skeleton/global'

// Import from MUI
import {
    //Paper,
    //Button,
} from '@mui/material'

// eslint-disable-next-line
import Masonry from '@mui/lab/Masonry'

/*
// Import mainStyles
import { 
    //Bar,
     // eslint-disable-next-line
    Card,
    // eslint-disable-next-line
    CardActions,
    // eslint-disable-next-line
    CardContent,
    // eslint-disable-next-line
    CardHeader,
     // eslint-disable-next-line
    CustomCardMedia as CardMedia,
    // eslint-disable-next-line
    CustomGrid as Grid,
    //CustomBox,
    CustomLink,
    //CustomButton as Button,
    //CustomGrid as Grid,
    CustomItem as Item,
    //CustomLink,
    //CustomStack as Stack,
    Heading,
    //Para,    
    //CustomTextField as TextField
} from '../../themes/Darwinian2023/skeleton/main'
*/

// Import from Components
//import { Col } from '../../components/Col'
//import { InnerCol } from '../../components/InnerCol'
//import { Row } from '../../components/Row'
import { Spacer } from './Spacer'

//import ReactHtmlParser from 'react-html-parser'
// ####################### END OF SPECIFIC ##################################################################
// [][][][][][][][][][][][][][][][][][][][][ END IMPORTS ][][][][][][][][][][][][][][][][][][][][][][][][][][][][]

const parse = require('html-react-parser')

export function CallToAction (props) { 
    // [][][][][][][][][][][][][][][][][][][][][ START SETUP ][][][][][][][][][][][][][][][][][][][][][][][][][][]
    // ####################### GLOBAL ############################################################################
    // #region -------------[ Variables ]-------------------------------------------------------------------------
    // Get user logged
    // eslint-disable-next-line
    const { 
        
    } = useAuth()   

    // Set navigate
    // eslint-disable-next-line
    const navigate = useNavigate()

    // #region --------------( FUNCTIONS )------------------------------------------------------------------------
    
    // #endregion ------------------------------------------------------------------------------------------------
    // ####################### END OF GLOBAL #####################################################################

    // ####################### SPECIFIC ##########################################################################
    // #region -------------[ Variables ]-------------------------------------------------------------------------
    
    // #endregion -----------------------------------------------------------------------------------------------
    // #region -------------[ Functions ]------------------------------------------------------------------------   
    // State Rendering
    useEffect(() => {
        
    }, [ ])
    // #endregion -----------------------------------------------------------------------------------------------
    // ####################### END OF SPECIFIC ##################################################################
    // [][][][][][][][][][][][][][][][][][][][][ ENDSETUP ][][][][][][][][][][][][][][][][][][][][][][][][][][][]

    // Render
    return (
        <>
        <props.skeleton.CustomGrid container gem="true" 
            color={props.color}
            padding={props.padding}      
            metaui={"<dark|light>.main.modules.gem.base.<type>"}
        >
            {
            // #region -----[ Left Column ]---------------------------------------------------------------------------------------------
            }
            <props.skeleton.CustomGrid item xs={1} gem="true" color={props.color}
                sx={{
                    //border: 'thin solid black'
                }}
            >
                
            </props.skeleton.CustomGrid>
            {
            // #endregion -----[ Left Column ]---------------------------------------------------------------------------------------------
            }
            
            {
            // #region -----[ Middle Column ]---------------------------------------------------------------------------------------------
            }
            
            {
                // #region ---[ Left Nougat ]---------------------------------------------------------------------------------------------
            }
            <props.skeleton.CustomGrid item xs={10} sm={10} md={6} lg={6} gem="true" color={props.color}
                sx={{
                    //border: 'thin solid black',
                }}
            >
                <Spacer length={props.abovetext} />
                
                {
                    // -----[ Primary Text ]----------------------------------------------------------------------------------------
                    (props.primarytextalign && props.primarytextalign === "left") 
                    ? <props.skeleton.Heading nobg="true" h1 fontheading1 left gem>{props.primarytext}</props.skeleton.Heading>
                    : (
                        (props.primarytextalign && props.primarytextalign === "center")
                        ? <props.skeleton.Heading nobg="true" h1 fontheading1 center gem>{props.primarytext}</props.skeleton.Heading>
                        : (
                            (props.primarytextalign && props.primarytextalign === "right")
                            ? <props.skeleton.Heading nobg="true" h1 fontheading1 right gem>{props.primarytext}</props.skeleton.Heading>
                            : <props.skeleton.Heading nobg="true" h1 fontheading1 left gem>{props.primarytext}</props.skeleton.Heading>
                        )
                    )
                    // -----[ Primary Text ]----------------------------------------------------------------------------------------
                }
                
                {
                    // -----[ Secondary Text ]--------------------------------------------------------------------------------------
                    (props.secondarytextalign && props.secondarytextalign === "left") 
                    ? <props.skeleton.Heading nobg="true" h6 fontheading6 left gem>{props.secondarytext}</props.skeleton.Heading>
                    : (
                        (props.secondarytextalign && props.secondarytextalign === "center")
                        ? <props.skeleton.Heading nobg="true" h6 fontheading6 center gem>{props.secondarytext}</props.skeleton.Heading>
                        : (
                            (props.secondarytextalign && props.secondarytextalign === "right")
                            ? <props.skeleton.Heading nobg="true" h6 fontheading6 right gem>{props.secondarytext}</props.skeleton.Heading>
                            : <props.skeleton.Heading nobg="true" h6 fontheading6 left gem>{props.secondarytext}</props.skeleton.Heading>
                        )
                    )
                    // -----[ Secondary Text ]--------------------------------------------------------------------------------------
                }

                {
                    // -----[ Body Text ]-------------------------------------------------------------------------------------------
                    (props.bodytextalign && props.bodytext === "left") 
                    ? <props.skeleton.Para fontpara1 left gem>{props.bodytext}</props.skeleton.Para>
                    : (
                        (props.bodytextalign && props.bodytextalign === "center")
                        ? <props.skeleton.Para fontpara1 center gem>{props.bodytext}</props.skeleton.Para>
                        : (
                            (props.bodytextalign && props.bodytextalign === "right")
                            ? <props.skeleton.Para fontpara1 right gem>{parse(props.bodytext)}</props.skeleton.Para>
                            : <props.skeleton.Para fontpara1 left gem>{parse(props.bodytext)}</props.skeleton.Para>
                        )
                    )
                    // -----[ Body Text ]-------------------------------------------------------------------------------------------
                }

                <Spacer length={props.belowtext} />
            </props.skeleton.CustomGrid>
            {
                // #endregion ---[ Left Nougat ]-----------------------------------------------------------------------------------------
            }

            {
                // #region ---[ Right Nougat ]--------------------------------------------------------------------------------------------
            }
            <props.skeleton.CustomGrid item xs={12} sm={12} md={4} lg={4} gem="true" center="true" color={props.color}
                sx={{
                    //border: 'thin solid black'
                }}
            >
                <Spacer length={props.abovebutton} />

                <props.skeleton.CustomGrid container gem="true" color={props.color}
                    sx={{
                        //border: 'thin solid black',
                        textAlign: 'center'
                    }}
                >
                    <props.skeleton.CustomGrid item xs={1} gem="true" color={props.color}
                        sx={{
                            //border: 'thin solid black',
                            textAlign: 'center'
                        }}
                    >
                        
                    </props.skeleton.CustomGrid>
                    <props.skeleton.CustomGrid item xs={10} gem="true" color={props.color}
                        sx={{
                            //border: 'thin solid black',
                            textAlign: 'center'
                        }}
                    >
                        <props.skeleton.Bar gembutton="true" metaui={"<dark|light>.main.modules.gem.button.<type>"} color={props.buttoncolor} onClick={() => (props.buttontoast !== "") ? (
                            (props.buttontype)
                            ? (
                                ((props.buttontype === "normal") && toast(props.buttontoast)) ||
                                ((props.buttontype === "error") && toast.error(props.buttontoast)) ||
                                ((props.buttontype === "info") && toast.info(props.buttontoast)) ||
                                ((props.buttontype === "warning") && toast.warning(props.buttontoast))
                            )
                            : toast.info(props.buttontoast, {theme: 'dark'})
                        ) : navigate(props.buttonurl)}>{props.buttontext}</props.skeleton.Bar>
                    </props.skeleton.CustomGrid>
                    <props.skeleton.CustomGrid item xs={1} gem="true" color={props.color}
                        sx={{
                            //border: 'thin solid black',
                            textAlign: 'center'
                        }}
                    >
                        
                    </props.skeleton.CustomGrid>
                </props.skeleton.CustomGrid>
                
                <Spacer length={props.belowbutton} />
            </props.skeleton.CustomGrid>
            {
                // #endregion ---[ Right Nougat ]-----------------------------------------------------------------------------------------
            }
            {
            // #endregion -----[ Middle Column ]------------------------------------------------------------------------------------------
            }

            {
            // #region -----[ Right Column ]---------------------------------------------------------------------------------------------
            }
            <props.skeleton.CustomGrid item xs={1} gem="true" color={props.color}
                sx={{
                    //border: 'thin solid black'
                }}
            >
                
            </props.skeleton.CustomGrid>
            {
            // #endregion -----[ Right Column ]---------------------------------------------------------------------------------------------
            }
        </props.skeleton.CustomGrid>
        </>
    )
}
export const notification = {
    base: {
        background: "white"
    }, 
    body: {
        error: {
            background: "#660000",
            color: "white"
        },
        info: {
            background: "#191919",
            color: "white"
        },
        normal: {
            background: "#009999",
            color: "white"
        },
        warning: {
            background: "#808000",
            color: "black"
        },
    },
    progressbar: {
        normal: {
            background: "cyan"
        },
        error: {
            background: "#ff9999"
        },
        info: {
            background: "cyan"
        }, 
        warning: {
            background: "gold"
        }
    }
}
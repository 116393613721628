export const item = {
    base: {
        background: "white"
    },
    bottomgizmo: {
        background: "black"
    },
    full: {
        background: 'green'
    },
    sink: {
        base: {
            background: "#ccffff",
            color: "black"
        }
    }
}
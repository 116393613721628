export const bar = {
    background: "#FFF",
    button: {
        base: {
            background: "lime",
            backgroundHover: "black",  
            backgroundActive: "black",
            color: "black",
            colorHover: "lime",
            colorActive: "lime"
        }
    }
}
export const item = {
    base: {
        background: "black",
        color: "black"
    },
    bottomgizmo: {
        background: "cyan"
    },
    full: {
        background: 'red'
    },
    sink: {
        base: {
            background: "#191919",
            color: "white"
        }
    }
}
// [][][][][][][][][][][][][][][][][][][][][ START IMPORTS ][][][][][][][][][][][][][][][][][][][][][][][][][][][]
// ####################### GLOBAL ############################################################################
// Import from React
// eslint-disable-next-line
import { useState, useEffect } from 'react'

// Import Toast
// eslint-disable-next-line
// eslint-disable-next-line
import { toast } from 'react-toastify'

// Import Auth
// eslint-disable-next-line
import { useAuth } from '../middleware/contextHooks'

// Import reactDOM
// eslint-disable-next-line
import { useNavigate } from 'react-router-dom'

// Import utils
// eslint-disable-next-line
import { DeTemps } from '../middleware/utils'
// ####################### END OF GLOBAL #####################################################################

// ####################### SPECIFIC ##########################################################################
// Import globalStyles elements
//import { /*CustomContainer as Container*/ } from '../styles/globalStyles'

// Import Icons

// Import from MUI
import {
    
} from '@mui/material'

/*
// Import mainStyles
import { 
    
} from '../styles/dashStyles'
*/

// ####################### END OF SPECIFIC #######################################################################
// [][][][][][][][][][][][][][][][][][][][][ END IMPORTS ][][][][][][][][][][][][][][][][][][][][][][][][][][][][]

// #region ----------------[ Outside Functions ]------------------------------------------------------------------

// #endregion -------------[ Outside Functions ]------------------------------------------------------------------

// #region ----------------[ Outside Variables ]------------------------------------------------------------------

// #endregion -------------[ Outside Variables ]------------------------------------------------------------------

function usePagination (data, itemsPerPage) { 
    // [][][][][][][][][][][][][][][][][][][][][ START SETUP ][][][][][][][][][][][][][][][][][][][][][][][][][][]
    // ####################### GLOBAL ############################################################################
    // #region -------------[ Variables ]-------------------------------------------------------------------------
    
    // #region -----------------[ State Variables ]---------------------------------------------------------------
    
    // #endregion --------------[ State Variables ]---------------------------------------------------------------

    // #endregion ------------------------------------------------------------------------------------------------
    // ####################### END OF GLOBAL #####################################################################

    // ####################### SPECIFIC ##########################################################################
    // #region -------------[ Variables ]-------------------------------------------------------------------------
    
    // #region -----------------[ State Variables ]---------------------------------------------------------------    
    const [ currentPage, setCurrentPage ] = useState(1)
    const maxPage = Math.ceil(data?.length / itemsPerPage)

    // #end region -------------[ State Variables ]---------------------------------------------------------------

    // #end region ----------------[ Variables ]------------------------------------------------------------------

    // #endregion ------------------------------------------------------------------------------------------------
    function currentData() {
        const begin = (currentPage - 1) * itemsPerPage
        const end = begin + itemsPerPage
        return data.slice(begin, end)
    }

    function next() {
        setCurrentPage(currentPage => Math.min(currentPage + 1, maxPage))
    }

    function prev() {
        setCurrentPage(currentPage => Math.max(currentPage - 1, 1))
    }

    function jump(page) {
        const pageNumber = Math.max(1, page);
        setCurrentPage(currentPage => Math.min(pageNumber, maxPage))
    }
    // #region -------------[ Functions ]-------------------------------------------------------------------------
    
    // State rendering for debugging
    useEffect(() => {
        
    }, [  ])

    // #endregion -----------------------------------------------------------------------------------------------
    // ####################### END OF SPECIFIC ##################################################################
    // [][][][][][][][][][][][][][][][][][][][][ ENDSETUP ][][][][][][][][][][][][][][][][][][][][][][][][][][][]

    // Render
    return { next, prev, jump, currentData, currentPage, maxPage}
}

export default usePagination
export const grid = {
    gem: {
        base: {
            background: "lime",
        }, 
        type1: {
            background: "#4f7cac"
        },
        type2: {
            background: "#c0e0de",
        },
        type3: {
            background: "#cc7e85",
        },
        type4: {
            background: "#cf4d6f",
        }
    },
}
export const navbar = {
    base: {
        background: "#00e6e6"
    },
    button: {
        background: "pink",
        backgroundHover: "darkred",
        backgroundActive: "darkred",
        color: "black",
        colorHover: "white",
        colorActive: "white",
        underbar: {
            params: {
                border: "thin solid #00cccc",
                borderwidth: "0em 0em 0.125em .25em"
            }
        }
    },
    drawer: {
        button: {
            background: "pink",
            backgroundHover: "violet",
            backgroundActive: "violet",
            color: "black",
            colorHover: "white",
            colorActive: "white"
        },
    },
    params: {
        height: '10px'
    }
}
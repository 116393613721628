export const typography = {
    base: {
        background: "red",
        color: "white",
        fontfamily: "Rubik"
    },
    button: {
        background: "red",
        color: "white",
        fontfamily: "Rubik"
    },
    divider1: {
        background: "#191919",
        color: "#805a5a",
    }, 
    divider2: {
        background: "#333333",
        color: "#804040",
    }, 
    divider3: {
        background: "#4d4d4d",
        color: "white",
    },
    drawer: {
        base: {
            background: "#7a0000",
            color: "white"
        },
        params: {
            fontfamily: "Rubik",
            fontsize: "20px",
            paddingbottom: "15px",
            paddingleft: "5px",
            paddingright: "5px",
            paddingtop: "15px"
        }
    },
    footer: {
        background: "black",
        color: "white",
        fontfamily: "Borel"
    }, 
    gem: {
        avatarletter: {
            background: 'red',
            backgroundHover: 'white',
            backgroundActive: 'white',
            borderradius: '5px',
            color: 'white',
            colorHover: 'black',
            colorActive: 'black',
            fontsize: '20px',
            fontweight: '700'
        },
        color: 'white',
        nougatcolor: 'white',
        faded: '#808080',
        fadedHover: 'white',
        h1: {
            fontfamily: "Rubik"
        },
        h6: {
            fontfamily: "Borel"
        }
    },
    navbar: {
        background: "red",
        color: "white",
        fontfamily: "Rubik"
    },
    para: {
        base: {
            background: "red",
            color: "white",
            fontfamily: "Rubik"
        },
    }
}